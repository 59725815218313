const trendBuilder = (arr) => {
	var lastArr = arr.slice(arr.length - 30, arr.length);
	var chartArr = arr.slice(arr.length - 30 - 60, arr.length - 60);

	var chartAvg = chartArr.reduce(function(res, val) { return res += val }) / chartArr.length;
	var lastAvg = Math.max.apply(null, lastArr);

	return lastAvg/chartAvg;
}

const calc90DayTrend = (accounts, flipValue) => {
	const endDate = new Date()

	let allDates = []
	for (let i = 0; i <= 90; i ++){
		const currentDate = new Date(new Date().setDate(endDate.getDate() - i));
		const niceCurrentDate = currentDate.getFullYear() + '-' + ('00' + (currentDate.getMonth() + 1)).slice(-2) + '-' + ('00' + (currentDate.getDate())).slice(-2)
		allDates.push(niceCurrentDate)
	}

	let datedBalances = []
	let currentBalance = null
	let allBalancesForTrend = []
	let previousDayTransactions = 0
	allDates.forEach(d => {
		let transactionsForDay = []
		if (currentBalance == null) {
			// First Date
			currentBalance = 0
			accounts.forEach(a => {
				let todaysBalance = a.balance.amount.value
				currentBalance += todaysBalance
				const transactions = a.transactions?.filter(t => t.shortDate === d)
				const transactionValue = transactions?.reduce((a, t) => a + t.amount.value, 0)
				previousDayTransactions = transactionValue
				transactions && transactionsForDay.push(...transactions)
			})
		} else {
			currentBalance -= previousDayTransactions
			accounts.forEach(a => {
				const transactions = a.transactions?.filter(t => t.shortDate === d)
				const transactionValue = transactions?.reduce((a, t) => a + t.amount.value, 0)
				previousDayTransactions = transactionValue
				transactions && transactionsForDay.push(...transactions)
			})
		}
		datedBalances.push({
			name: d,
			"Balance": currentBalance/100,
			"Positive": currentBalance > 0 ? currentBalance/100 : 0,
			"Negative": currentBalance < 0 ? currentBalance/100 : 0,
			transactions: transactionsForDay
		})
		allBalancesForTrend.push(currentBalance)
	})

	const trendDirection = (trendBuilder(allBalancesForTrend) >= 1 ? 'DECREASING' : 'INCREASING')
	const trendColor = flipValue ? trendDirection === 'INCREASING' : trendDirection !== 'INCREASING'

	return {direction: trendDirection, trendColor: trendColor, balances: datedBalances.sort((a,b) => a.name < b.name ? -1 : 1)}
}

export default calc90DayTrend;