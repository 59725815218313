import getDemoBalances from './getDemoBalances';

const getDemoData = async (successCallback) => {
	successCallback([
		{
			id: 'demoProperty',
			accountName: "17 Halifax Street",
			type: 'properties:residential',
			balance: {
				date: '2018-04-01',
				amount: {
					value: 23000000
				}
			},
			balances: [
				{
					date: '2018-04-01',
					amount: {
						value: 23000000
					}
				}
			],
			details: {
				postcode: 'GU7 3LR'
			},
			PropertySelected: true,
			hasManualInput: true
		},
		{
			id: 'demoMortgage',
			accountName: "My Mortgage",
			type: 'mortgage:repayment',
			providerName: 'Nationwide',
			balance: {
				date: '2024-07-01',
				amount: {
					value: -11607800
				}
			},
			balances: [
				{
				  "date": "2018-04-01",
				  "amount": {value:-12500000}
				},
				{
				  "date": "2018-05-01",
				  "amount": {value:-12495486}
				},
				{
				  "date": "2018-06-01",
				  "amount": {value:-12490961}
				},
				{
				  "date": "2018-07-01",
				  "amount": {value:-12486425}
				},
				{
				  "date": "2018-08-01",
				  "amount": {value:-12481878}
				},
				{
				  "date": "2018-09-01",
				  "amount": {value:-12477321}
				},
				{
				  "date": "2018-10-01",
				  "amount": {value:-12472752}
				},
				{
				  "date": "2018-11-01",
				  "amount": {value:-12468172}
				},
				{
				  "date": "2018-12-01",
				  "amount": {value:-12463581}
				},
				{
				  "date": "2019-01-01",
				  "amount": {value:-12458980}
				},
				{
				  "date": "2019-02-01",
				  "amount": {value:-12454367}
				},
				{
				  "date": "2019-03-01",
				  "amount": {value:-12449742}
				},
				{
				  "date": "2019-04-01",
				  "amount": {value:-12445107}
				},
				{
				  "date": "2019-05-01",
				  "amount": {value:-12440460}
				},
				{
				  "date": "2019-06-01",
				  "amount": {value:-12435803}
				},
				{
				  "date": "2019-07-01",
				  "amount": {value:-12431134}
				},
				{
				  "date": "2019-08-01",
				  "amount": {value:-12426453}
				},
				{
				  "date": "2019-09-01",
				  "amount": {value:-12421762}
				},
				{
				  "date": "2019-10-01",
				  "amount": {value:-12417059}
				},
				{
				  "date": "2019-11-01",
				  "amount": {value:-12412344}
				},
				{
				  "date": "2019-12-01",
				  "amount": {value:-12407619}
				},
				{
				  "date": "2020-01-01",
				  "amount": {value:-12402882}
				},
				{
				  "date": "2020-02-01",
				  "amount": {value:-12398133}
				},
				{
				  "date": "2020-03-01",
				  "amount": {value:-12393373}
				},
				{
				  "date": "2020-04-01",
				  "amount": {value:-12388601}
				},
				{
				  "date": "2020-05-01",
				  "amount": {value:-12372462}
				},
				{
				  "date": "2020-06-01",
				  "amount": {value:-12356299}
				},
				{
				  "date": "2020-07-01",
				  "amount": {value:-12340111}
				},
				{
				  "date": "2020-08-01",
				  "amount": {value:-12323899}
				},
				{
				  "date": "2020-09-01",
				  "amount": {value:-12307662}
				},
				{
				  "date": "2020-10-01",
				  "amount": {value:-12291402}
				},
				{
				  "date": "2020-11-01",
				  "amount": {value:-12275116}
				},
				{
				  "date": "2020-12-01",
				  "amount": {value:-12258807}
				},
				{
				  "date": "2021-01-01",
				  "amount": {value:-12242473}
				},
				{
				  "date": "2021-02-01",
				  "amount": {value:-12226114}
				},
				{
				  "date": "2021-03-01",
				  "amount": {value:-12209731}
				},
				{
				  "date": "2021-04-01",
				  "amount": {value:-12193324}
				},
				{
				  "date": "2021-05-01",
				  "amount": {value:-12176891}
				},
				{
				  "date": "2021-06-01",
				  "amount": {value:-12160435}
				},
				{
				  "date": "2021-07-01",
				  "amount": {value:-12143953}
				},
				{
				  "date": "2021-08-01",
				  "amount": {value:-12127447}
				},
				{
				  "date": "2021-09-01",
				  "amount": {value:-12110916}
				},
				{
				  "date": "2021-10-01",
				  "amount": {value:-12094360}
				},
				{
				  "date": "2021-11-01",
				  "amount": {value:-12077779}
				},
				{
				  "date": "2021-12-01",
				  "amount": {value:-12061174}
				},
				{
				  "date": "2022-01-01",
				  "amount": {value:-12044543}
				},
				{
				  "date": "2022-02-01",
				  "amount": {value:-12027888}
				},
				{
				  "date": "2022-03-01",
				  "amount": {value:-12011207}
				},
				{
				  "date": "2022-04-01",
				  "amount": {value:-11994502}
				},
				{
				  "date": "2022-05-01",
				  "amount": {value:-11977771}
				},
				{
				  "date": "2022-06-01",
				  "amount": {value:-11961016}
				},
				{
				  "date": "2022-07-01",
				  "amount": {value:-11944235}
				},
				{
				  "date": "2022-08-01",
				  "amount": {value:-11927429}
				},
				{
				  "date": "2022-09-01",
				  "amount": {value:-11910598}
				},
				{
				  "date": "2022-10-01",
				  "amount": {value:-11893742}
				},
				{
				  "date": "2022-11-01",
				  "amount": {value:-11876860}
				},
				{
				  "date": "2022-12-01",
				  "amount": {value:-11859953}
				},
				{
				  "date": "2023-01-01",
				  "amount": {value:-11843021}
				},
				{
				  "date": "2023-02-01",
				  "amount": {value:-11826063}
				},
				{
				  "date": "2023-03-01",
				  "amount": {value:-11809080}
				},
				{
				  "date": "2023-04-01",
				  "amount": {value:-11792072}
				},
				{
				  "date": "2023-05-01",
				  "amount": {value:-11779951}
				},
				{
				  "date": "2023-06-01",
				  "amount": {value:-11767807}
				},
				{
				  "date": "2023-07-01",
				  "amount": {value:-11755640}
				},
				{
				  "date": "2023-08-01",
				  "amount": {value:-11743449}
				},
				{
				  "date": "2023-09-01",
				  "amount": {value:-11731235}
				},
				{
				  "date": "2023-10-01",
				  "amount": {value:-11718998}
				},
				{
				  "date": "2023-11-01",
				  "amount": {value:-11706737}
				},
				{
				  "date": "2023-12-01",
				  "amount": {value:-11694453}
				},
				{
				  "date": "2024-01-01",
				  "amount": {value:-11682145}
				},
				{
				  "date": "2024-02-01",
				  "amount": {value:-11669813}
				},
				{
				  "date": "2024-03-01",
				  "amount": {value:-11657458}
				},
				{
				  "date": "2024-04-01",
				  "amount": {value:-11645080}
				},
				{
				  "date": "2024-05-01",
				  "amount": {value:-11632677}
				},
				{
				  "date": "2024-06-01",
				  "amount": {value:-11620251}
				},
				{
				  "date": "2024-07-01",
				  "amount": {value:-11607801}
				}
			   ],
			details: {
				endDate: '2048-01-04',
				term: 360,
				monthlyRepayment: 56970,
				APR: 2.3,
				fixedDate: '2027-01-04',
				interestType: 'fixed'
			},
			MortgageSelected: true,
			hasManualInput: true
		},
		{
			id: 'demoPension',
			accountName: "Employer Pension",
			providerName: "Scottish Widows",
			type: 'pension',
			balance: {
				date: new Date().toISOString().split('T')[0],
				amount: {
					value: 1395020
				}
			},
			balances: [
				{
				  "date": "2018-12-05",
				  "amount": {value:17502}
				},
				{
				  "date": "2019-01-05",
				  "amount": {value:35002}
				},
				{
				  "date": "2019-02-05",
				  "amount": {value:52505}
				},
				{
				  "date": "2019-03-05",
				  "amount": {value:70010}
				},
				{
				  "date": "2019-04-05",
				  "amount": {value:87510}
				},
				{
				  "date": "2019-05-05",
				  "amount": {value:105010}
				},
				{
				  "date": "2019-06-05",
				  "amount": {value:122515}
				},
				{
				  "date": "2019-07-05",
				  "amount": {value:140018}
				},
				{
				  "date": "2019-08-05",
				  "amount": {value:157518}
				},
				{
				  "date": "2019-09-05",
				  "amount": {value:175020}
				},
				{
				  "date": "2019-10-05",
				  "amount": {value:192519}
				},
				{
				  "date": "2019-11-05",
				  "amount": {value:210019}
				},
				{
				  "date": "2019-12-05",
				  "amount": {value:227520}
				},
				{
				  "date": "2020-01-05",
				  "amount": {value:245015}
				},
				{
				  "date": "2020-02-05",
				  "amount": {value:262510}
				},
				{
				  "date": "2020-03-05",
				  "amount": {value:280009}
				},
				{
				  "date": "2020-04-05",
				  "amount": {value:297507}
				},
				{
				  "date": "2020-05-05",
				  "amount": {value:317509}
				},
				{
				  "date": "2020-06-05",
				  "amount": {value:337507}
				},
				{
				  "date": "2020-07-05",
				  "amount": {value:357503}
				},
				{
				  "date": "2020-08-05",
				  "amount": {value:377508}
				},
				{
				  "date": "2020-09-05",
				  "amount": {value:397508}
				},
				{
				  "date": "2020-10-05",
				  "amount": {value:417509}
				},
				{
				  "date": "2020-11-05",
				  "amount": {value:437506}
				},
				{
				  "date": "2020-12-05",
				  "amount": {value:457505}
				},
				{
				  "date": "2021-01-05",
				  "amount": {value:477506}
				},
				{
				  "date": "2021-02-05",
				  "amount": {value:497503}
				},
				{
				  "date": "2021-03-05",
				  "amount": {value:517505}
				},
				{
				  "date": "2021-04-05",
				  "amount": {value:537504}
				},
				{
				  "date": "2021-05-05",
				  "amount": {value:557504}
				},
				{
				  "date": "2021-06-05",
				  "amount": {value:577502}
				},
				{
				  "date": "2021-07-05",
				  "amount": {value:597509}
				},
				{
				  "date": "2021-08-05",
				  "amount": {value:617509}
				},
				{
				  "date": "2021-09-05",
				  "amount": {value:637507}
				},
				{
				  "date": "2021-10-05",
				  "amount": {value:657510}
				},
				{
				  "date": "2021-11-05",
				  "amount": {value:677507}
				},
				{
				  "date": "2021-12-05",
				  "amount": {value:697508}
				},
				{
				  "date": "2022-01-05",
				  "amount": {value:717503}
				},
				{
				  "date": "2022-02-05",
				  "amount": {value:737500}
				},
				{
				  "date": "2022-03-05",
				  "amount": {value:760001}
				},
				{
				  "date": "2022-04-05",
				  "amount": {value:782504}
				},
				{
				  "date": "2022-05-05",
				  "amount": {value:805005}
				},
				{
				  "date": "2022-06-05",
				  "amount": {value:827506}
				},
				{
				  "date": "2022-07-05",
				  "amount": {value:850006}
				},
				{
				  "date": "2022-08-05",
				  "amount": {value:872502}
				},
				{
				  "date": "2022-09-05",
				  "amount": {value:895000}
				},
				{
				  "date": "2022-10-05",
				  "amount": {value:917500}
				},
				{
				  "date": "2022-11-05",
				  "amount": {value:940004}
				},
				{
				  "date": "2022-12-05",
				  "amount": {value:962505}
				},
				{
				  "date": "2023-01-05",
				  "amount": {value:985001}
				},
				{
				  "date": "2023-02-05",
				  "amount": {value:1007502}
				},
				{
				  "date": "2023-03-05",
				  "amount": {value:1030002}
				},
				{
				  "date": "2023-04-05",
				  "amount": {value:1052501}
				},
				{
				  "date": "2023-05-05",
				  "amount": {value:1075003}
				},
				{
				  "date": "2023-06-05",
				  "amount": {value:1097507}
				},
				{
				  "date": "2023-07-05",
				  "amount": {value:1120010}
				},
				{
				  "date": "2023-08-05",
				  "amount": {value:1142509}
				},
				{
				  "date": "2023-09-05",
				  "amount": {value:1165008}
				},
				{
				  "date": "2023-10-05",
				  "amount": {value:1187510}
				},
				{
				  "date": "2023-11-05",
				  "amount": {value:1210014}
				},
				{
				  "date": "2023-12-05",
				  "amount": {value:1232513}
				},
				{
				  "date": "2024-01-05",
				  "amount": {value:1255013}
				},
				{
				  "date": "2024-02-05",
				  "amount": {value:1277510}
				},
				{
				  "date": "2024-03-05",
				  "amount": {value:1301008}
				},
				{
				  "date": "2024-04-05",
				  "amount": {value:1324511}
				},
				{
				  "date": "2024-05-05",
				  "amount": {value:1348015}
				},
				{
				  "date": "2024-06-05",
				  "amount": {value:1371516}
				},
				{
				  "date": "2024-07-05",
				  "amount": {value:1395020}
				}
			   ],
			PensionSelected: true,
			hasManualInput: true
		},
		{
			id: 'scenarioPension',
			accountName: 'Scenario Pension',
			type: 'pension-scenario',
			PensionSelected: true,
			balance: {
				amount: {
					value: 0,
				},
				date: new Date().toISOString().split('T')[0]
			},
			balances: [
				{
					amount: {
						value: 0
					},
					date: new Date().toISOString().split('T')[0]
				}
			],
			currentValue: {
				low: 0,
				base: 0,
				high: 0
			},
			hasManualInput: true
		},
		{
			id: 'demoCashCurrent',
			accountName: 'Current Account',
			type: 'cash:current',
			BankAccountSelected: true,
			SpendingAnalysisSelected: true,
			connection: {
				friendlyName: "HSBC Bank"
			},
			balance: {
				amount: {
					value: 28571,
				},
				date: new Date().toISOString().split('T')[0]
			},
			balances: getDemoBalances('demoCashCurrent'),
			details: {},
			hasManualInput: true,
			transactionData: {
				earliestDate: addDays(new Date(), -366).toISOString()
			}
		},
		{
			id: 'demoCreditCard',
			accountName: 'Credit Card',
			type: 'card',
			BankAccountSelected: false,
			SpendingAnalysisSelected: true,
			connection: {
				friendlyName: "Barclaycard"
			},
			balance: {
				amount: {
					value: -175392,
				},
				date: new Date().toISOString().split('T')[0]
			},
			balances: getDemoBalances('demoCreditCard'),
			details: {},
			hasManualInput: true,
			transactionData: {
				earliestDate: addDays(new Date(), -366).toISOString()
			}
		},
		{
			id: 'demoSavings',
			accountName: 'Savings Account',
			type: 'savings',
			BankAccountSelected: false,
			SpendingAnalysisSelected: true,
			connection: {
				friendlyName: "Santander"
			},
			balance: {
				amount: {
					value: 228372,
				},
				date: new Date().toISOString().split('T')[0]
			},
			balances: getDemoBalances('demoSavings'),
			details: {},
			hasManualInput: true,
			transactionData: {
				earliestDate: addDays(new Date(), -366).toISOString()
			}
		}
	])
}

const addDays = (date, days) => {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}


export default getDemoData;