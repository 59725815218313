import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../Main";

import { PageTitle, AccountSelector, Tile, InteractionTile, ForecastChart, SSLogo } from '../../components/components';
import TwoColumnContentLayout from '../../layouts/TwoColumnContentLayout';
import { InfoCircle, ArrowBarLeft, ArrowBarRight, X, Check } from "react-bootstrap-icons";

import { Row, Col, Modal, Form, InputGroup, OverlayTrigger, Tooltip as TooltipRB, Button, Badge, ButtonGroup } from 'react-bootstrap';

import currencyFormatter from "../../utils/CurrencyFormatter";
import moneyHubAssetTypes from "../../utils/MoneyHubAssetTypes";
import { getSessionTokenAsync } from '../../utils/GetSessionTokenAsync';

import { setUser as setUserDataService } from '../../service/DataService';

import getStatePensionStartDate from '../../assets/model/state_pension_start_date';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from 'recharts';

import styles from '../../scss/exports.scss';
import Help from "./Help";

// TODO Update State Pension to have a NaN 0
const Pension = () => {

	const [ showStatePension, setShowStatePension ] = useState(true)

	const userContext = useContext(UserContext)
	const selected = "PensionSelected"
	const displayTypes = moneyHubAssetTypes['pensionDisplay']

	let retirementIncome = { low: 0, base: 0, high: 0 }
	let lumpSumValue = { low: 0, base: 0, high: 0 }
	let retirementValue = { low: 0, base: 0, high: 0 }

	const selectedAccounts = userContext.accounts.filter(a => a[selected] && (displayTypes.includes(a.type) || (a.type === 'pension-scenario' || a.type === 'pension-scenario:lastMonth')))

	useEffect(() => {
		userContext.setPensionSliderValues(old => {return {
			...old,
			monthlyPayment: 0
		}})
	}, [])

	selectedAccounts.forEach(a => {
		if (a.type === 'pension-scenario:lastMonth') return
		// if (a.type !== 'pension-scenario'){
			lumpSumValue.low += a.lumpSum?.low
			lumpSumValue.base += a.lumpSum?.base
			lumpSumValue.high += a.lumpSum?.high
		// }
		retirementIncome.low += a.annualRetirementPayment?.low
		retirementIncome.base += a.annualRetirementPayment?.base
		retirementIncome.high += a.annualRetirementPayment?.high
		retirementValue.low += a.retirementValue?.low
		retirementValue.base += a.retirementValue?.base
		retirementValue.high += a.retirementValue?.high
	})

	const throttle = (callback, limit) => {
		let waiting = false
		return function () {
			if (!waiting) {
				callback.apply(this, arguments)
				waiting = true
				setTimeout(() => {
					waiting = false
				}, limit)
			}
		}
	}

	const handleOnChange = (e) => {
		const {name, value} = e.target;
		userContext.setPensionSliderValues(old => {return {
			...old,
			[name]: value
		}})
	}

	const showPensionSliderSave = () => {
		return Number(userContext.pensionSliderValues.retirementAge) !== Number(userContext.user.retirementAge) ||
				Number(userContext.pensionSliderValues.lumpSumPercentage) !== Number(userContext.user.lumpSumPercentage)
	}

	const hasScenario = () => {
		return userContext.pensionSliderValues.monthlyPayment !== 0
	}

	const valueChangeBadge = (value1, value2, alwaysShow = false) => {
		if (!alwaysShow && value1 === value2) return <></>
		if (value2 <= value1) {
			return (
				<Badge className="badgePositive fw-light">
					<Row className="align-items-center text-saving-color svg-saving-color">
						<Col>
							<div>{currencyFormatter(value1 - value2, 0, true)}</div>
							<div>increase</div>
						</Col>
					</Row>
				</Badge>
			)
		} else {
			return (
				<Badge className="badgeNegative fw-light">
					<Row className="align-items-center text-negative-color svg-negative-color">
						<Col>
							<div>{currencyFormatter(value1 - value2, 0, true)}</div>
							<div>decrease</div>
						</Col>
					</Row>
				</Badge>
			)
		}
	}

	const lineChart = () => {
		let chartData = []
		let chartNames = []
		let chartDates = []
		selectedAccounts.filter(a => displayTypes.includes(a.type) || a.type === 'pension-scenario').forEach(a => {
			chartNames.push(a.accountName)
			a.valueForecast?.forEach(f => {
				chartDates.push(f.name)
			})
		})
		chartDates = [...new Set(chartDates)]
		chartDates.sort((a,b) => a > b ? 1 : -1)
		chartDates.forEach(d => {
			let emptyDataPoint = {}
			emptyDataPoint.name = d
			chartNames.forEach(n => {
				emptyDataPoint[n + '_l'] = null
				emptyDataPoint[n] = null
				emptyDataPoint[n + '_h'] = null
				emptyDataPoint[n + '_hd'] = null
			})
			emptyDataPoint.preTodayZeros = null
			emptyDataPoint.postTodayZeros = null
			chartData.push(emptyDataPoint)
		})

		selectedAccounts.filter(a => displayTypes.includes(a.type) || a.type === 'pension-scenario').forEach(a => {
			a.valueForecast?.forEach(f => {
				let data = chartData.filter(cd => cd.name === f.name)[0]
				data[a.accountName + '_l'] = Math.floor(f.low)
				data[a.accountName] = Math.floor(f.base)
				data[a.accountName + '_h'] = Math.floor(f.high)
				data[a.accountName + '_hd'] = Math.floor(f.highDiff)
			})
		})
		let previousData = {}
		let zerosStart = false
		const today = new Date()
		let oneYearBack = new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate())
		chartData.forEach(cd => {
			chartNames.forEach(n => {
				if (!cd[n + '_l']) cd[n + '_l'] = previousData[n + '_l']
				if (!cd[n]) cd[n] = previousData[n]
				if (!cd[n + '_h']) cd[n + '_h'] = previousData[n + '_h']
				if (!cd[n + '_hd']) cd[n + '_hd'] = previousData[n + '_hd']
				previousData[n + '_l'] = cd[n + '_l']
				previousData[n] = cd[n]
				previousData[n + '_h'] = cd[n + '_h']
				previousData[n + '_hb'] = cd[n + '_hb']
				if (cd[n] !== undefined) zerosStart = true
			})
			if (zerosStart && cd.name <= today) cd.preTodayZeros = 0
			if (zerosStart && cd.name >= oneYearBack) cd.postTodayZeros = 0
		})

		let dataConfig = {}
		dataConfig.chartNames = chartNames
		dataConfig.dataSplit = [[], []]
		selectedAccounts.filter(a => displayTypes.includes(a.type)).forEach(a => {
			dataConfig.dataSplit[0].push(a.accountName)
			hasScenario() && dataConfig.dataSplit[1].push(a.accountName)
		})
		selectedAccounts.filter(a => a.type === 'pension-scenario').forEach(a => {
			hasScenario() && dataConfig.dataSplit[1].push(a.accountName)
		})

		dataConfig.renderTooltip = ({ active, label, payload, displayConfig }) => {
			const tooltipContents = () => {
				const isScenario = hasScenario()
				let tooltipValue = { low: 0, base: 0, high: 0 }

				displayConfig.dataSplit[isScenario ? 1 : 0].forEach((n,i) => {
					let low = payload.filter(p => p.dataKey === n + '_l')
					let base = payload.filter(p => p.dataKey === n)
					let high = payload.filter(p => p.dataKey === n + '_h')
					if (low.length > 0) tooltipValue.low += low[0].value
					if (base.length > 0) tooltipValue.base += base[0].value
					if (high.length > 0) tooltipValue.high += high[0].value
				})
				tooltipValue.low = Math.max(tooltipValue.low, 0)
				tooltipValue.base = Math.max(tooltipValue.base, 0)
				tooltipValue.high = Math.max(tooltipValue.high, 0)
				let birthYear = new Date(userContext.user.dateOfBirth).toISOString().split('-')[0]
				let tickYear = label != null && new Date(label).toISOString().split('-')[0]

				return (
					<>
						{label && <h6 className="text-start"><span className="text-muted">Age:</span> {(Number(tickYear) - Number(birthYear))}</h6>}
						<div className="fw-bold fs-6 d-flex justify-content-between text-muted">
							{isScenario ? 'New ' : ''}Pension Forecast
						</div>
						<div className="fs-6 d-flex justify-content-between">
							<div>
								High est.&nbsp;
							</div>
							<div className="text-end mr-auto">
								{currencyFormatter(tooltipValue.high, 0, true)}
							</div>
						</div>
						<div style={{color: isScenario ? styles['positive-0'] : styles['asset-0']}} className="fw-bold fs-6 d-flex justify-content-between">
							<div>
								Medium est.&nbsp;
							</div>
							<div className="text-end mr-auto">
								{currencyFormatter(tooltipValue.base, 0, true)}
							</div>
						</div>
						<div className="fs-6 d-flex justify-content-between">
							<div>
								Low est.&nbsp;
							</div>
							<div className="text-end mr-auto">
								{currencyFormatter(tooltipValue.low, 0, true)}
							</div>
						</div>
					</>
				)
			}
			return(
				active &&
						<InteractionTile body={tooltipContents()} padding="p-1" bottomMargin="" />
			)
		}

		return (
			<>
				<h5 className="text-center">Your Pension Pot Forecast</h5>
				<div className="mx-0" style={{"height":"300px"}}>
					<ForecastChart
							xAxisType="age"
							dateOfBirth={userContext.user.dateOfBirth}
							data1={chartData}
							displayConfig={dataConfig}
							minZero={true}
							retirementDate={new Date(new Date(userContext.user.dateOfBirth).setFullYear(new Date(userContext.user.dateOfBirth).getFullYear() + Number(userContext.pensionSliderValues.retirementAge)))}
						/>
				</div>
			</>
		)
	}

	const HelpPopover = () => {
		const [ showHelpPopover, setShowHelpPopover ] = useState(false)
		return (
			<>
			<Row role="button" className="text-muted fs-6 pe-auto flex-row-reverse" style={{marginRight: "0.25rem", textDecoration:"underline", fontWeight: "300"}} onClick={()=>{setShowHelpPopover(true); return false}}>
				How is my pension forecast calculated?
			</Row>
			<hr className="mt-0" />
			<Modal centered show={showHelpPopover}>
				<Modal.Header closeButton onHide={() => setShowHelpPopover(false)} className="text-muted">
					<h4>How is my pension forecast calculated?</h4>
				</Modal.Header>
				<Modal.Body className="text-muted">
					<p>
						The pension forecast provides an indication of what your pension could look like at your chosen retirement age.
					</p>
					<p>
						The Retirement Income Forecast & Pension Pot Forecast is an estimation of what your future income could look like at retirement. All income are calculated before tax.
					</p>
					<p>
						Our assumptions as based derived from the 'Statutory Money Purchase Illustrations' which is updated regularly.
					</p>
					<p className="fs-6">
						This is not a guarantee of future value or income that can be drawn from your pensions at retirement age.These assumptions may not accurately reflect your actual investment portfolio or portfolio risk level. The information we show should not be interpreted as financial advice. Users should seek professional independent financial advice before making changes to their financial situation. Please refer to the 'Prift End User Terms' for further information.
					</p>
				</Modal.Body>
			</Modal>
			</>
		)
	}

	const PensionDetails = () => {

		const [ statePensionAmount, setStatePensionAmount ] = useState(userContext.user.statePensionAmount)
		const [ monthlyContributionPayment, setMonthlyContributionPayment ] = useState(userContext.user.currentMonthlyPayment)
		const [ isWorking, setIsWorking ] = useState(false)

		const renderEstRetirementIncomeHelp = (props) => (
			<TooltipRB id="button-tooltip" {...props} animation="false">
				<div>Pre-tax median estimated income in retirement, from pension pots (excluding state pension).</div>
				<div>Low & high estimates.</div>
			</TooltipRB>
		)
		const renderEstLumpSumValueHelp = (props) => (
			<TooltipRB id="button-tooltip" {...props} animation="false">
				<div>Pre-tax median lump sum taken at retirement age from pension pots.</div>
				<div>Low & high estimates.</div>
			</TooltipRB>
		)

		const timeToRetire = () => {
			const today = new Date()
			const retireDate = new Date(new Date(userContext.user.dateOfBirth).setFullYear(new Date(userContext.user.dateOfBirth).getFullYear() + Number(userContext.user.retirementAge)))
			let todaySplits = today.toISOString().split('T')[0].split('-').map(v => Number(v))
			let retireSplits = retireDate.toISOString().split('T')[0].split('-').map(v => Number(v))
			if (retireSplits[1] < todaySplits[1]){
				retireSplits[0] += 1
				retireSplits[1] += 12
			}
			const months = retireSplits[1] - todaySplits[1]
			const years = retireSplits[0] - todaySplits[0]
			return (<>{years + " year" + (years !== 1 ? "s" : "") + " " + months + " month" + (months !== 1 ? "s" : "")}</>)
		}

		const saveStatePension = async () => {
			setIsWorking(true)
			const currentSession = await getSessionTokenAsync()
			let userInfo = {}

			userInfo.statePensionAmount = statePensionAmount
			await setUserDataService(currentSession, userInfo)
			userContext.setUser({...userContext.user, statePensionAmount: statePensionAmount})
			setIsWorking(false)
		}

		const saveMonthlyContributionPension = async () => {
			setIsWorking(true)
			const currentSession = await getSessionTokenAsync()
			let userInfo = {}

			userInfo.currentMonthlyPayment = monthlyContributionPayment
			await setUserDataService(currentSession, userInfo)
			userContext.setUser({...userContext.user, currentMonthlyPayment: monthlyContributionPayment})
			setIsWorking(false)
		}

		const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

		const MonthlyContributionHelpPopover = () => {
			const [ showHelpPopover, setShowHelpPopover ] = useState(false)
			return (
				<>
				<div role="button" className="text-muted fs-6 pe-auto flex-row-reverse" style={{marginRight: "0.25rem", textDecoration:"underline", fontWeight: "300"}} onClick={()=>{setShowHelpPopover(true); return false}}>
					What is this?
				</div>
				<Modal centered show={showHelpPopover}>
					<Modal.Header closeButton onHide={() => setShowHelpPopover(false)} className="text-muted">
						<h4>Monthly Pension Contribution</h4>
					</Modal.Header>
					<Modal.Body className="text-muted">
						<p>
							This is some help text to tell you how to use this section
						</p>
						<p className="fs-6">
							Some small text at the bottom
						</p>
					</Modal.Body>
				</Modal>
				</>
			)
		}

		return (
			<Tile body={<>
					<div>
						<Row>
							<Col xs={9}>
								<h5 className="text-dark">Pension Details</h5>
							</Col>
							<Col xs={3} className="text-center fs-6">
								Change from last month
							</Col>
						</Row>
						<Row className="align-items-center my-3">
							<Col sm={4} className="">
								Retirement Est. Annual Income:
							</Col>
							<Col xs={9} sm={5} className="text-asset-color">
								<Row className="g-1">
									<Col xs={2}></Col>
									<Col xs={8} className="text-center">
										<div className="d-inline-flex">
											{currencyFormatter(userContext.pensionStaticTotals.retirementIncome?.base, 0 ,true)}
										</div>
									</Col>
									<Col xs={2}>
										<OverlayTrigger
													placement="auto"
													delay={{ show: 250, hide: 400 }}
													overlay={renderEstRetirementIncomeHelp}>
												<InfoCircle style={{fontSize: "0.75rem"}} />
											</OverlayTrigger>
									</Col>
								</Row>
								<Row className="g-1 fs-6">
									<Col xs={4} className="text-end">
										{currencyFormatter(userContext.pensionStaticTotals.retirementIncome?.low, 0 ,true)}
									</Col>
									<Col xs={2}>
										<ArrowBarRight />
									</Col>
									<Col xs={2} className="text-end">
										<ArrowBarLeft />
									</Col>
									<Col xs={4} className="text-start">
										{currencyFormatter(userContext.pensionStaticTotals.retirementIncome?.high, 0 ,true)}
									</Col>
								</Row>
							</Col>
							<Col xs={3} className="text-center">
								{valueChangeBadge(userContext.pensionStaticTotals.retirementIncome?.base, userContext.pensionStaticTotals.lastMonthRetirementIncome?.base)}
							</Col>
						</Row>
						<Row className="align-items-center my-3">
							<Col sm={4} className="">
								Retirement Est. Lump Sum:
							</Col>
							<Col xs={9} sm={5} className="text-asset-color">
								<Row className="g-1">
									<Col xs={2}></Col>
									<Col xs={8} className="text-center">
										<div className="d-inline-flex">
											{currencyFormatter(userContext.pensionStaticTotals.lumpSumValue?.base, 0 ,true)}
										</div>
									</Col>
									<Col xs={2}>
										<OverlayTrigger
													placement="auto"
													delay={{ show: 250, hide: 400 }}
													overlay={renderEstLumpSumValueHelp}>
												<InfoCircle style={{fontSize: "0.75rem"}} />
											</OverlayTrigger>
									</Col>
								</Row>
								<Row className="g-1 fs-6">
									<Col xs={4} className="text-end">
										{currencyFormatter(userContext.pensionStaticTotals.lumpSumValue?.low, 0 ,true)}
									</Col>
									<Col xs={2}>
										<ArrowBarRight />
									</Col>
									<Col xs={2} className="text-end">
										<ArrowBarLeft />
									</Col>
									<Col xs={4} className="text-start">
										{currencyFormatter(userContext.pensionStaticTotals.lumpSumValue?.high, 0 ,true)}
									</Col>
								</Row>
							</Col>
							<Col xs={3} className="text-center">
								{valueChangeBadge(userContext.pensionStaticTotals.lumpSumValue?.base, userContext.pensionStaticTotals.lastMonthLumpSumValue?.base)}
							</Col>
						</Row>
						<Row className="align-items-end">
							<Col sm={4} className="">
								Retirement Age:
							</Col>
							<Col sm={5} className="text-center">
								{userContext.user.retirementAge}
							</Col>
						</Row>
						<Row>
							<Col sm={4} className="d-none d-sm-block fs-6 mb-3">
								Remaining years & months:
							</Col>
							<Col sm={5} className="text-center fs-6">
								{timeToRetire()}
							</Col>
							<Col className="d-sm-none text-center mb-3 fs-6">
								(remaining years & months)
							</Col>
						</Row>
						<InteractionTile padding="p-2" bottomMargin="mb-4" title="Current Monthly Contribution" collapsible body={
							<Row className="align-items-center">
								<Col sm={4} className="">
									<div>
										Current Monthly Contribution
									</div>
								</Col>
								<Col xs={6} sm={5} className="px-3" style={{position: "relative"}}>
									<Form id="monthlyContributionPensionInputForm">
										<InputGroup>
											<InputGroup.Text>£</InputGroup.Text>
											<Form.Control
													className="bg-white text-end"
													name="monthlyContribution"
													id="monthlyContributionInput"
													value={(Math.floor(Number(monthlyContributionPayment) / 100) || 0).toLocaleString('en-GB')}
													onChange={(e) => {setMonthlyContributionPayment(e.target.value.replace(/\D/g,'') * 100)}} >
												</Form.Control>
										</InputGroup>
									</Form>
									{document.getElementById('monthlyContributionInput')?.checkValidity() && userContext.user.currentMonthlyPayment !== monthlyContributionPayment && <Row style={{position: "absolute", right: "2rem"}} className="g-0 justify-content-md-center">
										<Col xs={6}>
											<ButtonGroup className="">
												<Button
														disabled={isWorking}
														variant="danger"
														size="sm"
														onClick={() => {setMonthlyContributionPayment(userContext.user.currentMonthlyPayment)}}>
													<X />
												</Button>
												<Button
														disabled={isWorking}
														variant="primary"
														size="sm"
														onClick={() => {saveMonthlyContributionPension()}}>
													<Check />
												</Button>
											</ButtonGroup>
										</Col>
									</Row>}
								</Col>
								<Col xs={6} sm={3}  className="fs-6 d-flex align-items-center">
									<MonthlyContributionHelpPopover />
								</Col>
							</Row>
						} />
						<InteractionTile padding="p-2"  title="State Pension" collapsible body={
							<Row className="align-items-center">
								<Col sm={4} className="">
									<div >
										UK State Pension Forecast:
									</div>
									<div className="fs-6">
										(From age {userContext.user.statePensionAge})
									</div>
								</Col>
								<Col xs={6} sm={5} className="px-3" style={{position: "relative"}}>
									<Form id="statePensionInputForm">
										<InputGroup>
											<InputGroup.Text>£</InputGroup.Text>
											<Form.Control
													className="bg-white text-end"
													name="monthlyPayment"
													id="statePensionInput"
													value={Math.floor(statePensionAmount / 100).toLocaleString('en-GB')}
													onChange={(e) => {setStatePensionAmount(clamp((e.target.value.replace(/\D/g,'')), 0, 11502) * 100)}} >
												</Form.Control>
										</InputGroup>
									</Form>
									{document.getElementById('statePensionInputForm')?.checkValidity() && userContext.user.statePensionAmount !== statePensionAmount && <Row style={{position: "absolute", right: "2rem"}} className="g-0 justify-content-md-center">
										<Col xs={6}>
											<ButtonGroup className="">
												<Button
														disabled={isWorking}
														variant="danger"
														size="sm"
														onClick={() => {setStatePensionAmount(userContext.user.statePensionAmount)}}>
													<X />
												</Button>
												<Button
														disabled={isWorking}
														variant="primary"
														size="sm"
														onClick={() => {saveStatePension()}}>
													<Check />
												</Button>
											</ButtonGroup>
										</Col>
									</Row>}
								</Col>
								<Col xs={6} sm={3}  className="text-center fs-6 d-flex align-items-center">
									<Form.Check
											type="switch"
											aria-label="Include State Pension"
											defaultChecked={showStatePension}
											onChange={(e) => {setShowStatePension(e.target.checked)}} />
									Include UK State Pension
								</Col>
							</Row>} />
					</div>
				</>} />
		)
	}

	const pensionRetirementValue = () => (
		<Tile body={<>
			<h5 className="text-dark text-center">Estimated Value of Pension Pots at Retirement</h5>
			<Col className="text-asset-color">
				<Row className="g-1">
					<Col xs={4}></Col>
					<Col xs={4} className="text-center">
						<div className="d-inline-flex">
							{currencyFormatter(retirementValue.base, 0, true)}
						</div>
					</Col>
					<Col xs={4}>
					</Col>
				</Row>
				<Row className="g-1 fs-6">
					<Col xs={4} className="text-end">
						{currencyFormatter(retirementValue.low, 0, true)}
					</Col>
					<Col xs={2}>
						<ArrowBarRight />
					</Col>
					<Col xs={2} className="text-end">
						<ArrowBarLeft />
					</Col>
					<Col xs={4} className="text-start">
						{currencyFormatter(retirementValue.high, 0, true)}
					</Col>
				</Row>
			</Col>
		</>} />

	)

	const pensionSliders = () => {
		const shopStPotentialSavingAverage = () => {
			let total = 0
			userContext.accounts.forEach(a => {
				a.shopStAverageSaving && (total += a.shopStAverageSaving)
			})
			return total
		}

		const renderLumpSumHelp = (props) => (
			<TooltipRB id="button-tooltip" {...props} animation="false">
				Lump Sum is limited to 25% of amounts accumulated in pensions. This is limited to a maximum of 25% of your available lifetime allowance. For most individuals, the standard lifetime allowance applies. This is currently £1,073,100 as at 1 April 2024.
			</TooltipRB>
		)

		const scenarioChange = (e) => {
			let value = 0
			var checkBoxes = document.getElementsByName('savingSelector');
			for (let i = 0; i < checkBoxes.length; i++) {
				if (checkBoxes[i].checked){
					if (checkBoxes[i].id === 'customAmount'){
						let t = document.getElementById('customAmountInput')
						value += Number(t.value) * 100 * 1.2 // Convert to pence and increase by 1.2 for tax
					}
					if (checkBoxes[i].id === 'shopStAmount'){
						value += shopStPotentialSavingAverage() * 1.2
					}
				}
			}
			userContext.setPensionSliderValues(old => {return {
				...old,
				monthlyPayment: value
			}})
		}

		const savePensionSliders = async () => {
			const currentSession = await getSessionTokenAsync()
			let userInfo = {}

			userInfo.lumpSumPercentage = Number(userContext.pensionSliderValues.lumpSumPercentage)
			userInfo.retirementAge = Number(userContext.pensionSliderValues.retirementAge)
			await setUserDataService(currentSession, userInfo)
			userContext.setPensionStaticTotals({
				retirementIncome: null,
				lumpSumValue: null,
			})
			userContext.setUser(oldValue => ({
				...oldValue,
				lumpSumPercentage: userInfo.lumpSumPercentage,
				retirementAge: userInfo.retirementAge
			}))
			userContext.setUpdateStatic(true)
		}

		const retirementPlannerBody = () => {
			return (
				<>
					<h5 className="text-dark text-center">Retirement Planner</h5>
					<div className="fs-5">
					<Row className="">
						<Col xs={10}>
							Retirement Age
						</Col>
						<Col xs={2} className="text-end">
							{userContext.pensionSliderValues.retirementAge}
						</Col>
					</Row>
					<Row className="fs-6 text-muted">
						<Col xs={12}>
							(between 55 and 75)
						</Col>
					</Row>
					<InputGroup className="mb-3 fs-6">
						<InputGroup.Text className="fs-6">55</InputGroup.Text>
						<Form.Control
								id="retirementAgeSlider"
								className="bg-white fs-6"
								type="range"
								min="55"
								max="75"
								name="retirementAge"
								defaultValue={userContext.pensionSliderValues.retirementAge}
								onChange={throttle(handleOnChange,1)} />
						<InputGroup.Text className="fs-6">75</InputGroup.Text>
					</InputGroup>
					<Row className="">
						<Col xs={10}>
							<Form.Label className="d-inline" style={{marginRight: '0.5rem'}}>Lump Sum Percentage</Form.Label>
							<OverlayTrigger
									placement="right"
									delay={{ show: 250, hide: 400 }}
									overlay={renderLumpSumHelp}>
								<InfoCircle style={{fontSize: "0.75rem"}} />
							</OverlayTrigger>
						</Col>
						<Col xs={2} className="text-end">
							{userContext.pensionSliderValues.lumpSumPercentage}%
						</Col>
					</Row>
					<Row className="fs-6 text-muted">
						<Col xs={12}>
							(Max 25%, tax-free at retirement age)
						</Col>
					</Row>
					<InputGroup className="mb-3">
						<InputGroup.Text className="fs-6">0%</InputGroup.Text>
						<Form.Control
								id="lumpSumPercentageSlider"
								className="bg-white fs-6"
								type="range"
								min="0"
								max="25"
								name="lumpSumPercentage"
								defaultValue={userContext.pensionSliderValues.lumpSumPercentage}
								onChange={throttle(handleOnChange,1)} />
						<InputGroup.Text className="fs-6">25%</InputGroup.Text>
					</InputGroup>

					{showPensionSliderSave() &&
						<Row>
							<Col className="text-center fs-5">
								<Button onClick={savePensionSliders} className="fs-6">Save Changes</Button>
							</Col>
						</Row>
					}

					<hr />

					{/* TODO: Make logo veritcally centered */}
					<Row className="fs-5">
						<Row className="">
							<div>Increase Monthly Contributions By:</div>
						</Row>
						<Col xs={12} className="align-items-center">
							<InputGroup className="mb-3">
								<InputGroup.Checkbox
										className="fs-6"
										aria-label="Select Custom Saving Amount"
										name="savingSelector"
										id="customAmount"
										onClick={scenarioChange} />
								<InputGroup.Text className="fs-6">£</InputGroup.Text>
								<Form.Control
										className="bg-white fs-6"
										name="monthlyPayment"
										id="customAmountInput"
										onChange={scenarioChange} >
									</Form.Control>
							</InputGroup>
						</Col>
						<Col xs={12} className="align-items-center">
							<InputGroup className="mb-3">
								<InputGroup.Checkbox
										className="fs-6"
										aria-label="Select Shop St Saving"
										name="savingSelector"
										id="shopStAmount"
										onClick={scenarioChange} />
								<InputGroup.Text className="form-control d-block fs-6 text-muted">
									<div style={{marginTop: "10px"}}>
										<SSLogo size="22" />
										<div className="">Saving Potential</div>
									</div>
								</InputGroup.Text>
								<InputGroup.Text className="fs-6">{currencyFormatter(shopStPotentialSavingAverage())}</InputGroup.Text>
							</InputGroup>
						</Col>
					</Row>
					</div>
				</>
			)
		}

		return (
			<>
				<InteractionTile
						body={retirementPlannerBody()} />
			</>
		)
	}

	const barChart = () => {

		const getChartData = () => {
			let data = []
			let ages = Array.apply(null, Array(90 - 55 + 1)).map((i,j) => (j + 55));
			let statePensionStartDate = getStatePensionStartDate(new Date(userContext.user.dateOfBirth))
			let statePensionStartAge = statePensionStartDate.getFullYear() - new Date(userContext.user.dateOfBirth).getFullYear()
			let statePensionAmount = userContext.user.statePensionAmount

			let scenarioIncome = null
			selectedAccounts.forEach(a => {
				if (a.type === 'pension-scenario'){
					if (a.annualRetirementPayment == null || a.annualRetirementPayment.base === 0) return
					if (scenarioIncome === null) scenarioIncome = 0
					scenarioIncome += a.annualRetirementPayment.base
				}
			})

			ages.forEach(a => {
				let dataPoint = {
					name: a,
					"Lump Sum": a === Number(userContext.pensionSliderValues.retirementAge) ? lumpSumValue.base : null,
					"Private Pensions": a >= Number(userContext.pensionSliderValues.retirementAge) ? retirementIncome.base : null,
					"State Pension": showStatePension && a >= statePensionStartAge ? statePensionAmount : null,
					"Additional Contributions": a >= Number(userContext.pensionSliderValues.retirementAge) ? scenarioIncome: null
				}
				data.push(dataPoint)
			})
			return data
		}

		const RenderTooltip = ({ active, label, payload }) => {
			const preferredOrder = ['Lump Sum', 'Additional Contributions', 'Private Pensions', 'State Pension']
			payload.sort((a,b) => {
				if (preferredOrder.indexOf(a.name) < preferredOrder.indexOf(b.name)) {
					return -1
				} else {
					return 1
				}
			})
			const tooltipContents = () => {
				let totalValue = 0
				payload.forEach(p => {
					totalValue += p.value
				})
				return (
					<>
					{label && <h6 className="text-start"><span className="text-muted">Age:</span> {label}</h6>}
					<div className="fw-bold fs-6 d-flex justify-content-between text-muted">
						<div>
							Estimated Income:&nbsp;
						</div>
						<div className="text-end mr-auto">
							{currencyFormatter(totalValue, 0, true)}
						</div>
					</div>
					<div className="fs-6 d-flex justify-content-between text-muted">
						<div>
							Comprised of:&nbsp;
						</div>
					</div>
					{payload.map((p, index) => {
						const entry = p;
						return (
							entry && entry.value !== 0 &&
								(<div key={'tooltipItem' + index}>
									<div style={{color: entry.name === 'Additional Contributions' ? entry.stroke : entry.fill}} className="fs-6 d-flex justify-content-between">
										<div>
											{entry.name}:&nbsp;
										</div>
										<div className="text-end mr-auto">
											{currencyFormatter(entry.value, 0, true)}
										</div>
									</div>
									{/* <Row style={{color: entry.fill}} className="align-items-center fs-6">
										<Col xs={7}>
											{entry.name}:
										</Col>
										<Col xs={5} className="text-end">
											<span>{currencyFormatter(entry.value, 0, true)}</span>
										</Col>
									</Row> */}
								</div>)
						)
					})}
					</>
				)
			}
			return(
				active &&
						<InteractionTile padding="p-2" body={tooltipContents()} />
			)
		}


		return (
			<>
				<h5 className="text-dark text-center">Your Retirement Income Forecast</h5>
				{(true || hasScenario()) &&
					<InteractionTile padding="p-2" extraClass="borderDashed" body={<>
							<Row className="text-center text-muted mb-2 fs-5"><Col>Est. Planner Changes</Col></Row>
							<Row className="text-center">
								<Col xs={6}>
									<Row className="align-items-center">
										<Col xs={4} className="text-end text-muted">
											Annual Income:
										</Col>
										<Col xs={8} className="text-center text-asset-color">
											{currencyFormatter(retirementIncome.base, 0, true)}
										</Col>
									</Row>
									<Row className="text-center">
										<Col xs={{span: 8, offset: 4}}>
											{valueChangeBadge(retirementIncome.base, userContext.pensionStaticTotals.retirementIncome?.base, true)}
										</Col>
									</Row>
								</Col>
								<Col xs={6}>
									<Row className="align-items-center">
										<Col xs={4} className="text-end text-muted">
											Lump<br/>Sum:
										</Col>
										<Col xs={8} className="text-center text-asset-color">
											{currencyFormatter(lumpSumValue.base, 0, true)}
										</Col>
									</Row>
									<Row className="text-center">
										<Col xs={{span: 8, offset: 4}}>
											{valueChangeBadge(lumpSumValue.base, userContext.pensionStaticTotals.lumpSumValue?.base, true)}
										</Col>
									</Row>
								</Col>
							</Row>
							</>}
						 />
				}
				<div className="mx-0 mb-5" style={{"height":"300px"}}>
					<ResponsiveContainer className="border soft-corners" width="100%" height="100%">
						<BarChart
								width={500}
								height={300}
								data={getChartData()}
								margin={{
									top: 0,
									right: 5,
									left: 0,
									bottom: 5,
								}}
								barCategoryGap={4}>
							<CartesianGrid strokeDasharray="1 0" vertical={false} stroke="#E5E9F2" />
							<XAxis dataKey="name" tickLine={false} axisLine={false}
									tickFormatter={(value,i) => {return value}}
									domain={['dataMin', 'dataMax']}
									className="fs-6 text-muted"
									interval="0" >
								<Label value="Age" offset={0} position="insideBottom" />
							</XAxis>
							<YAxis
									dx={-5}
									padding={{ top: 25, bottom: 0}}
									tickFormatter={value => currencyFormatter(value)}
									tickLine={false}
									axisLine={false}
									width={70}
									allowDataOverflow
									domain={[0,"auto"]}
									className="fs-6 text-muted"
									isAnimationActive={true} />
							<Tooltip cursor={false} content={<RenderTooltip />} />
							<Bar dataKey="State Pension" stackId="a" fill="#a57df8" stroke="#36157C" isAnimationActive={true} />
							<Bar dataKey="Private Pensions" stackId="a" fill="#558eff" stroke="#003980" isAnimationActive={true}/>
							<Bar dataKey="Additional Contributions" stackId="a" fill="#AFEBE9" stroke="#3F9FA0" isAnimationActive={true}/>
							<Bar dataKey="Lump Sum" stackId="a" fill="#9bbaf9" stroke="#0052F5" isAnimationActive={true}/>
						</BarChart>
					</ResponsiveContainer>
				</div>
			</>
		)
	}

	return (
		<>
			<PageTitle pageName="Pensions" helpPage="pension">
				<TwoColumnContentLayout
						left={<AccountSelector title="Pension Pots" displayTypes={displayTypes} selected={selected} showDonut editButtonText="Edit Pensions" />}
						right={<span className="text-muted">{<PensionDetails />}</span>} />

				<HelpPopover />

				<TwoColumnContentLayout
						left={pensionSliders()}
						right={barChart()} />

				<TwoColumnContentLayout alignItemsCenter
						left={pensionRetirementValue()}
						right={lineChart()} />
			</PageTitle>
		</>
	)
}

export default Pension;