import React, { useContext, useEffect, useState } from "react";
import { updateUserAttributes, confirmUserAttribute } from 'aws-amplify/auth';
import { UserContext } from "../Main";

import { PageTitle, InteractionTile } from '../../components/components';

import { getSessionTokenAsync } from '../../utils/GetSessionTokenAsync';
import { setUser as setUserDataService, setAccount, getAccounts } from '../../service/DataService';

import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import TermsOfBusiness from "../../assets/content/terms-of-business";

const MyAccount = () => {
	const userContext = useContext(UserContext);

	const [ emailVerificationNeeded, setEmailVerificationNeeded ] = useState(false)
	const [ emailUpdateCode, setEmailUpdateCode ] = useState('')
	const [ hasChange, setHasChange ] = useState(false)

	const [isSavingUser, setIsSavingUser] = useState(false)
	const [ showUserTerms, setShowUserTerms ] = useState(false)
	const [ showDeleteAccount, setShowDeleteAccount ] = useState(false)
	const [ deleteConfirmText, setDeleteConfirmText ] = useState(false)

	const [ oldUserEmail ] = useState(userContext.user.email)

	const [userDetails, setUserDetails] = useState({
		firstName: '',
		lastName: '',
		email: ''
	});
	const handleOnChange = (e) => {
		let {name, value} = e.target
		if (name === 'dateOfBirth') {
			if (value == null || value === '') return
			value = new Date(value)
		}
		setUserDetails({
			...userDetails,
			[name]: value
		})
		setHasChange(true)
	}
	const updateEmailUpdateCode = (e) => {
		const {value} = e.target
		setEmailUpdateCode(value)
	}

	useEffect(() => {
		setUserDetails(userContext.user)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(userContext.user)])
	useEffect(() => {
		setDeleteConfirmText('')
	}, [showDeleteAccount])

	const handleSubmit = async () => {
		setIsSavingUser(true)
		let verificationNeeded = await updateUserEmail()
		if (verificationNeeded) {
			setEmailVerificationNeeded(true)
		} else {
			saveUserOptions()
		}
	}

	const updateUserEmail = async () => {
		try {
			const attributes = await updateUserAttributes({
				userAttributes: {
					email: userDetails.email
				}
			})
			return handleUpdateUserAttributeNextSteps(attributes.email)
		} catch (error){
			console.error(error)
		}
	}

	const handleUpdateUserAttributeNextSteps = (output) => {
		const { nextStep } = output
		switch(nextStep.updateAttributeStep){
			case 'CONFIRM_ATTRIBUTE_WITH_CODE':
				setEmailVerificationNeeded(true)
				return true
			case 'DONE':
			default:
		}
		setEmailVerificationNeeded(false)
		return false
	}

	const [ verificationError, setVerificationError ] = useState('')
	const handleConfirmUserEmail = async () => {
		const userAttributeKey = 'email'
		const confirmationCode = emailUpdateCode
		try{
			const error = await confirmUserAttribute({ userAttributeKey: userAttributeKey, confirmationCode: confirmationCode})
			if (error){
				console.error(error)
			} else {
				await saveUserOptions()
			}
		} catch (error){
			console.error(error)
			setVerificationError('Invalid verification code')
		}
	}

	const saveUserOptions = async () => {
		const currentSession = await getSessionTokenAsync()
		let userInfo = {}

		userInfo.firstName = userDetails.firstName
		userInfo.lastName = userDetails.lastName
		userInfo.email = userDetails.email
		userInfo.dateOfBirth = userDetails.dateOfBirth
		userInfo.retirementAge = Number(userDetails.retirementAge)
		userInfo.oldEmail = oldUserEmail

		await setUserDataService(currentSession, userInfo)
		setEmailVerificationNeeded(false)

		setTimeout(() => {
			setIsSavingUser(false)
			userContext.setLoadingState(oldStage => ({
				...oldStage,
				user: false,
			}))
		}, 1500)
	}

	const saveUserAccounts = async () => {
		let fields = [
			'id',
			'dateAdded',
			'dateModified',
			'accountName',
			'type',
			'accountType',
			'providerName',
			'providerId',
			'providerReference',
			'connectionId',
			'balance',
			'additionalBalances',
			'currency',
			'details',
			'performanceScore',
			'hasManualInput',
			'connection',
			'productName',
			'accountOpeningDate',
			'accountReference',
			'providerAccountName',
			'providerAccountId',
			'transactionData',
		]

		const currentSession = await getSessionTokenAsync()
		let accountInfo = {}

		let accounts = []
		let transactions = []
		let balances = []
		userContext.accounts.forEach(a => {
			let aClone = {}
			fields.forEach(f => {
				aClone[f] = a[f]
			})
			if (!a.type.includes('-')) {
				accounts.push(aClone)
				a.transactions && transactions.push(...a.transactions)
				a.balances.forEach(b => {
					let bClone = {...b}
					bClone.accountId = a.id
					balances.push(bClone)
				})
			}
		})

		accountInfo.accounts = accounts
		accountInfo.transactions = transactions
		accountInfo.balances = balances

		await setAccount(currentSession, accountInfo)
	}

	const getUserAccounts = async () => {
		const currentSession = await getSessionTokenAsync()
		await getAccounts(currentSession)
	}

	const body = () => {
		return (
			<Form>
				<Form.Group
						as={Row}
						className="mb-3 align-items-center"
						controlId="firstName">
					<Col sm={4}>
						<Form.Label>
							First Name
						</Form.Label>
					</Col>
					<Col sm={8}>
						<Form.Control
								className="bg-white"
								placeholder=""
								name="firstName"
								onChange={handleOnChange}
								defaultValue={userDetails.firstName} />
					</Col>
				</Form.Group>
				<Form.Group
						as={Row}
						className="mb-3 align-items-center"
						controlId="lastName">
					<Col sm={4}>
						<Form.Label>
							Last Name
						</Form.Label>
					</Col>
					<Col sm={8}>
						<Form.Control
								className="bg-white"
								placeholder=""
								name="lastName"
								onChange={handleOnChange}
								defaultValue={userDetails.lastName} />
					</Col>
				</Form.Group>
				<Form.Group
						as={Row}
						className="mb-3 align-items-center"
						controlId="email">
					<Col sm={4}>
						<Form.Label>
							Email
						</Form.Label>
					</Col>
					<Col sm={8}>
						<Form.Control
								className="bg-white"
								placeholder=""
								name="email"
								onChange={handleOnChange}
								aria-describedby="emailHelpBlock"
								defaultValue={userDetails.email} />
						<Form.Text id="emailHelpBlock" muted>
							You can use your personal email instead of your work email if you wish. No financial information is shared with your employer.
						</Form.Text>
					</Col>
				</Form.Group>
				<Form.Group
						as={Row}
						className="mb-3 align-items-center"
						controlId="dateOfBirth">
					<Col sm={4}>
						<Form.Label>
							Date of Birth
						</Form.Label>
					</Col>
					<Col sm={8}>
						<Form.Control
								className="bg-white"
								type="date"
								placeholder=""
								name="dateOfBirth"
								onChange={handleOnChange}
								defaultValue={userDetails.dateOfBirth?.toISOString().split('T')[0]} />
					</Col>
				</Form.Group>
				{/* <Form.Group
						as={Row}
						className="mb-3 align-items-center"
						controlId="retirementAge">
					<Col sm={4}>
						<Form.Label className="mt-4">
							Planned Retirement Age
						</Form.Label>
					</Col>
					<Col sm={8}>
						<div className="text-center">{userDetails.retirementAge}</div>
						<InputGroup>
							<InputGroup.Text>55</InputGroup.Text>
							<Form.Control
									className="bg-white"
									type="range"
									min="55"
									max="75"
									name="retirementAge"
									defaultValue={userDetails.retirementAge}
									onChange={handleOnChange} />
							<InputGroup.Text>75</InputGroup.Text>
						</InputGroup>
					</Col>
				</Form.Group> */}
				<Row>
					<Col className="text-center">
						{hasChange && <Button className="mb-3" variant="primary" disabled={isSavingUser || userContext.user.isDemo} onClick={!isSavingUser ? handleSubmit : null}>
							{isSavingUser ? 'Saving...' : 'Save'}
						</Button>}
					</Col>
				</Row>
			</Form>
		)
	}

	return (
		<>
			<PageTitle pageName="My Account" >
				<Row className="align-items-center">
					<Col xs={12} sm={12} md={8} lg={8}>
						<InteractionTile
								title="Personal Details"
								body={body()} />
					</Col>
					<Col xs={12} sm={12} md={4} lg={4} className="text-center">
						<div className="d-grid gap-2">
							<Button variant="secondary" onClick={() => setShowUserTerms(true)}>User Agreement</Button>
							<Button variant="outline-danger" disabled={userContext.user.isDemo} onClick={() => setShowDeleteAccount(true)}>Delete Account</Button>
							{/* <Button variant="danger" onClick={saveUserAccounts}>Save Accounts</Button>
							<Button variant="danger" onClick={getUserAccounts}>Get Accounts</Button> */}
						</div>
					</Col>
				</Row>
			</PageTitle>

			{emailVerificationNeeded && <Modal centered show>
				<Modal.Body>
					<Form>
						<Form.Group>
							<Form.Label>
								A temporary verification code has been sent to your new email. Please enter the code below to verify your identity.
							</Form.Label>
							<Form.Control
									className="bg-white"
									placeholder=""
									name="emailCodeInput"
									onChange={updateEmailUpdateCode} />
							{verificationError !== '' && <Form.Label className="text-danger">
								{verificationError}
							</Form.Label>}
						</Form.Group>
					</Form>
					<Row>
						<Col className="mt-2 text-center">
							<Button variant="secondary" onClick={() => {setEmailVerificationNeeded(false); setIsSavingUser(false)}}>Cancel</Button>
						</Col>
						<Col className="mt-2 text-center">
							<Button onClick={handleConfirmUserEmail}>Continue</Button>
						</Col>
					</Row>

				</Modal.Body>
			</Modal>}

			{showUserTerms && <Modal centered show dialogClassName="modal-60w modal-max-w-700 ">
				<Modal.Header closeButton onHide={() => setShowUserTerms(false)}>
					<h3>User waiver and agreement</h3>
				</Modal.Header>
				<Modal.Body>
					<div className="border p-2" style={{maxHeight: "400px", overflow: "scroll"}}>
						<TermsOfBusiness />
					</div>
					<Row className="mt-3">
						<Col className="text-center">
							<Button variant="secondary" onClick={() => setShowUserTerms(false)}>Close</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>}

			{false && showDeleteAccount && <Modal centered show dialogClassName="modal-60w modal-max-w-700 ">
				<Modal.Header closeButton onHide={() => setShowDeleteAccount(false)}>
					<h3 className="text-danger">DELETE ACCOUNT</h3>
				</Modal.Header>
				<Modal.Body>
					<div className="mb-3">Deleting your account will close your Prift account and you will no longer be able to access our services</div>
					<Form>
						<Form.Group>
							<Form.Label className="text-muted fs-6">
								To confirm this deletion, type "confirm".
							</Form.Label>
							<Form.Control
									className="bg-white"
									placeholder="confirm"
									name="deleteAccountConfirm"
									onChange={v => setDeleteConfirmText(v.target.value)} />
						</Form.Group>
					</Form>
					{/* TODO Wire up Delete Account button */}
					<Row className="mt-3">
						<Col className="text-center">
							<Button style={{marginRight: "10px"}} variant="secondary" onClick={() => setShowDeleteAccount(false)}>Cancel</Button>
							<Button variant="danger" disabled={deleteConfirmText !== 'confirm'} onClick={() => setShowDeleteAccount(false)}>Delete Account</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>}
		</>
	)
}

export default MyAccount;