import React from "react";

import { Container, Row, Col } from 'react-bootstrap';

/**
 * Two column layout for used for laying out the page
 * @param {<></>} left The contents of the left column (left-navigation)
 * @param {<></>} right The contents of the right column (content)
 * @param {<></>} top The contents of the top section (top-navigation for small screen sizes)
 */
function TwoColumnLayout({ left, right, top }) {
	return (
		<Container fluid={"xl"} className="g-0">
			<Row className="g-0 d-xl-none">
				{top}
			</Row>
			<Row className="g-0">
				<Col className="vh-100 overflow-scroll d-none d-xl-block"
						xl={{ span: 3, order: 1 }}>
					{left}
				</Col>
				<Col className="bg-white"
						xs={{ span: 12, order: 1 }}
						sm={{ span: 12, order: 1 }}
						md={{ span: 12, order: 1 }}
						lg={{ span: 12, order: 1 }}
						xl={{ span: 9, order: 2 }}
						xxl={{ span: 9, order: 2 }}>
					{right}
				</Col>
			</Row>
		</Container>
	)
}

export default TwoColumnLayout;