const axios = require('axios');

const exchangeCodeForToken = async function(code, id_token, state, successCallback, errorCallback){
	const requestConfig = {
		headers: {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'x-api-key': process.env.REACT_APP_AWS_API_KEY,
		}
	}
	const requestBody = {
		code, id_token, state
	}
	await axios.post(process.env.REACT_APP_AWS_DATA_ENDPOINT + '/mh/exchange', requestBody, requestConfig).then(response => {
		successCallback(response);
		return;
	}).catch(error => {
		console.error('Error (FE/s/d/exchangeCodeForToken): ', error);
		errorCallback(error);
		return;
	})
}

export default exchangeCodeForToken;