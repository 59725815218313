import React, { useState, useContext, useEffect } from "react"

import { UserContext } from "../../pages/Main";
import { ResponsiveContainer, Tooltip, Sankey, Layer, Rectangle } from 'recharts';
import TwoColumnContentLayout from '../../layouts/TwoColumnContentLayout';

import { Pencil } from "react-bootstrap-icons";

import { Row, Col, Button, Modal, Form, Spinner, OverlayTrigger, Tooltip as TooltipRB, Image } from 'react-bootstrap';
import currencyFormatter from "../../utils/CurrencyFormatter";
import titleCase from '../../utils/TitleCase';

import { getSessionTokenAsync } from '../../utils/GetSessionTokenAsync';
import { patchMhTransactions } from '../../service/DataService';

import styles from '../../scss/exports.scss';

const DisplayIncomeAndExpensesSankey = ({ displayTypes, selected }) => {

	const userContext = useContext(UserContext)

	const [ selectedTimePeriod, setSelectedTimePeriod ] = useState('Past Month')
	const selectedAccounts = userContext.accounts.filter(a => a[selected] && displayTypes.includes(a.type))
	const [ selectedCategory, setSelectedCategory ] = useState(null)
	const [ selectedGroupDepth, setSelectedGroupDepth] = useState(0)
	const [ selectedTransactions, setSelectedTransactions ] = useState([])

	let transactionsByCategory = {}
	let allPossiblePositiveGroupNodes = []
	let allPossibleNegativeGroupNodes = []

	let spendingTotal = 0

	const getDateRange = () => {
		const today = new Date()
		if (selectedTimePeriod === 'Past Month'){
			const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
			return { start: lastMonth, end: today }
		}
		if (selectedTimePeriod === 'Past 3 Months'){
			const lastMonth = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate())
			return { start: lastMonth, end: today }
		}
		if (selectedTimePeriod === 'Past 6 Months'){
			const lastMonth = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate())
			return { start: lastMonth, end: today }
		}
		if (selectedTimePeriod === 'Past Year'){
			const lastMonth = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())
			return { start: lastMonth, end: today }
		}
	}

	const SankeyNode = ({x, y, width, height, index, payload, containerWidth}) => {
		const isOut = (payload.targetLinks.length === 0 || payload.name === 'Total Spending') && payload.sourceLinks.length !== 0
		const isSmall = (payload.value / spendingTotal < 0.1)
		const sankeyNodeStyle = [ 'sankeyNode-income', 'sankeyNode-spending', 'sankeyNode-category' ]
		if (payload.value === 0) return <></>
		const handleClick = (payload) => {
			setNewSelectedCategory(null)
			setSelectedCategory(payload.name)
			setSelectedGroupDepth(payload.depth)
		}
		return (
			<Layer key={`CustomNode${index}`}>
				<foreignObject
						x={x}
						y={y}
						width={width}
						height={height}>
					<div className={"hoverInteract sankeyNode " + sankeyNodeStyle[payload.depth]} onClick={() => setSelectedCategory(payload.name)}></div>
				</foreignObject>
				{/* <Rectangle
						x={x}
						y={y}
						width={width}
						height={height}
						fill="#5192ca"
						fillOpacity="1" /> */}
				<foreignObject
						x={isOut ? x - 106 : x + width + 6}
						y={y + (height / 2) - 15}
						width="100"
						height={payload.name === 'Total Spending' ? "60" : (isSmall ? "20" : "40")}>
					<div style={{lineHeight: "0.9rem"}}>
						{payload.name === 'Total Spending' ? <div className={"hoverInteract " + (isOut && "text-end")}>
							<div className="fs-5" onClick={() => handleClick(payload)}>
								Total
							</div>
							<div className="fs-5" onClick={() => handleClick(payload)}>
								Spending
							</div>
							<div className={"fs-6 fw-bold "} onClick={() => handleClick(payload)}>
								{ currencyFormatter(spendingTotal, 0, true) }
							</div>
						</div> : <>
							{!isOut && <div className="fs-5 hoverInteract" onClick={() => handleClick(payload)}>
								{ titleCase(payload.name.replace('_inc', '').replace('_exp', '')) }
							</div>}
							{isOut && <div className="fs-5 text-end hoverInteract" onClick={() => handleClick(payload)}>
								{ titleCase(payload.name.replace('_inc', '').replace('_exp', '')) }
							</div>}
							{(!isSmall) && <div className={"fs-6 fw-bold " + (isOut && "text-end")} onClick={() => handleClick(payload)}>
								{ currencyFormatter(payload.value, 0, true) }
							</div>}
						</>}
					</div>

				</foreignObject>
				{/* <text
						textAnchor={isOut ? 'end' : 'start'}
						x={isOut ? x - 6 : x + width + 6}
						y={y + height / 2}
						fontSize="14"
						stroke="#333">
					{ titleCase(payload.name.replace('_inc', '').replace('_exp', '')) }
				</text> */}
				{/* {(payload.value / spendingTotal) > 0.1 && <text
						textAnchor={isOut ? 'end' : 'start'}
						x={isOut ? x - 6 : x + width + 6}
						y={y + height / 2 + 13}
						fontSize="12"
						stroke="#333"
						strokeOpacity="0.5">
					{ currencyFormatter(payload.value, 0, true) }
				</text>} */}
			</Layer>
		);
	}

	const getNoOutsideData = () => {
		const dateRange = getDateRange()
		let noDataOutside = []
		selectedAccounts.forEach(a => {
			let hasDataOutsideRange = false
			let earliestTransactionDate = new Date()
			a.transactions && a.transactions.forEach(t => {
				if (hasDataOutsideRange) return
				const tDate = new Date(t.date)
				if (!(tDate <= dateRange.end && tDate >= dateRange.start)){
					hasDataOutsideRange = true
				}
				if (tDate.valueOf() < earliestTransactionDate.valueOf()) earliestTransactionDate = tDate
			})
			if (!hasDataOutsideRange){
				noDataOutside.push({
					account: a,
					earliestTransactionDate: earliestTransactionDate
				})
			}
		})
		return noDataOutside
	}

	const getSankeyData = () => {
		const dateRange = getDateRange()
		let positiveTransactions = []
		let negativeTransactions = []
		selectedAccounts.forEach(a => {
			a.transactions && a.transactions.forEach(t => {
				if (t.category === null) return
				const tDate = new Date(t.date)
				t.isIntraAccount = false
				if (tDate <= dateRange.end && tDate >= dateRange.start){
					if (t.amount.value > 0){
						positiveTransactions.push(t)
						allPossibleNegativeGroupNodes.push(titleCase(t.category?.displayCategory.toLowerCase()))
					}
					if (t.amount.value < 0){
						negativeTransactions.push(t)
						allPossiblePositiveGroupNodes.push(titleCase(t.category?.displayCategory.toLowerCase()))
					}
				}
			})
		})
		allPossiblePositiveGroupNodes = [...new Set([...allPossiblePositiveGroupNodes])]
		allPossibleNegativeGroupNodes = [...new Set([...allPossibleNegativeGroupNodes])]

		positiveTransactions.forEach(pt => {
			negativeTransactions.forEach(nt => {
				if (!pt.isIntraAccount && !nt.isIntraAccount &&
						nt.amount.value === -1 * pt.amount.value &&
						pt.date === nt.date){
					pt.isIntraAccount = true
					nt.isIntraAccount = true
				}
			})
		})
		const transactions = [...positiveTransactions?.filter(t => !t.isIntraAccount),...negativeTransactions?.filter(t => !t.isIntraAccount)]

		let nodes = []
		let nodeNames = []
		transactions.forEach(t => {
			if (!t.category) return
			nodeNames.push(t.category?.displayCategory + '_inc')
			nodeNames.push(t.category?.displayCategory + '_exp')
			transactionsByCategory[t.category?.displayCategory + '_inc'] = []
			transactionsByCategory[t.category?.displayCategory + '_exp'] = []
		})
		let setNodes = [...new Set(nodeNames), 'Total Spending']
		transactionsByCategory['Total Spending'] = []
		setNodes.forEach(n => {
			nodes.push({name: n})
		})
		let links = []

		transactions.forEach(t => {
			if (!t.category) return
			if (t.amount.value > 0){
				// INCOME
				transactionsByCategory[t.category?.displayCategory + '_inc'].push(t)
				const source = setNodes.indexOf(t.category?.displayCategory + '_inc')
				const target = setNodes.indexOf('Total Spending')
				let found = false
				links.forEach(l => {
					if (l.source === source && l.target === target){
						found = true
						l.value += t.amount.value
					}
				})
				!found && links.push({
					source: source,
					target: target,
					value: t.amount.value,
					color: '#afebe970',
				})
			} else {
				// EXPENSE
				transactionsByCategory[t.category?.displayCategory + '_exp'].push(t)
				transactionsByCategory['Total Spending'].push(t)
				const source = setNodes.indexOf('Total Spending')
				const target = setNodes.indexOf(t.category?.displayCategory + '_exp')
				spendingTotal -= t.amount.value
				let found = false
				links.forEach(l => {
					if (l.source === source && l.target === target){
						found = true
						l.value -= t.amount.value
					}
				})
				!found && links.push({
					source: source,
					target: target,
					value: -1 * t.amount.value,
					color: '#ffafa460',
				})
			}
		})
		return {
			nodes: nodes,
			links: links
		}
	}

	const DisplayTransactions = ({ transactions }) => {
		const sankeyTextStyle = [ 'sankeyText-income', 'sankeyText-spending', 'sankeyText-category' ]
		if (transactions == null) {
			setSelectedCategory(null)
			return
		}
		let data = {}
		transactions.forEach(t => {
			let key = t.merchantName
			if (key == null) key = t.shortDescription
			if (data[key] == null) data[key] = []
			data[key].push(t)
		})

		let displayList = []
		let total = 0
		Object.keys(data).forEach(k => {
			let display = {
				text: k,
				amount: 0,
			}
			data[k].forEach(t => {
				display.amount += Math.abs(t.amount.value)
			})
			displayList.push(display)
		})
		displayList.sort((a,b) => a.amount < b.amount ? 1 : -1)
		displayList.forEach(dl => total += dl.amount)

		return (<div className={sankeyTextStyle[selectedGroupDepth]}>
			<div className={"d-flex justify-content-between"}>
				<h5>{ titleCase(selectedCategory.replace('_inc', '').replace('_exp', '')) }</h5>
				<h5>{currencyFormatter(total, 0, true)}</h5>
			</div>
			<hr className="my-0" />
			{displayList.map(d => (
				<div className={"my-1 d-flex justify-content-between hoverInteract"} onClick={() => setSelectedTransactions(data[d.text])}>
					<div className="text-truncate">
						{titleCase(d.text.toLowerCase())}
					</div>
					<div className="text-end">
						{currencyFormatter(d.amount, 0, true)}
					</div>
				</div>

			))}
		</div>)
	}

	const [ newSelectedCategory, setNewSelectedCategory ] = useState(null)
	const handleSelectChange = (e) => {
		setNewSelectedCategory(e.currentTarget.value)
	}
	const handleSaveNewCategory = async () => {
		if (newSelectedCategory === null) return

		let newCategory = null
		Object.keys(userContext.categories).forEach(k => {
			let category = userContext.categories[k]
			if (category.displayCategory.toLowerCase() === newSelectedCategory.toLowerCase()){
				newCategory = category
			}
		})

		let transactionIds = []
		selectedTransactions.forEach(t => {
			transactionIds.push(t.id)
		})

		const currentSession = await getSessionTokenAsync()
		let transactions = []
		selectedTransactions.forEach(t => {
			transactions.push({
				id: t.id,
				categoryId: newCategory.categoryId
			})
		})
		let targetCategory = null
		selectedTransactions.forEach(updatedTransaction => {
			userContext.accounts.forEach(a => {
				a.transactions?.forEach(t => {
					if (t.id === updatedTransaction.id){
						t.category = newCategory
						targetCategory = newCategory.name
					}
				})
			})
		})
		setSelectedTransactions([])

		await patchMhTransactions(currentSession, userContext.user.moneyhubUser, transactions, (updatedTransactions) => {
			// Hold
		})
	}

	const renderListAccountsWithNoDataOutside = (props) => {
		const noOutsideData = getNoOutsideData()
		return (<TooltipRB id="button-tooltip" {...props} animation="false">
			<>
				{noOutsideData.map(i => (
					<div>
						{i.account.connection && <Image src={i.account.connection.iconUrl} height="25px" />} {i.account.accountName} only has data from {new Date(i.earliestTransactionDate).toLocaleDateString()}
					</div>
				))}
			</>
		</TooltipRB>)
	}

	const CustomLinkComponent = (props) => {
		return <path
			d={`
				M${props.sourceX},${props.sourceY}
				C${props.sourceControlX},${props.sourceY} ${props.targetControlX},${props.targetY} ${props.targetX},${props.targetY}
			`}
			stroke={props.payload.color}
			strokeWidth={props.linkWidth}
			fill="#00000000"
			{...props}
		/>
	  }

	return (<>
		{!userContext.loadingState.loadComplete && <div className="text-center" style={{height: "300px"}}>
			<div style={{paddingTop: "60px"}}>
				<Spinner className="center" animation="border" role="status">
					<span className="visually-hidden">Loading..</span>
				</Spinner>
			</div>
			<div className="mt-3">
				Loading..
			</div>
		</div>}
		{userContext.loadingState.loadComplete && selectedAccounts.length === 0 && <div className="text-center" style={{height: "300px"}}>
			<div style={{paddingTop: "60px"}} className="text-muted fs-5">
				Select an account above to see a categorised breakdown of your spending
			</div>
		</div>}
		{userContext.loadingState.loadComplete && selectedAccounts.length > 0 && <><Row>
			<Col>
				<div className="d-flex justify-content-center mx-3 mb-1">

					{['Past Month', 'Past 3 Months', 'Past 6 Months', 'Past Year'].map(x => (
						<Button
								className="soft-corners mx-1"
								onClick={() => setSelectedTimePeriod(x)}
								variant={(selectedTimePeriod !== x ? "outline-" : "") + "selector"}>
							{x}
						</Button>
					))}
				</div>
			</Col>
		</Row>
		{getNoOutsideData().length > 0 && <div className="mb-1">
			<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={renderListAccountsWithNoDataOutside}>
				<div className="fs-6 text-muted text-center interaction"><u>Not all selected accounts have full data for the selected timescale</u></div>
			</OverlayTrigger>
		</div>}
		<TwoColumnContentLayout
				wide="left"
				left={<>
					<div style={{'height': '500px'}}>
						<ResponsiveContainer className="soft-corners" width="100%" height="100%">
							<Sankey
									margin={{left: 0, right: 0, top: 0, bottom: 0}}
									data={getSankeyData()}
									node={<SankeyNode />}
									nodeWidth={10}
									nodePadding={15}
									linkCurvature={0.61}
									iterations={32}
									link={<CustomLinkComponent />}>
								{/* <Tooltip /> */}
							</Sankey>
						</ResponsiveContainer>
					</div>
				</>}
				right={<div className="mt-3">
					{selectedCategory == null && <div className="text-muted fs-5 text-center d-none d-md-block">
						Select a category on the right to see a breakdown of transactions
					</div>}
					{selectedCategory == null && <div className="text-muted fs-5 text-center d-md-none">
						Select a category above to see a breakdown of transactions
					</div>}
					{selectedCategory != null && <div>
						<DisplayTransactions transactions={transactionsByCategory[selectedCategory]} />
					</div>}
				</div>}
			/></>}
		{selectedTransactions.length !== 0 && (
			<Modal centered show>
				<Modal.Header>
					Edit Transaction Categories
				</Modal.Header>
				<Modal.Body>
					{selectedTransactions.map((t,i) => (<>
						{i === 0 && <div className="d-flex text-muted justify-content-between align-items-center">
							<div>
								<div className="">
									{titleCase((t.merchantName || t.shortDescription).toLowerCase())}
								</div>
							</div>
							<div>
								<Form.Select defaultValue={titleCase(t.category?.displayCategory)} onChange={(e) => handleSelectChange(e)}>
									{t.amount.value > 0 && allPossibleNegativeGroupNodes?.map(n => (
										<option value={n}>{n}</option>
									))}
									{t.amount.value < 0 && allPossiblePositiveGroupNodes?.map(n => (
										<option value={n}>{n}</option>
									))}
								</Form.Select>
							</div>
						</div>}
					</>))}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="bare" onClick={() => setSelectedTransactions([])}>Close</Button>
					<Button variant="primary" onClick={handleSaveNewCategory}>Save</Button>
				</Modal.Footer>
			</Modal>
		)}
	</>)
}

export default DisplayIncomeAndExpensesSankey;