import React from "react";

import currencyFormatter from "../../utils/CurrencyFormatter";

import { Col } from 'react-bootstrap';

const PotentialSavings = ({data, userContext, columns = 1}) => {

	const topSavingPotentials = () => {
		if (data == null) return
		let savingsByVendor = data.savingByVendor
		savingsByVendor.sort((a,b) => {
			return a.value < b.value ? 1 : -1
		})
		return savingsByVendor.map((r,i) => {
			if (i < 4){
				return (<Col sm={columns === 2 ? 6 : 12}  key={"topSavings" + i}>
					<div className="d-flex my-2 mx-1 align-items-center">
						<div className="border soft-corners p-1 bg-white justify-content-center align-items-center d-flex" style={{flexGrow: "1", height: "3.25rem"}}>
							{r.vendorDetails.Logo &&
									<img style={{maxHeight: "25px", maxWidth: "100%", marginTop: "0rem"}} src={r.vendorDetails.Logo} alt={r.vendorDetails.Name} />}
							{!r.vendorDetails.Logo && r.vendorDetails.Name}
						</div>
						<div className="ssSavingBorder soft-corners text-saving-color text-center bg-white" style={{position: "relative", minWidth: "40%", height: "3.25rem"}}>
							<div className="">
								<div className="fs-5">Save</div>
								<div className="mb-0 fw-bold" style={{lineHeight: "0.75rem"}}>{currencyFormatter(r.value/12, r.value >= 10000 ? 0 : 2)}</div>
								<div className="fs-6 text-muted">{r.vendorDetails.Discount}%</div>
							</div>
						</div>
					</div>
					{/* <div className="border m-1 p-2">
						<Row className="align-items-center">
							<Col xs={6} className="text-truncate">
								{r.vendorDetails.Logo &&
										<img style={{maxHeight: "40px", maxWidth: "100%"}} src={r.vendorDetails.Logo} alt={r.vendorDetails.Name} />}
									{!r.vendorDetails.Logo && r.vendorDetails.Name}
							</Col>
							<Col xs={6} className="text-center">
								<div>
									<div className="fs-6 text-muted">
										Save {r.vendorDetails.Discount}%
									</div>
								</div>
								<div>
									<div className="">
										{currencyFormatter(r.value/12, 2)}
									</div>
								</div>
							</Col>
						</Row>
					</div> */}
				</Col>)
			} else {return null}
		})
	}

	return (
		<>{topSavingPotentials()}</>
	)
}

export default PotentialSavings