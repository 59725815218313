const moneyHubAssetSvgStyles = {
	'cash:current': 'svg-income-color',
	'savings': 'svg-saving-color',
	'card': 'svg-spending-color',
	'investment': 'svg-saving-color',
	'loan': 'svg-spending-color',
	'mortgage': 'svg-spending-color',
	'mortgage:repayment': 'svg-spending-color',
	'mortgage:interestOnly': 'svg-spending-color',
	'pension': 'svg-saving-color',
	'pension:definedBenefit': 'svg-saving-color',
	'pension:definedContribution': 'svg-saving-color',
	'asset': 'svg-positive-color',
	'properties:residential': 'svg-income-color',
	'properties:butToLet': 'svg-income-color',
	'crypto': 'svg-positive-color',
}

export default moneyHubAssetSvgStyles;