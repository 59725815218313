import React, { useState, useEffect } from "react";

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, BarChart, Bar } from 'recharts';

import { InfoCircle } from "react-bootstrap-icons";

import { Tile, InteractionTile } from "../../components/components";

import { Form, OverlayTrigger, Tooltip as TooltipRB, Spinner } from 'react-bootstrap';
import currencyFormatter from "../../utils/CurrencyFormatter";


const FutureSavings = ({ userContext }) => {

	const [ savingGraphType, setSavingGraphType ] = useState("Savings Pot")

	const model = { low: 2/12, base: 5/12, high: 7/12 }

	useEffect(() => {

		let averageSaving = getAverageSaving()
		userContext.setPensionSliderValues(old => {return {
			...old,
			monthlyPayment: averageSaving * 1.2
		}})
	}, [])

	const getAverageSaving = () => {
		let averageSaving = 0
		userContext.accounts.forEach(a => {
			if (a.shopStAverageSaving) averageSaving += a.shopStAverageSaving
		})
		return averageSaving
	}

	const getRetirementIncomeIncrease = () => {
		let retirementIncrease = 0
		userContext.accounts.forEach(a => {
			if (a.type === 'pension-scenario') retirementIncrease += (a.annualRetirementPayment?.base || 0)
		})
		return retirementIncrease
	}

	const getBarGraphData = () => {
		let retirementTotal = 0
		userContext.accounts.forEach(a => {
			if (a.type === 'pension') retirementTotal += (a.annualRetirementPayment?.base || 0)
		})
		let barChartData = []
		for (let i = Number(userContext.user.retirementAge); i <= 90; i++){
			barChartData.push({
				name: i,
				original: retirementTotal,
				increase: getRetirementIncomeIncrease()
			})
		}
		return barChartData
	}

	const getLineGraphData = () => {
		let allData = []
		let currentData = {
			name: 'Today',
			noGrowth: 0,
			lowGrowth: 0,
			baseGrowth: 0,
			highGrowth: 0,
			lowHighDiff: 0
		}
		allData.push({...currentData})

		const averageSaving = getAverageSaving()
		for (let i = 1; i <= 60; i++){
			currentData.name = ''
			if (i >= 12){
				currentData.name += Math.floor(i/12) + ' year' + (i>12 ? 's' : '')
			}
			currentData.name += (i%12 !== 0) ? (' ' + (i%12) + ' month' + (i%12 === 1 ? '' : 's')) : ''

			currentData.noGrowth += averageSaving
			currentData.lowGrowth = currentData.lowGrowth * (1 + (model.low/100)) + averageSaving
			currentData.baseGrowth = currentData.baseGrowth * (1 + (model.base/100)) + averageSaving
			currentData.highGrowth = currentData.highGrowth * (1 + (model.high/100)) + averageSaving
			currentData.lowHighDiff = currentData.highGrowth - currentData.lowGrowth
			currentData.noGrowthLabel = i === 60 ? 'Deposits' : ''
			currentData.baseGrowthLabel = i === 60 ? 'Savings Forecast' : ''
			allData.push({...currentData})
		}
		return allData
	}

	const labelRenderer = (offset) => {
		return (props) => {
			const { x, y, value } = props;
			if (value == null || value === '') return null
			return (
				<g className="fs-6">
					<text x={x+10} y={y + Number(offset)} fill="#666">
						{value.split(" ")[0]}
					</text>
					<text x={x+10} y={y+4 + Number(offset)} dominantBaseline="hanging" fill="#666">
						{value.split(" ")[1]}
					</text>
				</g>
			)
		}
	}
	const RenderTooltip = ({ active, label, payload }) => {
		let additionalIncome = 0
		let currentPensionIncome = 0
		payload.forEach(p => {
			if (p.dataKey === 'increase'){
				additionalIncome += p.value
			}
			if (p.dataKey === 'original'){
				currentPensionIncome += p.value
			}
		})
		const tooltipContents = () => {
			return (
				<>
					{label && <h6 className="text-start"><span className="text-muted">Age:</span> {label}</h6>}
					<div className="fw-bold fs-6 d-flex justify-content-between text-muted">
						Estimated income:
					</div>
					<div className="fs-6 d-flex justify-content-between fw-bold text-saving-color">
						<div>
							Additional Income:&nbsp;
						</div>
						<div className="text-end mr-auto">
							{currencyFormatter(additionalIncome, 0, true)}
						</div>
					</div>
					<div className="fs-6 d-flex justify-content-between fw-bold text-asset-color">
						<div>
							Current Pensions:&nbsp;
						</div>
						<div className="text-end mr-auto">
							{currencyFormatter(currentPensionIncome, 0, true)}
						</div>
					</div>
				</>
			)
		}
		return(
			active &&
					<InteractionTile body={tooltipContents()} padding="p-1" bottomMargin="" />
		)
	}

	const BarGraph = () => {
		return (
			<ResponsiveContainer className="border soft-corners" width="100%" height="100%">
				<BarChart
						width={500}
						height={300}
						data={getBarGraphData()}
						margin={{
							top: 0,
							right: 5,
							left: 0,
							bottom: 0,
						}}>
					<CartesianGrid strokeDasharray="1 0" vertical={false} stroke="#E5E9F2" />
					<XAxis
							dataKey="name"
							tickFormatter={value => {
								return value % 2 === 0 ? value : ""
							}}
							interval={2}
							tickLine={false}
							tickCount={10}
							axisLine={false}
							type="category"
							domain={['dataMin', 'dataMax']}
							className="fs-6 text-muted" />
					<YAxis
							dx={-5}
							padding={{ top: 25, bottom: 0}}
							tickFormatter={value => currencyFormatter(value)}
							tickLine={false}
							axisLine={false}
							width={70}
							allowDataOverflow
							domain={ ['auto', 'auto'] }
							className="fs-6 text-muted" />
					<Tooltip cursor={false} content={<RenderTooltip />} />
					<Bar
							activeDot={false}
							key="original"
							type="monotone"
							dataKey="original"
							stackId="1"
							dot={false}
							fill="#558eff" stroke="#003980"
							connectNulls
							isAnimationActive={false} />
					<Bar
							activeDot={false}
							key="increase"
							type="monotone"
							dataKey="increase"
							stackId="1"
							dot={false}
							fill="#AFEBE9" stroke="#3F9FA0"
							connectNulls
							isAnimationActive={false} />
				</BarChart>
			</ResponsiveContainer>
		)
	}

	const LineGraph = () => {
		const RenderTooltip = ({ active, label, payload }) => {
			let savingForecast = 0
			let deposits = 0
			payload.forEach(p => {
				if (p.dataKey === 'baseGrowth'){
					savingForecast += p.value
				}
				if (p.dataKey === 'noGrowth'){
					deposits += p.value
				}
			})
			const tooltipContents = () => {
				return (
					<>
						{label && <h6 className="text-start">{label}</h6>}
						<div className="fw-bold fs-6 d-flex justify-content-between text-muted">
							Estimated Savings:
						</div>
						<div className="fs-6 d-flex justify-content-between fw-bold text-saving-color">
							<div>
								Savings Forecast:&nbsp;
							</div>
							<div className="text-end mr-auto">
								{currencyFormatter(savingForecast, 0, true)}
							</div>
						</div>
						<div className="fs-6 d-flex justify-content-between fw-bold text-asset-color">
							<div>
								Deposits:&nbsp;
							</div>
							<div className="text-end mr-auto">
								{currencyFormatter(deposits, 0, true)}
							</div>
						</div>
					</>
				)
			}
			return(
				active &&
						<InteractionTile body={tooltipContents()} padding="p-1" bottomMargin="" />
			)
		}

		return (
			<ResponsiveContainer className="border soft-corners" width="100%" height="100%">
				<AreaChart
						width={500}
						height={300}
						data={getLineGraphData()}
						margin={{
							top: 0,
							right: 100,
							left: 0,
							bottom: 0,
						}}>
					<CartesianGrid strokeDasharray="1 0" vertical={false} stroke="#E5E9F2" />
					<Tooltip cursor={false} content={<RenderTooltip />} />
					<XAxis
							dataKey="name"
							tickFormatter={value => {
								if (value.length <= 7) return value
								return ''
							}}
							interval={1}
							tickLine={false}
							axisLine={false}
							type="category"
							domain={['dataMin', 'dataMax']}
							className="text-muted fs-6" />
					<YAxis
							dx={5}
							padding={{ top: 25, bottom: 0}}
							tickFormatter={value => currencyFormatter(value)}
							tickLine={false}
							axisLine={false}
							width={70}
							allowDataOverflow
							domain={ ['auto', 'auto'] }
							className="text-muted fs-6" />

					<Area
							activeDot={false}
							key="lowGrowth"
							type="monotone"
							dataKey="lowGrowth"
							stackId="wings"
							dot={false}
							fill="#00000000"
							stroke="#00000000"
							connectNulls
							isAnimationActive={false} />
					<Area
							activeDot={false}
							key="lowHighDiff"
							type="monotone"
							dataKey="lowHighDiff"
							stackId="wings"
							dot={false}
							fill="#afebe9"
							stroke="#00000000"
							connectNulls
							isAnimationActive={false} />
					<Area
							activeDot={false}
							key="noGrowth"
							type="monotone"
							dataKey="noGrowth"
							stackId="noGrowth"
							dot={false}
							fill="#00000000"
							stroke="#3F9FA0"
							strokeWidth={2}
							connectNulls
							isAnimationActive={false} >
						<LabelList dataKey="noGrowthLabel" content={labelRenderer(10)} position="bottom" />
					</Area>
					<Area
							activeDot={false}
							key="baseGrowth"
							type="monotone"
							dataKey="baseGrowth"
							stackId="baseGrowth"
							dot={false}
							fill="#00000000"
							stroke="#255555"
							strokeWidth={2}
							connectNulls
							isAnimationActive={false} >
						<LabelList dataKey="baseGrowthLabel" content={labelRenderer(-15)} position="top" />
					</Area>
				</AreaChart>
			</ResponsiveContainer>
		)
	}

	const renderSavingAccountHelp = (props) => (
		<TooltipRB id="button-tooltip" {...props} animation="false">
			<div>If you put the savings in a savings account.</div>
		</TooltipRB>
	)
	const renderRetirementAnnualHelp = (props) => (
		<TooltipRB id="button-tooltip" {...props} animation="false">
			<div>If monthly pension contributions increased by Shop St saving each month until retirement.</div>
		</TooltipRB>
	)

	return (
		<Tile body={<>
					{!userContext.loadingState.loadComplete && <div className="d-flex justify-content-center">
						<div className="text-center p-5">
							<Spinner className="center" animation="border" role="status">
								<span className="visually-hidden">Loading..</span>
							</Spinner>
							<div className="mt-1 text-muted">
								Loading..
							</div>
						</div>
					</div>}
					{userContext.loadingState.loadComplete && <>
						<div className="d-flex mb-3">
							<div className="text-muted fs-5" style={{marginRight: "0.5rem"}}>
								If you pay and save with Shop St, <b>{currencyFormatter(getAverageSaving())}</b> a month would mean:
							</div>
							<div>
							<Form.Select aria-label="Retirement or Savings Pot" onChange={(v) => setSavingGraphType(v.target.value)}>
								<option value="Savings Pot">Savings Pot</option>
								<option value="Retirement">Retirement</option>
							</Form.Select>
							</div>
						</div>
						{savingGraphType === 'Savings Pot' && <div className="text-muted text-center">
							<Tile
									title={
										<div className="fs-5">
											A Savings Pot of <b className="text-saving-color" style={{textDecoration: "underline"}}>{currencyFormatter(getLineGraphData()[getLineGraphData().length-1].baseGrowth)}</b> after <b className="text-saving-color" style={{textDecoration: "underline"}}>5 years</b> <OverlayTrigger
														placement="auto"
														delay={{ show: 250, hide: 400 }}
														overlay={renderSavingAccountHelp}>
													<InfoCircle style={{fontSize: "0.75rem"}} />
												</OverlayTrigger>
										</div>}
									body={<div style={{height: "200px"}}><LineGraph /></div>} />
						</div>}
						{savingGraphType === 'Retirement' && <div className="text-center">
							<Tile
									title={
										<div className="fs-5 text-muted ">
											An additional est. retirement income of <b className="text-saving-color" style={{textDecoration: "underline"}}>{currencyFormatter(getRetirementIncomeIncrease())}</b> a year <OverlayTrigger
														placement="auto"
														delay={{ show: 250, hide: 400 }}
														overlay={renderRetirementAnnualHelp}>
													<InfoCircle style={{fontSize: "0.75rem"}} />
												</OverlayTrigger>
										</div>}
									body={<div style={{height: "200px"}}><BarGraph /></div>} />
						</div>}
					</>}
				</>} />
	)

}

export default FutureSavings;