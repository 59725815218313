import React, { useState, useEffect } from "react";

import { Tile } from "../../components/components";

import { Row, Col, Form } from 'react-bootstrap';

const ParticipatingRetailers = ({userContext}) => {

	const [currentDisplayRetailers, setCurrentDisplayRetailers ] = useState(userContext.shopStVendors)
	const [searchKey, setSearchKey] = useState('')

	useEffect(() => {
		setCurrentDisplayRetailers(userContext.shopStVendors.filter(v => {
			let found = false
			v.targets.forEach(target => {
				if (target.toLowerCase().includes(searchKey.toLowerCase())) found = true
			})
			return found
		}))
	}, [searchKey, userContext.shopStVendors])

	const showRetailers = () => {
		const showRetailer = (r, i) => {
			return (
				<Col xs={6} sm={6} md={4} lg={3} key={"retailer" + r.Name}>
					<div className="border p-2 soft-corners">
						<Row className="align-items-center g-0">
							<Col xs={9} className="text-truncate text-center">
								{r.Logo &&
									<img style={{maxHeight: "40px", maxWidth: "100%"}} src={r.Logo} alt={r.Name} />}
								{!r.Logo && r.Name}
							</Col>
							<Col xs={3} className="text-center text-saving-color">
								<div className="fs-6">
									Save
								</div>
								<div className="fw-bold">
									{r.Discount}%
								</div>
							</Col>
						</Row>
					</div>
				</Col>
			)
		}
		return (
			currentDisplayRetailers.map((r,i) => (showRetailer(r,i)))
		)
	}

	return (<div className="mx-3 my-2">
		<Tile body={<>
					<h5 className="">Participating Retailers</h5>
					<Row>
						<Col md={4}></Col>
						<Col md={4}>
							<Form.Control
									type="text"
									placeholder="Search"
									className="mb-3 bg-white"
									onChange={(v) => setSearchKey(v.target.value)}
								/>
						</Col>
						<Col md={4}></Col>
					</Row>
					<Row className="g-3">
						{showRetailers()}
					</Row>
				</>} />
	</div>)
}

export default ParticipatingRetailers