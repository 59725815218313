import WebSocket from 'isomorphic-ws';

let WSService = null;

class WebSocketService {

	constructor() {
		this.websocket = null
		this.messageListeners = []
		this.isOpen = false
		this.user = null
	}

	initSocket = () => {
		this.websocket = new WebSocket("wss://aneo2sm36j.execute-api.eu-west-2.amazonaws.com/production/?userId=123")
		this.websocket.onopen = this.onConnOpen
		this.websocket.onmessage = this.onMessage
		this.websocket.onclose = this.onConnClose
	}

	onConnOpen = () => {
		this.isOpen = true
	}

	onConnClose = () => {
	}

	addMessageListener = (listener) => {
		this.messageListeners.push({listener})
	}

	setUser = (user) => {
		this.user = user
		if (this.websocket && this.isOpen){
			this.websocket.send(JSON.stringify({
				action: 'setUserId',
				userId: this.user.moneyhubUser
			}))
		}
	}

	onMessage = (data) => {
		if (data){
			const message = data.data
			this.messageListeners.forEach(listener => {
				listener.listener(message, this.user)
			})
		}
	}

	sendPing = () => {
		if (this.websocket && this.isOpen){
			this.websocket.send(JSON.stringify({
				action: 'default',
				type: 'ping'
			}))
		}
	}

	connect = () => {
		if (this.websocket && this.isOpen){
			this.websocket.send(JSON.stringify({
				action: 'onConnect',
				userId: this.user.moneyhubUser
			}))
		}
	}

	static initWSService() {
		if (!WSService){
			WSService = new WebSocketService()
			WSService.initSocket()
			return WSService
		}
		return WSService
	}
}

export const getWSService = WebSocketService.initWSService