const axios = require('axios');

const getMhConnections = async function(token, successCallback){
	const requestConfig = {
		headers: {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'x-api-key': process.env.REACT_APP_AWS_API_KEY,
			'Authorization': token,
		}
	}
	await axios.get(process.env.REACT_APP_AWS_DATA_ENDPOINT + '/mh/connections', requestConfig).then(response => {
		const data = response.data.connections.filter(b => {
				return	!b.isTest &&
						!b.isBeta &&
						b.country === 'GB' &&
						b.userTypes.includes('personal') &&
						b.status.auth === 'AVAILABLE'
					 });
		successCallback(data)
		return;
	}).catch(error => {
		console.error('Error (FE/s/d/getMhConnections): ', error);
		return;
	})
}

export default getMhConnections;