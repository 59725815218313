export const addTransactionsToAccount = (transactions, accounts, shopStVendors, categories) => {
	let lastSixMonths = []
	let currentDate = new Date()
	for (let i = 0; i < 6; i++){
		currentDate.setDate(0)
		lastSixMonths.push(currentDate.toISOString().substring(0,7))
	}
	let transactionsByAccount = {}
	transactions.forEach(t => {
		if (!transactionsByAccount[t.accountId]) transactionsByAccount[t.accountId] = []
		transactionsByAccount[t.accountId].push(t)
	})
	accounts.forEach(a => {
		a.transactions = transactionsByAccount[a.id]?.filter(t => true || t.status === 'posted')
		let movement = {
			total: {},
			incoming: {},
			outgoing: {},
			shopSt: {},
			shopStSpend: {},
		}
		let punctRE = /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%()*+,\-.\/:;<=>?@\[\]^_`{|}~]/g;
		let spaceRE = /\s+/g;
		a.transactions?.forEach(t => {
			const tDate = t.date.substring(0,7);
			if (movement.total[tDate] == null) movement.total[tDate] = 0
			if (movement.incoming[tDate] == null) movement.incoming[tDate] = 0
			if (movement.outgoing[tDate] == null) movement.outgoing[tDate] = 0
			if (movement.shopSt[tDate] == null) movement.shopSt[tDate] = 0
			if (movement.shopStSpend[tDate] == null) movement.shopStSpend[tDate] = 0
			movement.total[tDate] += t.amount.value
			t.amount.value > 0 ? movement.incoming[tDate] += t.amount.value : movement.outgoing[tDate] += t.amount.value
			t.discountPotential = 0

			shopStVendors.forEach(ssv => {
				let found = false;
				ssv.targets.forEach(target => {
					if (t.longDescription.replace(punctRE, '').replace(spaceRE, ' ').toLowerCase().includes(target.replace(punctRE, '').replace(spaceRE, ' ')) && !t.longDescription.toLowerCase().includes('cash') && !t.shortDescription.toLowerCase().includes('cash')) found = true
				})
				if (found){
					const totalDiscountPotential = -1 * t.amount.value * (ssv.Discount / 100)
					t.discountPotential = totalDiscountPotential
					t.percentageDiscount = ssv.Discount
					t.vendorDetails = ssv
				}
			})
			movement.shopSt[tDate] += t.discountPotential

			if (t.longDescription.toLowerCase().includes('shop st') && !t.longDescription.toLowerCase().includes('cash')){
				a.hasSSTransaction = true
				movement.shopStSpend[tDate] += -1 * t.amount.value * (7 / 100)
			}
			t.category = categories[t.categoryId]
			t.shortDate = new Date(t.date).toISOString().split('T')[0]
		})
		let shopStThreeMonthTotal = 0
		let monthsWithData = 0
		lastSixMonths.forEach(m => {
			if (movement.shopSt[m] > 0){
				shopStThreeMonthTotal += movement.shopSt[m]
				monthsWithData ++
			}
		})
		a.shopStAverageSaving = shopStThreeMonthTotal / monthsWithData
		a.movement = movement
	})
	return accounts
}