const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const monthsLong = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const dateFormatter = (value, style = 'long') => {
	if (!value) return ''
	const splits = value.split('T')[0].split('-')
	const year = splits[0].slice(-2)
	const fullYear = splits[0]
	const month = months[Number(splits[1])]
	const monthLong = monthsLong[Number(splits[1])]
	const date = Number(splits[2])
	if (style === 'long') return (date ? (date + ' ') : '') + month + ' ' + year
	if (style === 'short') return (date ? (date + '/') : '') + Number(splits[1])
	if (style === 'yearOnly') return (splits[0])
	if (style === 'mmmYYYY') return month + ' ' + splits[0]
	if (style === 'longMonth') return (date ? (date + ' ') : '') + monthLong + ' ' + fullYear
	if (style === 'longMonthYearOnly') return (monthLong + ' ' + fullYear)
}

export default dateFormatter;