// State pension - UK government sets the state pension amount from specific dates
const statePensionPayments = [
	{
		ends: new Date(2023, 2, 31),
		value: 1060020,
	}, {
		ends: new Date(2400, 11,31),
		value: 1150084,
	}
]

/**
 * Get the state pension amount for a given retirement date
 * @param {Date} retirementDate
 * @returns Amount of state pension in minor currency units
 */
const getStatePensionAmount = (retirementDate) => {
	let payout = 0
	statePensionPayments.forEach(statePensionPayout => {
		if (statePensionPayout.ends > retirementDate){
			payout = statePensionPayout.value
		}
	})
	return payout
}

export default getStatePensionAmount;