const axios = require('axios');

const createUser = async function(token, userDetails){
	const requestConfig = {
		headers: {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'x-api-key': process.env.REACT_APP_AWS_API_KEY,
			'authorizationToken': token,
		}
	}
	const requestBody = userDetails;
	axios.post(process.env.REACT_APP_AWS_DATA_ENDPOINT + '/create-user', requestBody, requestConfig).then(response => {
		return;
	}).catch(error => {
		return;
	})
}

export default createUser;