const axios = require('axios');

const createMhAccount = async function(token, moneyhubId, accountDetails, successCallback){
	const requestConfig = {
		headers: {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'x-api-key': process.env.REACT_APP_AWS_API_KEY,
			'Authorization': token,
		}
	}
	const requestBody = {
		userId: moneyhubId,
		account: accountDetails,
		action: 'CREATE'
	}
	await axios.put(process.env.REACT_APP_AWS_DATA_ENDPOINT + '/mh/accounts', requestBody, requestConfig).then(() => {
		successCallback()
		return;
	}).catch(error => {
		console.error('Error (FE/s/d/createAccount): ', error);
		return;
	})
}

export default createMhAccount;