import React, { useState, useEffect } from "react";

import { PageTitle } from '../../components/components';

import { helpTopics } from "../../assets/content/help-topics";

import { Accordion, Form, Row, Col } from 'react-bootstrap';

const Help = () => {

	const [ displayHelpTopics, setDisplayHelpTopics ] = useState(helpTopics)
	const [ searchKey, setSearchKey ] = useState('')

	useEffect(() => {
		setDisplayHelpTopics(helpTopics.filter(t => {
			let found = false
			if (t.title.toLowerCase().includes(searchKey.toLowerCase())) found = true
			if (t.content.toLowerCase().includes(searchKey.toLowerCase())) found = true
			return found
		}))
	}, [searchKey])

	return (
		<>
			<PageTitle pageName={<>Help & Support</>}>
				<div className="m-3">
					<Row>
						<Col xs={3}></Col>
						<Col xs={6}>
							<Form.Control
									type="text"
									placeholder="Search"
									className="mb-3"
									onChange={(v) => setSearchKey(v.target.value)}
								/>
						</Col>
					</Row>

					<Accordion>
						{displayHelpTopics.map((h,i) => (
							<Accordion.Item eventKey={i} key={i}>
								<Accordion.Header>{h.title}</Accordion.Header>
								<Accordion.Body className="bg-white">{h.content}</Accordion.Body>
							</Accordion.Item>
						))}
					</Accordion>
				</div>
			</PageTitle>
		</>
	)
}

export default Help;