import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../pages/Main";

import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Sankey, Layer, Rectangle } from "recharts";

import { ChevronDoubleDown, QuestionCircle, ChevronDoubleLeft, ChevronDoubleRight } from "react-bootstrap-icons";

import { HorizontalColors, InteractionTile, SSLogo } from '../components';
import DisplayIncomeAndExpensesRow from './DisplayIncomeAndExpensesRow';

import currencyFormatter from "../../utils/CurrencyFormatter";
import dateFormatter from "../../utils/DateFormatter";

import styles from '../../scss/exports.scss';

const DisplayIncomeAndExpenses = ({ displayTypes, selected }) => {

	const userContext = useContext(UserContext)

	const [ currentSelectedMonth, setCurrentSelectedMonth ] = useState('')

	const [ currentPositiveDisplayTotal, setCurrentPositiveDisplayTotal ] = useState(5)
	const [ currentNegativeDisplayTotal, setCurrentNegativeDisplayTotal ] = useState(5)

	const [ data, setData ] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);

	const [ screenSize, getDimension ] = useState({
		dynamicWidth: window.innerWidth,
		dynamicHeight: window.innerHeight
	})
	const setDimension = () => {
		getDimension({
			dynamicWidth: window.innerWidth,
			dynamicHeight: window.innerHeight
		})
	}
	useEffect(() => {
		window.addEventListener('resize', setDimension)
		return (() => {
			window.removeEventListener('resize', setDimension)
		})
	}, [screenSize])

	useEffect(() => {
		setCurrentPositiveDisplayTotal(5)
		setCurrentNegativeDisplayTotal(5)
	}, [currentSelectedMonth])

	let positiveTransactionsByMonth = {}
	let negativeTransactionsByMonth = {}
	const selectedAccounts = userContext.accounts.filter(a => a[selected] && displayTypes.includes(a.type))
	selectedAccounts.forEach(a => {
		a.transactions && a.transactions.forEach(t => {
			const tDate = t.date.substring(0,7);
			t.isIntraAccount = false;
			if (t.amount.value > 0){
				if (!positiveTransactionsByMonth[tDate]) positiveTransactionsByMonth[tDate] = [];
				positiveTransactionsByMonth[tDate].push(t)
			}
			if (t.amount.value < 0){
				if (!negativeTransactionsByMonth[tDate]) negativeTransactionsByMonth[tDate] = [];
				negativeTransactionsByMonth[tDate].push(t)
			}
		})
	})
	Object.keys(positiveTransactionsByMonth).forEach((month, index) => {
		const negativeForMonth = negativeTransactionsByMonth[month];
		if (!negativeForMonth) return
		positiveTransactionsByMonth[month].forEach(pt => {
			for (let i = 0; i < negativeForMonth.length; i++){
				if (!pt.isIntraAccount && !negativeForMonth[i].isIntraAccount &&
						negativeForMonth[i].amount.value === -1 * pt.amount.value &&
						pt.date === negativeForMonth[i].date){
					pt.isIntraAccount = true;
					negativeForMonth[i].isIntraAccount = true;
				}
			}
		})
	})
	let monthPositiveSum = {}
	Object.keys(positiveTransactionsByMonth).forEach(month => {
		monthPositiveSum[month] = 0
		positiveTransactionsByMonth[month].forEach(t => {
			monthPositiveSum[month] += t.isIntraAccount ? 0 : t.amount.value
		})
	})
	let monthNegativeSum = {}
	Object.keys(negativeTransactionsByMonth).forEach(month => {
		monthNegativeSum[month] = 0
		negativeTransactionsByMonth[month].forEach(t => {
			monthNegativeSum[month] += -1 * t.isIntraAccount ? 0 : t.amount.value
		})
	})
	let positiveSum = monthPositiveSum[currentSelectedMonth]
	let negativeSum = -1 * monthNegativeSum[currentSelectedMonth]

	let savingsByMonth = {};
	Object.keys(negativeTransactionsByMonth).forEach(month => {
		savingsByMonth[month] = 0;
		negativeTransactionsByMonth[month].forEach(t => {
			savingsByMonth[month] += t.discountPotential ? Math.floor(t.discountPotential) : 0;
		})
	})
	let positiveCategorized = {};
	positiveTransactionsByMonth[currentSelectedMonth]?.forEach(t => {
		if (!positiveCategorized[t.shortDescription]) positiveCategorized[t.shortDescription] = {
			name: t.shortDescription,
			total: 0,
			data: [],
			show: false,
			merchant: t.vendorDetails?.Name_Options.replaceAll('"', '').split(',')[0],
			transaction: t
		}
		positiveCategorized[t.shortDescription].data.push(t)
		positiveCategorized[t.shortDescription].data.sort((a,b) => {
			return a.amount.value > b.amount.value ? -1 : 1
		})
		positiveCategorized[t.shortDescription].total += t.amount.value
	})
	const positiveKeys = Object.keys(positiveCategorized).sort((a,b) => {
		return positiveCategorized[a].total < positiveCategorized[b].total ? 1 : -1
	});
	let negativeCategorized = {};
	let discountPotentialTotal = 0;
	negativeTransactionsByMonth[currentSelectedMonth]?.forEach(t => {
		let key = t.merchantName
		if (!key) key = t.shortDescription
		if (!negativeCategorized[key]) negativeCategorized[key] = {
			name: key,
			total: 0,
			data: [],
			discountPotential: 0,
			show: false,
			merchant: t.vendorDetails?.Name_Options.replaceAll('"', '').split(',')[0],
			transaction: t
		};
		negativeCategorized[key].data.push(t);
		negativeCategorized[key].data.sort((a,b) => {
			return a.amount.value > b.amount.value ? 1 : -1
		})
		negativeCategorized[key].total += -1 * t.amount.value
		negativeCategorized[key].discountPotential += t.discountPotential ? Math.floor(t.discountPotential) : 0
		discountPotentialTotal += t.discountPotential ? Math.floor(t.discountPotential) : 0
	})
	const negativeKeys = Object.keys(negativeCategorized).sort((a,b) => {
		return negativeCategorized[a].total < negativeCategorized[b].total ? 1 : -1
	})

	const getSankeyData = () => {
		const transactions = [...positiveTransactionsByMonth[currentSelectedMonth]?.filter(t => !t.isIntraAccount),...negativeTransactionsByMonth[currentSelectedMonth]?.filter(t => !t.isIntraAccount)]
		let nodes = []
		let nodeNames = []
		transactions.forEach(t => {
			nodeNames.push(t.category.name + '_inc')
			nodeNames.push(t.category.name + '_exp')
		})
		let setNodes = [...new Set(nodeNames), 'SPENDING']
		setNodes.forEach(n => {
			nodes.push({name: n})
		})
		let links = []

		transactions.forEach(t => {
			if (t.amount.value > 0){
				// INCOME
				const source = setNodes.indexOf(t.category.name + '_exp')
				const target = setNodes.indexOf('SPENDING')
				let found = false
				links.forEach(l => {
					if (l.source === source && l.target === target){
						found = true
						l.value += t.amount.value
					}
				})
				!found && links.push({
					source: source,
					target: target,
					value: t.amount.value
				})
			} else {
				// EXPENSE
				const source = setNodes.indexOf('SPENDING')
				const target = setNodes.indexOf(t.category.name + '_inc')
				let found = false
				links.forEach(l => {
					if (l.source === source && l.target === target){
						found = true
						l.value -= t.amount.value
					}
				})
				!found && links.push({
					source: source,
					target: target,
					value: -1 * t.amount.value
				})
			}
		})
		return {
			nodes: nodes,
			links: links
		}
	}

	const renderTransactionListLargeHelp = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Marked transactions are between two of your accounts, and not included in the totals
		</Tooltip>
	)

	const renderTransactionListSmallHelp = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Deleted transactions are between two of your accounts, and not included in the totals
		</Tooltip>
	)

	useEffect(() => {
		let dataObject = {};
		Object.keys(monthPositiveSum).forEach(month => {
			dataObject[month] = {
				name: month,
				net: 0,
				incoming: 0,
				outgoing: 0,
				shopst: 0
			}
		})
		Object.keys(monthNegativeSum).forEach(month => {
			dataObject[month] = {
				name: month,
				net: 0,
				incoming: 0,
				outgoing: 0,
				shopst: 0
			}
		})
		Object.keys(dataObject).forEach(month => {
			dataObject[month].incoming = monthPositiveSum[month]
			dataObject[month].outgoing = -1 * monthNegativeSum[month]
			dataObject[month].net = (dataObject[month].incoming || 0) - (dataObject[month].outgoing || 0)
			dataObject[month].shopst = savingsByMonth[month]
		})

		let dataArray = Object.values(dataObject);
		if (dataArray.length === 0){
			const currentDate = new Date()
			dataArray.push({
				name: currentDate.getFullYear() + '-' + ('00' + (currentDate.getMonth() + 1)).slice(-2),
				net: 0,
				incoming: 0,
				outgoing: 0,
				shopst: 0
			})
		}
		dataArray = dataArray.sort((a,b) => a.name > b.name ? 1 : -1)
		setData(dataArray);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAccounts.length])

	useEffect(() => {
		setCurrentIndex(data.length - 1)
	}, [data])

	const scrollIntoViewHorizontally = (container, child, currentIndex) => {
		if (container === null || child === null) return
		const childWidth = child.getBoundingClientRect().width
		const containerWidth = container.getBoundingClientRect().width
		const isSmallScreen = childWidth/containerWidth > 0.85
		container.scrollLeft = childWidth * currentIndex + (!isSmallScreen ? childWidth/2 : 0)
	}

	useEffect(() => {
		const currentTarget = document.querySelector('[data-id="'+ currentIndex + '"]');
		const container = document.querySelector('[data-id="displayMonthContainer"]');
		scrollIntoViewHorizontally(container, currentTarget, currentIndex);
		setCurrentSelectedMonth(data[currentIndex]?.name);
	}, [currentIndex, data, setCurrentSelectedMonth, screenSize.dynamicWidth])

	const scrollTo = (event) => {
		const currentIndex = Number(event.currentTarget.dataset.index);
		setCurrentIndex(currentIndex);
	}

	const displayMonth = (monthData, index) => {
		const currentDate = new Date()
		const currentDateString = currentDate.getFullYear() + '-' + ('00' + (currentDate.getMonth() + 1)).slice(-2)

		return (
		<div style={{border: "4px solid " + (index === currentIndex ? "#B5D5FF" : "#00000000")}} className="p-1 ">
			<div onClick={e => scrollTo(e)} data-index={index} className={"interaction p-2 border " + (monthData.name === currentDateString ? "bg-dark-gray " :"bg-white ")}>
				<div className="text-center">
					<h5 className="mb-0">
						{dateFormatter(monthData.name, 'longMonth')}
					</h5>
				</div>
				<div className="text-center mb-3 text-muted" style={{"minHeight": "1.5rem"}}>
					{monthData.name === currentDateString ? "(current month)": ""}
				</div>
				<Row style={{"color": styles['asset-0']}}>
					<Col sm={8}>
						Income:
					</Col>
					<Col sm={4} className="text-end">
						{currencyFormatter(monthData.incoming, 2, true)}
					</Col>
				</Row>
				<Row style={{"color": styles['liability-0']}}>
					<Col sm={8}>
						Spending:
					</Col>
					<Col sm={4} className="text-end">
						{currencyFormatter(monthData.outgoing, 2, true)}
					</Col>
				</Row>
				<hr/>
				<Row style={{"color": monthData.net < 0 ? styles['negative-0'] : styles['positive-0'], "fontWeight": "700"}}>
					<Col sm={8}>
						{monthData.net < 0 ? "Net Overspend" : "Net Saving"}
					</Col>
					<Col sm={4} className="text-end">
						{currencyFormatter(monthData.net, 2, true)}
					</Col>
				</Row>
			</div>
			<div className="p-2 border-top-0" style={{border: "1px dashed var(--bs-border-color)"}}>
				<Row className="align-items-center">
					<Col sm={8}>
						<SSLogo size="22" />
						<div>Saving Potential</div>
					</Col>
					<Col sm={4} className="text-end">
						{currencyFormatter(monthData.shopst, 2, true)}
					</Col>
				</Row>
			</div>
		</div>
		)
	}

	const selector = () => {
		return (
			<>
			<div style={{position: "relative", width: "100%", height: "0", backgroundColor:"green", overflow: "visible"}}>
				{currentIndex > 0 && <Button variant="info" onClick={v=>{setCurrentIndex(Math.max(0, currentIndex - 1))}} className="date-selector-arrow" style={{float: "left", borderRadius: "9px 0 0 9px", boxShadow: "10px 0px 10px -5px #adb5bd"}}>
					<ChevronDoubleLeft style={{marginLeft:"-6px"}} />
				</Button>}
				{currentIndex < (data.length-1) && <Button variant="info" onClick={v=>{setCurrentIndex(Math.min(data.length - 1, currentIndex + 1))}} className="date-selector-arrow" style={{float: "right", borderRadius: "0 9px 9px 0", boxShadow: "-10px 0px 10px -5px #adb5bd"}}>
					<ChevronDoubleRight style={{marginLeft:"-6px"}} />
				</Button>}
			</div>
			<Row style={{position: "relative"}} className="shadow-scroll-h overflow-auto pb-3 flex-nowrap mx-1" data-id="displayMonthContainer" >
				<Col className="d-none d-lg-block" xs={0} lg={6} xl={6} xxl={6} data-id="start"></Col>
				{data.map((d,i) => {
					return (<Col xs={12} lg={6} xl={6} xxl={6} data-id={i} key={'dateSelector' + i}>{displayMonth(d, i)}</Col>)
				})}
				<Col className="d-none d-lg-block" xs={0} lg={6} xl={6} xxl={6} data-id="end"></Col>
			</Row>
			</>
		)
	}



	return (
		<>
			<div className="mx-0">
				<InteractionTile body={selector()} />
			</div>

			<div className="mt-5 mx-0 text-account-analysis-income">
				<Row className="">
					<Col xs={8} className="d-flex">
						<h4>
							Income
						</h4>
						<span className="mx-2">
							<OverlayTrigger
									placement="right"
									delay={{ show: 250, hide: 400 }}
									overlay={renderTransactionListSmallHelp}>
								<QuestionCircle />
							</OverlayTrigger>
						</span>
					</Col>
					<Col xs={4} className="text-end">
						<h4>{currencyFormatter(positiveSum, 2, true)}</h4>
					</Col>
				</Row>
			</div>
			<HorizontalColors mode="income" />
			<div className="mx-0">
				{positiveKeys.map((c,i) => {
					if (i < currentPositiveDisplayTotal){
						return (<DisplayIncomeAndExpensesRow showSavingColumn={false} key={"positiveRowContainer" + i} categorized={positiveCategorized} c={c} cssColor="text-asset-color" />)
					}
					if (i === currentPositiveDisplayTotal){
						return (
							<Button
									onClick={(e) => {
										setCurrentPositiveDisplayTotal(currentPositiveDisplayTotal + 10);
									}}
									key="positiveShowMore"
									className="my-2"
									variant="info"
									style={{"width": "100%"}}>
								<ChevronDoubleDown/> View More <ChevronDoubleDown/>
							</Button>
						)
					}
					return null
				})}

			</div>
			<div className="mt-3 mx-0 text-account-analysis-spending">
				{/* <Row className="d-none d-md-flex align-items-end">
					<Col xs={2}>
						<h4>Spending</h4>
					</Col>
					<Col xs={6}>

					</Col>
					<Col xs={2} className="text-end">
						<h4>{currencyFormatter(negativeSum, 2, true)}</h4>
					</Col>
					<Col xs={2} className="text-center text-saving-color">
						<div>
							<SSLogo size="22" />
						</div>
						<h4>
							Saving Potential
						</h4>
						<h4>{currencyFormatter(discountPotentialTotal, 2, true)}</h4>
					</Col>
				</Row> */}
				<Row className="">
					<Col xs={6} className="d-flex">
						<h4>
							Spending
						</h4>
						<span className="mx-2">
							<OverlayTrigger
									placement="right"
									delay={{ show: 250, hide: 400 }}
									overlay={renderTransactionListSmallHelp}>
								<QuestionCircle />
							</OverlayTrigger>
						</span>
					</Col>
					<Col xs={6} className="text-end">
						<h4>{currencyFormatter(negativeSum, 2, true)}</h4>
					</Col>
				</Row>
				{discountPotentialTotal > 0 && <Row className="align-items-center">
					<Col xs={8} className="text-saving-color fs-5">
						<SSLogo size="22" marginTop="-4px" /> Saving Potential
					</Col>
					<Col xs={4} className="text-end">
						<h4 className="text-saving-color">{currencyFormatter(discountPotentialTotal, 2, true)}</h4>
					</Col>
				</Row>}
			</div>
			<HorizontalColors mode="expenses" />
			<div className="mx-0">
				{negativeKeys.map((c,i) => {
					if (i < currentNegativeDisplayTotal){
						return (<DisplayIncomeAndExpensesRow key={"negativeRowContainer" + i} categorized={negativeCategorized} c={c} cssColor="text-spending-color" />)
					}
					if (i === currentNegativeDisplayTotal){
						return (
							<Button
									onClick={(e) => {
										setCurrentNegativeDisplayTotal(currentNegativeDisplayTotal + 10);
									}}
									key="negativeShowMore"
									className="my-2"
									variant="info"
									style={{"width": "100%"}}>
								<ChevronDoubleDown/> View More <ChevronDoubleDown/>
							</Button>
						)
					}
					return null
				})}
			</div>
			{/* {displayMode === 'categories' && <div style={{width: "100%", height: "1000px"}}>
				<Sankey
						width={800}
						height={1000}
						margin={{left: 20, right: 150, top: 200, bottom: 200}}
						data={getSankeyData()}
						node={<SankeyNode containerWidth={800}/>}
						nodeWidth={15}
						nodePadding={15}
						linkCurvature={0.6}
						iterations={0}
						link={{ stroke: '#77c878' }}>
					<Tooltip />
				</Sankey>
			</div>} */}
		</>
	)
}

export default DisplayIncomeAndExpenses;