import housePriceIndex from '../../assets/model/house_price_index';

/**
 * Forecast Property values
 * @param {*} moneyHubAssetTypes The object type map to get specific property types from
 * @param {*} user The current user
 * @param {*} accounts The list of accounts for the current user
 * @param {*} setLoadingStage Function to update the loading stage
 */
export const forecastProperty = async ( moneyHubAssetTypes, user, accountState, setLoadingStage ) => {
	let accounts = [...accountState.state]
	const propertyTypes = moneyHubAssetTypes['property']
	const today = new Date().toISOString().split('T')[0]

	// Start at the users aged 18 date, or the earliest account balance
	let startDate = user.dateAged18
	accounts.filter(a => propertyTypes.includes(a.type)).forEach(a => {
		if (a.balances?.length > 0) {
			if (new Date(a.balances[0].date) < new Date(startDate)){
				startDate = a.balances[0].date
			}
		}
	})
	// End at the users aged 90 date
	const endDate = user.dateAged90

	// For property type accounts, create a forecast
	accounts.filter(a => propertyTypes.includes(a.type)).forEach(a => {
		let currentValue = { low: null, base: null, high: null }
		let purchasePrice = null
		let purchaseDate = null
		let currentDate = new Date(startDate)
		let valueDataGraph = []
		let keyDates = []

		// For each day from start to end dates
		while (currentDate <= endDate){
			const shortDate = currentDate.toISOString().split('T')[0]
			let currentBalances = a.balances?.filter(b => b.date === shortDate).sort((a,b) => a.date < b.date ? 1 : -1)
			if (currentBalances == null) return

			if (currentBalances.length > 0) {
				// A specific balance is available for this day
				currentValue = {
					low: currentBalances[0].amount.value,
					base: currentBalances[0].amount.value,
					high: currentBalances[0].amount.value
				}
				if (purchasePrice === null) {
					purchasePrice = currentBalances[0].amount.value
					purchaseDate = currentDate
				}
				keyDates.push(currentDate)
			} else if (currentValue.low != null && currentValue.base != null && currentValue.high != null){
				// Generate a forecast balance for this day
				const yearRate = housePriceIndex[currentDate.getFullYear()]
				const baseRate = 1 + (yearRate.base/100)
				const lowRate = yearRate.low ? (1 + (yearRate.low/100)) : baseRate
				const highRate = yearRate.high ? (1 + (yearRate.high/100)) : baseRate
				currentValue.low = currentValue.low * (Math.pow(lowRate, 1/364))
				currentValue.base = currentValue.base * (Math.pow(baseRate, 1/364))
				currentValue.high = currentValue.high * (Math.pow(highRate, 1/364))
			}

			// Setup the extra information needed from the new forecasts
			if (shortDate === today) {
				a.currentValue = {...currentValue}
				keyDates.push(currentDate)
			}
			if (keyDates.includes(currentDate) || (currentDate.getDate() === 1)){
				valueDataGraph.push({
					name: Number(currentDate),
					low: currentValue.low,
					base: currentValue.base,
					high: currentValue.high,
					highDiff: currentValue.high - currentValue.low
				})
			}
			currentDate = addDays(currentDate, 1)
		}

		a.valueForecast = valueDataGraph
		a.initialPurchase = {
			value: purchasePrice,
			date: purchaseDate
		}
	})
	accountState.setState(accounts)
	// Update the UI to say property forecasts are complete
	setLoadingStage(oldLoadingStage => ({...oldLoadingStage, forecastProperty: true}))
}

/**
 * Adds days to a date
 * @param {Date} date
 * @param {Integer} days
 * @returns
 */
const addDays = (date, days) => {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}