import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../Main";

import { PageTitle, AccountSelector, Tile, InteractionTile, ForecastChart } from '../../components/components';
import TwoColumnContentLayout from '../../layouts/TwoColumnContentLayout';
import { ArrowUp, ArrowDown } from "react-bootstrap-icons";

import { Row, Col, Modal } from 'react-bootstrap';

import currencyFormatter from "../../utils/CurrencyFormatter";
import dateFormatter from "../../utils/DateFormatter";
import moneyHubAssetTypes from "../../utils/MoneyHubAssetTypes";

const Property = ( ) => {

	const userContext = useContext(UserContext)
	const selected = "PropertySelected"
	const displayTypes = moneyHubAssetTypes['property']

	const selectedAccounts = userContext.accounts.filter(a => a[selected] && displayTypes.includes(a.type))
	useEffect(() => {
	}, [selectedAccounts])

	const RenderTooltip = ({ active, label, payload, displayConfig }) => {
		const tooltipContents = () => {
			let propertyData = { low: 0, base: 0, high: 0 }
			let propertyCount = 0
			let propertyName = ''
			displayConfig.chartNames.forEach((n,i) => {
				let low = payload.filter(p => p.dataKey === n + '_l')
				let base = payload.filter(p => p.dataKey === n)
				let high = payload.filter(p => p.dataKey === n + '_h')
				if (low.length > 0) propertyData.low += low[0].value
				if (base.length > 0) propertyData.base += base[0].value
				if (high.length > 0) propertyData.high += high[0].value
				propertyCount ++
				propertyName = n
			})
			return (
				<div className="text-muted fs-6">
					{label && <h6>{dateFormatter(new Date(label).toISOString(), 'yearOnly')}</h6>}
					<div className="">
						<b>Property Forecast:</b>
					</div>
					<div>
						<div style={{float: "left"}}>
							High est.&nbsp;
						</div>
						<div className="text-end" style={{float: "right"}}>
							{currencyFormatter(Math.max(0, propertyData.high))}
						</div>
					</div>
					<div className="text-asset-color-1">
						<div className="text-truncate" style={{float: "left"}}>
							<b>{propertyCount === 1 ? (propertyName + ' est.') : 'Medium est.'}&nbsp;</b>
						</div>
						<div className="text-end" style={{float: "right"}}>
							<b>{currencyFormatter(Math.max(0, propertyData.base))}</b>
						</div>
					</div>
					<div>
						<div style={{float: "left"}}>
							Low est.&nbsp;
						</div>
						<div className="text-end" style={{float: "right"}}>
							{currencyFormatter(Math.max(0, propertyData.low))}
						</div>
					</div>

					{/* {label && <h5 className="text-center">{dateFormatter(new Date(label).toISOString(), 'yearOnly')}</h5>}
					<Row>
						<Col xs={1}>
							<SquareFill style={{"fill": styles['asset-2']}} />
						</Col>
						<Col sm={4}>
							Properties:
						</Col>
						<Col sm={7} className="text-asset-color">
							<Row className="g-1">
								<Col xs={2}></Col>
								<Col xs={8} className="text-center">
									<div className="d-inline-flex">
										<h5 className="mb-0">
											{currencyFormatter(Math.max(0, propertyData.base))}
										</h5>
									</div>
								</Col>
								<Col xs={2}></Col>
							</Row>
							<Row className="g-1 fs-6">
								<Col xs={4} className="text-end">
									{currencyFormatter(Math.max(0, propertyData.low))}
								</Col>
								<Col xs={2}>
									<ArrowBarRight />
								</Col>
								<Col xs={2} className="text-end">
									<ArrowBarLeft />
								</Col>
								<Col xs={4} className="text-start">
									{currencyFormatter(Math.max(0, propertyData.high))}
								</Col>
							</Row>
						</Col>
					</Row> */}
				</div>
			)
		}
		return(
			active &&
					<InteractionTile body={tooltipContents()} padding="p-1" bottomMargin="" />
		)
	}

	const lineChart = () => {
		let chartData = []
		let chartNames = []
		let chartDates = []
		selectedAccounts.forEach(a => {
			chartNames.push(a.accountName)
		})
		selectedAccounts.forEach(a => {
			a.valueForecast?.forEach(f => {
				chartDates.push(f.name)
			})
		})
		chartDates = [...new Set(chartDates)]
		chartDates.sort((a,b) => a > b ? 1 : -1)
		chartDates.forEach(d => {
			let emptyDataPoint = {}
			emptyDataPoint.name = d
			chartNames.forEach(n => {
				emptyDataPoint[n + '_l'] = null
				emptyDataPoint[n] = null
				emptyDataPoint[n + '_h'] = null
				emptyDataPoint[n + '_hd'] = null
			})
			emptyDataPoint.preTodayZeros = null
			emptyDataPoint.postTodayZeros = null
			chartData.push(emptyDataPoint)
		})

		selectedAccounts.forEach(a => {
			a.valueForecast?.forEach(f => {
				let data = chartData.filter(cd => cd.name === f.name)[0]
				data[a.accountName + '_l'] = Math.floor(f.low)
				data[a.accountName] = Math.floor(f.base)
				data[a.accountName + '_h'] = Math.floor(f.high)
				data[a.accountName + '_hd'] = Math.floor(f.highDiff)
			})
		})
		let previousData = {}
		let zerosStart = false
		const today = new Date()
		chartData.forEach(cd => {
			chartNames.forEach(n => {
				if (!cd[n + '_l']) cd[n + '_l'] = previousData[n + '_l']
				if (!cd[n]) cd[n] = previousData[n]
				if (!cd[n + '_h']) cd[n + '_h'] = previousData[n + '_h']
				if (!cd[n + '_hd']) cd[n + '_hd'] = previousData[n + '_hd']
				previousData[n + '_l'] = cd[n + '_l']
				previousData[n] = cd[n]
				previousData[n + '_h'] = cd[n + '_h']
				previousData[n + '_hb'] = cd[n + '_hb']
				if (cd[n] !== undefined) zerosStart = true
			})
			if (zerosStart && cd.name <= today) cd.preTodayZeros = 0
			if (zerosStart) cd.postTodayZeros = 0
		})

		let dataConfig = {}
		// dataConfig.renderLegend = RenderLegend
		dataConfig.renderTooltip = RenderTooltip
		dataConfig.chartNames = chartNames

		return (<>
			<h5>Your Property Forecast</h5>
			<div className="" style={{"height":"300px"}}>
				<ForecastChart data1={chartData} displayConfig={dataConfig} />
			</div>
		</>)
	}

	const HelpPopover = () => {
		const [ showHelpPopover, setShowHelpPopover ] = useState(false)
		return (
			<>
			<Row role="button" className="text-muted fs-6 pe-auto flex-row-reverse" style={{marginRight: "0.25rem", textDecoration:"underline", fontWeight: "300"}} onClick={()=>{setShowHelpPopover(true); return false}}>
				How is the Property Value calculated & forecasted?
			</Row>
			<hr className="mt-0" />
			<Modal centered show={showHelpPopover}>
				<Modal.Header closeButton onHide={() => setShowHelpPopover(false)} className="text-muted">
					<h4>How is the Property Value calculated & forecasted?</h4>
				</Modal.Header>
				<Modal.Body className="text-muted">
					<p>
						The property forecast provides an indication of what a property value would be today based upon forecasts from the UK House Price Index. This is an indication and is not a guaranteed value of your property.
					</p>
					<p>
						Our team reviews and collates information from many different sources and provides a range of possible scenarios for forecasts. 
					</p>
					<p className="fs-6">
						The forecast is an estimation and example of the future value of a property could look like from today. This is not a guarantee of future value. These assumptions may not accurately reflect your actual investment portfolio or portfolio risk level. The information we show should not be interpreted as financial advice and/or recommendation. Please refer to the ‘Prift End User Terms’ for further information.
					</p>
				</Modal.Body>
			</Modal>
			</>
		)
	}

	const propertyDetails = () => {
		let purchaseDetails = []
		let purchasePriceTotal = 0
		let currentValueTotal = 0
		selectedAccounts.forEach(a => {
			purchaseDetails.push({...a.initialPurchase, name: a.accountName})
			purchasePriceTotal += a.initialPurchase?.value
			currentValueTotal += a.currentValue?.base
		})
		const valueIncrease = currentValueTotal - purchasePriceTotal
		const valuePercentageIncrease = Math.floor(1000 * valueIncrease / purchasePriceTotal) / 10

		return (
			<Tile body={<>
				{(selectedAccounts.length === 1) && <div>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							<h5 className="text-dark mb-0 pb-0">Property Details:</h5>
						</Col>
						<Col xs={6} className="text-dark">
							{purchaseDetails[0].name}
						</Col>
					</Row>
					{purchaseDetails[0]?.date && <Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							Purchase Price:
						</Col>
						<Col xs={6} className="">
						{/* Error here when adding property */}
							<div className="text-asset-color">{currencyFormatter(purchaseDetails[0]?.value, 0, true)}</div>
							<div className="fs-6">({dateFormatter(new Date(purchaseDetails[0]?.date).toISOString(), 'mmmYYYY')})</div>
						</Col>
					</Row>}
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							Value change since purchase:
						</Col>
						<Col xs={6} className="">
							<div className={valueIncrease >= 0 ? "text-asset-color" : "text-spending-color"}>
								<div className="d-flex">
									<div style={{marginTop: "-3px"}}>
										{valueIncrease >= 0 ? <ArrowUp /> : <ArrowDown />}
									</div>
									<div>
										{currencyFormatter(valueIncrease, 0, true)}
									</div>
								</div>
							</div>

							<div className="fs-6">{valuePercentageIncrease}%</div>
						</Col>
					</Row>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							Est. Current Value:
						</Col>
						<Col xs={6} className="text-asset-color">
							{currencyFormatter(currentValueTotal, 0, true)}
						</Col>
					</Row>
				</div>}
				{(selectedAccounts.length > 1) && <div>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							<h5 className="text-dark mb-0 pb-0">Property Details:</h5>
						</Col>
						<Col xs={6} className="">
							Multiple Selected
						</Col>
					</Row>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							Total Purchase Price:
						</Col>
						<Col xs={6} className="">
							<div className="text-asset-color">{currencyFormatter(purchaseDetails[0].value, 0, true)}</div>
						</Col>
					</Row>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							Value change since purchases:
						</Col>
						<Col xs={6} className="">
							<div className={valueIncrease >= 0 ? "text-asset-color" : "text-spending-color"}>
								<div className="d-flex">
									<div style={{marginTop: "-3px"}}>
										{valueIncrease >= 0 ? <ArrowUp /> : <ArrowDown />}
									</div>
									<div>
										{currencyFormatter(valueIncrease, 0, true)}
									</div>
								</div>
							</div>

							<div className="fs-6">{valuePercentageIncrease}%</div>
						</Col>
					</Row>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							Total est. Current Value:
						</Col>
						<Col xs={6} className="text-asset-color">
							{currencyFormatter(currentValueTotal, 0, true)}
						</Col>
					</Row>
				</div>}
				{(selectedAccounts.length === 0) && <div>
					Select an property to see more details
				</div>}
			</>} />
		)
	}

	return (
		<>
			<PageTitle pageName="Properties" helpPage="property" >
				<TwoColumnContentLayout
						left={<AccountSelector title="Properties" displayTypes={displayTypes} selected={selected} valuePath='currentValue.base' editButtonText="Edit Property" />}
						right={<span className="text-muted">{propertyDetails()}</span>} />

				<HelpPopover />

				{lineChart()}
			</PageTitle>
		</>
	)
}

export default Property;