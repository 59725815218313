const axios = require('axios');

const generateAuthorizationUrl = async function(token, moneyhubId, bankId, successCallback, errorCallback){
	const requestConfig = {
		headers: {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'x-api-key': process.env.REACT_APP_AWS_API_KEY,
			'Authorization': token,
		}
	}
	const requestBody = {
		userId: moneyhubId,
		bankId: bankId
	}
	await axios.post(process.env.REACT_APP_AWS_DATA_ENDPOINT + '/mh/generate-url', requestBody, requestConfig).then(response => {
		successCallback(response);
		return;
	}).catch(error => {
		console.error('Error (FE/s/d/generateAuthorizationUrl): ', error);
		errorCallback(error);
		return;
	})
}

export default generateAuthorizationUrl;