const axios = require('axios');

const getShopStVendors = async function(token, successCallback){
	const requestConfig = {
		headers: {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'x-api-key': process.env.REACT_APP_AWS_API_KEY,
			'Authorization': token,
		}
	}
	await axios.get(process.env.REACT_APP_AWS_DATA_ENDPOINT + '/ss/vendors', requestConfig).then(response => {
		let data = response.data.vendors.Items
		data.forEach(ssv => {
			ssv.targets = ssv.Name_Options.toLowerCase().replaceAll('"', '').split(',');
		})
		data.sort((a,b) => {
			return a.Name < b.Name ? -1 : 1
		})
		successCallback(data)
		return
	}).catch(error => {
		console.error('Error (FE/s/d/getShopStVendors): ', error)
		return
	})
}

export default getShopStVendors;