export const menuItems = [
	{
		title: 'Home',
		url: ''
	},
	{
		title: 'Assets',
		submenu: [
			{
				title: 'Bank Accounts',
				url: '/bank-account'
			},
			{
				title: 'Pensions',
				url: '/pension'
			},
			{
				title: 'Properties',
				url: '/property'
			}
		]
	},
	{
		title: 'Debts',
		submenu: [
			{
				title: 'Credit Cards',
				url: '/bank-account'
			},
			{
				title: 'Mortgage',
				url: '/mortgage'
			}
		]
	},
	{
		title: 'Spending Insights',
		url: '/spending-insights'
	},
	{
		title: 'Shop St Savings',
		url: '/shop-st-savings'
	},
	{
		title: 'My Profile',
		submenu: [
			{
				title: 'My Details',
				url: '/my-details'
			},
			{
				title: 'Manage Accounts',
				url: '/manage-accounts'
			}
		]
	},
	{
		title: 'Help & Support',
		url: '/help'
	},
	{
		title: 'Logout',
		url: '/logout'
	},
]