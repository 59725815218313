import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Main";

import QRCode from "react-qr-code";
import { House, Bank, PiggyBank, CashCoin, CreditCard, CashStack, QuestionSquare, CheckLg } from "react-bootstrap-icons";

import { PageTitle } from '../../components/components';
import { generateAuthorizationUrl } from "../../service/DataService";

import { createMhAccount } from '../../service/DataService';
import { getSessionTokenAsync } from '../../utils/GetSessionTokenAsync';

import {
		Form,
		Row,
		Col,
		Container,
		Image,
		Button,
		Modal,
		Spinner,
		InputGroup,
		ButtonGroup,
		ToggleButton
	} from 'react-bootstrap';

const AddAsset = () => {

	const navigate = useNavigate()
	const userContext = useContext(UserContext)

	const [ authUrl, setAuthUrl ] = useState('')
	const [ searchKey, setSearchKey ] = useState('')
	const [ showQRModal, setShowQRModal ] = useState(false)
	const [ addAccountType, setAddAccountType ] = useState('none')
	const [ isLoading, setIsLoading ] = useState(false)
	const [ navigationTarget, setNavigationTarget ] = useState('')
	const [ currentBankId, setCurrentBankId ] = useState('')

	const [ showPropertyButton, setShowPropertyButton ] = useState('continue')
	const [ zooplaUrl, setZooplaUrl ] = useState('')

	const [ saveComplete, setSaveComplete ] = useState(false)
	useEffect(() => {
		if (saveComplete){
			setIsLoading(false)
			userContext.setLoadingMessage('Waiting for account details')
			setTimeout(() => {
				setSaveComplete(false)
				userContext.reloadAccounts(false)
				userContext.setLoadingBlocker(true)
				navigate(navigationTarget)
			}, 1500)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saveComplete])

	const addMonths = (date, months) => {
		var d = date.getDate();
		date.setMonth(date.getMonth() + +months);
		if (date.getDate() !== d) {
		  date.setDate(0);
		}
		return date;
	}

	const generateUrl = async (e) => {
		setAuthUrl("")
		setShowQRModal(true)
		setAddAccountType('none')
		const { bankId } = e.currentTarget.dataset
		setCurrentBankId(bankId)
		const currentSession = await getSessionTokenAsync()
		await generateAuthorizationUrl(
			currentSession,
			userContext.user.moneyhubUser,
			bankId,
			(response) => {
				setAuthUrl(response.data.authorizationUrl)
			},
			(error) => {
				console.error(error)
			}
		)
	}

	const handleModalClose = () => {
		if (!userContext.user.isDemo) {
			userContext.reloadAccounts()
		}
		setSearchKey('')
		setShowQRModal(false)
	}

	const handleModalFinish = () => {
		navigate(navigationTarget)
		setTimeout(() => userContext.setLoadingBlocker(true), 200)
	}

	const accountTypes = () => {
		return ([
			{
				name: 'property',
				label: 'Property',
				icon: () => (<House style={{fontSize: "3rem"}} />),
				allowManual: true
			},
			{
				name: 'mortgage',
				label: 'Mortgage',
				icon: () => (<Bank style={{fontSize: "3rem"}} />),
				allowManual: true
			},
			{
				name: 'cash',
				label: 'Bank Account',
				icon: () => (<CashCoin style={{fontSize: "3rem"}} />),
				allowManual: false
			},
			{
				name: 'savings',
				label: 'Savings Account',
				icon: () => (<CashStack style={{fontSize: "3rem"}} />),
				allowManual: false
			},
			{
				name: 'card',
				label: 'Credit Card',
				icon: () => (<CreditCard style={{fontSize: "3rem"}} />),
				allowManual: false
			},
			{
				name: 'pension',
				label: 'Pension',
				icon: () => (<PiggyBank style={{fontSize: "3rem"}} />),
				allowManual: true
			}
		])
	}


	const connectionOptions = (type, searchKey) => (
		userContext.connections.map((o,i) => (
				o.accountTypes.map(i => i.name).includes(type) &&
				o.friendlyName.toLowerCase().includes(searchKey.toLowerCase()) &&
			<Container key={'connection' + o.name + i} className="mb-1">
				<Row className="align-items-center" style={{height: "40px"}}>
					<Col xs={2} sm={1} style={{overflow: "hidden"}}>
						<Image src={o.iconUrl} height="40px" />
					</Col>
					<Col xs={10} sm={11}>
						<div data-bank-id={o.id} onClick={generateUrl}>
							<Button className="p-0" variant="link">{o.friendlyName}</Button>
						</div>
					</Col>
				</Row>
			</Container>
		))
	)

	const [showPensionManualScreen, setShowPensionManualScreen] = useState(false)
	const PensionManualScreen = () => {
		const [validated, setValidated] = useState(false);
		const [inputValue, setInputValue] = useState({
			accountName: '',
			providerName: '',
			valueDate: new Date().toISOString().split('T')[0],
			value: 0
		})
		const handleOnChange = (e) => {
			const {name, value} = e.target;
			setInputValue({
				...inputValue,
				[name]: value
			})
		}

		const savePension = async () => {
			const form = document.getElementById('manualPension')
			if (form.checkValidity() === true) {
				setIsLoading(true)
				setShowPensionManualScreen(false)
				let pension = {
					accountName: inputValue.name,
					providerName: inputValue.provider,
					type: 'pension',
					balance: {
						date: inputValue.valueDate || new Date(),
						amount: {
							value: Number(inputValue.value) * 100
						}
					}
				}
				const currentSession = await getSessionTokenAsync()
				await createMhAccount(currentSession, userContext.user.moneyhubUser, pension, () => {setSaveComplete(true); setIsLoading(false)})
				setNavigationTarget('/pension')
			}
			setValidated(true)
		}

		return (
			<Modal.Body className="">
				<Form noValidate validated={validated} id="manualPension">
					<Row>
						<Form.Group as={Col} className="mb-3" controlId="pensionName" sm={6}>
							<Form.Label>
								Name
							</Form.Label>
							<Form.Control required placeholder="My Pension" name="name" onChange={handleOnChange} />
						</Form.Group>
						<Form.Group as={Col} className="mb-3" controlId="pensionProviderName" sm={6}>
							<Form.Label>
								Provider
							</Form.Label>
							<Form.Control required placeholder="Pension Provider" name="provider" onChange={handleOnChange} />
						</Form.Group>
					</Row>
					<Form.Group as={Row} className="mb-3" controlId="pensionCurrentValue">
						<Form.Label>
							Todays Value
						</Form.Label>
						<InputGroup>
							<InputGroup.Text>£</InputGroup.Text>
							<Form.Control required aria-label="Amount (to the nearest pound)" name="value" onChange={handleOnChange} />
							<InputGroup.Text>.00</InputGroup.Text>
						</InputGroup>
					</Form.Group>

					<Form.Group as={Row} className="mb-3">
						<div className="d-grid gap-2">
							<Button variant="primary" disabled={userContext.user.isDemo} onClick={savePension}>{userContext.user.isDemo ? 'Unavailable on a demo account' : 'Save'}</Button>
						</div>
					</Form.Group>
				</Form>
			</Modal.Body>
		)
	}

	const [showMortgageManualScreen, setShowMortgageManualScreen] = useState(false)
	const MortgageManualScreen = () => {


		const [validated, setValidated] = useState(false);
		const [ selectedMortgageType, setSelectedMortgageType ] = useState('repayment')
		const [ selectedMortgageInterestType, setSelectedMortgageInterestType ] = useState('fixed')
		const [inputValue, setInputValue] = useState({
			accountName: '',
			providerName: '',
			type: '',
			valueDate: new Date().toISOString().split('T')[0],
			value: 0,
			APR: 0,
			payment: 0,
			term: 0,
			endDate: new Date().toISOString().split('T')[0],
			fixedEndDate: new Date().toISOString().split('T')[0]
		})
		const handleOnChange = (e) => {
			const {name, value} = e.target;
			if (name === 'term'){
				setInputValue({
					...inputValue,
					[name]: value * 12,
					'endDate': new Date(addMonths(new Date(), value * 12)).toISOString().split('T')[0]
				})
			} else if (name === 'value' || name === 'payment'){
				setInputValue({
					...inputValue,
					[name]: Math.abs(value)
				})
			} else {
				setInputValue({
					...inputValue,
					[name]: value
				})
			}
		}

		const saveMortgage = async () => {
			const form = document.getElementById('manualMortgage')
			if (form.checkValidity() === true) {
				setIsLoading(true)
				setShowMortgageManualScreen(false)
				let mortgage = {
					accountName: inputValue.name,
					providerName: inputValue.provider || 'personal',
					type: 'mortgage:' + selectedMortgageType,
					balance: {
						date: inputValue.valueDate,
						amount: {
							value: Number(inputValue.value) * 100
						}
					},
					details: {
						APR: Number(inputValue.APR),
						monthlyRepayment: Number(inputValue.payment) * 100,
						term: Number(inputValue.term),
						endDate: inputValue.endDate,
						interestType: selectedMortgageInterestType,
						fixedDate: inputValue.fixedEndDate
					}
				}
				const currentSession = await getSessionTokenAsync()
				await createMhAccount(currentSession, userContext.user.moneyhubUser, mortgage, () => {setSaveComplete(true); setIsLoading(false)})
				setNavigationTarget('/mortgage')
			}
			setValidated(true)
		}

		return (
			<Modal.Body className="">
				<Form noValidate validated={validated} id="manualMortgage">
					<Row>
						<Form.Group as={Col} className="mb-3" controlId="mortgageName" sm={6}>
							<Form.Label>
								Name
							</Form.Label>
							<Form.Control required placeholder="My Mortgage" name="name" onChange={handleOnChange} />
						</Form.Group>
						<Form.Group as={Col} className="mb-3" controlId="mortgageProviderName" sm={6}>
							<Form.Label>
								Provider
							</Form.Label>
							<Form.Control placeholder="Mortgage Provider" name="provider" onChange={handleOnChange} />
						</Form.Group>
					</Row>
					<Form.Group as={Row} className="mb-3">
						<Form.Label>
							Mortgage Type
						</Form.Label>
						<ButtonGroup>
							<ToggleButton
									id="mortgageType:repayment"
									type="radio"
									variant="outline-primary"
									name="repayment"
									checked={selectedMortgageType === 'repayment'}
									onChange={(e) => setSelectedMortgageType(e.currentTarget.name)} >
								Repayment
							</ToggleButton>
							<ToggleButton
									id="mortgageType:interestOnly"
									type="radio"
									variant="outline-primary"
									name="interestOnly"
									checked={selectedMortgageType === 'interestOnly'}
									onChange={(e) => setSelectedMortgageType(e.currentTarget.name)} >
								Interest Only
							</ToggleButton>
						</ButtonGroup>
					</Form.Group>
					<Form.Group as={Row} className="mb-3" controlId="mortgageCurrentValue">
						<Form.Label>
							Balance
						</Form.Label>
						<InputGroup>
							<InputGroup.Text>£</InputGroup.Text>
							<Form.Control required aria-label="Amount (to the nearest pound)" name="value" onChange={handleOnChange} pattern='^-?\d*$' />
							<InputGroup.Text>.00</InputGroup.Text>
							<Form.Control required type="date" defaultValue={new Date().toISOString().substring(0, 10)} name="valueDate" onChange={handleOnChange} aria-describedby="dateHelpBlock" max={new Date().toISOString().split('T')[0]} />
							<Form.Control.Feedback type="invalid">
								Please add a balance (to the nearest £) and valid date
							</Form.Control.Feedback>
						</InputGroup>
						<Form.Text id="dateHelpBlock" muted>
							Either the current balance, or the balance when you first got this mortgage, you should be able to find this information on your mortgage statement
						</Form.Text>
					</Form.Group>
					<Row>
						<Form.Group as={Col} className="mb-3" controlId="mortgagePayment" sm={6}>
							<Form.Label>
								Current Monthly Payment
							</Form.Label>
							<InputGroup >
								<InputGroup.Text>£</InputGroup.Text>
								<Form.Control required aria-label="Amount (to the nearest pound)" name="payment" onChange={handleOnChange} pattern='^\d*$' />
								<InputGroup.Text>.00</InputGroup.Text>
							</InputGroup>
						</Form.Group>
						<Form.Group as={Col} className="mb-3" controlId="mortgageAPR" sm={6}>
							<Form.Label>
								Current APR
							</Form.Label>
							<InputGroup>
								<Form.Control required name="APR" onChange={handleOnChange} pattern='^\d*\.?\d+$'/>
								<InputGroup.Text>%</InputGroup.Text>
							</InputGroup>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} className="mb-3" controlId="mortgageTerm" sm={6}>
							<Form.Label>
								Remaining Term (years from today)
							</Form.Label>
							<Form.Control required placeholder="Mortgage Term" name="term" onChange={handleOnChange} pattern='^\d*$' />
						</Form.Group>
						{/* <Form.Group as={Col} className="mb-3" controlId="mortgageEndDate" sm={6}>
							<Form.Label>
								End Date
							</Form.Label>
							<Form.Control required type="date" name="endDate" onChange={handleOnChange} />
						</Form.Group> */}
					</Row>
					<Form.Group as={Row} className="mb-3">
						<Form.Label>
							Interest Type
						</Form.Label>
						<ButtonGroup>
							<ToggleButton
									id="mortgageInterestType:fixed"
									type="radio"
									variant="outline-primary"
									name="fixed"
									checked={selectedMortgageInterestType === 'fixed'}
									onChange={(e) => setSelectedMortgageInterestType(e.currentTarget.name)} >
								Fixed / Tracker
							</ToggleButton>
							<ToggleButton
									id="mortgageInterestType:variable"
									type="radio"
									variant="outline-primary"
									name="variable"
									checked={selectedMortgageInterestType === 'variable'}
									onChange={(e) => setSelectedMortgageInterestType(e.currentTarget.name)} >
								Variable
							</ToggleButton>
						</ButtonGroup>
					</Form.Group>
					{selectedMortgageInterestType === 'fixed' && <Row>
						<Form.Group required as={Col} className="mb-3" controlId="mortgageFixedEndDate" sm={6}>
							<Form.Label>
								When does the Fixed/Tracker period end?
							</Form.Label>
							<Form.Control required type="date" name="fixedEndDate" onChange={handleOnChange} />
						</Form.Group>
					</Row>}

					<Form.Group as={Row} className="mb-3">
						<div className="d-grid gap-2">
							<Button variant="primary" disabled={userContext.user.isDemo} onClick={saveMortgage}>{userContext.user.isDemo ? 'Unavailable on a demo account' : 'Save'}</Button>
						</div>
					</Form.Group>
				</Form>
			</Modal.Body>
		)
	}

	const PropertyManualScreen = () => {

		useEffect(() => {
			if (zooplaUrl === ''){
				getHometrackZooplaUrl()
			}
		}, [])

		const getHometrackZooplaUrl = async () => {
			let homeTrackZooplaConnections = userContext.connections.filter(o => o.type === 'zoopla')
			const currentSession = await getSessionTokenAsync()
			await generateAuthorizationUrl(
				currentSession,
				userContext.user.moneyhubUser,
				homeTrackZooplaConnections[0].id,
				(response) => {
					setZooplaUrl(response.data.authorizationUrl)
					setNavigationTarget('/property')
				},
				(error) => {
					setZooplaUrl('')
				}
			)
		}

		return (
			<Modal.Body className="">
				{zooplaUrl === '' && <>
					Loading Property Connection..
				</>}
				{zooplaUrl !== '' && <>

					<div className="text-center">
						<h5>Continue with Zoopla</h5>
						<p>To get an accurate current valuation of your property we will navigate you to a MoneyHub page to connect to Zoopla via Hometrack.</p>
						{showPropertyButton === 'continue' && <a href={zooplaUrl} target="_blank" rel="opener">
							<Button onClick={() => {setShowPropertyButton('finish')}}>Continue</Button>
						</a>}
						{showPropertyButton === 'finish' && <>
							<Button onClick={handleModalFinish}>Finish</Button>
						</>}
					</div>

				</>}
			</Modal.Body>
		)

		// const [validated, setValidated] = useState(false);
		// const [inputValue, setInputValue] = useState({
		// 	accountName: '',
		// 	providerName: '',
		// 	valueDate: new Date().toISOString().split('T')[0],
		// 	value: 0,
		// 	postcode: ''
		// })
		// const handleOnChange = (e) => {
		// 	const {name, value} = e.target;
		// 	setInputValue({
		// 		...inputValue,
		// 		[name]: value
		// 	})
		// }

		// const saveProperty = async () => {
		// 	const form = document.getElementById('manualProperty')
		// 	if (form.checkValidity() === true) {
		// 		setIsLoading(true)
		// 		setAddAccountType('none')
		// 		let property = {
		// 			accountName: inputValue.name,
		// 			providerName: 'personal',
		// 			type: 'properties:residential',
		// 			balance: {
		// 				date: inputValue.valueDate,
		// 				amount: {
		// 					value: Number(inputValue.value) * 100
		// 				}
		// 			},
		// 			details: {
		// 				postcode: inputValue.postcode
		// 			}
		// 		}
		// 		const currentSession = await getSessionTokenAsync()
		// 		await createMhAccount(currentSession, userContext.user.moneyhubUser, property, () => {setSaveComplete(true); setIsLoading(false) });
		// 		setNavigationTarget('/property')
		// 	}
		// 	setValidated(true)
		// }

		// return (
		// 	<Modal.Body className="">
		// 		<Form noValidate validated={validated} id="manualProperty">
		// 			<Row>
		// 				<Form.Group as={Col} className="mb-3" controlId="propertyName" sm={6}>
		// 					<Form.Label>
		// 						Property Name
		// 					</Form.Label>
		// 					<Form.Control required placeholder="" name="name" onChange={handleOnChange} />
		// 				</Form.Group>
		// 				<Form.Group as={Col} className="mb-3" controlId="propertyPostCode" sm={6}>
		// 					<Form.Label>
		// 						Post Code
		// 					</Form.Label>
		// 					<Form.Control required placeholder="" name="postcode" onChange={handleOnChange} pattern='^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$' />
		// 					<Form.Control.Feedback type="invalid">
		// 						Please add a valid UK postcode
		// 					</Form.Control.Feedback>
		// 				</Form.Group>
		// 			</Row>
		// 			<Form.Group as={Row} className="mb-3" controlId="propertyCurrentValue">
		// 				<Form.Label>
		// 					Purchase Price and Date
		// 				</Form.Label>
		// 				<InputGroup>
		// 					<InputGroup.Text>£</InputGroup.Text>
		// 					<Form.Control required aria-label="Amount (to the nearest pound)" name="value" onChange={handleOnChange} />
		// 					<InputGroup.Text>.00</InputGroup.Text>
		// 					{/* TODO: Month Year only  */}
		// 					<Form.Control required type="date" defaultValue={new Date().toISOString().substring(0, 10)} name="valueDate" onChange={handleOnChange} aria-describedby="dateHelpBlock" max={new Date().toISOString().split('T')[0]} />
		// 					<Form.Control.Feedback type="invalid">
		// 						Please add a purchase price and valid date
		// 					</Form.Control.Feedback>
		// 				</InputGroup>
		// 				<Form.Text id="dateHelpBlock" muted>
		// 					If you cannot remember the price or date exactly add your best guess
		// 				</Form.Text>
		// 			</Form.Group>

		// 			<Form.Group as={Row} className="mb-3">
		// 				<div className="d-grid gap-2">
		// 					<Button variant="primary" disabled={userContext.user.isDemo} onClick={saveProperty}>{userContext.user.isDemo ? 'Unavailable on a demo account' : 'Save'}</Button>
		// 				</div>
		// 			</Form.Group>
		// 		</Form>
		// 	</Modal.Body>
		// )
	}

	const manualItemSelected = (e) => {
		const { name } = e.target
		setAddAccountType('none');
		if (name === 'pension') setShowPensionManualScreen(true);
		if (name === 'mortgage') setShowMortgageManualScreen(true);
	}

	const handleTypeClick = (type) => {
		const pageNames = {
			'cash:current': '/bank-account',
			'savings': '/bank-account',
			'card': '/bank-account',
			'pension': '/pension',
			'properties:residential': '/property',
			'mortgage:repayment': '/mortgage',
			'mortgage:interestOnly': '/mortgage',
		}
		setNavigationTarget(pageNames[type])
		setAddAccountType(type)
	}

	return (
		<>
			<PageTitle pageName="Add Account">
				<Row className="m-3 g-2">
					{accountTypes().map(type => {
						return (
							<Col key={type.name} sm={6}>
								<div className="d-grid">
									<Button onClick={() => handleTypeClick(type)} name={type.name} variant="secondary" size="lg" style={{minHeight: "150px"}}>
										<Row className="align-items-center">
											{type.icon()}
										</Row>
										<Row className="align-items-center">
											<Col>
												{type.label}
											</Col>
										</Row>
									</Button>
								</div>
							</Col>
						)
					})}
				</Row>
			</PageTitle>

			<Modal show={addAccountType !== 'none' && addAccountType.name !== 'property'} centered dialogClassName="modal-60w modal-max-w-700 ">
				<Modal.Header closeButton onHide={() => {setSearchKey(''); setAddAccountType('none')}}>
					<Modal.Title className="flex-fill">
						<Row className="px-3">
							<Col sm={8}>
								Add {addAccountType.label}
							</Col>
							<Col sm={4}>
								<Form.Control
										type="text"
										placeholder="Search"
										className="mr-sm-3"
										onChange={(v) => setSearchKey(v.target.value)}
									/>
							</Col>
						</Row>
					</Modal.Title>

				</Modal.Header>
				<Modal.Body style={{minHeight: "600px", maxHeight: "600px", overflow: "scroll"}}>
					{addAccountType.allowManual && <Container key={'manual'} className="mb-1">
						<Row className="align-items-center" style={{height: "40px"}}>
							<Col xs={2} sm={1}>
								<QuestionSquare style={{fontSize: "38px"}} />
							</Col>
							<Col xs={10} sm={11}>
								<div name={addAccountType.name} onClick={manualItemSelected}>
									<Button name={addAccountType.name} className="p-0" variant="link" style={{fontWeight: "700"}}>Cant find your provider? Click here to add your {addAccountType.name} manually</Button>
								</div>
							</Col>
						</Row>
					</Container>}
					{connectionOptions(addAccountType.name, searchKey)}
				</Modal.Body>
			</Modal>
			<Modal show={showQRModal} centered>
				{!userContext.user.isDemo && authUrl && <>
					<Modal.Header closeButton onHide={handleModalClose}>
						<Modal.Title>Connect</Modal.Title>
					</Modal.Header>
				</>}
				<Modal.Body className="text-center">
					{userContext.user.isDemo && <div>
						Adding accounts is unavailable on a demo account
					</div>}
					{!userContext.user.isDemo && authUrl && <>
						<div>
							Scan the QR code to continue with your phone banking app
						</div>
						<div>
							<QRCode value={authUrl} />
						</div>
						<div>
							{// eslint-disable-next-line react/jsx-no-target-blank
							<a href={authUrl} target="_blank" rel="opener">Or click here to continue in your browser</a>}
						</div>
					</>}
					{!userContext.user.isDemo && !authUrl && <>
						<div className="text-center position-relative">
							<div className="mt-2">
								<Spinner className="center" animation="border" role="status">
									<span className="visually-hidden">Generating Link..</span>
								</Spinner>
							</div>
							<div className="mt-3">
								Generating Link..
							</div>
						</div>
					</>}
				</Modal.Body>
				{!userContext.user.isDemo && authUrl && <>
					<Modal.Footer>
						<Button variant="secondary" data-bank-id={currentBankId} onClick={generateUrl}>Refresh QR</Button>
						<Button onClick={handleModalFinish}>Finished</Button>
					</Modal.Footer>
				</>}
				{userContext.user.isDemo && <>
					<Modal.Footer>
						<Button onClick={handleModalClose}>Close</Button>
					</Modal.Footer>
				</>}
			</Modal>
			<Modal show={showPensionManualScreen} centered dialogClassName="modal-60w modal-max-w-700 ">
				<Modal.Header closeButton onHide={() => {setSearchKey(''); setShowPensionManualScreen(false)}}>
					<Modal.Title>Add Pension</Modal.Title>
				</Modal.Header>
				<PensionManualScreen />
			</Modal>
			<Modal show={addAccountType.name === 'property'} centered dialogClassName="modal-60w modal-max-w-700 ">
				<Modal.Header closeButton onHide={() => {setSearchKey(''); setAddAccountType('none')}}>
					<Modal.Title>Add Property</Modal.Title>
				</Modal.Header>
				<PropertyManualScreen />
			</Modal>
			<Modal show={showMortgageManualScreen} centered dialogClassName="modal-60w modal-max-w-700 ">
				<Modal.Header closeButton onHide={() => {setSearchKey(''); setShowMortgageManualScreen(false)}}>
					<Modal.Title>Add Mortgage</Modal.Title>
				</Modal.Header>
				{<MortgageManualScreen />}
			</Modal>
			<Modal show={saveComplete} centered>
				<Modal.Body>
					<div className="text-center position-relative">
						<div className="mt-2">
							<CheckLg style={{fontSize: "3rem"}} />
						</div>
						<div className="mt-3">
							Save Complete
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={isLoading} centered>
				<Modal.Body>
					<div className="text-center position-relative">
						<div className="mt-2">
							<Spinner className="center" animation="border" role="status">
								<span className="visually-hidden">Loading..</span>
							</Spinner>
						</div>
						<div className="mt-3">
							Saving new account..
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default AddAsset;