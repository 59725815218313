import { BarChart, Bar, LabelList, XAxis, YAxis } from 'recharts';

import currencyFormatter from "../../utils/CurrencyFormatter";

const MonthlySaving = (month, accounts, width = 60, primary = true, showTargetText = true, onlyAchieved = false, height = 175, hideMonthText = false) => {
	let potentialSaving = 0
	let achievedSaving = 0
	let averageSaving = 0
	if (month.split('-').length === 2){
		accounts.forEach(a => {
			if (a.movement.shopSt[month]) potentialSaving += a.movement.shopSt[month]
			if (a.movement.shopStSpend[month]) achievedSaving += a.movement.shopStSpend[month]
			if (a.shopStAverageSaving) averageSaving += a.shopStAverageSaving
		})
	} else {
		accounts.forEach(a => {
			Object.keys(a.movement.shopSt).forEach(d => {
				if (d.split('-')[0] === month){
					if (a.movement.shopSt[d]) potentialSaving += a.movement.shopSt[d]
				}
			})
			Object.keys(a.movement.shopStSpend).forEach(d => {
				if (d.split('-')[0] === month){
					if (a.movement.shopStSpend[d]) achievedSaving += a.movement.shopStSpend[d]
				}
			})
		})
	}
	//achievedSaving = potentialSaving * 0.3
	const today = new Date()
	const todayYear = today.getFullYear()
	const todayMonth = ('00' + (today.getMonth() + 1)).slice(-2)
	const currentDate = todayYear + '-' + todayMonth
	const isCurrentMonth = month === currentDate

	const total = (isCurrentMonth ? averageSaving : potentialSaving) + achievedSaving
	let data = [{
		name: month,
		potentialSaving: total,
		achievedSaving: achievedSaving,
		potentialPercentage: (isCurrentMonth ? averageSaving : potentialSaving) / total,
		achievedPercentage: achievedSaving / total,
	}]

	const BarWithBorder = (borderHeight, borderColor) => {
		return (props) => {
			const { fill, x, y, width, height } = props
			return (
				<g>
					<rect x={x} y={y} width={width} height={height} stroke="none" fill={fill} />
					<line x1={x-10} y1={y+height} x2={x + width + 10} y2={y+height} strokeDasharray="9 4" stroke={borderColor} strokeWidth={borderHeight} />
				</g>
			)
		}
	}

	const AchievedLabel = (textValue, targetValue = Infinity) => {
		const isHigh = (textValue > (0.8 * targetValue))
		return (props) => {
			const { x, y, width, height } = props
			return (<>
				{primary && <g>
					<text x={x + width + 12} y={y + height} fill="#255555" textAnchor="left" dominantBaseline="auto" className="fw-bold fs-4">
						{currencyFormatter(textValue, 2)}
					</text>
					<text x={x + width + 12} y={y + height} fill="#255555" textAnchor="left" dominantBaseline="hanging"  className="text-muted fs-6">
						saved
					</text>
				</g>}
				{/* TODO: If isHigh -> put value in light grey pill */}
				{!primary && <g>
					<text x={x + (width / 2)} y={y + height + (isHigh ? 4 : -4)} fill="#255555" textAnchor="middle" dominantBaseline={isHigh ? "hanging" : "auto"} className="fw-bold">
						{currencyFormatter(textValue, 2)}
					</text>
				</g>}
			</>)
		}
	}

	const TargetLabel = (textValue) => {
		return (props) => {
			const { x, y, width } = props
			return (
				<g>
					<text x={x + (width / 2)} y={y-4} fill="#5ABEBB" textAnchor="middle" dominantBaseline="auto" className="fw-bold">
						{currencyFormatter(textValue, 0)}
					</text>
					{primary && showTargetText && <text x={x + width + 18} y={y-4} fill="#5ABEBB" textAnchor="left" dominantBaseline="auto" className="">
						target
					</text>}
				</g>
			)
		}
	}

	const MonthLabel = (month) => {
		const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
		return (props) => {
			const { x, y, height, width } = props
			return (
				<g>
					{isNaN(month.split('-')[1]) && <>
						<text x={x + (width/2)} y={y+height + 20} fill="#6B7B9C" textAnchor="middle" dominantBaseline="auto" className="">
							In {month.split('-')[0]}
						</text>
					</>}
					{!isNaN(month.split('-')[1]) && <>
						<text x={x + (width / 2)} y={y+height + 20} fill="#6B7B9C" textAnchor="middle" dominantBaseline="auto" className="">
							{months[Number(month.split('-')[1])]}
						</text>
						<text x={x + (width / 2)} y={y+height + 25} fill="#6B7B9C" textAnchor="middle" dominantBaseline="hanging" className="">
							{month.split('-')[0]}
						</text>
					</>}

				</g>
			)
		}
	}

	const getRightPadding = () => primary ? 100 : 10

	return <>
		{!onlyAchieved && <BarChart width={primary ? width+90 : width} height={height} data={data}>
			<YAxis hide padding={{ top: 25, bottom: !hideMonthText ? 50 : 25}} />
			<XAxis hide padding={{ left: 10, right: getRightPadding()}}/>
			<Bar dataKey="achievedPercentage" stackId='1' fill="#3F9FA0" isAnimationActive={false}>
				{!hideMonthText && <LabelList content={MonthLabel(month)} />}
			</Bar>
			<Bar dataKey="potentialPercentage" stackId='1' fill="#D9F5F5" shape={BarWithBorder(3, "#6B7B9C")} isAnimationActive={false}>
				{achievedSaving > 0 && <LabelList content={AchievedLabel(achievedSaving, (isCurrentMonth ? averageSaving : potentialSaving) + achievedSaving)} />}
				<LabelList content={TargetLabel((isCurrentMonth ? averageSaving : potentialSaving) + achievedSaving)} />
			</Bar>
		</BarChart>}
		{onlyAchieved && <div>
			<div className="fw-bold fs-4 text-saving-color">
				{currencyFormatter(achievedSaving, 2, true)}
			</div>
			<div className="text-muted fs-6">
				saved in {month.split('-')[0]}
			</div>
		</div>}
	</>

}

export default MonthlySaving