const currencyFormatter = (v, decimals = 0, full = false) => {
	if (isNaN(v)) v = 0
	v /= 100
	if (v === 0) v = Math.abs(v)
	if (full || Math.abs(v) < 10000){
		return new Intl.NumberFormat('en-GB', {
			style: 'currency',
			currency: 'GBP',
			currencyDisplay: 'symbol',
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals
		}).format(v);
	} else if (Math.abs(v) < 1000000) {
		return new Intl.NumberFormat('en-GB', {
			style: 'currency',
			currency: 'GBP',
			currencyDisplay: 'symbol',
			minimumFractionDigits: 1,
			maximumFractionDigits: 1,
			notation: "compact",
		}).format(v);
	} else {
		return new Intl.NumberFormat('en-GB', {
			style: 'currency',
			currency: 'GBP',
			currencyDisplay: 'symbol',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			notation: "compact",
		}).format(v);
	}
}

export default currencyFormatter;