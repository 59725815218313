import getStatePensionAmount from '../../assets/model/state_pension_payouts';
import getStatePensionStartDate from '../../assets/model/state_pension_start_date';

const axios = require('axios');

const getUser = async function(token, successCallback){
	const requestConfig = {
		headers: {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'x-api-key': process.env.REACT_APP_AWS_API_KEY,
			'Authorization': token,
		}
	}
	await axios.get(process.env.REACT_APP_AWS_DATA_ENDPOINT + '/user', requestConfig).then(response => {
		let user = response.data.user
		if (user.dateOfBirth != null){
			user.dateOfBirth = new Date(user.dateOfBirth)
			let dateCounter = new Date(user.dateOfBirth)
			dateCounter.setFullYear(dateCounter.getFullYear() + 18)
			user.dateAged18 = new Date(dateCounter)
			dateCounter = new Date(user.dateOfBirth)
			dateCounter.setFullYear(dateCounter.getFullYear() + 90)
			user.dateAged90 = new Date(dateCounter)

			let statePensionStartDate = getStatePensionStartDate(new Date(user.dateOfBirth))
			if (user.statePensionAmount == null) {
				user.statePensionAmount = Math.floor(getStatePensionAmount(statePensionStartDate) / 100) * 100
			}
			user.statePensionAge = statePensionStartDate.getFullYear() - (user.dateOfBirth.getFullYear())
		}

		successCallback(user)
		return
	}).catch(error => {
		console.error('Error (FE/s/d/getUser): ', error)
		return
	})
}

export default getUser;