import React from "react";

import { Row, Col } from 'react-bootstrap';

const Tile = ({ title, actions, body, removeBottomMargin = false }) => {

	const tileClasses = () => {
		return "p-0" + (!removeBottomMargin ? ' mb-2' : '');
	}

	return (
		<div fluid={"xs"} className={tileClasses()}>
			{ (title || actions) && (
				<Row className="align-items-center">
					<Col md={12} lg={actions ? 6 : 12}>
						{/* <h5 className="">{title}</h5> */}
						{title}
					</Col>
					<Col md={12} lg={6} className="text-end">
						{actions}
					</Col>
				</Row>
			)}
			{ (body) && (
				<Row>
					<Col>
						{body}
					</Col>
				</Row>
			)}
		</div>
	)
}

export default Tile;