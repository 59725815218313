import React from "react";
import { Route, Routes } from "react-router-dom";

import { MoneyHubHandler } from "./pages/contentPages";
import { Logout } from './pages/authPages';

import "./scss/style.scss";

import ProtectedApp from "./ProtectedApp";

function App() {
	return (
		<div className="App">
			<Routes>
				{/* Ensure that logout and moneyhub consent are available from anywhere */}
				<Route exact path="/mh/consent" element={<MoneyHubHandler />} />
				<Route exact path="/logout" element={<Logout />} />
				<Route path="/*" element={<ProtectedApp />} />
			</Routes>
		</div>
	);
}

export default App;