import React from "react";

import { LinkContainer } from "react-router-bootstrap";

import { Navbar, Nav, Row, Col } from 'react-bootstrap';
import { Logo, SSLogo } from '../components';
import Button from 'react-bootstrap/Button';
import moneyhubLogo from '../../assets/images/money-hub-logo.png';
import { CCircle } from "react-bootstrap-icons";

import { menuItems } from '../../assets/content/menu-items';

const NavigationLargeDisplay = () => {

	return (<>
		<div className={"nav-v " + process.env.REACT_APP_AWS_NAVV}>
			<Navbar className="flex-column navbar-dark" style={{"width": "100%"}}>
				<LinkContainer to="/" className="nav-contents">
					<Nav.Link>
						<Logo cssClass="prift-logo-nav g-0 mx-3 p-0" />
					</Nav.Link>
				</LinkContainer>
				<LinkContainer to="/add-asset" className="nav-contents">
					<Nav.Link>
						<div className="text-center px-3 mt-2">
							<Button variant="primary" size="lg">
								<b>+ Add Account</b>
							</Button>
						</div>
					</Nav.Link>
				</LinkContainer>
				<hr className="white" />
			</Navbar>
		</div>
		<div className={"nav-v " + process.env.REACT_APP_AWS_NAVV} style={{height:"calc(100vh - 167px", overflow: 'scroll'}}>
			<Navbar className="flex-column navbar-dark" style={{"width": "100%"}}>
				<Nav className="nav-contents justify-content-end flex-grow-1 pe-3 flex-column px-1">
						{menuItems.map((menuItem, i) => {
							if (menuItem.submenu){
								const titleRow = () => {
									if (menuItem.url){
										return (
											<div key={"menuItem" + i}>
											<LinkContainer to={menuItem.url}>
												<Nav.Link>
													<div>
														{menuItem.title}
													</div>
												</Nav.Link>
											</LinkContainer>
											</div>
										)
									} else {
										return (
											<h4 style={{marginLeft: "0.5rem", marginTop: "1.5rem"}} key={"menuItem" + i}>
												{menuItem.title}
											</h4>
										)
									}
								}
								return (
									<div key={"menuItem" + i}>
										{titleRow()}
										{menuItem.submenu.map((submenu, j) => {
											return (
												<LinkContainer className="pt-0" key={"submenu" + i + ":" + j} to={submenu.url}>
													<Nav.Link>
														<h5 className="px-3 mb-0">
															{submenu.title}
														</h5>
													</Nav.Link>
												</LinkContainer>
											)
										})}
									</div>
								)
							}
							if (menuItem.url != null){
								return (
									<div key={"menuItem" + i}>
									<LinkContainer to={menuItem.url}>
										<Nav.Link>
											<h4 style={{marginTop: menuItem.title === 'Home' ? "0rem" : "1.5rem"}}>
												{menuItem.title === 'Shop St Savings' ?
														<><SSLogo size="22" /> Savings</> :
														menuItem.title}
											</h4>
										</Nav.Link>
									</LinkContainer>
									</div>
								)
							}
							return null
						})}
				</Nav>
			</Navbar>
			<div className="navigationTerms px-3 pb-1 mt-3">
				<Row className="mb-2 align-items-end">
					<Col xs={4}>
						<Logo cssClass="prift-logo-small mb-2" logoStyle={"stack"} />
					</Col>
					<Col xs={4}>
						<div className="text-center">
							Powered by:
						</div>
						<div>
							<img height="16px" src={moneyhubLogo} alt="Shop St" />
						</div>
					</Col>
				</Row>
				<Row className="mb-2">
					<Col xs={1}><CCircle /></Col>
					<Col>Prift 2024. All rights reserved</Col>
				</Row>
				<Row className="mb-2">
					<Col>
						<b>Prift OPCO UK Limited</b> is a company registered in England and Wales (No.13464853) and is the registered agent of Moneyhub Financial Technology Ltd (“Moneyhub”) who is authorised and regulated by the Financial Conduct Authority under the Payment Services Regulations 2017 (reg. no. 809360) for the provision of payment services. Registered office is c/o Roxburgh Milkins Limited, Merchants House North, Wapping Road, Bristol, United Kingdom, BS1 4RW Email: support@moneyhub.co.uk.
					</Col>
				</Row>
				<Row className="mb-2">
					<Col>
						We are not authorised or regulated by the FCA for the provision of regulated Financial Advice. In providing our services, we are merely providing a platform on which you can upload and view your financial information. We may provide guidance, but we do not provide any Financial Advice and/or recommendations.
					</Col>
				</Row>
			</div>
		</div>

	</>

	);
}

export default NavigationLargeDisplay;