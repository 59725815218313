import React from "react";
import { ComposedChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import currencyFormatter from "../../utils/CurrencyFormatter";
import dateFormatter from "../../utils/DateFormatter";

import styles from '../../scss/exports.scss';

const LineChart = ({ data, displayConfig }) => {

	if (!displayConfig) displayConfig = {};
	if (!displayConfig.bars) displayConfig.bars = [];

	const gradientOffset = () => {
		const dataMax = Math.max(...data.map((i) => i.Balance));
		const dataMin = Math.min(...data.map((i) => i.Balance));

		if (dataMax <= 0){
			return 0
		}
		else if (dataMin >= 0){
			return 1
		}
		else{
			return dataMax / (dataMax - dataMin);
		}
	}
	const off = gradientOffset();

	return (
		<>
			<ResponsiveContainer className="pt-1 border soft-corners" width="100%" height="100%">
				<ComposedChart
						width={500}
						height={300}
						data={data}
						margin={{
							top: 0,
							right: 5,
							left: 0,
							bottom: 0,
						}}>
					<CartesianGrid strokeDasharray="1 0" vertical={false} stroke="#E5E9F2" />
					<XAxis
							dataKey="name"
							tickFormatter={value => {
								if (displayConfig.xAxisStyle === 'endOfYear'){
									const year = Number(value.split('-')[0])
									return year % 10 ? '' : year
								} else {
									if (Number(value.split('-')[2]) === 1){
										return dateFormatter(value.slice(0,-3))
									}
									return ''
								}
							}}
							tickLine={false}
							axisLine={false}
							interval={0}
							tickCount={10}
							className="text-muted fs-6" />
					<YAxis
							dx={5}
							padding={{ top: 0, bottom: 0 }}
							tickFormatter={value => currencyFormatter(value * 100)}
							tickLine={false}
							axisLine={false}
							domain={ displayConfig.type === 'card' ? ['auto', 0] : [0, 'auto'] }
							className="text-muted fs-6"
							 />
					{displayConfig.renderTooltip && <Tooltip cursor={false} content={<displayConfig.renderTooltip displayConfig={displayConfig} />} />}
					{displayConfig.renderLegend && <Legend content={<displayConfig.renderLegend displayConfig={displayConfig} />} />}
					<defs>
						<linearGradient id="splitColorStroke" x1="0" y1="0" x2="0" y2="1">
							<stop offset={off} stopColor={displayConfig.type === 'savings' ? styles['positive-0'] : styles['asset-0']} stopOpacity={1} /> {/* Positive Color */}
							<stop offset={off} stopColor={displayConfig.type === 'card' ? styles['liability-0'] : styles['negative-0']} stopOpacity={1} /> {/* Negative Color */}
						</linearGradient>
						<linearGradient id="splitColorFill" x1="0" y1="0" x2="0" y2="1">
							<stop offset={off} stopColor={displayConfig.type === 'savings' ? styles['positive-4'] : styles['asset-4']} stopOpacity={1} /> {/* Positive Color */}
							<stop offset={off} stopColor={displayConfig.type === 'card' ? styles['liability-4'] : styles['negative-4']} stopOpacity={1} /> {/* Negative Color */}
						</linearGradient>
					</defs>
					{displayConfig.bars.map((b,i) => {
						return (
							<Area
									key={b.dataKey + i}
									type="monotone"
									dataKey={b.dataKey}
									dot={false}
									fill="url('#splitColorFill')"
									stroke="url('#splitColorStroke')"
									strokeWidth={2}
									connectNulls={true}
									isAnimationActive={false}>
							</Area>
						)
					})}
				</ComposedChart>
			</ResponsiveContainer>
		</>
	)
}

export default LineChart;