import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../Main";

import appleStoreLogo from '../../assets/images/apple-app-store-logo.png';
import googleStoreLogo from '../../assets/images/google-play-store-logo.png';

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, BarChart, Bar } from 'recharts';

import { ChevronDoubleLeft, ChevronDoubleRight, InfoCircle } from "react-bootstrap-icons";

import { PageTitle, Tile, InteractionTile, SSParticipatingRetailers, SSMonthlySaving, SSLogo, SSPotentialSavings, SSSavingsAchieved, SSFutureSavings } from "../../components/components";
import TwoColumnContentLayout from "../../layouts/TwoColumnContentLayout";

import { Row, Col, Form, Button, OverlayTrigger, Tooltip as TooltipRB, Modal, Spinner } from 'react-bootstrap';
import currencyFormatter from "../../utils/CurrencyFormatter";

import { sendSSRequestEmail, setUser as setUserDataService } from "../../service/DataService"
import { getSessionTokenAsync } from '../../utils/GetSessionTokenAsync';
import dateFormatter from "../../utils/DateFormatter";

const ShopStSaving = () => {

	const userContext = useContext(UserContext)

	const WhatIsShopSt = () => {

		const [ showLoading, setShowLoading ] = useState(0)
		const sendSSRequest = async () => {
			setShowLoading(1)
			const currentSession = await getSessionTokenAsync()
			await sendSSRequestEmail(currentSession, userContext.user.email, async () => {
				const currentSession = await getSessionTokenAsync()
				let userInfo = {}

				userInfo.ssRequestSent = true
				await setUserDataService(currentSession, userInfo)
				userContext.setUser({...userContext.user, ssRequestSent: true})
			})
		}

		useEffect(() => {
			if (showLoading === 2){
				setTimeout(() => setShowLoading(0), 1500)
			}
		}, [showLoading])

		return (<>
			<InteractionTile body={<>
				<h5 className="text-dark text-center">What is <SSLogo size="18" />?</h5>
				<p className="text-center fs-5">
					All users have exclusive access to discounts at hundreds of retailers. Just pay at the check-out <nobr>with <SSLogo size="18" /></nobr>
				</p>
				<p className="text-center text-muted fw-bold fs-5">
					Click below for an Activation PIN
				</p>
				<div className="text-center">
					<Button variant="primary" className="fw-bold" onClick={sendSSRequest} disabled={userContext.user.ssRequestSent}>Request PIN</Button>
				</div>
				<Row className="align-items-center g-2 mt-2 text-center d-flex justify-content-center">
					<img src={appleStoreLogo} alt="Apple Store" style={{maxHeight: "25px", maxWidth: "min(90px, 50%)"}} />
					<img src={googleStoreLogo} alt="Google Store" style={{maxHeight: "25px", maxWidth: "min(90px, 50%"}} />
				</Row>
			</>} />
			{showLoading > 0 && (
				<Modal centered show>
					<Modal.Body>
						<div className="text-center position-relative">
							<div className="mt-2">
								{showLoading === 1 && <Spinner className="center" animation="border" role="status">
									<span className="visually-hidden">Loading..</span>
								</Spinner>}
							</div>
							<div className="mt-3">
								{showLoading === 1 && <>Sending request..</>}
								{showLoading === 2 && <>You will receive an email when your Shop St account is enabled</>}
							</div>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>)
	}

	const getThisMonthShortDate = () => {
		const today = new Date()
		const year = today.getFullYear()
		const month = ('00' + (today.getMonth() + 1)).slice(-2)
		return year + '-' + month
	}

	const renderTargetHelp = (props) => (
		<TooltipRB id="button-tooltip" {...props} animation="false">
			<div>Monthly target is automatically calculated based upon your past months transactions. </div>
		</TooltipRB>
	)

	const getCurrentMonthDisplay = () => {
		return (<>Saved in {dateFormatter(new Date().toISOString(), 'longMonthYearOnly')}</>)
	}

	const getLastTwelveMonthsPotential = () => {
		const today = new Date()
		let oneYearAgo = new Date()
		oneYearAgo.setFullYear(oneYearAgo.getFullYear() -1)
		let totalSaving = 0
		let savingByVendor = []
		userContext.accounts.forEach(a => {
			a.transactions?.forEach(t => {
				if (t.discountPotential > 0 && new Date(t.date) <= today && new Date(t.date) > oneYearAgo){
					totalSaving += t.discountPotential
					let found = false
					savingByVendor.forEach(sbv => {
						if (sbv.name === t.vendorDetails.Name){
							sbv.value += t.discountPotential
							sbv.count ++
							found = true
						}
					})
					if (!found){
						savingByVendor.push({
							name: t.vendorDetails.Name,
							vendorDetails: t.vendorDetails,
							value: t.discountPotential,
							count: 1
						})
					}
				}
			})
		})
		return {totalSaving, savingByVendor}
	}

	return (
		<>
			<PageTitle pageName={<><SSLogo size="40" /> Savings</>} helpPage="shopst" >
				<TwoColumnContentLayout
						left={<WhatIsShopSt />}
						right={<Row>
							<Col sm={6} md={4} lg={6} style={{borderRight: "2px dashed #C4C4C4"}}>
								<Tile body={<>
											<h5 className="text-center" style={{marginRight: "3rem"}}>{getCurrentMonthDisplay()}</h5>
											<div className="d-flex justify-content-center">
												{SSMonthlySaving(getThisMonthShortDate(), userContext.accounts, 60, true, true, false, 290, true)}
											</div>
										</>}
										removeBottomMargin />
							</Col>
							<Col sm={6} md={8} lg={6}>
								<Tile body={<>
											<h5 className="text-center"> Where can you save:</h5>
											<div className="fs-6 d-flex justify-content-between text-muted" style={{maxWidth: "240px", margin: "auto"}} >
												<div></div>
												<div className="text-center mr-auto">
													<div>
														Estimated
													</div>
													<div>
														monthly savings:
													</div>
												</div>
											</div>
											<div className="" style={{width: "100%"}}>
												<SSPotentialSavings className="justify-content-center" userContext={userContext} data={getLastTwelveMonthsPotential()} />
											</div>
										</>}
									removeBottomMargin />
							</Col>
						</Row>} />
				<hr className="mx-3" />
				<div className="mx-3 my-3">
					<Row className="">
						<Col md={6} className="text-center">
							<div><h5 className="mb-0"><SSLogo size="18" /> Savings Achieved</h5></div>
							<OverlayTrigger
									placement="auto"
									delay={{ show: 250, hide: 400 }}
									overlay={renderTargetHelp}>
								<div className="text-muted mb-1 fs-6 interaction" style={{cursor: "pointer", textDecoration: "underline"}}>(How is my target calculated?)</div>
							</OverlayTrigger>

							<SSSavingsAchieved userContext={userContext} />
						</Col>
						<Col md={6} style={{borderLeft: "2px solid #C4C4C4"}}>
							<h5 className="text-center">Potential Saving Forecast</h5>
							<SSFutureSavings userContext={userContext} />
						</Col>
					</Row>

				</div>

				<hr className="mx-3" />
				<SSParticipatingRetailers userContext={userContext} />
			</PageTitle>
		</>
	)
}

export default ShopStSaving