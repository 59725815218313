import WebSocket from 'isomorphic-ws'

const WS_URL = process.env.REACT_APP_WEBSOCKET_URL;

let WSService = null;

class WebSocketService {

	constructor(){
		this.websocket = null;
		this.messageListeners = [];
		this.isOpen = false;
	}

	initSocket = () => {
		this.websocket = new WebSocket(WS_URL);
		this.websocket.onopen = this.onConnOpen;
		this.websocket.onmessage = this.onMessage;
		this.websocket.onclose = this.onConnClose;
	}

	onConnOpen = () => {
		this.isOpen = true;
	}

	onConnClose = () => {
	}

	sendMessage = (routeKey, data) => {
		if (this.websocket && this.isOpen){
			this.websocket.send(JSON.stringify({
				...data,
				action: routeKey,
			}));
		} else {
			console.error('Error: WebSocket connection not found');
		}
	}

	onMessage = (data) => {
		// const message = JSON.parse(data);
	}

	static initWSService() {
		if (!WSService){
			WSService = new WebSocketService();
			WSService.initSocket();
			return WSService;
		}
		return WSService;
	}
}

export const getWSService = WebSocketService.initWSService;