import React, { useState } from "react";

import { Row, Col, Button } from 'react-bootstrap';
import { X } from "react-bootstrap-icons";
import { motion, AnimatePresence } from "framer-motion"

const InteractionTile = ({ title, body, extraClass, padding = 'p-3', bottomMargin = 'mb-5', collapsible = false }) => {

	const [ collapsed, setCollapsed ] = useState(true)

	const tileClasses = () => {
		return "border shadow-sm bg-interaction soft-corners " + extraClass + " " + padding + " " + bottomMargin;
	}

	return (<>
		{!collapsible && <div className={tileClasses()}>
			{ (title) && (
				<Row>
					<Col>
						<h4 className="text-center">{title}</h4>
					</Col>
				</Row>
			)}
			{ (body) && (
				<Row>
					<Col>
						{body}
					</Col>
				</Row>
			)}
		</div>}
		{collapsible && <>
			{collapsed && <div>
				<Button className="p-0 mb-2" variant="link" onClick={() => setCollapsed(false)}>{title}</Button>
			</div>}
			{!collapsed && <div
					className={tileClasses()} style={{position: "relative", zIndex: "700"}}>
				<Button onClick={() => setCollapsed(true)}
						variant="light"
						className="border"
						style={{position: "absolute", right: "-1rem", top: "-1rem", margin: "0", padding: "0"}}>
					<X onClick={() => setCollapsed(true)} className="fs-2 interaction hasHoverEffect_large" />
				</Button>
				{ (body) && (
					<Row>
						<Col>
							{body}
						</Col>
					</Row>
				)}
			</div>}
		</>}
	</>)

}

export default InteractionTile;