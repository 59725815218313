import { Logo } from '../components/components';

/**
 * Layout used for authentication and login screens
 */
function PublicLayout({ children }) {
	return (
		<div className="login-panel align-center">
			<Logo cssClass="login-logo mb-3" logoStyle="login" />
			{children}
		</div>
	)
}

export default PublicLayout;