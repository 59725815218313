// FAQ contents
// TODO: Move this to a dynamo table
export const helpTopics = [
	{
		title: 'How secure is Prift?',
		content: 'Security measures have been built within our platform to ensure users\' data is protected against unauthorised access and other cyber threats. Prift has obtained certification from IASME Cyber Essentials, independently checking our cyber security and procedures to reduce vulnerability from cyber-attacks.'
	},
	{
		title: 'Is Prift regulated by the FCA?',
		content: 'Prift is registered with the UK Financial Conduct Authority (FCA), reference number 992581, as a PSD agent of Moneyhub Financial Technology Ltd, reference number 809360.'
	},
	{
		title: 'Does my employer see my financial information?',
		content: 'We do not share your financial information with employers. This allows you to keep your financial circumstances confidential from your employer. The only information shared with employers is the user\'s usage and engagement.'
	},
	{
		title: 'What is Shop St.?',
		content: 'All users have free access to Shop St, a shopping app providing savings on everyday essentials, from weekly groceries, fuel, holidays, restaurants and much more. See the Shop St Saving page for more information.'
	},
	{
		title: 'Does Prift see or store my bank or financial providers log-in details?',
		content: 'We do not have access to view or store your financial providers log in credentials. We are provided with a restrictive access token to view only balances and transactions. You can stop and revoke Prift\'s access at any time.'
	},
	{
		title: 'I need technical help or support?',
		content: 'E-mail support@prift.co with details of your query and our dedicated team will try to answer your query as soon as possible.'
	},
	{
		title: 'Can I change my email to my personal email?',
		content: 'Yes, to change your email go to the \'My Details\' page where you can change your email address, password and other personal information.'
	},
	{
		title: 'Are transactions and balances updated in real-time?',
		content: 'Balances, valuations and transactions are correct as of the last business day, some transactions take a few days for your financial provider to process and for Prift to view.'
	},
	{
		title: 'Why can\'t I see my bank or financial provider?',
		content: 'We support thousands of connections with financial providers, however in some cases, provider led decisions have prevented the ability of a connection. If your provider is not available, you can add a pension and mortgage manually.'
	},
	{
		title: 'My listed financial provider is not working.',
		content: 'If your provider is listed but the connection does not work, e-mail support@prift.co with the relevant details and our dedicated team will investigate the issue.'
	},
	{
		title: 'How accurate is my current property value?',
		content: 'The current value of your property is an estimation of the value today based upon UK House Price Index. This estimate does not include local factors that may affect the true value or expected sale price of the property.'
	},
	{
		title: 'Are my forecasts accurate?',
		content: 'Our team reviews forecasts regularly to ensure these represent current market conditions and expectations. Our forecasts are an estimation and do not guarantee future value. The forecasts may not be fully representative of your investment portfolio or portfolio risk level.'
	},
	{
		title: 'Is the guidance right for me?',
		content: 'Our financial guidance is a rules-based system, this means that guidance provided to you is based on only the information that is provided to us. You should consider other factors before actioning upon any guidance. We do not provide any financial advice or recommendations.'
	},
]