import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../Main";

import { PageTitle, AccountSelector, LineChart, Tile, InteractionTile, HorizontalColors } from '../../components/components';
import TwoColumnContentLayout from '../../layouts/TwoColumnContentLayout';
import { ChevronDoubleLeft, ChevronDoubleRight, ArrowUp, ArrowDown, InfoCircle } from "react-bootstrap-icons";

import { Image, Row, Col, Badge, Tooltip as TooltipRB, OverlayTrigger } from 'react-bootstrap';

import currencyFormatter from "../../utils/CurrencyFormatter";
import calc90DayTrend from "../../utils/TrendBuilder";
import dateFormatter from "../../utils/DateFormatter";
import moneyHubAssetTypes from "../../utils/MoneyHubAssetTypes";
import titleCase from '../../utils/TitleCase';

import styles from '../../scss/exports.scss';

const BankAccounts = ( ) => {

	const userContext = useContext(UserContext)
	const selected = "BankAccountSelected"
	const displayTypes = moneyHubAssetTypes['cashAccounts']

	const formatter = new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'numeric' });
	const formattedDate = (d) => formatter.format(new Date(d));

	const selectedAccounts = userContext.accounts.filter(a => a[selected] && displayTypes.includes(a.type))
	useEffect(() => {
	}, [selectedAccounts])

	// const RenderLegend = ({ payload, displayConfig }) => {
	// 	let config = [...displayConfig.bars]
	// 	return (
	// 		<div className="text-center">
	// 			{payload.map((entry, index) => (
	// 				config.length > index && (
	// 				<span key={'legendItem' + index}>
	// 					<SquareFill style={{"fill": config[index].fill}} />
	// 					<span className="mx-2">
	// 						{entry.value}
	// 					</span>
	// 				</span>
	// 				)
	// 			))}
	// 		</div>
	// 	)
	// }

	const RenderTooltip = ({ active, label, payload, displayConfig }) => {
		let configs = [...displayConfig.bars]

		const tooltipContents = () => {
			return (
				<>
				{label && <h6 className="text-start">{dateFormatter(label)}</h6>}
				{/* <div className="fw-bold fs-6 d-flex justify-content-between text-muted">
					Account Balances:
				</div> */}
				{configs.map((config, index) => {
					const entry = payload[index];
					return (
						entry?.value !== 0 &&
							(<div key={'tooltipItem' + index}>
								<div className="fs-6 d-flex justify-content-between fw-bold" style={{color: config.fill}}>
									<div>
										{entry.name}:&nbsp;
									</div>
									<div className="text-end mr-auto">
										{currencyFormatter(entry.value * 100, 0, true)}
									</div>
								</div>
							</div>)
					)
				})}
				</>
			)
		}
		return(
			active &&
					<InteractionTile body={tooltipContents()} padding="p-1" bottomMargin="" />
		)
	}

	const lineChart = () => {
		const trendData = calc90DayTrend(selectedAccounts, selectedAccounts[0]?.type !== 'card')
		let data = trendData.balances
		let dataConfig = {}
		//dataConfig.renderLegend = RenderLegend
		dataConfig.renderTooltip = RenderTooltip
		dataConfig.type = selectedAccounts[0]?.type
		dataConfig.bars = [
			{
				dataKey: "Balance",
				stackId: '1',
				labelPosition: "top",
				labelFormatter: (value) => value,
				fill: dataConfig.type === 'savings' ? styles['positive-0'] : (dataConfig.type === 'card' ? styles['liability-0'] : styles['asset-0'])
			}
		];

		return (
			<div className="" style={{"height":"300px"}}>
				<LineChart data={data} displayConfig={dataConfig} />
			</div>
		)
	}

	const renderAerHelp = (props) => (
		<TooltipRB id="button-tooltip" {...props} animation="false">
			<div className="fw-bold">Estimated Annual Equivalent Rate</div>
			<div>An estimate rate is provided as bank providers do not reliably provide interest rates</div>
		</TooltipRB>
	)

	const accountDetails = () => {
		const displayDetails = (name, providers, balance, overdraft, interest, type, estimatedInterestRate, creditLimit) => {
			const trendData = calc90DayTrend(selectedAccounts, type !== 'card')
			return (
				<div key={name}>
					{!!name && <Row className="align-items-center mb-3">
						<Col xs={6} className="text-end">
							Account Name:
						</Col>
						<Col xs={6} className="text-dark text-truncate">
							{name}
						</Col>
					</Row>}
					{!!providers && <Row className="align-items-center mb-3">
						<Col xs={6} className="text-end">
							Provider:
						</Col>
						<Col xs={6} className="text-dark text-truncate">
							{providers.map((provider, i) => {
								return (
									<Row key={"provider" + i} className="align-items-center">
										{provider.logo && <Col xs={2} sm={1}>
											<Image src={provider.logo} height="25px" width="25px" />
										</Col>}
										<Col>
											{provider.provider}
										</Col>
									</Row>
								)
							})}
						</Col>
					</Row>}
					{!!balance && <Row className="align-items-center mb-3">
						<Col xs={6} className="text-end">
							Current Balance:
						</Col>
						<Col xs={6} className={type === 'cash:current' ? 'text-asset-color' : type === 'savings' ? 'text-saving-color' : 'text-spending-color'}>
							{currencyFormatter(balance, 2, true)}
						</Col>
					</Row>}
					{!!overdraft && <Row className="align-items-center mb-3">
						<Col xs={6} className="text-end">
							Arranged Overdraft Limit:
						</Col>
						<Col xs={6} className="text-spending-color">
							{currencyFormatter(overdraft, 2, true)}
						</Col>
					</Row>}
					{!!creditLimit && <Row className="align-items-center mb-3">
						<Col xs={6} className="text-end">
							Credit Limit:
						</Col>
						<Col xs={6} className="text-negative-color">
							{currencyFormatter(-1 * creditLimit, 2, true)}
						</Col>
					</Row>}
					{!!interest && <Row className="align-items-center mb-3">
						<Col xs={6} className="text-end">
							Estimated Interest Rate:
						</Col>
						<Col xs={6} className="text-dark">
							{interest}
						</Col>
					</Row>}
					{(!interest) && <Row className="align-items-center mb-3">
						<Col xs={6} className="text-end">
							Estimated Interest Rate (AER):
						</Col>
						<Col xs={6} className="text-dark">
							{!!estimatedInterestRate && (Math.round(estimatedInterestRate * 100 * 100) / 100).toFixed(2) + '%'}
							{!estimatedInterestRate && 'Unknown'} <OverlayTrigger
														placement="auto"
														delay={{ show: 250, hide: 400 }}
														overlay={renderAerHelp}>
													<InfoCircle style={{fontSize: "0.75rem"}} />
												</OverlayTrigger>
						</Col>
					</Row>}
					{!!trendData.direction && <Row className="mb-3">
						<Col xs={6} className="text-end">
							Trending Balance:
							<div className="fs-6">(90 days)</div>
						</Col>
						<Col xs={6} className={"align-items-center"}>
							<Badge className={(trendData.trendColor ? "badgePositive" : "badgeNegative")}>
								<Row className={"align-items-center " + (trendData.trendColor ? "text-saving-color svg-saving-color" : "text-negative-color svg-negative-color")}>
									<Col xs={1}>
										{trendData.direction === 'INCREASING' ? <ArrowUp /> : <ArrowDown />}
									</Col>
									<Col>
										{trendData.direction}
									</Col>
								</Row>
							</Badge>
						</Col>
					</Row>}
				</div>
			)
		}

		return (
			<Tile title={<h5 className="mb-3">Account Details</h5>} body={<div className="text-muted">
				{(selectedAccounts.length === 1) &&
						<div>
							{displayDetails(
									selectedAccounts[0].accountName,
									[{provider: selectedAccounts[0].connection.friendlyName, logo: selectedAccounts[0].connection.iconUrl}],
									selectedAccounts[0].balance.amount.value,
									selectedAccounts[0].details.overdraftLimit,
									selectedAccounts[0].details.apr,
									selectedAccounts[0].type,
									selectedAccounts[0].estimatedInterestRate,
									selectedAccounts[0].details.creditLimit
								)}
						</div>}
				{(selectedAccounts.length > 1) &&
						<div>
							{displayDetails(
									'Multiple Selected',
									[...new Map(selectedAccounts.map(i => {return {provider: i.connection.friendlyName, logo: i.connection.iconUrl}}).map(item => [item['provider'], item])).values()],
									selectedAccounts.map(i => i.balance.amount.value).reduce((a,b) => a+b, 0),
									selectedAccounts.map(i => i.details.overdraftLimit).reduce((a,b) => a+(b ? b : 0), 0),
									null,
									selectedAccounts[0].type
								)}
						</div>}
				{(selectedAccounts.length === 0) &&
						<div className="text-center">
							Select an account to see more details
						</div>}
				</div>
			} />
		)
	}

	const months = [
		{start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date(new Date().setDate(new Date().getDate() - 60))},
		{start: new Date(new Date().setDate(new Date().getDate() - 60)), end: new Date(new Date().setDate(new Date().getDate() - 30))},
		{start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date(new Date().setDate(new Date().getDate()))}
	]
	const [ currentMonth, setCurrentMonth ] = useState(2);

	const monthOverviewData = () => {
		const startDate = months[currentMonth].start.toISOString()
		const endDate = months[currentMonth].end.toISOString()
		let incomeTotal = 0
		let spendingTotal = 0
		selectedAccounts.forEach(a => {
			a.transactions?.forEach(t => {
				if (t.date > startDate && t.date <= endDate){
					t.amount.value > 0 ? (incomeTotal += t.amount.value) : (spendingTotal -= t.amount.value)
				}
			})
		})
		let net = incomeTotal - spendingTotal
		return (
			<Row className="align-items-center g-0 mt-4">
				<Col xs={1} className="g-0 p-0 m-0 interaction text-end text-primary">
					{currentMonth !== 0 && <ChevronDoubleLeft onClick={() => setCurrentMonth(Math.max(0,currentMonth-1))} />}
				</Col>
				<Col className="g-0">
					<div className="p-2 border soft-corners bg-white">
						<div className="text-center">
							<h6 className="text-muted">Month to:</h6>
							<h5>{dateFormatter(endDate, 'longMonth')}</h5>
						</div>
						<div className="d-flex justify-content-between" style={{"color": styles['asset-0']}}>
							<div>
								Income:
							</div>
							<div>
								{currencyFormatter(incomeTotal, 2, true)}
							</div>
						</div>
						<div className="d-flex justify-content-between" style={{"color": styles['liability-0']}}>
							<div>
								Spending:
							</div>
							<div>
								{currencyFormatter(spendingTotal, 2, true)}
							</div>
						</div>
						<hr/>
						<div className="d-flex justify-content-between align-items-center" style={{color: net >= 0 ? styles['positive-0'] : styles['negative-0'], fontWeight: "700"}}>
							<div>
								{net >= 0 ? "Net Saving" : "Net Overspend"}
							</div>
							<div>
								{currencyFormatter(net, 2, true)}
							</div>
						</div>
					</div>
				</Col>
				<Col xs={1} className="g-0 interaction text-primary">
					{currentMonth !== 2 && <ChevronDoubleRight onClick={() => setCurrentMonth(Math.min(2,currentMonth+1))} />}
				</Col>
			</Row>

		)
	}

	const accountTransactions = () => {
		const trendData = calc90DayTrend(selectedAccounts, selectedAccounts[0]?.type !== 'card')
		let data = trendData.balances
		let startDate = new Date(new Date().setDate(new Date().getDate() - 90));

		let transactions = []
		selectedAccounts.forEach(a => {
			const ts = a.transactions?.filter(t => new Date(t.date) >= startDate)
			ts && transactions.push(...ts)
		})
		data.sort((a,b) => a.name < b.name ? 1 : -1)

		return (<>
			<div className="">
				<Row className="">
					<Col xs={12}>
						<h4>Account Transactions</h4>
					</Col>
				</Row>
			</div>
			<HorizontalColors mode="greys" />
			<div className="">
				<Row className="align-items-center d-none d-md-flex">
					<Col xs={1}>
						<h6 className="text-muted">Date</h6>
					</Col>
					<Col xs={5}>
						<h6 className="text-muted">Description</h6>
					</Col>
					<Col xs={2} className="text-end">
						<h6 className="text-muted">Amount In</h6>
					</Col>
					<Col xs={2} className="text-end">
						<h6 className="text-muted">Amount Out</h6>
					</Col>
					<Col xs={2} className="text-end">
						<h6 className="text-muted">Balance</h6>
					</Col>
				</Row>
				{data.map((data, i) => {
					return ((data.transactions.length > 0 || new Date(data.name).toISOString().split('T')[0] === new Date().toISOString().split('T')[0]) && <div key={"dataRow" + data.id + i}>
						{/* Large Screens */}
						<Row className="pt-1 text-muted rowHoverColor d-none d-md-flex" style={{borderBottom: "1px solid #E5E5E5"}}>
							<Col xs={1} className="">
								{formattedDate(data.name)}
							</Col>
							<Col xs={5}>
								{data.transactions.map((t,j) => {
									return (
										<Row key={"transactionRow1" + j}>
											<Col className="text-truncate">
												{titleCase(t.shortDescription.toLowerCase())}
											</Col>
										</Row>
									)
								})}
							</Col>
							<Col xs={2}>
								{data.transactions.map((t,j) => {
									return (
										<Row key={"transactionRow2" + j}>
											{t.amount.value > 0 && <Col className="text-end">
												{currencyFormatter(t.amount.value, 2, true)}
											</Col>}
											{t.amount.value <= 0 && <Col className="text-end">&nbsp;</Col>}
										</Row>
									)
								})}
							</Col>
							<Col xs={2}>
								{data.transactions.map((t,j) => {
									return (
										<Row key={"transactionRow3" + j}>
											{t.amount.value >= 0 && <Col className="text-end">&nbsp;</Col>}
											{t.amount.value < 0 && <Col className="text-end">
												{currencyFormatter(t.amount.value, 2, true)}
											</Col>}
										</Row>
									)
								})}
							</Col>
							<Col xs={2} className="text-end">
								{currencyFormatter(data.Balance*100, 2, true)}
							</Col>
						</Row>
						{/* Small Screens */}
						<Row className="pt-1 text-muted d-md-none" style={{borderBottom: "1px solid #E5E5E5"}}>
							<Col xs={2} className="">
								{formattedDate(data.name)}
							</Col>
							<Col xs={6}>
								<Row>Closing Balance</Row>
								{data.transactions.map((t,j) => {
									return (
										<Row key={"transactionRow4" + j}>
											<Col className="text-truncate">
												{t.shortDescription}
											</Col>
										</Row>
									)
								})}
							</Col>
							<Col xs={4}>
								<Row><Col className="text-end fw-bold">{currencyFormatter(data.Balance*100, 2, true)}</Col></Row>
								{data.transactions.map((t,j) => {
									return (
										<Row className="fw-light fst-italic" key={"transactionRow5" + j}>
											{t.amount.value > 0 && <Col className="text-end text-saving-color">
												+{currencyFormatter(t.amount.value, 2, true)}
											</Col>}
											{t.amount.value < 0 && <Col className="text-end text-spending-color">
												{currencyFormatter(t.amount.value, 2, true)}
											</Col>}
										</Row>
									)
								})}
							</Col>
						</Row>
					</div>)
					// if (i === currentTransactionDisplayTotal){
					// 	return (
					// 		<Button
					// 				onClick={(e) => {
					// 					setCurrentTransactionDisplayTotal(currentTransactionDisplayTotal + 5)
					// 				}}
					// 				key="showMoreTransactions"
					// 				className="my-2 text-primary"
					// 				variant=""
					// 				style={{"width": "100%", "backgroundColor": "#E8F2FF"}}>
					// 			<ChevronDoubleDown/> View More <ChevronDoubleDown/>
					// 		</Button>
					// 	)
					// }
					// return null
				})}
			</div>
		</>)
	}

	return (
		<>
			<PageTitle pageName="Bank Accounts" helpPage="bank">
				<TwoColumnContentLayout
						left={<AccountSelector displayTypes={displayTypes} oneTypeOnly={true} selected={selected} editButtonText="Edit Accounts" />}
						right={accountDetails()} />

				<hr className="" />

				<TwoColumnContentLayout alignItemsCenter
						left={<Tile body={monthOverviewData()} />}
						right={<Tile title={<h5 className="mb-3">Account Balance</h5>} body={lineChart()} />} />

				{accountTransactions()}

			</PageTitle>
		</>
	)
}

export default BankAccounts;