import React, { PureComponent, useState, useEffect } from "react";
import { AreaChart,ComposedChart,  Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine, Label, Brush, Surface, LineChart, Line } from 'recharts';
import currencyFormatter from "../../utils/CurrencyFormatter";

import styles from '../../scss/exports.scss';

const ForecastChart = ({ data1, displayConfig, retirementDate, minZero = false, xAxisType = 'year', dateOfBirth = null, showBrush = false }) => {

	if (!displayConfig) displayConfig = {}
	if (!displayConfig.bars) displayConfig.bars = []
	if (!displayConfig.color) displayConfig.color = styles['asset-0']
	if (!displayConfig.dataSplit) displayConfig.dataSplit = [displayConfig.chartNames, []]
	const hasScenario = displayConfig.dataSplit[1].length > 0

	const [ graphData, setGraphData ] = useState([])
	const [ brushZoom, setBrushZoom ] = useState(null)
	const [ wingGradient, setWingGradient ] = useState(null)
	const [ linePreGradient, setLinePreGradient ] = useState(null)
	const [ linePostGradient, setLinePostGradient ] = useState(null)
	const [ lineGradient, setLineGradient ] = useState(null)
	const [ maxXAxis, setMaxXAxis ] = useState('')

	const [ showXMonthLabel, setShowXMonthLabel ] = useState(false)


	function uuidv4() {
		return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
			(+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
		);
	}

	const id = uuidv4()


	useEffect(() => {
		const brushData = data1.filter((_,i) => brushZoom === null || (i >= brushZoom.startIndex && i <= brushZoom.endIndex))
		const dataHighMax = Math.max(...brushData.map((i) => i.high || -Infinity))
		const dataLowMin = Math.min(...brushData.map((i) => i.low || Infinity))
		const dataBasePreMax = Math.max(...brushData.map((i) => i.basePreToday || -Infinity))
		const dataBasePreMin = Math.min(...brushData.map((i) => i.basePreToday || Infinity))
		const dataBasePostMax = Math.max(...brushData.map((i) => i.basePostToday || -Infinity))
		const dataBasePostMin = Math.min(...brushData.map((i) => i.basePostToday || Infinity))

		const allDataMax = Math.max(...[...data1.map(i => i.basePreToday || -Infinity), ...data1.map(i => i.basePostToday || -Infinity)])
		const allDataMin = Math.min(...[...data1.map(i => i.basePreToday || Infinity), ...data1.map(i => i.basePostToday || Infinity)])

		if (dataHighMax <= 0){
			setWingGradient(0)
		} else if (dataLowMin >= 0){
			setWingGradient(1)
		} else {
			setWingGradient(dataHighMax / (dataHighMax - dataLowMin))
		}
		if (dataBasePreMax <= 0){
			setLinePreGradient(0)
		} else if (dataBasePreMin >= 0){
			setLinePreGradient(1)
		} else {
			setLinePreGradient(dataBasePreMax / (dataBasePreMax - dataBasePreMin))
		}
		if (dataBasePostMax === dataBasePostMin){
			setLinePostGradient(dataBasePostMax > 0 ? -1 : -2)
		} else if (dataBasePostMax <= 0){
			setLinePostGradient(0)
		} else if (dataBasePostMin >= 0){
			setLinePostGradient(1)
		} else {
			setLinePostGradient(dataBasePostMax / (dataBasePostMax - dataBasePostMin))
		}
		setLineGradient(allDataMax / (allDataMax - allDataMin))
		let xMin = Math.min(...data1.filter((_,i) => brushZoom === null || (i > brushZoom.stackIndex && i < brushZoom.endIndex)).map(i => i.name))
		let xMax = Math.max(...data1.filter((_,i) => brushZoom === null || (i > brushZoom.stackIndex && i < brushZoom.endIndex)).map(i => i.name))
		let xMinYear = new Date(xMin).getFullYear()
		let xMaxYear = new Date(xMax).getFullYear()
		setShowXMonthLabel(xMaxYear < (xMinYear + 5))

		setGraphData(data1)
	}, [data1, brushZoom])

	const getXMax = () => {
		if (brushZoom?.endIndex) return brushZoom.endIndex
		let endPoint = data1.length - 1
		for (let i = data1.length - 1; i >= 0; i--){
			if (data1[i].basePostToday === 0) {
				endPoint = i
			} else {
				break
			}
		}

		return endPoint
	}

	class XAxisTickFormatter extends PureComponent{
		render() {
			const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
			const {x, y, payload} = this.props
			const brushSize = brushZoom?.endIndex - brushZoom?.startIndex
			const isSmall = brushZoom ? (brushSize < 100) : showXMonthLabel

			const date = new Date(payload.value)
			const year = date.getFullYear()
			const month = date.getMonth() + 1
			let largeText = year

			if (xAxisType === 'age' && dateOfBirth != null){
				let birthYear = new Date(dateOfBirth).toISOString().split('-')[0]
				let tickYear = year
				return (<>
					<g transform={`translate(${x}, ${y})`}>
						<text x={0} y={0} dy={16} textAnchor="end" fill="#666">{(Number(tickYear) - Number(birthYear))}</text>
					</g>
				</>)
			}


			if (isSmall){
				let smallText = months[month] + ' ' + year
				return (<>
					<g transform={`translate(${x}, ${y})`}>
						<text x={0} y={0} dy={16} textAnchor="end" fill="#666">{smallText}</text>
					</g>
				</>)
			}
			return (<>
				<g transform={`translate(${x}, ${y})`}>
					<text x={0} y={0} dy={16} textAnchor="end" fill="#666">{largeText}</text>
				</g>
			</>)
		}
	}

	return (
		<>
			<ResponsiveContainer className="border soft-corners" width="100%" height="100%">
				<ComposedChart
						width={500}
						height={300}
						data={graphData}
						margin={{
							top: 0,
							right: 5,
							left: 0,
							bottom: showBrush ? 15 : 0,
						}}>
					<CartesianGrid strokeDasharray="1 0" vertical={false} stroke="#E5E9F2" />
					<XAxis
							dataKey="name"
							tick={<XAxisTickFormatter />}
							tickCount={40}
							tickLine={false}
							axisLine={false}
							domain={['dataMin', 'dataMax']}
							type="number"
							className="text-muted fs-6"
							allowDataOverflow
							/>
					{!minZero && <ReferenceLine
							y={0} label="" stroke="#505D68" />}
					<YAxis
							dx={-5}
							padding={{ top: 25, bottom: 0}}
							tickFormatter={value => currencyFormatter(value)}
							tickLine={false}
							axisLine={false}
							width={70}
							allowDataOverflow
							domain={ minZero ? ['auto', 'auto'] : ['auto', 'auto'] }
							className="text-muted fs-6" />
					{displayConfig.renderTooltip && <Tooltip content={<displayConfig.renderTooltip displayConfig={displayConfig} />} />}
					{displayConfig.renderLegend && <Legend content={<displayConfig.renderLegend displayConfig={displayConfig} />} />}
					<defs>
						<linearGradient id={`splitColorStrokePre${id}`} x1="0" y1="0" x2="0" y2="1">
							<stop offset={linePreGradient} stopColor={styles['asset-0']} stopOpacity={1} /> {/* Positive Color */}
							<stop offset={linePreGradient} stopColor={styles['negative-0']} stopOpacity={1} /> {/* Negative Color */}
						</linearGradient>
						<linearGradient id={`splitColorStrokePost${id}`} x1="0" y1="0" x2="0" y2="1">
							<stop offset={linePostGradient} stopColor={styles['asset-0']} stopOpacity={1} /> {/* Positive Color */}
							<stop offset={linePostGradient} stopColor={styles['negative-0']} stopOpacity={1} /> {/* Negative Color */}
						</linearGradient>
						<linearGradient id={`splitColorStrokeAll${id}`} x1="0" y1="0" x2="0" y2="1">
							<stop offset={lineGradient} stopColor={styles['asset-0']} stopOpacity={1} /> {/* Positive Color */}
							<stop offset={lineGradient} stopColor={styles['negative-0']} stopOpacity={1} /> {/* Negative Color */}
						</linearGradient>
						<linearGradient id={`splitColorFill${id}`} x1="0" y1="0" x2="0" y2="1">
							<stop offset={wingGradient} stopColor={styles['asset-4']} stopOpacity={1} /> {/* Positive Color */}
							<stop offset={wingGradient} stopColor={styles['negative-4']} stopOpacity={1} /> {/* Negative Color */}
						</linearGradient>
					</defs>
					{showBrush && <Brush
							dataKey="name"
							travellerWidth={10}
							height={40}
							tickFormatter={value => {
								if (xAxisType === 'age' && dateOfBirth != null){
									let birthYear = new Date(dateOfBirth).toISOString().split('-')[0]
									let tickYear = new Date(value).toISOString().split('-')[0]
									return (Number(tickYear) - Number(birthYear))
								} else if (xAxisType === 'year'){
									return new Date(value).toISOString().split('-')[0]
								} else {
									return new Date(value).toISOString().split('-')[0]
								}
							}}
							stroke="#8E939C"
							endIndex={getXMax()}
							onChange={(v) => setBrushZoom(v)}>
						<LineChart data={graphData}>
							<Line
									type="monotone"
									dataKey="base"
									stroke={`url('#splitColorStrokeAll${id}')`}
									activeDot={false}
									dot={false} />
							<XAxis
									dataKey="name"
									domain={['dataMin', 'dataMax']}
									type="number"
									allowDataOverflow
									hide />
							<ReferenceLine
									x={new Date().getTime()}>
							</ReferenceLine>
							{retirementDate && <ReferenceLine x={retirementDate.getTime()}>
							</ReferenceLine>}
						</LineChart>
					</Brush>}

					{displayConfig.dataSplit.map((chartData, stackIndex) => {
						if (chartData == null) return <React.Fragment key={"stackIndex" + stackIndex}></React.Fragment>
						return chartData.length > 0 && <React.Fragment key={"stackIndex" + stackIndex}>
							{!showBrush && <>
								{chartData.map(n => (
									<Area
											activeDot={false}
											key={n + "lowBase" + stackIndex}
											type="monotone"
											dataKey={n + "_l"}
											stackId={"area" + stackIndex}
											dot={false}
											fill={'#00000000'}
											stroke={'#00000000'}
											connectNulls
											isAnimationActive={true}>
										</Area>
								))}
								{chartData.map(n => (
									<Area
											activeDot={false}
											key={n + "highDiff" + stackIndex}
											type="monotone"
											dataKey={n + "_hd"}
											stackId={"area" + stackIndex}
											dot={false}
											fill={stackIndex === 0 ? (hasScenario ? ('#E8F2FF') : (styles['asset-4'])) : (styles['positive-3'] + '80')}
											stroke={'#00000000'}
											connectNulls
											isAnimationActive={true}>
										</Area>
								))}
								{chartData.map(n => (
									<Area
											activeDot={false}
											key={n + "high" + stackIndex}
											type="monotone"
											dataKey={n + "_h"}
											stackId={"high" + stackIndex}
											dot={false}
											fill="#00000000"
											stroke="none"
											connectNulls
											isAnimationActive={true}>
										</Area>
								))}
								{chartData.map(n => {
									return (
										<Area
												activeDot={false}
												key={n + "_base" + stackIndex}
												type="monotone"
												dataKey={n}
												stackId={"blueLine" + stackIndex}
												dot={false}
												fill="#00000000"
												stroke="#00000000"
												connectNulls
												isAnimationActive={true}>
											</Area>
									)
								})}
								<Area
										activeDot={false}
										key={"basePast" + stackIndex}
										type="monotone"
										dataKey={"preTodayZeros"}
										stackId={"blueLine" + stackIndex}
										dot={false}
										fill="#00000000"
										stroke={stackIndex === 0 ? displayConfig.color : '#00000000'}
										strokeWidth={2}
										connectNulls
										isAnimationActive={true}>
									</Area>
								<Area
										activeDot={false}
										key={"baseFuture" + stackIndex}
										type="monotone"
										dataKey={"postTodayZeros"}
										stackId={"blueLine" + stackIndex}
										dot={false}
										fill="#00000000"
										stroke={stackIndex === 0 ? (hasScenario ? styles['asset-2'] : displayConfig.color) : styles['positive-0']}
										strokeWidth={2}
										strokeDasharray="6 3"
										isAnimationActive={true}>
									</Area>
							</>}
							{showBrush && stackIndex === 0 && <>
								<Area
										activeDot={false}
										key={"low"}
										type="monotone"
										dataKey={"low"}
										stackId={"area"}
										dot={false}
										fill={'#00000000'}
										stroke={'#00000000'}
										connectNulls
										isAnimationActive={true}>
									</Area>
								<Area
										activeDot={false}
										key={"highDiff"}
										type="monotone"
										dataKey={"highDiff"}
										stackId={"area"}
										dot={false}
										fill={`url('#splitColorFill${id}')`}
										stroke={'#00000000'}
										connectNulls
										isAnimationActive={true}>
									</Area>
								<Area
										activeDot={false}
										key={"base"}
										type="monotone"
										dataKey={"base"}
										stackId={"tooltipBaseValue"}
										dot={false}
										fill="#00000000"
										stroke="#00000000"
										connectNulls
										isAnimationActive={true}>
									</Area>
								<Area
										activeDot={false}
										key={"high"}
										type="monotone"
										dataKey={"high"}
										stackId={"tooltipHighValue"}
										dot={false}
										fill="#00000000"
										stroke="#00000000"
										connectNulls
										isAnimationActive={true}>
									</Area>
								<Line
										activeDot={false}
										key={"scenarioPost"}
										type="monotone"
										dataKey={"scenarioPostToday"}
										stackId={"scenarioPostTodayLine"}
										dot={false}
										stroke={styles['positive-1']}
										strokeWidth={2}
										isAnimationActive={true}
										strokeDasharray="6 3">
									</Line>
								<Line
										activeDot={false}
										key={"basePast"}
										type="monotone"
										dataKey={"basePreToday"}
										stackId={"basePreTodayLine"}
										dot={false}
										stroke={`url('#splitColorStrokePre${id}')`}
										strokeWidth={2}
										connectNulls
										isAnimationActive={true}>
									</Line>
								<Line
										activeDot={false}
										key={"basePost"}
										type="monotone"
										dataKey={"basePostToday"}
										stackId={"basePostTodayLine"}
										dot={false}
										stroke={linePostGradient >= 0 ? `url('#splitColorStrokePost${id}')` : (linePostGradient === -2 ? styles['negative-0'] : styles['asset-0'])}
										strokeWidth={2}
										connectNulls
										isAnimationActive={true}
										strokeDasharray="6 3">
									</Line>
							</>}
						</React.Fragment>
					})}

					<ReferenceLine
							x={new Date().getTime()}>
						<Label value="Today" position="insideTop" />
					</ReferenceLine>
					{retirementDate && <ReferenceLine
							x={retirementDate.getTime()}>
						<Label value="Retirement" position="insideTop" />
					</ReferenceLine>}

				</ComposedChart>
			</ResponsiveContainer>
		</>
	)
}

export default ForecastChart;