export const forecastPension = async ( moneyHubAssetTypes, user, accountState, pensionSliderValues, pensionStaticTotals, setPensionStaticTotals, setLoadingStage, updateStatic = false ) => {
	let accounts = [...accountState.state]
	const pensionTypes = moneyHubAssetTypes['pension']
	let pensionScenariosLastMonth = []
	let lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0]
	accounts.filter(a => pensionTypes.includes(a.type) && !a.lastMonthScenario && a.type !== 'pension-scenario' && a.type !== 'pension-scenario:lastMonth').forEach(a => {
		let aClone = structuredClone(a)
		aClone.balances = aClone.balances?.filter(balance => balance.date <= lastMonth)
		aClone.type = 'pension-scenario:lastMonth'
		pensionScenariosLastMonth.push(aClone)
		a.lastMonthScenario = true
	})
	accounts.push(...pensionScenariosLastMonth)
	const annuityPayoutCalculator = (pv, rate, periods) => {
		if (pv === 0) return 0
		return (rate * pv) / (1 - Math.pow((1 + rate), (-1 * periods)));
	}
	const pensionModel = {
		inPast: { low: 0, base: 0, high: 0},
		preRetire: { low: -0.5, base: 2.5, high: 4.5},
		postRetire: { low: 1.9, base: 1.9, high: 1.9 }
	}
	const getYearRate = (currentDate, today, retireDate) => {
		if (currentDate <= today) return pensionModel.inPast
		if (currentDate <= retireDate) return pensionModel.preRetire
		return pensionModel.postRetire
	}
	const today = new Date().toISOString().split('T')[0]
	let startDate = user.dateAged18
	let countPensions = 0
	accounts.filter(a => pensionTypes.includes(a.type)).forEach(a => {
		if (a.balances?.length > 0) {
			if (new Date(a.balances[0].date) < new Date(startDate)){
				startDate = a.balances[0].date
			}
		}
		if (a.type.split(':')[0] !== 'pension-scenario') {
			countPensions ++
			console.log(a)
		}
	})
	const endDate = user.dateAged90
	const retireDate = new Date(new Date(user.dateOfBirth).setFullYear(new Date(user.dateOfBirth).getFullYear() + Number(pensionSliderValues.retirementAge))).toISOString().split('T')[0]
	const lumpSumPercentage = pensionSliderValues.lumpSumPercentage / 100

	accounts.filter(a => pensionTypes.includes(a.type)).forEach(a => {
		let currentValue = { low: null, base: null, high: null }
		let currentPayment = 0
		let currentDate = new Date(startDate)
		let valueDataGraph = []
		let keyDates = []
		while (currentDate <= endDate){
			const shortDate = currentDate.toISOString().split('T')[0]
			let currentBalances = a.balances?.filter(b => b.date === shortDate).sort((a,b) => a.date < b.date ? 1 : -1)

			if (currentBalances?.length > 0) {
				currentValue = {
					low: currentBalances[0].amount.value,
					base: currentBalances[0].amount.value,
					high: currentBalances[0].amount.value
				}
				keyDates.push(currentDate)
			} else if (currentValue.low != null && currentValue.base != null && currentValue.high != null) {
				const yearRate = getYearRate(currentDate, new Date(today), new Date(retireDate))
				const baseRate = 1 + (yearRate.base/100)
				const lowRate = yearRate.low ? (1 + (yearRate.low/100)) : baseRate
				const highRate = yearRate.high ? (1 + (yearRate.high/100)) : baseRate
				currentValue.low = Math.max(0, (currentValue.low * (Math.pow(lowRate, 1/364))) + (currentPayment / 364))
				currentValue.base = Math.max(0, (currentValue.base * (Math.pow(baseRate, 1/364))) + (currentPayment / 364))
				currentValue.high = Math.max(0, (currentValue.high * (Math.pow(highRate, 1/364))) + (currentPayment / 364))
			}
			if (shortDate === today) {
				a.currentValue = {...currentValue}
				keyDates.push(currentDate)
				if (a.type === 'pension-scenario'){
					currentPayment = pensionSliderValues.monthlyPayment * 12
				} else {
					console.log(a, pensionSliderValues)
					currentPayment = pensionSliderValues.currentMonthlyPayment != null ? (1.2 * 12 * pensionSliderValues.currentMonthlyPayment / countPensions) : 0
				}
			}
			if (shortDate === retireDate) {
				a.retirementValue = {...currentValue}
				keyDates.push(currentDate)
			}
			if (keyDates.includes(currentDate) || (currentDate.getDate() === 1)){
				valueDataGraph.push({
					name: Number(currentDate),
					low: currentValue.low,
					base: currentValue.base,
					high: currentValue.high,
					highDiff: currentValue.high - currentValue.low
				})
			}
			if (shortDate === retireDate){
				a.lumpSum = {
					low: currentValue.low * lumpSumPercentage,
					base: currentValue.base * lumpSumPercentage,
					high: currentValue.high * lumpSumPercentage
				}
				currentValue.low -= a.lumpSum.low
				currentValue.base -= a.lumpSum.base
				currentValue.high -= a.lumpSum.high
				a.annualRetirementPayment = {
					low: annuityPayoutCalculator(currentValue.low, pensionModel.postRetire.low / 100, 91 - pensionSliderValues.retirementAge),
					base: annuityPayoutCalculator(currentValue.base, pensionModel.postRetire.base / 100, 91 - pensionSliderValues.retirementAge),
					high: annuityPayoutCalculator(currentValue.high, pensionModel.postRetire.high / 100, 91 - pensionSliderValues.retirementAge)
				}
				currentPayment = -1 * a.annualRetirementPayment.base
				valueDataGraph.push({
					name: Number(currentDate) + 1,
					low: currentValue.low,
					base: currentValue.base,
					high: currentValue.high,
					highDiff: currentValue.high - currentValue.low
				})
			}
			currentDate = addDays(currentDate, 1)
		}
		a.valueForecast = valueDataGraph
	})
	if (updateStatic){
		let retirementIncome = { low: 0, base: 0, high: 0}
		let lumpSumValue = { low: 0, base: 0, high: 0}
		let lastMonthRetirementIncome = { low: 0, base: 0, high: 0}
		let lastMonthLumpSumValue = { low: 0, base: 0, high: 0}
		accounts.filter(a => pensionTypes.includes(a.type) && a.type !== 'pension-scenario:lastMonth').forEach(a => {
			retirementIncome.low += a.annualRetirementPayment.low
			retirementIncome.base += a.annualRetirementPayment.base
			retirementIncome.high += a.annualRetirementPayment.high
			lumpSumValue.low += a.lumpSum.low
			lumpSumValue.base += a.lumpSum.base
			lumpSumValue.high += a.lumpSum.high
		})
		accounts.filter(a => pensionTypes.includes(a.type) && a.type === 'pension-scenario:lastMonth').forEach(a => {
			lastMonthRetirementIncome.low += a.annualRetirementPayment.low
			lastMonthRetirementIncome.base += a.annualRetirementPayment.base
			lastMonthRetirementIncome.high += a.annualRetirementPayment.high
			lastMonthLumpSumValue.low += a.lumpSum.low
			lastMonthLumpSumValue.base += a.lumpSum.base
			lastMonthLumpSumValue.high += a.lumpSum.high
		})
		setPensionStaticTotals({retirementIncome, lumpSumValue, lastMonthRetirementIncome, lastMonthLumpSumValue})
	}
	accountState.setState(accounts)
	setLoadingStage(oldLoadingStage => ({...oldLoadingStage, forecastPension: true}))
}

const addDays = (date, days) => {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}