// State pension start dates, this is an algorithm based on the UK government state pension start dates from date of birth
const getStatePensionStartDate = (userDateOfBirth) => {

	const addYearsAndMonths = (years, months) => {
		return new Date(new Date(userDateOfBirth).getFullYear() + years, new Date(userDateOfBirth).getMonth() + months, new Date(userDateOfBirth).getDate());
	}

	if (userDateOfBirth >= new Date(1978, 4, 6)){
		return addYearsAndMonths(68,0);
	} else if (userDateOfBirth >=  new Date(1978, 2, 6)){
		return new Date(2046, 2, 6);
	} else if (userDateOfBirth >=  new Date(1978, 1, 6)){
		return new Date(2046, 0, 6);
	} else if (userDateOfBirth >=  new Date(1978, 0, 6)){
		return new Date(2045, 10, 6);
	} else if (userDateOfBirth >=  new Date(1977, 11 , 6)){
		return new Date(2045, 8, 6);
	} else if (userDateOfBirth >=  new Date(1977, 10, 6)){
		return new Date(2045, 6, 6);
	} else if (userDateOfBirth >=  new Date(1977, 9, 6)){
		return new Date(2045, 4, 6);
	} else if (userDateOfBirth >=  new Date(1977, 8, 6)){
		return new Date(2045, 2, 6);
	} else if (userDateOfBirth >=  new Date(1977, 7, 6)){
		return new Date(2045, 0, 6);
	} else if (userDateOfBirth >=  new Date(1977, 6, 6)){
		return new Date(2044, 10, 6);
	} else if (userDateOfBirth >=  new Date(1977, 5, 6)){
		return new Date(2044, 8, 6);
	} else if (userDateOfBirth >=  new Date(1977, 4, 6)){
		return new Date(2044, 6, 6);
	} else if (userDateOfBirth >=  new Date(1977, 3, 6)){
		return new Date(2044, 4, 6);
	} else if (userDateOfBirth >=  new Date(1961, 2, 6)){
		return addYearsAndMonths(67,0);
	} else if (userDateOfBirth >=  new Date(1961, 1, 6)){
		return addYearsAndMonths(66,11);
	} else if (userDateOfBirth >=  new Date(1961, 0, 6)){
		return addYearsAndMonths(66,10);
	} else if (userDateOfBirth >=  new Date(1960, 11, 6)){
		return addYearsAndMonths(66,9);
	} else if (userDateOfBirth >=  new Date(1960, 10, 6)){
		return addYearsAndMonths(66,8);
	} else if (userDateOfBirth >=  new Date(1960, 9, 6)){
		return addYearsAndMonths(66,7);
	} else if (userDateOfBirth >=  new Date(1960, 8, 6)){
		return addYearsAndMonths(66,6);
	} else if (userDateOfBirth >=  new Date(1960, 7, 6)){
		return addYearsAndMonths(66,5);
	} else if (userDateOfBirth >=  new Date(1960, 6, 6)){
		return addYearsAndMonths(66,4);
	} else if (userDateOfBirth >=  new Date(1960, 5, 6)){
		return addYearsAndMonths(66,3);
	} else if (userDateOfBirth >=  new Date(1960, 4, 6)){
		return addYearsAndMonths(66,2);
	} else if (userDateOfBirth >=  new Date(1960, 3, 6)){
		return addYearsAndMonths(66,1);
	} else if (userDateOfBirth >=  new Date(1954, 9, 6)){
		return addYearsAndMonths(66,0);
	} else if (userDateOfBirth >=  new Date(1954, 8, 6)){
		return new Date(2020, 8, 6);
	} else if (userDateOfBirth >=  new Date(1954, 7, 6)){
		return new Date(2020, 6, 6);
	} else if (userDateOfBirth >=  new Date(1954, 6, 6)){
		return new Date(2020, 4, 6);
	} else if (userDateOfBirth >=  new Date(1954, 5, 6)){
		return new Date(2020, 2, 6);
	} else if (userDateOfBirth >=  new Date(1954, 4, 6)){
		return new Date(2020, 0, 6);
	} else if (userDateOfBirth >=  new Date(1954, 3, 6)){
		return new Date(2019, 10, 6);
	} else if (userDateOfBirth >=  new Date(1954, 2, 6)){
		return new Date(2019, 8, 6);
	} else if (userDateOfBirth >=  new Date(1954, 1, 6)){
		return new Date(2019, 6, 6);
	} else if (userDateOfBirth >=  new Date(1954, 0, 6)){
		return new Date(2019, 4, 6);
	} else if (userDateOfBirth >=  new Date(1953, 11, 6)){
		return new Date(2019, 2, 6);
	} else {
		return addYearsAndMonths(65,0);
	}
}

export default getStatePensionStartDate;