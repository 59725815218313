import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../pages/Main";

import { Row, Col } from 'react-bootstrap';
import 'react-multi-carousel/lib/styles.css';
import { QuestionDiamond, QuestionDiamondFill } from "react-bootstrap-icons";

import { motion, AnimatePresence } from "framer-motion"

import HelpPopover from "./HelpPopover";


function PageTitle({ pageName, helpPage, children }){

	const userContext = useContext(UserContext)

	const [ showHelp, setShowHelp ] = useState(false)

	useEffect(() => {
		const hasClosedHelp = JSON.parse(window.localStorage.getItem('hasClosedHelp'))

		if (helpPage === 'home') {
			setShowHelp(!hasClosedHelp)
		} else {
			if (helpPage != null){
				if (helpPage === 'pension'){
					// if count pension == 0 show
					const countPension = userContext.accounts?.filter(a => a.type === 'pension').length
					setShowHelp(countPension === 0)
				} else if (helpPage === 'insights'){
					// if count cash == 0 show
					const cashAccountTypes = ['cash:current', 'savings', 'card']
					const countCashAccounts = userContext.accounts?.filter(a => cashAccountTypes.includes(a.type)).length
					setShowHelp(countCashAccounts === 0)
				} else if (helpPage === 'bank'){
					// if count cash == 0 show
					const cashAccountTypes = ['cash:current', 'savings', 'card']
					const countCashAccounts = userContext.accounts?.filter(a => cashAccountTypes.includes(a.type)).length
					setShowHelp(countCashAccounts === 0)
				} else if (helpPage === 'shopst'){
					// if count cash == 0 show
					const cashAccountTypes = ['cash:current', 'savings', 'card']
					const countCashAccounts = userContext.accounts?.filter(a => cashAccountTypes.includes(a.type)).length
					setShowHelp(countCashAccounts === 0)
				} else if (helpPage === 'property'){
					// if count property == 0 show
					const countProperty = userContext.accounts?.filter(a => a.type.split(':')[0] === 'properties').length
					setShowHelp(countProperty === 0)
				} else if (helpPage === 'mortgage'){
					// if count property == 0 show
					const countMortgage = userContext.accounts?.filter(a => a.type.split(':')[0] === 'mortgage').length
					setShowHelp(countMortgage === 0)
				} else {
					setShowHelp(false)
				}
			} else {
				setShowHelp(helpPage != null)
			}
		}
	}, [])

	const getHasHelp = () => {
		return helpPage != null
	}

	return (<>
		<div className="p-0 d-none d-xl-block" style={{position: "relative"}}>
			<Row className="g-0">
				<Col className="page-title page-title-background">
					{pageName}
					{getHasHelp() && <div style={{right: "50px", position: "absolute", marginTop: "-44px"}} className="fs-1 interaction hasHoverEffect_large" onClick={() => setShowHelp(!showHelp)}>
						<AnimatePresence>
							{ !showHelp && <motion.div
									initial={{ opacity: 0}}
									animate={{ opacity: 1 }} //, scale: [1, 1.75, 1, 1.5, 1], color: ['hsl(6.97, 98.02%, 39.61%)', 'hsl(6.97, 98.02%, 39.61%)', 'hsl(6.97, 98.02%, 39.61%)', 'hsl(6.97, 98.02%, 39.61%)', 'hsl(238.38, 82.22%, 8.82%)'] }}
									exit={{ opacity: 0 }}
									transition={{ duration: 1 }}
									whileHover={{
										scale: 1.1,
										transition: { duration: 0.1 },
									}}
									style={{ position: "absolute" }}>
								<QuestionDiamond />
							</motion.div>}
						</AnimatePresence>
						<AnimatePresence>
							{ showHelp && <motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0, scale: [1,1,1,1.5,1,2,1], color: ['hsl(6.97, 98.02%, 39.61%)', 'hsl(238.38, 82.22%, 8.82%)'] }}
									transition={{ duration: 1.5 }}
									style={{ position: "absolute" }}>
								<QuestionDiamondFill />
							</motion.div>}
						</AnimatePresence>
					</div>}
				</Col>
			</Row>
		</div>
		<div className="p-0 d-xl-none">
			{getHasHelp() && <div style={{right: "50px", position: "absolute", marginTop: "-69px"}} className="fs-1 interaction hasHoverEffect_large" onClick={() => setShowHelp(!showHelp)}>
				<AnimatePresence>
					{ !showHelp && <motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 1 }}
							style={{ position: "absolute" }}>
						<QuestionDiamond color='hsl(210, 15.79%, 92.55%)' />
					</motion.div>}
				</AnimatePresence>
				<AnimatePresence>
					{ showHelp && <motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0, scale: [1,1,1,1.5,1,2,1] }}
							transition={{ duration: 1.5 }}
							style={{ position: "absolute" }}>
						<QuestionDiamondFill color='hsl(210, 15.79%, 92.55%)' />
					</motion.div>}
				</AnimatePresence>
			</div>}
		</div>
		<div style={{position: "relative"}}>
			<HelpPopover pageName={helpPage} showHelp={showHelp} setShowHelp={setShowHelp} />

			<div className="p-3 p-md-5 p-xl-4 p-xxl-5 pageHeight">
				{children}
			</div>
		</div>
	</>)
}

export default PageTitle;