// Terms of Business
// TODO: Move this to somewhere on AWS so it can be edited outside of the codebase
const TermsOfBusiness = () => {
	return (
		<div className="terms mh-100 overflow-scroll">
			<ol>
				<li>
					Who we are and what these Terms do
					<ol>
						<li>
							We, Prift OPCO UK Limited (Prift), a company registered in England and Wales with registered number 13464853 and whose office is at 1 Mayfair Mews, Balham Grove, London, United Kingdom, SW12 8AZ, allow you to use the Prift platform as permitted in these Terms.
						</li>
						<li>
							We are registered with the UK Financial Conduct Authority (FCA) as a PSD Agent (reference number 992581) of MoneyHub Financial Technology Ltd (“MoneyHub”) who is authorised and regulated by the Financial Conduct Authority under the Payment Services Regulations 2017 (reg. no. 809360) for the provision of payment services. Registered office is c/o Roxburgh Milkins Limited, Merchants House North, Wapping Road, Bristol, United Kingdom, BS1 4RW Email: support@moneyhub.co.uk.
						</li>
						<li>
							We are registered with the ICO with registration number ZB439580.
						</li>
						<li>
							Our VAT number is 426340026.
						</li>
					</ol>
				</li>
				<li>
					Definitions
					<ol>
						<li>
							Beta Users means Users provided access to the Prift platform directly by us as beta testers.
						</li>
						<li>
							Customer means your employer business with which we have contracted as our customer (where applicable).
						</li>
						<li>
							User Data means your information, which is processed using the Prift platform.
						</li>
						<li>
							Users means people who are authorised by the Customer (or, for Beta Users, directly by us) to use the Prift platform.
						</li>
					</ol>
				</li>
				<li>
					Grant and scope of agreement
					<ol>
						<li>
							If you are accessing the Prift platform as a Beta User:
							<ol>
								<li>
									By accessing and using the Prift platform, you confirm that you have been authorised to do so by us.
								</li>
								<li>
									You further confirm that you will only use the Prift platform in accordance with any instructions given to you by us.
								</li>
								<li>
									We reserve the right at our sole and absolute discretion to suspend or terminate your access to the Prift platform at any time.
								</li>
							</ol>
						</li>
						<li>
							If you are accessing the Prift platform subject to a Customer's contract with Prift:
							<ol>
								<li>
									By accessing and using the Prift platform, you confirm that you have been authorised to do so by the Customer.
								</li>
								<li>
									You further confirm that the Customer has made you aware of the terms under which you are permitted to use the Prift platform.
								</li>
								<li>
									If we have reason to believe that your use of the Prift platform would put the Customer in breach of its agreement with us, or where we have reason to believe that you have not been authorised by the Customer, we reserve the right at our sole and absolute discretion to suspend or terminate your access to the Prift platform.
								</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>
					Disclaimers
					<ol>
						<li>
							You acknowledge and agree that:
							<ol>
								<li>
									We are not authorised or regulated by the FCA for the provision of regulated financial advice. In providing our services, we are merely providing a platform on which you can upload and view your financial information. We may provide guidance, but we do not provide any financial advice and/or recommendations.
								</li>
								<li>
									Without limiting clause 9.1:
									<ol>
										<li>
											All information received from third parties using Open Finance and Open Banking is the responsibility of the third parties from which we received it; and
										</li>
										<li>
											we make no warranties or representations: (i) as to the availability of specific information; (ii) as to the accuracy and/or completeness of any information; or (iii) that any information obtained through the Prift platform will meet your requirements.
										</li>
									</ol>
								</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>
					Your obligations
					<ol>
						<li>
							You confirm that:
							<ol>
								<li>
									you own all rights in any User Data you provide or upload to the Prift platform and you have all the rights necessary to grant us the right to carry out our obligations under these Terms;
								</li>
								<li>
									the User Data you provide or upload does not and will not infringe any intellectual property rights or other rights of any person, nor is it obscene, defamatory, libellous or slanderous, nor will it cause injury to, invade the privacy of or otherwise violate other rights of any person;
								</li>
								<li>
									you shall keep your username and password confidential;
								</li>
								<li>
									you shall procure that only you shall use or access the Prift platform through your username, password and/or User account;
								</li>
								<li>
									you shall procure that no other unauthorised access to and/or use is made of the Prift platform and utilising the username or password allocated to you; and
								</li>
								<li>
									you shall inform us immediately if you have any reason to believe that your username and password has become known to any individuals not authorised to use them or if the Prift platform is being or is likely to be used in an unauthorised way.
								</li>
							</ol>
						</li>
						<li>
							You will:
							<ol>
								<li>
									be responsible for verifying the User Data prior to providing or uploading it to the Prift platform;
								</li>
								<li>
									comply with all applicable laws and regulations with respect to your activities under these Terms;
								</li>
								<li>
									ensure that your network and systems comply with the relevant specifications provided by us from time to time; and
								</li>
								<li>
									be solely responsible for procuring and maintaining your network connections and telecommunications links from your systems to ours, and all problems, conditions, delays, delivery failures and all other loss or damage arising from or relating to your network connections or telecommunications links or caused by the internet.
								</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>
					Restrictions
					<ol>
						<li>
							You shall not:
							<ol>
								<li>
									except as may be allowed by any applicable law which is incapable of exclusion by agreement between the parties:
									(a)	and except to the extent expressly permitted under these Terms, attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Prift platform in any form or media or by any means; or
									(b)	attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Prift platform; or
								</li>
								<li>
									access all or any part of the Prift platform in order to build a product or services which competes with the Prift platform; or
								</li>
								<li>
									use the Prift platform to provide services to third parties; or
								</li>
								<li>
									license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Prift platform available to any third party; or
								</li>
								<li>
									attempt to obtain, or assist third parties in obtaining, access to the Prift platform other than as provided under clause 4.
								</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>
					Intellectual property rights
					<ol>
						<li>
							You acknowledge and agree that we or our licensors own all intellectual property rights in the Prift platform. Except as expressly stated herein, these Terms do not grant you any rights to, or in, patents, copyright, database right, trade secrets, trade names, trade marks (whether registered or unregistered), or any other rights or licences in respect of the Prift platform.
						</li>
						<li>
							We confirm that we have all the rights in relation to the Prift platform that are necessary to grant all the rights we purport to grant under, and in accordance with, these Terms.
						</li>
					</ol>
				</li>
				<li>
					Data protection
					<ol>
						<li>
							We collect and process personal data relating to you and other Users in the course of providing the Prift platform. We are the data controller of such personal data. Where data is processed by us as a data controller, such processing is carried out in accordance with our Privacy Notice.
						</li>
						<li>
						If we process personal data relating to you as a processor on behalf of a Customer, we will do so in accordance with the data processing terms agreed with the Customer.
						</li>
					</ol>
				</li>
				<li>
					Limitation of Liability
					<ol>
						<li>
							The Prift platform is provided to you “as is,” without warranty of any kind, either express or implied, including without limitation any warranty for information, data, services, uninterrupted access, or products provided through or in connection with the Prift platform. Any condition, warranty, representation or other term concerning the supply of the Prift platform which might otherwise be implied into, or incorporated in, these Terms whether by statute, common law or otherwise, is excluded to the fullest extent permitted by law.
						</li>
						<li>
							You assume sole responsibility for your use of the Prift platform and for conclusions drawn from, and actions based on, such use.
						</li>
						<li>
							You acknowledge that the Prift platform has not been developed to meet your individual requirements, including any particular cybersecurity requirements you might be subject to under law or otherwise, and that it is therefore your responsibility to ensure that the facilities and functions of the Prift platform meet your requirements.
						</li>
						<li>
							We shall not in any circumstances whatever be liable to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, arising under or in connection with the Agreement for:
							<ol>
								<li>
									loss of profits, sales, business, or revenue;
								</li>
								<li>
									business interruption;
								</li>
								<li>
									loss of anticipated savings;
								</li>
								<li>
									loss or corruption of data or information, or costs of recreating lost data;
								</li>
								<li>
									loss of business opportunity, goodwill or reputation;
								</li>
								where any of the losses set out in clause 9.4.1 to clause 9.4.5 are direct or indirect; or
								<li>
									any special, indirect or consequential loss, damage, charges or expenses.
								</li>
							</ol>
						</li>
						<li>
							Other than the losses set out in clause 9.4 (for which we are not liable), our maximum aggregate liability under or in connection with these Terms whether in contract, tort (including negligence) or otherwise, shall in all circumstances be limited to £10.
						</li>
						<li>
							Nothing in these Terms shall limit or exclude our liability for:
							<ol>
								<li>
									death or personal injury resulting from our negligence;
								</li>
								<li>
									fraud or fraudulent misrepresentation;
								</li>
								<li>
									any other liability that cannot be excluded or limited by English law.
								</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>
					Termination
					<ol>
						<li>
							We may terminate these Terms immediately by written notice to you if you commit a material or persistent breach of these Terms which you fail to remedy (if remediable) within 14 days after the service of written notice requiring you to do so. We may not be able to provide you with information about why we have terminated these Terms.
						</li>
						<li>
							If you are accessing the Prift platform subject to a Customer’s contract with us, we may terminate these Terms immediately by written notice to you if our contract with that Customer ends for any reason.
						</li>
						<li>
							On termination for any reason:
							<ol>
								<li>
									all rights granted to you under these Terms shall cease;
								</li>
								<li>
									you must immediately cease all activities authorised by these Terms; and
								</li>
								<li>
									we will permanently revoke your access to the Prift platform from all computer equipment (including mobile phones and other devices) in your possession.
								</li>
							</ol>
						</li>
					</ol>
				</li>
				<li>
					Communications between us
					<ol>
						<li>
							We may update these Terms at any time on notice to you in accordance with this clause 11. Your continued access to and use of the Prift platform following the deemed receipt and service of the notice under clause 11.3 shall constitute your acceptance to the terms of these Terms, as varied. If you do not wish to accept these Terms (as varied) you must immediately stop using and accessing the Prift platform.
						</li>
						<li>
							If we have to contact you, we will do so by email to the address you provided in accordance with your registration on the Prift platform.
						</li>
						<li>
							Note that any notice:
							<ol>
								<li>
									given by us to you will be deemed received and properly served 24 hours after it is first posted on our website, 24 hours after an email is sent; and
								</li>
								<li>
									given by you to us will be deemed received and properly served 24 hours after an email is sent to support@prift.co.
								</li>
							</ol>
						</li>
						<li>
							In proving the service of any notice, it will be sufficient to prove, in the case of posting on our website, that the website was generally accessible to the public for a period of 24 hours after the first posting of the notice; and, in the case of an email, that such email was sent to the email address of the recipient given for these purposes.
						</li>
					</ol>
				</li>
				<li>
					Other important terms
					<ol>
						<li>
							We may transfer our rights and obligations under these Terms to another organisation, but this will not affect your rights or our obligations under these Terms.
						</li>
						<li>
							You may only transfer your rights or your obligations under these Terms to another person if we agree in writing.
						</li>
						<li>
							These Terms and any document expressly referred to in it constitutes the entire agreement between us and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between us, whether written or oral, relating to its subject matter. You agree that you shall have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in these Terms or any document expressly referred to in it. You agree that you shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in these Terms or any document expressly referred to in it.
						</li>
						<li>
							If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.
						</li>
						<li>
							Each clause of these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining clauses will remain in full force and effect.
						</li>
						<li>
							These Terms, their subject matter and their formation (and any non-contractual disputes or claims) are governed by English law. We both irrevocably agree to the exclusive jurisdiction of the courts of England and Wales.
						</li>
					</ol>
				</li>









			</ol>
		</div>
	)
}

export default TermsOfBusiness;