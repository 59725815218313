import demoData from './demoData';

const getDemoTransactions = () => {
	return demoData.filter(t => t.transactionDetails !== "").map(transaction => {
		return {
			accountId: transaction.accountName,
			amount: {
				value: Number(transaction.transactionAmount)
			},
			categoryId: transaction.categoryId,
			date: addDays(new Date(), Number(transaction.relativeDate)).toISOString().split('T')[0],
			status: 'posted',
			longDescription: transaction.transactionDetails,
			shortDescription: transaction.transactionDetails,
		}
	})
}

const addDays = (date, days) => {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}

export default getDemoTransactions;