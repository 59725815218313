import React from "react";

import { PageTitle, ConnectionDetails } from '../../components/components';

import { House, Bank, PiggyBank, CashCoin, CreditCard } from "react-bootstrap-icons";

import { Row, Col, Tab, Nav } from 'react-bootstrap';

const ManageConnections = () => {

	return (
		<>
			<PageTitle pageName="Manage Accounts">
				<Tab.Container defaultActiveKey="cash:current">
					<Row className="mx-0 my-2 g-0 text-center">
						<Col xs={12}>
							<Nav variant="pills" className="flex-row" style={{justifyContent: "center"}}>
								<Nav.Item>
									<Nav.Link eventKey="cash:current" className="cashAccountTab"><CashCoin /> Cash Accounts</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="card" className="cardTab"><CreditCard /> Credit Cards</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="pension" className="pensionTab"><PiggyBank /> Pensions</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="properties:residential" className="propertyTab"><House /> Properties</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="mortgage" className="mortgageTab"><Bank /> Mortgages</Nav.Link>
								</Nav.Item>
							</Nav>
						</Col>
					</Row>
					<Row className="mx-0 g-0">
						<Col xs={12}>
							<Tab.Content>
								<Tab.Pane eventKey="cash:current">
									<ConnectionDetails type={['cash:current', 'savings']} />
								</Tab.Pane>
								<Tab.Pane eventKey="card">
									<ConnectionDetails type={['card']} />
								</Tab.Pane>
								<Tab.Pane eventKey="pension">
									<ConnectionDetails type={['pension']} />
								</Tab.Pane>
								<Tab.Pane eventKey="properties:residential">
									<ConnectionDetails type={['properties:residential']} />
								</Tab.Pane>
								<Tab.Pane eventKey="mortgage">
									<ConnectionDetails type={['mortgage:repayment', 'mortgage:interestOnly']} />
								</Tab.Pane>
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</PageTitle>
		</>
	)
}

export default ManageConnections;