import React, { useContext, useState } from "react";
import { UserContext } from "../Main";

import { PageTitle, InteractionTile, NetMovementChart, DisplayIncomeAndExpenses, DisplayIncomeAndExpensesSankey, AccountSelector, SSLogo } from '../../components/components';
import { ResponsiveContainer, Tooltip, Sankey, Layer, Rectangle } from 'recharts';
import TwoColumnContentLayout from '../../layouts/TwoColumnContentLayout';

import titleCase from '../../utils/TitleCase';

import { SquareFill, ThreeDots } from "react-bootstrap-icons";

import { Row, Col, Form, Button } from 'react-bootstrap';
import currencyFormatter from "../../utils/CurrencyFormatter";
import dateFormatter from "../../utils/DateFormatter";
import moneyHubAssetTypes from "../../utils/MoneyHubAssetTypes";

import styles from '../../scss/exports.scss';

const SpendingAnalysis = () => {

	const userContext = useContext(UserContext)
	const displayTypes = moneyHubAssetTypes['cashAccounts']
	const [ showShopSt, setShowShopSt ] = useState(false)
	const [ displayMode, setDisplayMode ] = useState('transactions')

	const selected = "SpendingAnalysisSelected"

	const netMovementChart = () => {
		const chart1 = () => {
			let data = []
			let movement = {}
			let movementShopSt = {}
			const selectedAccounts = userContext.accounts.filter(a => a[selected] && displayTypes.includes(a.type))
			selectedAccounts.forEach(a => {
				if (!a.movement || !a.movement.total || !a.movement.shopSt) return
				Object.keys(a.movement.total).forEach(key => {
					if (!movement[key]) movement[key] = 0
					movement[key] += a.movement.total[key]
				})
				Object.keys(a.movement.shopSt).forEach(key => {
					if (!movementShopSt[key]) movementShopSt[key] = 0
					movementShopSt[key] += a.movement.shopSt[key]
				})
			})
			Object.keys(movement).forEach(key => {
				data.push({
					name: key,
					"Monthly Saving": movement[key] > 0 ? movement[key]/100 : 0,
					"Monthly Overspend": movement[key] < 0 ? movement[key]/100 : 0,
					"Potential Saving with Shop St": movementShopSt[key]/100,
				})
			})
			data.sort((a,b) => a.name > b.name ? 1 : -1)
			data = data.splice(-6)

			const renderLabelSigned = (value) => {
				return (
					(value >= 0 ? '+' : '') + currencyFormatter(value * 100)
				)
			}
			const renderLabelUnder = (value) => {
				return (
					!showShopSt ? (value > 0 ? currencyFormatter(value * 100) : '') : ''
				)
			}
			const renderLabelOver = (value) => {
				return (
					!showShopSt ? (value < 0 ? currencyFormatter(value * 100) : '') : ''
				)
			}
			const RenderLegend = ({ payload, displayConfig }) => {
				let config = [...displayConfig.bars]
				if (config.length === 4) config.pop()
				return (
					<div className="text-center text-muted fs-6">
						{payload.map((entry, index) => (
							config.length > index && (
							<span key={'legendItem' + index}>
								{config[index].legendStyle === 'fill' &&
										<SquareFill style={{"fill": config[index].fill, marginTop: "-3px"}} />}
								{config[index].legendStyle === 'dotted' &&
										<ThreeDots style={{"fill": config[index].fill, marginTop: "-3px"}} />}
								<span className="mx-2">
									{entry.value}
								</span>
							</span>
							)
						))}
					</div>
				)
			}

			const RenderTooltip = ({ active, label, payload, displayConfig }) => {
				let configs = [...displayConfig?.bars]
				if (configs.length === 4) configs.pop()
				const tooltipContents = () => {
					return (
						<div className="">
						{label && <h6 className="">{dateFormatter(label, 'longMonth')}</h6>}
						{configs.map((config, index) => {
							const entry = payload[index];
							return (
								entry && entry.value !== 0 &&
									(<div key={'tooltipItem' + index} className="text-muted fs-6">
										<Row className="align-items-center">
											<Col xs={1}>
												{config.legendStyle === 'fill' &&
														<SquareFill style={{"fill": config.fill}} />}
												{config.legendStyle === 'dotted' &&
														<ThreeDots style={{"fill": config.fill}} />}
											</Col>
											<Col xs={7}>
												{entry.name}
											</Col>
											<Col className="text-end ">
												<span>{currencyFormatter(entry.value * 100)}</span>
											</Col>
										</Row>
									</div>)
							)
						})}
						</div>
					)
				}
				return(
					active &&
							<InteractionTile body={tooltipContents()} padding="p-1" bottomMargin="" />
				)
			}

			let dataConfig = {}
			dataConfig.renderLegend = RenderLegend;
			dataConfig.renderTooltip = RenderTooltip;
			dataConfig.bars = [
				{
					dataKey: "Monthly Saving",
					fill: styles['positive-3'],
					stackId: '1',
					shapeDraw: null,
					labelPosition: "top",
					labelFormatter: renderLabelUnder,
					legendStyle: "fill",
					textConfigFill: styles['positive-0']
				},
				{
					dataKey: "Monthly Overspend",
					fill: styles['negative-3'],
					stackId: '1',
					shapeDraw: null,
					labelPosition: "top",
					labelFormatter: renderLabelOver,
					legendStyle: "fill",
					textConfigFill: styles['negative-0']
				},
			];

			showShopSt && dataConfig.bars.push({
				dataKey: "Potential Saving with Shop St",
				fill: styles['positive-0'],
				stackId: '1',
				shapeDraw: 'drawDotted',
				labelPosition: "top",
				labelFormatter: renderLabelSigned,
				legendStyle: "dotted",
				textConfigFill: styles['positive-0']
			})

			return (
				<div style={{'height': '300px'}}>
					<NetMovementChart data={data} displayConfig={dataConfig} />
				</div>
			);
		}

		const shopStToggle = () => {
			const interactionBody = () => {
				return (
					<Row className="align-items-center text-center g-0">
						<Col sm={7}>
							How much can <SSLogo size="22" /> save me?
						</Col>
						<Col sm={5}>
							<div>
								Turn-on
							</div>
							<div>
								<Form.Check
										type="switch"
										aria-label="Toggle ShopSt"
										value={showShopSt}
										defaultChecked={showShopSt}
										onChange={e => setShowShopSt(!showShopSt)} />
							</div>
						</Col>
					</Row>
				)
			}
			return (
				<InteractionTile body={interactionBody()} bottomMargin="mb-2" padding="p-1" />
			)
		}

		return (
			<>
				<Row className="mb-2">
					<Col xs={6}>
						<h5>Monthly Income & Spending</h5>
						<h6 className="text-muted">(On Selected Accounts)</h6>
					</Col>
					<Col xs={6}>
						{shopStToggle()}
					</Col>
				</Row>
				<Row>
					<Col>
						{chart1()}
					</Col>
				</Row>
			</>
		)
	}

	const DisplayTransactionsIncomeAndExpensesSankey2 = () => {

		const [ selectedTimePeriod, setSelectedTimePeriod ] = useState('1M')
		const selectedAccounts = userContext.accounts.filter(a => a[selected] && displayTypes.includes(a.type))

		let spendingTotal = 0

		const getDateRange = () => {
			const today = new Date()
			if (selectedTimePeriod === '1M'){
				const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
				return { start: lastMonth, end: today }
			}
			if (selectedTimePeriod === '6M'){
				const lastMonth = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate())
				return { start: lastMonth, end: today }
			}
			if (selectedTimePeriod === '1Y'){
				const lastMonth = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())
				return { start: lastMonth, end: today }
			}
			if (selectedTimePeriod === '2Y'){
				const lastMonth = new Date(today.getFullYear() - 2, today.getMonth(), today.getDate())
				return { start: lastMonth, end: today }
			}
			if (selectedTimePeriod === '5Y'){
				const lastMonth = new Date(today.getFullYear() - 5, today.getMonth(), today.getDate())
				return { start: lastMonth, end: today }
			}
		}

		const SankeyNode = ({x, y, width, height, index, payload, containerWidth}) => {
			const isOut = payload.targetLinks.length === 0
			const isSmall = (payload.value / spendingTotal < 0.1)
			if (payload.value === 0) return <></>
			return (
				<Layer key={`CustomNode${index}`}>
					<Rectangle
							x={x}
							y={y}
							width={width}
							height={height}
							fill="#5192ca"
							fillOpacity="1" />
					<foreignObject
							x={isOut ? x - 106 : x + width + 6}
							y={y + (height / 2) - 15}
							width="100"
							height={isSmall ? "20" : "40"}>
						{!isOut && <div className="fs-5">
							{ titleCase(payload.name.replace('_inc', '').replace('_exp', '')) }
						</div>}
						{isOut && <div className="fs-5 text-end hoverInteract">
							{ titleCase(payload.name.replace('_inc', '').replace('_exp', '')) }
						</div>}
						{(!isOut || !isSmall) && <div className={"fs-6 fw-bold " + (isOut && "text-end")}>
							{ currencyFormatter(payload.value, 0, true) }
						</div>}
					</foreignObject>
					{/* <text
							textAnchor={isOut ? 'end' : 'start'}
							x={isOut ? x - 6 : x + width + 6}
							y={y + height / 2}
							fontSize="14"
							stroke="#333">
						{ titleCase(payload.name.replace('_inc', '').replace('_exp', '')) }
					</text> */}
					{/* {(payload.value / spendingTotal) > 0.1 && <text
							textAnchor={isOut ? 'end' : 'start'}
							x={isOut ? x - 6 : x + width + 6}
							y={y + height / 2 + 13}
							fontSize="12"
							stroke="#333"
							strokeOpacity="0.5">
						{ currencyFormatter(payload.value, 0, true) }
					</text>} */}
				</Layer>
			);
		}

		const getSankeyData = () => {
			const dateRange = getDateRange()
			let positiveTransactions = []
			let negativeTransactions = []
			selectedAccounts.forEach(a => {
				a.transactions && a.transactions.forEach(t => {
					const tDate = new Date(t.date)
					t.isIntraAccount = false
					if (tDate <= dateRange.end && tDate >= dateRange.start){
						if (t.amount.value > 0){
							positiveTransactions.push(t)
						}
						if (t.amount.value < 0){
							negativeTransactions.push(t)
						}
					}
				})
			})
			positiveTransactions.forEach(pt => {
				negativeTransactions.forEach(nt => {
					if (!pt.isIntraAccount && !nt.isIntraAccount &&
							nt.amount.value === -1 * pt.amount.value &&
							pt.date === nt.date){
						pt.isIntraAccount = true
						nt.isIntraAccount = true
					}
				})
			})
			const transactions = [...positiveTransactions?.filter(t => !t.isIntraAccount),...negativeTransactions?.filter(t => !t.isIntraAccount)]

			let nodes = []
			let nodeNames = []
			transactions.forEach(t => {
				if (!t.category) return
				nodeNames.push(t.category.displayCategory + '_inc')
				nodeNames.push(t.category.displayCategory + '_exp')
			})
			let setNodes = [...new Set(nodeNames), 'Total Spending']
			setNodes.forEach(n => {
				nodes.push({name: n})
			})
			let links = []

			transactions.forEach(t => {
				if (!t.category) return
				if (t.amount.value > 0){
					// INCOME
					const source = setNodes.indexOf(t.category.displayCategory + '_inc')
					const target = setNodes.indexOf('Total Spending')
					let found = false
					links.forEach(l => {
						if (l.source === source && l.target === target){
							found = true
							l.value += t.amount.value
						}
					})
					!found && links.push({
						source: source,
						target: target,
						value: t.amount.value
					})
				} else {
					// EXPENSE
					const source = setNodes.indexOf('Total Spending')
					const target = setNodes.indexOf(t.category.displayCategory + '_exp')
					spendingTotal -= t.amount.value
					let found = false
					links.forEach(l => {
						if (l.source === source && l.target === target){
							found = true
							l.value -= t.amount.value
						}
					})
					!found && links.push({
						source: source,
						target: target,
						value: -1 * t.amount.value
					})
				}
			})
			return {
				nodes: nodes,
				links: links
			}
		}

		return (<>
			<Row>
				<Col>
					<div className="d-flex justify-content-center m-3">
						{['1M', '6M', '1Y', '2Y', '5Y'].map(x => (
							<Button
									className="soft-corners mx-1"
									onClick={() => setSelectedTimePeriod(x)}
									variant={(selectedTimePeriod !== x ? "outline-" : "") + "selector"}>
								{x}
							</Button>
						))}
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={8}>
					<div style={{'height': '500px'}}>
						<ResponsiveContainer className="border soft-corners" width="100%" height="100%">
							<Sankey
									margin={{left: 20, right: 20, top: 10, bottom: 10}}
									data={getSankeyData()}
									node={<SankeyNode />}
									nodeWidth={10}
									nodePadding={15}
									linkCurvature={0.61}
									iterations={32}
									link={{ stroke: '#77c878' }}>
								{/* <Tooltip /> */}
							</Sankey>
						</ResponsiveContainer>
					</div>
				</Col>
				<Col xs={4}>
					Transactions List
				</Col>
			</Row>
		</>)
	}

	return (
		<>
			<PageTitle pageName="Spending Insights" helpPage="insights">
				<TwoColumnContentLayout
						left={<AccountSelector displayTypes={displayTypes} selected={selected} editButtonText="Edit Accounts" />}
						right={netMovementChart()} />

				<hr className="" />
				<div className="d-flex justify-content-center m-3">
					<Button
							className="mx-1"
							onClick={() => setDisplayMode('transactions')}
							variant={(displayMode !== 'transactions' ? "outline-" : "") + "selector"}>
						Income / Spending
						</Button>
					<Button
							className="mx-1"
							onClick={() => setDisplayMode('categories')}
							variant={(displayMode !== 'categories' ? "outline-" : "") + "selector"}>
						Category
					</Button>
				</div>

				{displayMode === 'transactions' &&
					<DisplayIncomeAndExpenses
							displayTypes={displayTypes}
							selected={selected} />}
				{displayMode === 'categories' && <DisplayIncomeAndExpensesSankey displayTypes={displayTypes} selected={selected} />}

			</PageTitle>
		</>
	)
}

export default SpendingAnalysis;