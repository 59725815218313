const axios = require('axios');

const deleteMhAccount = async function(token, moneyhubId, accountDetails, successCallback){
	const requestConfig = {
		headers: {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'x-api-key': process.env.REACT_APP_AWS_API_KEY,
			'Authorization': token,
			userId: moneyhubId,
			accountId: accountDetails
		}
	}
	await axios.delete(process.env.REACT_APP_AWS_DATA_ENDPOINT + '/mh/connections', requestConfig).then(() => {
		successCallback()
		return;
	}).catch(error => {
		console.error('Error (FE/s/d/deleteAccount): ', error);
		return;
	})
}

export default deleteMhAccount;