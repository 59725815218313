import React, { useState, useEffect } from "react";

import { ChevronDoubleLeft, ChevronDoubleRight } from "react-bootstrap-icons";
import { SSMonthlySaving } from "../../components/components";

const SavingsAchieved = ({ userContext }) => {

	const [ currentKey, setCurrentKey ] = useState(0)
	const [ currentDisplay, setCurrentDisplay ] = useState([])
	const [ fullList, setFullList ] = useState([])

	useEffect(() => {
		const dates = new Set()
		userContext.accounts.forEach(a => {
			Object.keys(a.movement.shopSt).forEach(key => {
				dates.add(key)
			})
		})
		let dateArray = Array.from(dates)
		dateArray.sort()
		setFullList(dateArray)
		setCurrentKey(dateArray.length - 2)
	}, [])

	useEffect(() => {
		setCurrentDisplay(fullList.filter((l,i) => i >= currentKey && i < currentKey + 2))
	}, [currentKey, fullList])

	return (<div className="d-flex g-0 align-items-center my-3">
		<div className="fs-6 align-self-start my-2">
			<div style={{color: "#5ABEBB", marginBottom: "49px", marginTop: "4px"}}>
				Savings Target:
			</div>
			<div className="fw-bold" style={{color: "#255555"}}>
				Est. saved:
			</div>
		</div>
		<div className="d-flex align-items-center mx-3">
			<div className="interaction text-end">
				<div onClick={v => setCurrentKey(Math.max(0, currentKey - 1))} style={{"color": currentKey > 0 ? "#6B7B9C" : "#00000000"}}>
					<ChevronDoubleLeft />
				</div>
			</div>
			{currentDisplay.map((d,i) => (
				<div key={"savingsMonth" + i}>
					{SSMonthlySaving(d, userContext.accounts, 60, false)}
				</div>
			))}
			<div className="interaction text-primary">
				<div onClick={v => setCurrentKey(Math.min(fullList.length - 2, currentKey + 1))} style={{"color": currentKey < (fullList.length - 2) ? "#6B7B9C" : "#00000000"}}>
					<ChevronDoubleRight />
				</div>
			</div>
		</div>
		<div className="" style={{borderLeft: "2px dashed #C4C4C4"}}>
			{SSMonthlySaving('2024', userContext.accounts, 80, false)}
		</div>
	</div>)
}

export default SavingsAchieved;