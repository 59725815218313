import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../Main";

import { PageTitle, InteractionTile, SSLogo, SSPotentialSavings, SSMonthlySaving, FinancialForecast } from '../../components/components';
import { House, Bank, PiggyBank, CashCoin, CreditCard, CashStack } from "react-bootstrap-icons";

import { Row, Col, Button, Modal, Spinner, OverlayTrigger, Tooltip as TooltipRB } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import shopStExamples from '../../assets/images/shopst-examples.png';

import currencyFormatter from "../../utils/CurrencyFormatter";
import getStatePensionStartDate from '../../assets/model/state_pension_start_date';

import { getSessionTokenAsync } from '../../utils/GetSessionTokenAsync';
import { sendSSRequestEmail, setUser as setUserDataService } from "../../service/DataService"

import styles from '../../scss/exports.scss';

// TODO: Value Tiles overflow - grow tile - and match all in the column

const Home = () => {

	const userContext = useContext(UserContext)

	const AccountOverview = () => {

		const bankTilesAssets = [
			{
				name: 'Current Accounts',
				type: 'cash:current',
				linkTarget: '/bank-account',
				icon: () => (<CashCoin style={{fontSize: "1.5rem"}} />),
				color: styles['asset-0'],
				flip: false,
				preNavigation: () => {userContext.accounts.forEach(a => {a.BankAccountSelected = a.type === 'cash:current'})}
			},
			{
				name: 'Savings Accounts',
				type: 'savings',
				linkTarget: '/bank-account',
				icon: () => (<CashStack style={{fontSize: "1.5rem"}} />),
				color: styles['positive-0'],
				flip: false,
				preNavigation: () => {userContext.accounts.forEach(a => {a.BankAccountSelected = a.type === 'savings'})}
			}
		]
		const bankTilesLiabilities = [
			{
				name: 'Credit Cards',
				type: 'card',
				linkTarget: '/bank-account',
				icon: () => (<CreditCard style={{fontSize: "1.5rem"}} />),
				color: styles['liability-0'],
				flip: true,
				preNavigation: () => {userContext.accounts.forEach(a => {a.BankAccountSelected = a.type === 'card'})}
			}
		]
		const savingTilesAssets = [
			{
				name: 'Pension Pots',
				type: 'pension',
				linkTarget: '/pension',
				icon: () => (<PiggyBank style={{fontSize: "1.5rem"}} />),
				color: styles['positive-0'],
				flip: false
			}
		]
		const savingTilesLiabilities = []
		const propertyTilesAssets = [
			{
				name: 'Properties',
				type: 'properties:residential',
				linkTarget: '/property',
				icon: () => (<House style={{fontSize: "1.5rem"}} />),
				color: styles['asset-0'],
				flip: false
			}
		]
		const propertyTilesLiabilities = [
			{
				name: 'Mortgages',
				type: 'mortgage',
				linkTarget: '/mortgage',
				icon: () => (<Bank style={{fontSize: "1.5rem"}} />),
				color: styles['liability-0'],
				flip: false
			}
		]

		const getValue = (type) => {
			let value = 0
			userContext.accounts.forEach(a => {
				if (a.type.split(':')[0] === type.split(':')[0]){
					if (a.currentValue){
						if (a.currentValue.base){
							value += a.currentValue.base
						} else {
							value += a.currentValue
						}
					} else if (a.balance.amount.value) {
						value += a.balance.amount.value
					}
				}
			})
			return value
		}

		const TypeTile = ({ tileDetails }) => {
			return (
				<Link to={tileDetails.linkTarget} onClick={tileDetails.preNavigation} className="noDecoration">
					<div
							className="border bg-white p-1 mx-2 mx-xxl-3 mt-1 mb-4 text-center soft-glow soft-corners interaction hasHoverEffect noDecoration colorBorder"
							style={{color: tileDetails.color, fill: tileDetails.color}}>
						<div className="my-1">{tileDetails.icon()}</div>
						<h4 className="mb-0 fw-bold">{currencyFormatter(getValue(tileDetails.type) * (tileDetails.flip ? -1 : 1), 0, getValue(tileDetails.type) * (tileDetails.flip ? -1 : 1) < 100000000)}</h4>
						<div className="mb-2 fs-6 text-muted">{tileDetails.name}</div>
					</div>
				</Link>)
		}

		const renderAccountHelp = (props) => (
			<TooltipRB id="button-tooltip" {...props} animation="false">
				<div>Combined total value of all accounts in each category</div>
			</TooltipRB>
		)

		return (<InteractionTile body={<div className="text-center">
			<h5 className="mb-3">Bank Accounts & Credit Cards</h5>
			<Row className="">
				<Col sm={6}>
					{bankTilesAssets.map(t => (
						<TypeTile key={t.name} tileDetails={t} />
					))}
				</Col>
				<Col sm={6}>
					{bankTilesLiabilities.map(t => (
						<TypeTile key={t.name} tileDetails={t} />
					))}
				</Col>
			</Row>
			<hr className="mt-2" />
			<h5 className="mb-3">Pensions, Savings & Loans</h5>
			<Row className="">
				<Col sm={6}>
					{savingTilesAssets.map(t => (
						<TypeTile key={t.name} tileDetails={t} />
					))}
				</Col>
				<Col sm={6}>
					{savingTilesLiabilities.map(t => (
						<TypeTile key={t.name} tileDetails={t} />
					))}
				</Col>
			</Row>
			<hr className="mt-2" />
			<h5 className="mb-3">Properties & Mortgages</h5>
			<Row className="">
				<Col sm={6}>
					{propertyTilesAssets.map(t => (
						<TypeTile key={t.name} tileDetails={t} />
					))}
				</Col>
				<Col sm={6}>
					{propertyTilesLiabilities.map(t => (
						<TypeTile key={t.name} tileDetails={t} />
					))}
				</Col>
			</Row>
			<Row>
				<Col>
					<OverlayTrigger
							placement="auto"
							delay={{ show: 250, hide: 400 }}
							overlay={renderAccountHelp}>
						<div className="fs-6 text-muted float-end interaction"><u><small>What are these values?</small></u></div>
					</OverlayTrigger>
				</Col>
			</Row>
		</div>} />)
	}

	const hasCashAccount = () => {
		let found = false
		userContext.accounts.forEach(a => {
			found ||= a.type === 'cash:current'
			found ||= a.type === 'card'
		})
		return found
	}

	const hasSSTransaction = () => {
		let found = false
		userContext.accounts.forEach(a => {
			if (a.hasSSTransaction) found = true
		})
		return found
	}

	const ShopStOverview = () => {
		const [ showLoading, setShowLoading ] = useState(0)
		const sendSSRequest = async () => {
			setShowLoading(1)
			const currentSession = await getSessionTokenAsync()
			await sendSSRequestEmail(currentSession, userContext.user.email, async () => {
				const currentSession = await getSessionTokenAsync()
				let userInfo = {}

				userInfo.ssRequestSent = true
				await setUserDataService(currentSession, userInfo)
				userContext.setUser({...userContext.user, ssRequestSent: true})
			})
		}

		useEffect(() => {
			if (showLoading === 2){
				setTimeout(() => setShowLoading(0), 1500)
			}
		}, [showLoading])

		const renderShopStHelp = (props) => (
			<TooltipRB id="button-tooltip" {...props} animation="false">
				<div>Monthly target is automatically calculated based upon your past months transactions.</div>
			</TooltipRB>
		)

		const getLastTwelveMonthsPotential = () => {
			const today = new Date()
			let oneYearAgo = new Date()
			oneYearAgo.setFullYear(oneYearAgo.getFullYear() -1)
			let totalSaving = 0
			let savingByVendor = []
			userContext.accounts.forEach(a => {
				a.transactions?.forEach(t => {
					if (t.discountPotential > 0 && new Date(t.date) <= today && new Date(t.date) > oneYearAgo){
						totalSaving += t.discountPotential
						let found = false
						savingByVendor.forEach(sbv => {
							if (sbv.name === t.vendorDetails.Name){
								sbv.value += t.discountPotential
								sbv.count ++
								found = true
							}
						})
						if (!found){
							savingByVendor.push({
								name: t.vendorDetails.Name,
								vendorDetails: t.vendorDetails,
								value: t.discountPotential,
								count: 1
							})
						}
					}
				})
			})
			return {totalSaving, savingByVendor}
		}

		return (<InteractionTile body={<div className="text-center">
			<h5 className=""><SSLogo size="24" /> Savings</h5>
			{(!hasCashAccount() || getLastTwelveMonthsPotential().savingByVendor.length < 4) && <Row className="align-items-center">
				<Col sm={6} className="text-muted">
					Add a Bank Account or Credit Card to unlock Shop St savings
				</Col>
				<Col sm={6} className="text-muted">
					<h5>Save at:</h5>
					<img width="100%" src={shopStExamples} alt="Sainsburys, Waitrose, ASDA, Tesco, John Lewis, Uber, M&S, amazon" />
					<Link to="/shop-st-savings">
						<Button className="fs-6" variant="link">and many more...</Button>
					</Link>
				</Col>
			</Row>}
			{(hasCashAccount() && (!hasSSTransaction() || userContext.user.isDemo)) && getLastTwelveMonthsPotential().savingByVendor.length >= 4 && <>
				<Row className="g-0">
					<div className="text-muted fs-5">Identified potential retailer savings:</div>
					<SSPotentialSavings data={getLastTwelveMonthsPotential()} userContext={userContext} columns={2} />
				</Row>
				<Row>
					<Col className="text-center fw-bold text-positive-color">
						Est. annual saving: {currencyFormatter(Math.max(getLastTwelveMonthsPotential().totalSaving, 37500), 0, true)}
					</Col>
				</Row>
				<Row className="mt-3">
					<Col xs={6}>
						<Button variant="primary" className="" onClick={sendSSRequest} disabled={userContext.user.ssRequestSent}>Request PIN</Button>
					</Col>
					<Col xs={6}>
						<Link to="/shop-st-savings">
							<Button variant="secondary">View Savings</Button>
						</Link>
					</Col>
				</Row>
				{showLoading > 0 && (
					<Modal centered show>
						<Modal.Body>
							<div className="text-center position-relative">
								<div className="mt-2">
									{showLoading === 1 && <Spinner className="center" animation="border" role="status">
										<span className="visually-hidden">Loading..</span>
									</Spinner>}
								</div>
								<div className="mt-3">
									{showLoading === 1 && <>Sending request..</>}
									{showLoading === 2 && <>You will receive an email when your Shop St account is enabled</>}
								</div>
							</div>
						</Modal.Body>
					</Modal>
				)}
			</>}
			{(hasCashAccount() && (hasSSTransaction() && !userContext.user.isDemo)) && <>
				<Row className="align-items-center">
					<Col xs={2} className="text-positive-color-secondary fs-6" style={{height: '175px'}}>
						Saving Target:
					</Col>
					<Col xs={3}>
						{SSMonthlySaving('2024-04', userContext.accounts, 50, true, false)}
					</Col>
					<Col xs={4}>
						<div style={{borderRight: "var(--bs-border-width) dashed var(--bs-border-color)"}}>
							{SSMonthlySaving('2024-05', userContext.accounts, 50, true, false)}
						</div>
					</Col>
					<Col xs={3}>
						{SSMonthlySaving('2024', userContext.accounts, 0, true, false, true)}
					</Col>
				</Row>
				<Row className="mt-3">
					<Col>
						<Link to="/shop-st-savings">
							<Button variant="secondary">View Savings</Button>
						</Link>
					</Col>
				</Row>
				<Row>
					<Col>
						<OverlayTrigger
								placement="auto"
								delay={{ show: 250, hide: 400 }}
								overlay={renderShopStHelp}>
							<div className="fs-6 text-muted float-end interaction"><u><small>How is this calculated?</small></u></div>
						</OverlayTrigger>
					</Col>
				</Row>
			</>}
		</div>} />)
	}

	const getTotalRetirementIncome = () => {
		let value = 0
		userContext.accounts.forEach(a => {
			if (a.type === 'pension'){
				value += a.annualRetirementPayment?.base
			}
		})
		return value
	}

	const getStatePensionAge = () => {
		const userDateOfBirth = new Date(userContext.user.dateOfBirth)
		const statePensionDate = getStatePensionStartDate(userDateOfBirth)
		return statePensionDate.getFullYear() - userDateOfBirth.getFullYear()
	}

	const renderPensionHelp = (props) => (
		<TooltipRB id="button-tooltip" {...props} animation="false">
			<div>These forecasts and estimated amounts and may increase or decrease. The amount and age at which you will receive a UK state pension may change according to government legislation.</div>
		</TooltipRB>
	)

	const PensionOverview = () => {
		return (<InteractionTile body={<div className="text-center">
			<h5 className="">Pension Forecast</h5>
			<Row className="mt-3">
				<Col xs={5}>
					<h4 className="text-asset-color fw-bold mb-0">{currencyFormatter(getTotalRetirementIncome(), 0 ,true)}</h4>
				</Col>
				<Col xs={2}>
					<h3 as="p" className="pt-1 text-muted fw-light" style={{height: 0, overflow: "visible"}}>at</h3>
				</Col>
				<Col xs={5}>
					<h4 className="text-asset-color fw-bold mb-0">{userContext.user.retirementAge}</h4>
				</Col>
			</Row>
			<Row className="">
				<Col xs={5}>
					<div className="text-muted fs-6">Est. Retirement Income</div>
				</Col>
				<Col xs={2}></Col>
				<Col xs={5}>
					<div className="text-muted fs-6">Retirement Age</div>
				</Col>
			</Row>
			<Row className="mt-3">
				<Col xs={5}>
					<h4 className="text-saving-color fw-bold mb-0">{currencyFormatter(userContext.user.statePensionAmount, 0 ,true)}</h4>
				</Col>
				<Col xs={2}>
					<h3 as="p" className="pt-1 text-muted fw-light" style={{height: 0, overflow: "visible"}}>at</h3>
				</Col>
				<Col xs={5}>
						<h4 className="text-saving-color fw-bold mb-0">{getStatePensionAge()}</h4>
				</Col>
			</Row>
			<Row className="">
				<Col xs={5}>
					<div className="text-muted fs-6">UK State Pension</div>
				</Col>
				<Col xs={2}></Col>
				<Col xs={5}>
					<div className="text-muted fs-6">UK Pension Age</div>
				</Col>
			</Row>
			<Row className="mt-3">
				<Col>
					<Link to="/pension">
						<Button variant="secondary">View Pensions</Button>
					</Link>
				</Col>
			</Row>
			<Row>
				<Col>
					<OverlayTrigger
							placement="auto"
							delay={{ show: 250, hide: 400 }}
							overlay={renderPensionHelp}>
						<div className="fs-6 text-muted float-end interaction"><u><small>Estimated Forecast</small></u></div>
					</OverlayTrigger>
				</Col>
			</Row>
		</div>} />)
	}

	const isLoaded = () => {
		if (userContext.loadingState.loadComplete) return true
		if (userContext.loadingState.demoForecasts) return true
		//if (userContext.loadingState.awsForecasts && !userContext.loadingState.mhBufferShown) return true
		return false
	}

	const getPageTitle = () => {
		return 'Welcome ' + userContext.user.firstName
	}

	return (
		<>
			<PageTitle pageName={getPageTitle()} helpPage="home">
				<Row className="g-sm-0 g-md-5">
					<Col xs={12} sm={12} md={6} lg={6} className="">
						<AccountOverview />
					</Col>
					<Col xs={12} sm={12} md={6} lg={6}>
						<div className="mb-3">
							<ShopStOverview />
						</div>
						<div>
							<PensionOverview />
						</div>
					</Col>
				</Row>
				<Row className="g-sm-0 g-md-5">
					<Col xs={12} className="">
						<FinancialForecast load={isLoaded()} accounts={userContext.accounts} dateOfBirth={userContext.user.dateOfBirth} retirementAge={userContext.pensionSliderValues.retirementAge} lumpSumPercentage={userContext.pensionSliderValues.lumpSumPercentage} />
					</Col>
				</Row>

			</PageTitle>
		</>
	)
}

export default Home;