import housePriceIndex from '../../assets/model/house_price_index';

/**
 * Forecast a Generic Account
 */
export const forecastGeneric = async ( account, retireDate, retirementAge, dateAged90, type, monthlyPayment, lumpSumPercentage = 25, currentCardBalance = 0, mortgages = [], cashAccounts = [] ) => {
	//TODO Add interest forecast
	if (type == null) return
	const today = new Date().toISOString().split('T')[0]
	const endDate = dateAged90

	// For cash account type accounts, create a forecast

	let currentValue = { low: null, base: null, high: null }
	let currentDate = new Date(today)
	let valueDataGraph = []
	let keyDates = []

	retireDate = new Date(retireDate).toISOString().split('T')[0]

	account.balances = [{date: today, amount: {value: 0}}]
	let currentPayment = monthlyPayment * 12 * (type === 'pension' ? 1.2 : 1)

	// For each day from start to end dates
	while (currentDate <= endDate){
		const shortDate = currentDate.toISOString().split('T')[0]
		let currentBalances = account.balances.filter(b => b.date === shortDate).sort((a,b) => a.date < b.date ? 1 : -1)

		if (currentBalances.length > 0) {
			// A specific balance is available for this day
			currentValue = {
				low: currentBalances[0].amount.value,
				base: currentBalances[0].amount.value,
				high: currentBalances[0].amount.value,
			}
			keyDates.push(currentDate)
		} else if (currentValue.low != null && currentValue.base != null && currentValue.high != null){
			// Generate a forecast balance for this day
			const yearRate = getForecastRate(type, currentDate, today, retireDate, mortgages, cashAccounts)
			const baseRate = 1 + (yearRate.base/100)
			const lowRate = yearRate.low ? (1 + (yearRate.low/100)) : baseRate
			const highRate = yearRate.high ? (1 + (yearRate.high/100)) : baseRate
			currentValue.low = currentValue.low * (Math.pow(lowRate, 1/364)) + (currentPayment / 364)
			currentValue.base = currentValue.base * (Math.pow(baseRate, 1/364)) + (currentPayment / 364)
			currentValue.high = currentValue.high * (Math.pow(highRate, 1/364)) + (currentPayment / 364)
		}
		// Setup the extra information needed from the new forecasts
		if (shortDate === today) {
			account.currentValue = {...currentValue}
			keyDates.push(currentDate)
		}
		if (type === 'card' && ((currentValue.base + currentCardBalance) >= 0)){
			currentPayment = 0
			currentValue = currentCardBalance
		}
		if (type.split(':')[0] === 'mortgage'){
			let totalRemainingBalance = 0
			mortgages.forEach(m => {
				m.valueForecast.forEach(f => {
					if (f.shortName === shortDate){
						totalRemainingBalance += f.value
					}
				})
			})
			if (totalRemainingBalance !== 0 && ((currentValue.base + totalRemainingBalance) > 0)){
				currentPayment = 0
				currentValue = totalRemainingBalance
			}
		}

		if (shortDate === retireDate) {
			account.retirementValue = {...currentValue}
			keyDates.push(currentDate)
		}
		if (keyDates.includes(currentDate) || (currentDate.getDate() === 1)){
			valueDataGraph.push({
				name: Number(currentDate),
				low: currentValue.low,
				base: currentValue.base,
				high: currentValue.high,
				value: currentValue.base,
				highDiff: currentValue.high - currentValue.low
			})
		}
		if (type === 'pension' && shortDate === retireDate){
			account.lumpSum = {
				low: currentValue.low * lumpSumPercentage/100,
				base: currentValue.base * lumpSumPercentage/100,
				high: currentValue.high * lumpSumPercentage/100
			}
			currentValue.low -= account.lumpSum.low
			currentValue.base -= account.lumpSum.base
			currentValue.high -= account.lumpSum.high
			account.annualRetirementPayment = {
				low: annuityPayoutCalculator(currentValue.low, getPostRetireRate().low / 100, 91 - retirementAge),
				base: annuityPayoutCalculator(currentValue.base, getPostRetireRate().base / 100, 91 - retirementAge),
				high: annuityPayoutCalculator(currentValue.high, getPostRetireRate().high / 100, 91 - retirementAge)
			}
			// TODO: Should this include the payment
			currentPayment = -1 * account.annualRetirementPayment.base
			valueDataGraph.push({
				name: Number(currentDate) + 1,
				low: currentValue.low,
				base: currentValue.base,
				high: currentValue.high,
				highDiff: currentValue.high - currentValue.low
			})
		}
		currentDate = addDays(currentDate, 1)
	}

	account.valueForecast = valueDataGraph
	return account
}

/**
 * Adds days to a date
 * @param {Date} date
 * @param {Integer} days
 * @returns
 */
const addDays = (date, days) => {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}

const getForecastRate = (type, currentDate, today, retireDate, mortgages, cashAccounts) => {
	const shortDate = currentDate.toISOString().split('T')[0]
	if (type === 'cash:current'){
		if (shortDate < today) return {low: 0, base: 0, high: 0 }
		let cashRates = [0]
		cashAccounts.forEach(a => {
			if (a.type !== 'cash:current') return
			let found = false
			if (a.details.APR) {
				cashRates.push(a.details.APR)
				found = true
			} else if (a.estimatedInterestRate){
				cashRates.push(a.estimatedInterestRate)
				found = true
			}

			if (!found) cashRates.push(0)
		})
		return { low: 0, base: Math.max(...cashRates), high: 0}
	}

	if (type === 'card'){
		if (true || shortDate < today) return {low: 0, base: 0, high: 0 }
		let dateNow = new Date(today)
		let dateInXYears = new Date(dateNow.getFullYear() + 5, dateNow.getMonth(), dateNow.getDate)
		if (currentDate > dateInXYears) return {low: 0, base: 0, high: 0 }
		let cardRates = [0]
		cashAccounts.forEach(a => {
			if (a.type !== 'card') return
			let found = false
			if (a.details.APR) {
				cardRates.push(a.details.APR)
				found = true
			} else if (a.estimatedInterestRate){
				cardRates.push(a.estimatedInterestRate)
				found = true
			}

			if (!found) cardRates.push(0)
		})
		return { low: 0, base: Math.max(...cardRates), high: 0}
	}

	if (type === 'savings'){
		if (shortDate < today) return {low: 0, base: 0, high: 0 }
		let savingsRates = [0]
		cashAccounts.forEach(a => {
			if (a.type !== 'savings') return
			let found = false
			if (a.details.APR) {
				savingsRates.push(a.details.APR)
				found = true
			} else if (a.estimatedInterestRate){
				savingsRates.push(a.estimatedInterestRate)
				found = true
			}

			if (!found) savingsRates.push(2.7)
		})
		return { low: 0, base: Math.max(...savingsRates), high: 0}
	}

	if (type === 'pension'){
		if (currentDate > retireDate){
			// Pre Retire
			return { low: -0.5, base: 2.5, high: 4.5}
		} else {
			// Post Retire
			return { low: 1.9, base: 1.9, high: 1.9 }
		}
	}

	if (type === 'properties:residential'){
		return housePriceIndex[currentDate.getFullYear()]
	}

	if (type.split(':')[0] === 'mortgage'){
		let mortgageRates = [0]

		mortgages.forEach(m => {
			let found = false
			if (new Date(m.details.fixedDate) > currentDate){
				if (m.details.APR) {
					mortgageRates.push(m.details.APR)
					found = true
				}
			}
			if (!found) mortgageRates.push(7.5)
		})

		return { low: 0, base: Math.max(...mortgageRates), high: 0}
	}
}

const getPostRetireRate = () => {
	return { low: 1.9, base: 1.9, high: 1.9}
}

const annuityPayoutCalculator = (pv, rate, periods) => {
	if (pv === 0) return 0
	return (rate * pv) / (1 - Math.pow((1 + rate), (-1 * periods)));
}