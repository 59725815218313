import Row from 'react-bootstrap/Row';

import styles from '../../scss/exports.scss';

const HorizontalColors = ({ mode }) => {
	const incomeColors = [styles['asset-0'], styles['asset-1'], styles['asset-2'], styles['asset-3']]
	const expenseColors = [styles['liability-0'], styles['liability-1'], styles['liability-2'], styles['liability-3']]
	const greyColors = [styles['grey-0'], styles['grey-1'], styles['grey-2'], styles['grey-3']]
	const colors = mode === "income" ? incomeColors
			: mode === "greys" ? greyColors
			: expenseColors

	return (
		<Row className="mb-3 mx-0">
			<div className="px-0 mx-0" style={{"minHeight": "10px", "width": "2%", "backgroundColor": colors[0]}}></div>
			<div className="px-0 mx-0" style={{"minHeight": "10px", "width": "4%", "backgroundColor": colors[1]}}></div>
			<div className="px-0 mx-0" style={{"minHeight": "10px", "width": "25%", "backgroundColor": colors[2]}}></div>
			<div className="px-0 mx-0" style={{"minHeight": "10px", "flex": "1", "backgroundColor": colors[3]}}></div>
		</Row>
	)
}

export default HorizontalColors;