const moneyHubCategoryGrouping = {
	'insurance': 'bills',
	'cable': 'bills',
	'online': 'bills',
	'service': 'bills',
	'other-bills': 'bills',
	'telephone': 'bills',
	'utilities': 'bills',
	'office-supplies': 'business',
	'advertising': 'business',
	'business': 'business',
	'postage': 'business',
	'printing': 'business',
	'office-maintenance': 'business',
	'wages': 'business',
	'atm': 'cash',
	'entertainment': 'entertainment',
	'restaurants': 'entertainment',
	'hobbies': 'entertainment',
	'dues': 'entertainment',
	'gambling': 'entertainment',
	'charitable': 'gifts',
	'gifts': 'gifts',
	'groceries': 'groceries',
	'general': 'groceries',
	'healthcare': 'health',
	'personal': 'health',
	'child': 'household',
	'clothing': 'household',
	'education': 'household',
	'homeMaintenance': 'household',
	'homeImprovement': 'household',
	'pets': 'household',
	'electronic': 'household',
	'benefits': 'income',
	'consulting': 'income',
	'deposits': 'income',
	'expense': 'income',
	'interest': 'income',
	'investment': 'income',
	'other-income': 'income',
	'paychecks': 'income',
	'rental-income': 'income',
	'retirement': 'income',
	'rewards': 'income',
	'sales': 'income',
	'services': 'income',
	'mixed': 'mixed',
	'uncategorized': 'other',
	'other': 'other',
	'cheques': 'other',
	'rent': 'rent',
	'loans': 'repayments',
	'mortgages': 'repayments',
	'taxes': 'taxes',
	'credit': 'transfers',
	'transfers': 'transfers',
	'securities': 'transfers',
	'savings': 'transfers',
	'pension-contributions': 'transfers',
	'automotive': 'transport',
	'gasoline': 'transport',
	'travel': 'transport'
}

export default moneyHubCategoryGrouping;