import React from "react";
import logo from '../../assets/images/prift-logo-sm.png';
import fullLogo from '../../assets/images/prift-logo-small.png';

import { Container, Row, Col } from 'react-bootstrap';

function Logo({ cssClass, logoStyle = "inline" }){

	if (logoStyle === "inline"){
		return (
			<div className={cssClass}>
				<div className="g-0 d-flex justify-content-center">
					<div className="g-0">
						<img src={logo} alt="Prift Logo" width="64px" height="64px" />
					</div>
					<div className="text g-0 text-end">
						<Row className="large">
							<Col>
								PRIFT
							</Col>
						</Row>
						<Row className="small">
							<Col>
								<i>Personalised Financial Support</i>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		)
	} else if (logoStyle === 'login'){
		return (
			<Container className={cssClass}>
				<Row className="my-3">
					<Col><img src={fullLogo} alt="Prift Logo" /></Col>
				</Row>
			</Container>
		)
	} else if (logoStyle === "stack"){
		return (
			<Container className={cssClass}>
				<Row className="mb-1">
					<Col><img src={logo} alt="Prift Logo" /></Col>
				</Row>
				<Row>
					<Col className="text">PRIFT</Col>
				</Row>
			</Container>
		)
	}


}

export default Logo;