import React, { useState } from "react";
import { LinkContainer } from "react-router-bootstrap";

import { List } from "react-bootstrap-icons";

import { Navbar, Container, Nav, Col, Button, NavDropdown, Offcanvas } from 'react-bootstrap';
import { Logo, SSLogo } from '../components';

import { menuItems } from '../../assets/content/menu-items';
import { motion, AnimatePresence } from "framer-motion"
import { QuestionDiamond, QuestionDiamondFill } from "react-bootstrap-icons";

const NavigationSmallDisplay = () => {

	const [ menuOpen, setMenuOpen ] = useState(false)
	const toggleMenu = () => {
		setMenuOpen(!menuOpen)
	}

	const handleClose = () => setMenuOpen(false)

	return (<>
		<Navbar expand={false} className={"nav-h " + process.env.REACT_APP_AWS_NAVH} collapseOnSelect>
			<Container fluid={true}>
				<Col xs={1}>
					<Navbar.Toggle aria-controls={`priftNavbar`} className="nav-h-burger" onClick={toggleMenu}>
						<List />
					</Navbar.Toggle>
				</Col>
				<Col className="d-flex justify-content-center">
					<LinkContainer to="/">
						<Nav.Link>
							<Logo cssClass="prift-logo-nav g-0 m-0 p-0" />
						</Nav.Link>
					</LinkContainer>
				</Col>
				<Col xs={1}>
					{/* <div style={{right: "50px", position: "absolute", marginTop: "-44px"}} className="fs-1 interaction hasHoverEffect_large" onClick={() => showHelp.current = !showHelp.current}>
						<AnimatePresence>
							{ !showHelp && <motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1, scale: [1, 1.25, 1, 1.25, 1] }}
									exit={{ opacity: 0 }}
									transition={{ duration: 5, delay: 0.5 }}
									style={{ position: "absolute" }}>
								<QuestionDiamond />
							</motion.div>}
						</AnimatePresence>
						<AnimatePresence>
							{ showHelp && <motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 1 }}
									style={{ position: "absolute" }}>
								<QuestionDiamondFill />
							</motion.div>}
						</AnimatePresence>

					</div> */}
				</Col>
				<Navbar.Offcanvas
						id={`priftNavbar`}
						aria-labelledby={`priftNavbarLabel`}
						placement="start"
						className={"nav-h " + process.env.REACT_APP_AWS_NAVH}
						show={menuOpen}
						onHide={handleClose}
						restoreFocus={false}>
					<Offcanvas.Header closeButton closeVariant="white" className="py-0">
						<Offcanvas.Title id={`priftNavbarLabel`}>
							<LinkContainer to="/" onClick={toggleMenu}>
								<Nav.Link>
									<Logo cssClass="prift-logo-nav g-0 m-0 p-0" />
								</Nav.Link>
							</LinkContainer>
						</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body className="pt-0">
						<Nav className="justify-content-end flex-grow-1 pe-3">
							<LinkContainer to="/add-asset" className="nav-contents">
								<Nav.Link>
									<div className="d-grid px-3">
										<Button variant="primary" size="lg" onClick={toggleMenu}>
											+ Add Account
										</Button>
									</div>
								</Nav.Link>
							</LinkContainer>
							{menuItems.map((menuItem, i) => {
								if (menuItem.submenu){
									return (
										<NavDropdown title={menuItem.title} key={"menuItem" + i}>
											{menuItem.submenu.map((submenu, j) => {
												return (
													<LinkContainer key={"submenu" + i + ":" + j} to={submenu.url}>
														<NavDropdown.Item>
															<div className="secondary" onClick={toggleMenu}>
																{submenu.title}
															</div>
														</NavDropdown.Item>
													</LinkContainer>
												)
											})}
										</NavDropdown>
									)
								}
								if (menuItem.url != null){
									return (
										<LinkContainer key={"menuItem" + i} to={menuItem.url}>
											<Nav.Link>
												<div onClick={toggleMenu}>
													{menuItem.title === 'Shop St Savings' ?
															<><SSLogo size="22" /> Savings</> :
															menuItem.title}
												</div>
											</Nav.Link>
										</LinkContainer>
									)
								}
								return null
							})}
						</Nav>
					</Offcanvas.Body>
				</Navbar.Offcanvas>
			</Container>
		</Navbar>

	</>);
}

export default NavigationSmallDisplay;