import React, { useState, useEffect } from 'react';

import { InteractionTile, ForecastChart, SSLogo } from '../../components/components';

import { Row, Col, Button, Modal, Spinner, OverlayTrigger, Tooltip as TooltipRB, Dropdown, ButtonGroup, Image, Form } from 'react-bootstrap';

import currencyFormatter from "../../utils/CurrencyFormatter";

import { House, Bank, PiggyBank, CashCoin, CreditCard, CashStack } from "react-bootstrap-icons";

// Forecasts
import { forecastGeneric } from '../../utils/forecast/forecastGeneric';

import styles from '../../scss/exports.scss';

const FinancialForecast = ({ accounts, dateOfBirth, retirementAge, load = true, lumpSumPercentage }) => {

	const type = [
		'Current Account',
		'Savings Account',
		'Credit Card',
		'Pension',
		'Property',
		'Mortgage',
	]
	const scenarioTypes = [
		'Current Account',
		'Savings Account',
		'Credit Card',
		'Pension',
		'Mortgage',
	]
	const typeNames = {
		'cash:current': 'Current Account',
		'savings': 'Savings Account',
		'card': 'Credit Card',
		'pension': 'Pension',
		'properties:residential': 'Property',
		'mortgage:repayment': 'Mortgage',
		'mortgage:interestOnly': 'Mortgage',
	}
	const typeNamesReverse = {
		'Current Account': 'cash:current',
		'Savings Account': 'savings',
		'Credit Card': 'card',
		'Pension': 'pension',
		'Property': 'properties:residential',
		'Mortgage': 'mortgage:repayment',
	}
	const typeConversion = {
		'cash:current': 'cash:current',
		'savings': 'savings',
		'card': 'card',
		'pension': 'pension',
		'properties:residential': 'property',
		'mortgage:repayment': 'mortgage',
		'mortgage:interestOnly': 'mortgage',
	}
	const typeColorsCss = {
		'cash:current': 'text-asset-color',
		'savings': 'text-saving-color',
		'card': 'text-spending-color',
		'pension': 'text-saving-color',
		'properties:residential': 'text-asset-color',
		'mortgage:repayment': 'text-spending-color',
		'mortgage:interestOnly': 'text-spending-color',
	}
	const typeValueFields = {
		'cash:current': {low: 'low', base: 'base', high: 'high'},
		'savings': {low: 'low', base: 'base', high: 'high'},
		'card': {low: 'low', base: 'base', high: 'high'},
		'pension': {low: 'low', base: 'base', high: 'high'},
		'properties:residential': {low: 'low', base: 'base', high: 'high'},
		'mortgage:repayment': {low: 'value', base: 'value', high: 'value'},
		'mortgage:interestOnly': {low: 'value', base: 'value', high: 'value'},
	}
	const maxTodayTypes = [
		'cash:current',
		'savings',
		'card'
	]
	const wingedForecastTypes = [
		'pension',
		'properties:residential'
	]

	const [ selectedAccounts, setSelectedAccounts ] = useState([])
	const [ selectedCategories, setSelectedCategories ] = useState([])
	const [ shopStType, setShopStType ] = useState(null)
	const [ showWings, setShowWings ] = useState(false)

	useEffect(() => {
		let accountIds = []
		let categories = []
		accounts.forEach(a => {
			if (typeNames[a.type] != null) accountIds.push(a.id)
			if (!categories.includes(typeNames[a.type])) categories.push(typeNames[a.type])
		})
		setSelectedAccounts(accountIds)
		setSelectedCategories(categories)
	}, [])

	useEffect(() => {
		setShopStScenario()
		let showWings = false
		selectedAccounts.forEach(aId => {
			accounts.forEach(a => {
				if (aId === a.id){
					if (wingedForecastTypes.includes(a.type)){
						showWings = true
						return
					}
				}
			})
		})
		setShowWings(showWings)
	}, [selectedAccounts, selectedCategories])

	const Selector = () => {

		let categories = {}
		accounts.forEach(a => {
			if (categories[typeNames[a.type]] == null){
				categories[typeNames[a.type]] = []
			}
			categories[typeNames[a.type]].push(a)
		})

		const toggleCategory = (e) => {
			const target = e.target.value
			let existingCategories = [...selectedCategories]
			let existingAccounts = [...selectedAccounts]
			let relevantAccounts = accounts.filter(a => typeNames[a.type] === target)
			if (existingCategories.includes(target)){
				existingCategories = existingCategories.filter(i => i !== target)
				relevantAccounts.forEach(a => {
					existingAccounts = existingAccounts.filter(i => i !== a.id)
				})
			} else {
				existingCategories.push(target)
				relevantAccounts.forEach(a => {
					if (!existingAccounts.includes(a.id)) existingAccounts.push(a.id)
				})
			}
			setSelectedCategories(existingCategories)
			setSelectedAccounts(existingAccounts)
		}

		const toggleAccount = (e) => {
			const target = e.target.dataset.value
			let existingCategories = [...selectedCategories]
			let existingAccounts = [...selectedAccounts]
			let relevantAccount = accounts.filter(a => a.id === target)[0]
			if (existingAccounts.includes(target)){
				existingAccounts = existingAccounts.filter(a => a !== target)
				let count = 0
				let ofType = accounts.filter(a => a.type === relevantAccount.type)
				ofType.forEach(a => {
					if (existingAccounts.includes(a.id)) count ++
				})
				if (count === 0) existingCategories = existingCategories.filter(c => c !== typeNames[relevantAccount.type])
			} else {
				existingAccounts.push(target)
				if (!existingCategories.includes(typeNames[relevantAccount.type])) existingCategories.push(typeNames[relevantAccount.type])
			}
			setSelectedCategories(existingCategories)
			setSelectedAccounts(existingAccounts)
		}

		const getButtonVariant = (type) => {
			let variant = ''
			if (type === 'Current Account') variant = 'asset'
			if (type === 'Savings Account') variant = 'saving'
			if (type === 'Credit Card') variant = 'liability'
			if (type === 'Pension') variant = 'saving'
			if (type === 'Property') variant = 'asset'
			if (type === 'Mortgage') variant = 'liability'

			if (selectedAccounts.includes(type)) variant = 'outline-' + variant

			return variant
		}

		return (<Row className="d-flex justify-content-center g-1">
			{type.map(k => {
				return k != null && categories[k] && <Col key={k} xs={6} sm={6} md={4} lg={4} xl={4} xxl={2}>
					<Dropdown as={ButtonGroup} align="end" className="mx-1 justify-content-between align-self-stretch" style={{width: "100%", height: "100%"}}>
	  					<Button
								variant={(selectedCategories.includes(k) ? '' : 'outline-') + getButtonVariant(k)}
								onClick={toggleCategory}
								value={k}
								className="fs-5">
							{k}
						</Button>
						<Dropdown.Toggle
								split
								variant={(selectedCategories.includes(k) ? '' : 'outline-') + getButtonVariant(k)}
								id={"dropdownToggle:" + k}
								autoClose="outside"
								style={{width: "50px", flexGrow: "0"}} />

						<Dropdown.Menu>
							{categories[k]?.map(item => (
								<Dropdown.Item
										key={item.id}
										data-value={item.id}
										onClick={toggleAccount}>
									<input
											type="checkbox"
											data-value={item.id}
											onChange={toggleAccount}
											checked={selectedAccounts.includes(item.id)} /> {item.accountName}
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</Col>
			})}
		</Row>)
	}

	const Graph = () => {

		const [ limitedChartData, setLimitedChartData ] = useState([])
		const [ dataConfig, setDataConfig ] = useState({})
		const [ hasNegative, setHasNegative ] = useState(false)

		useEffect(() => {
			let chartData = []
			let chartNames = []
			let chartDates = []
			const today = new Date()
			const lastMonth = addDays(today, -30)
			const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

			let aged18Date = new Date(new Date(dateOfBirth).getFullYear() + 18, new Date(dateOfBirth).getMonth(), new Date(dateOfBirth).getDate())
			let aged90Date = new Date(new Date(dateOfBirth).getFullYear() + 90, new Date(dateOfBirth).getMonth(), new Date(dateOfBirth).getDate())
			let retireDate = new Date(new Date(dateOfBirth).setFullYear(new Date(dateOfBirth).getFullYear() + Number(retirementAge)))

			let total = 0
			let cardBalance = 0
			let mortgages = []
			let cashAccounts = []
			let mortgageRate = []
			accounts.forEach(a => {
				a.shopStAverageSaving && (total += a.shopStAverageSaving)
				a.type === 'card' && (cardBalance += a.balance.amount.value)
				maxTodayTypes.includes(a.type) && (cashAccounts.push(a))
				a.type.split(':')[0] === 'mortgage' && (mortgages.push(a))
				a.type.split(':')[0] === 'mortgage' && (mortgageRate.push(a.details.APR))
			})

			forecastGeneric({type: typeNamesReverse[shopStType], accountName: 'scenario'}, retireDate, retirementAge, aged90Date, typeNamesReverse[shopStType], total, lumpSumPercentage, cardBalance, mortgages, cashAccounts).then(scenario => {

				let accountsList = [...accounts.filter(a => selectedAccounts.includes(a.id))]
				scenario && accountsList.push(scenario)
				let isAllMaxToday = true
				accountsList.forEach(a => {
					chartNames.push(a.accountName)
					a.valueForecast?.forEach(f => {
						chartDates.push(new Date(new Date(f.name).getFullYear(), new Date(f.name).getMonth(), new Date(f.name).getDate()).valueOf())
					})
					if (!maxTodayTypes.includes(a.type)) isAllMaxToday = false
					isAllMaxToday &= shopStType === null
					//if (shopStType !== null && !maxTodayTypes.includes(a.type)) isAllMaxToday = false
				})
				if (shopStType != null) isAllMaxToday = false

				let limitedDates = []
				let dateSweep = new Date(aged18Date)
				while (dateSweep < aged90Date){
					dateSweep = new Date(new Date(dateSweep).getFullYear(), new Date(dateSweep).getMonth()+1, new Date(dateSweep).getDate())
					chartDates.push(dateSweep.valueOf())
					limitedDates.push(dateSweep.valueOf())
				}

				chartDates = [...new Set(chartDates)]
				chartDates.sort((a,b) => a > b ? 1 : -1)
				chartDates.forEach(d => {
					if (isAllMaxToday){
						if (new Date(d) > today) return
					}
					let emptyDataPoint = {}
					emptyDataPoint.name = d
					chartNames.forEach(n => {
						emptyDataPoint[n + '_l'] = null
						emptyDataPoint[n] = null
						emptyDataPoint[n + '_h'] = null
						emptyDataPoint[n + '_hd'] = null
					})
					emptyDataPoint.preTodayZeros = null
					emptyDataPoint.postTodayZeros = null
					emptyDataPoint.low = 0
					emptyDataPoint.base = 0
					emptyDataPoint.high = 0
					emptyDataPoint.highDiff = 0
					chartData.push(emptyDataPoint)
				})

				let accountsListWithScenario = [...accountsList]
				scenario != null && accountsListWithScenario.push(scenario)
				accountsListWithScenario.forEach(a => {
					const fields = typeValueFields[a.type]
					a.valueForecast?.forEach(f => {
						let data = chartData.filter(cd => cd.name <= (f.name + 1000 * 60 * 60 * 12) && cd.name >= (f.name - 1000 * 60 * 60 * 12))[0]
						if (!data) return

						data[a.accountName + '_l'] = Math.floor(f[fields.low])
						data[a.accountName] = Math.floor(f[fields.base])
						data[a.accountName + '_h'] = Math.floor(f[fields.high])
						data[a.accountName + '_hd'] = data[a.accountName + '_h'] - data[a.accountName + '_l']
					})
				})

				let previousData = {}
				let zerosStart = false
				let hasNegative = false
				let startDate = null
				let oneMonthBack = new Date(new Date().getFullYear(), new Date().getMonth()-1, new Date().getDate())
				chartData.forEach(cd => {
					chartNames.forEach(n => {
							if (cd[n + '_l'] == null) cd[n + '_l'] = previousData[n + '_l']
							if (cd[n] == null) cd[n] = previousData[n]
							if (cd[n + '_h'] == null) cd[n + '_h'] = previousData[n + '_h']
							if (cd[n + '_hd'] == null) cd[n + '_hd'] = previousData[n + '_hd']
							previousData[n + '_l'] = cd[n + '_l']
							previousData[n] = cd[n]
							previousData[n + '_h'] = cd[n + '_h']
							previousData[n + '_hb'] = cd[n + '_hb']
							if (cd[n] !== undefined) zerosStart = true
							if (n !== 'scenario') {
								cd.low += (cd[n + '_l'] !== null) ? cd[n + '_l'] : 0
								cd.base += (cd[n] !== null) ? cd[n] : 0
								cd.high += (cd[n + '_h'] !== null) ? cd[n + '_h'] : 0
								cd.highDiff +=( (cd[n + '_h'] !== null) ? cd[n + '_h'] : 0) - ((cd[n + '_l'] !== null) ? cd[n + '_l'] : 0)
							}
							if (shopStType != null && new Date(cd.name) > today) cd.scenarioPostToday = cd['scenario'] + cd.base
					})
					if (cd.base !== 0 && !startDate) startDate = new Date(cd.name).valueOf()
					if (zerosStart && cd.name <= today) cd.preTodayZeros = 0
					if (zerosStart && cd.name >= oneMonthBack) cd.postTodayZeros = 0
					if (cd.base < 0) hasNegative = true
				})

				chartData.forEach(cd => {
					let cdDate = new Date(cd.name)
					if (cdDate < today){
						cd.basePreToday = cd.base
					}
					if (cdDate >= lastMonth) {
						cd.basePostToday = cd.base
					}
				})

				limitedDates = limitedDates.filter(ld => ld > startDate)
				let limitedChartData = chartData.filter(cd => limitedDates.includes(cd.name))

				let dataConfig = {}
				dataConfig.chartNames = chartNames
				dataConfig.dataSplit = [[], []]
				accounts.filter(a => selectedAccounts.includes(a.id)).forEach(a => {
					dataConfig.dataSplit[0].push(a.accountName)
				})

				dataConfig.renderTooltip = ({ active, label, payload, displayConfig }) => {
					const tooltipContents = () => {
						let tooltipValue = { low: 0, base: 0, high: 0, scenario: 0 }

						let low = payload.filter(p => p.dataKey === "low")
						let base = payload.filter(p => p.dataKey === "base")
						let high = payload.filter(p => p.dataKey === "high")
						let scenario = payload.filter(p => p.dataKey === "scenarioPostToday")
						if (low.length > 0) tooltipValue.low += low[0].value
						if (base.length > 0) tooltipValue.base += base[0].value
						if (high.length > 0) tooltipValue.high += high[0].value
						if (scenario.length > 0) tooltipValue.scenario += scenario[0].value

						let birthYear = new Date(dateOfBirth).toISOString().split('-')[0]
						let birthMonth = Number(new Date(dateOfBirth).toISOString().split('-')[1])
						let tickYear = label != null && new Date(label).toISOString().split('-')[0]
						let tickMonth = label != null && Number(new Date(label).toISOString().split('-')[1])
						let inPast = label != null && new Date(label) < new Date()

						return (
							<>
								{label && <h6 className="text-start"><span className="text-muted">
									Age:</span> {(Number(tickYear) - Number(birthYear) - (birthMonth > tickMonth ? 1 : 0))} <span className="text-muted">({months[tickMonth]} {tickYear})</span>
								</h6>}
								{showWings && <div className="fw-bold fs-6 d-flex justify-content-between text-muted">
									{inPast ? "Historic Value" : "Forecast Value"}
								</div>}
								{!inPast && showWings && <div className="fs-6 d-flex justify-content-between">
									<div>
										High est.&nbsp;
									</div>
									<div className="text-end mr-auto">
										{currencyFormatter(tooltipValue.high, 0, true)}
									</div>
								</div>}
								<div style={{color: tooltipValue.base >=0 ? styles['asset-0'] : styles['liability-0']}} className="fw-bold fs-6 d-flex justify-content-between">
									<div>
										{inPast ? "Value" : (!showWings ? "Forecast Value." : "Medium est.")}&nbsp;
									</div>
									<div className="text-end mr-auto">
										{currencyFormatter(tooltipValue.base, 0, true)}
									</div>
								</div>
								{!inPast && showWings && <div className="fs-6 d-flex justify-content-between">
									<div>
										Low est.&nbsp;
									</div>
									<div className="text-end mr-auto">
										{currencyFormatter(tooltipValue.low, 0, true)}
									</div>
								</div>}
								{!inPast && shopStType != null && <div className="fw-bold fs-6 d-flex justify-content-between text-saving-color">
									<div>
										With Shop St.&nbsp;
									</div>
									<div className="text-end mr-auto">
										{currencyFormatter(tooltipValue.scenario, 0, true)}
									</div>
								</div>}
							</>
						)
					}
					return(
						active &&
								<InteractionTile body={tooltipContents()} padding="p-1" bottomMargin="" />
					)
				}

				setDataConfig(dataConfig)
				setHasNegative(hasNegative)
				setLimitedChartData(limitedChartData)

			})
		}, [accounts])

		const renderLumpSumHelp = (props) => (
			<TooltipRB id="button-tooltip" {...props} animation="false">
				<>
					<div>Each item has a forecast based upon it's type. The graph combines each selected type forecast to produce an aggregate forecast.</div>
					<div>Forecasts are estimated amounts and do not guarantee of future value.</div>
				</>
			</TooltipRB>
		)

		return (<div>
			<Row className="mt-3">
				<Col>
					<OverlayTrigger
							placement="auto"
							delay={{ show: 250, hide: 400 }}
							overlay={renderLumpSumHelp}>
						<div className="fs-6 text-muted float-end interaction"><u><small>What are these values?</small></u></div>
					</OverlayTrigger>
				</Col>
			</Row>
			<div className="mx-0 mb-3" style={{"height":"400px"}}>

				{load && <>
					{accounts.length > 0 && selectedAccounts.length === 0 && <div className="pt-5 text-center text-muted">
						Select a category above
					</div>}
					{accounts.length === 0 && <div className="pt-5 text-center text-muted">
						Add an account to see your forecast
					</div>}
					{selectedAccounts.length !== 0 && <ForecastChart
							xAxisType="month"
							dateOfBirth={dateOfBirth}
							data1={limitedChartData}
							displayConfig={dataConfig}
							minZero={!hasNegative}
							showBrush={true}
							retirementDate={new Date(new Date(dateOfBirth).setFullYear(new Date(dateOfBirth).getFullYear() + Number(retirementAge)))}
						/>}
					</>}
				{!load && <div >
							<div style={{paddingTop: "180px"}}>
								<Spinner className="center" animation="border" role="status">
									<span className="visually-hidden">Loading..</span>
								</Spinner>
							</div>
							<div className="mt-3">
								Loading..
							</div>
						</div>}
			</div>
		</div>)
	}

	const Overview = () => {

		let orderedData = []
		selectedAccounts.forEach(aId => {
			orderedData.push(accounts.filter(a => a.id === aId)[0])
		})
		orderedData.sort((a,b) => type.indexOf(typeNames[a.type]) < type.indexOf(typeNames[b.type]) ? -1 : 1)
		let totalValue = 0
		orderedData.forEach(a => {
			if (a.currentValue){
				if (a.currentValue.base) totalValue += a.currentValue.base
				if (!a.currentValue.base) totalValue -= a.currentValue
			}
			if (!a.currentValue) totalValue += a.balance?.amount.value
		})

		return (<>
			<h5 className="text-asset-color">Today's Estimated Value</h5>
			<Row className="align-items-stretch">
				<Col className="d-md-none">
					<Row className="text-asset-color">
						<Col>
							<div className={"d-flex text-start align-items-center"} style={{paddingLeft: "0.25rem"}}>

								<div className="text-truncate fw-bold" style={{marginRight: "0.5rem", flexGrow: "1"}}>
									Total:
								</div>
								<div className={"mr-auto fw-bold " + (totalValue < 0 ? 'text-spending-color' : 'text-asset:color')}>
									{currencyFormatter(totalValue, 0, true)}
								</div>
							</div>
						</Col>
					</Row>
				</Col>
				<Col md={9}>
					<Row>
						{orderedData.map(a => {
							return (<Col md={6} key={a.id}>
								<div className={"d-flex text-start align-items-center " + typeColorsCss[a.type]} style={{paddingLeft: "0.25rem"}}>
									<div style={{marginRight: "0.25rem"}}>
										{a.connection && <Image src={a.connection.iconUrl} height="25px" />}
										{!a.connection && <TypeIcon type={a.type} />}
									</div>
									<div className="text-truncate" style={{marginRight: "0.5rem", flexGrow: "1"}}>
										{a.accountName}
									</div>
									<div className="mr-auto" style={{whiteSpace: "nowrap"}}>
										{/* {currencyFormatter(fetchByDotOperator(a, valuePath), 0, true)} */}
										{a.currentValue && <>
											{a.currentValue.base && currencyFormatter(a.currentValue.base, 0, true)}
											{!a.currentValue.base && <>-{currencyFormatter(a.currentValue, 0, true)}</>}
										</>}
										{!a.currentValue && currencyFormatter(a.balance?.amount.value, 0, true)}
									</div>
								</div>
							</Col>)
						})}
					</Row>
				</Col>
				<Col md={3} style={{borderLeft: "4px solid #E5E5E5"}} className="d-flex justify-content-center align-items-center d-none d-md-block">
					<Row className="text-asset-color align-items-center">
						<div>Total:</div>
						<div className={"fw-bold " + (totalValue < 0 ? 'text-spending-color' : 'text-asset:color')}>{currencyFormatter(totalValue, 0, true)}</div>
					</Row>
				</Col>
			</Row>

		</>)
	}

	const TypeIcon = ({ type }) => {
		return (<>
			{typeConversion[type] === 'cash:current' && <CashCoin style={{fontSize: "1.5rem"}} />}
			{typeConversion[type] === 'savings' && <CashStack style={{fontSize: "1.5rem"}} />}
			{typeConversion[type] === 'card' && <CreditCard style={{fontSize: "1.5rem"}} />}
			{typeConversion[type] === 'pension' && <PiggyBank style={{fontSize: "1.5rem"}} />}
			{typeConversion[type] === 'property' && <House style={{fontSize: "1.5rem"}} />}
			{typeConversion[type] === 'mortgage' && <Bank style={{fontSize: "1.5rem"}} />}
		</>)
	}

	const handleShopStToggleClick = () => {
		if (shopStType === null){
			for (let i = 0; i < scenarioTypes.length; i++){
				if (selectedCategories.includes(scenarioTypes[i])){
					setShopStType(scenarioTypes[i])
					return
				}
			}
		} else {
			setShopStType(null)
		}
	}

	const setShopStScenario = () => {
		if (shopStType === null) return
		if (!selectedCategories.includes(shopStType)) {
			for (let i = 0; i < scenarioTypes.length; i++){
				if (selectedCategories.includes(scenarioTypes[i])){
					setShopStType(scenarioTypes[i])
					return
				}
			}
			setShopStType(null)
		}
	}

	const isShopStreetToggleAvailable = () => {
		return !selectedCategories.some(c => scenarioTypes.includes(c))
	}

	/**
	 * Adds days to a date
	 * @param {Date} date
	 * @param {Integer} days
	 * @returns
	 */
	const addDays = (date, days) => {
		var result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}

	return (<InteractionTile body={<div className="text-center">

			<h5 className="my-0 financialForecastTitle" style={{overflow: "visible"}}>
				<div className="pt-2">
					Your Financial Forecast
				</div>
			</h5>
			<div className="d-flex justify-content-end mx-2">
				<div className="border soft-corners bg-white p-2 mb-1">
					<div className="d-flex align-items-center">
						<div className="fs-5">
							Add Potential <br /><SSLogo size="18" /> savings:
						</div>
						<div className="mx-2">
							<Form.Check
									type="switch"
									aria-label="Toggle ShopSt"
									checked={shopStType != null}
									onChange={() => handleShopStToggleClick()}
									disabled={isShopStreetToggleAvailable()} />
						</div>
						<div>
							<Dropdown align="end" className="mx-1 justify-content-between align-self-stretch" style={{width: "100%", height: "100%"}}>
								<Dropdown.Toggle
										split
										variant='outline-asset'
										id={"dropdownToggle:shopSt"}
										autoClose="outside"
										style={{width: "38px", flexGrow: "0"}}
										disabled={isShopStreetToggleAvailable()} />

								<Dropdown.Menu>
									{scenarioTypes.map(type => {
										return (selectedCategories.includes(type) && <Dropdown.Item
												key={type}
												data-value={type}
												onClick={() => setShopStType(type)}>
											<input
													type="radio"
													onChange={() => setShopStType(type)}
													checked={shopStType === type} /> {type}
										</Dropdown.Item>)
									})}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				</div>
			</div>

			<InteractionTile extraClass="bg-white" body={<>
				<Selector />
				<Graph />
				<Overview />
			</>} />

		</div>} />)
}

export default FinancialForecast