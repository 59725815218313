import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { signOut } from 'aws-amplify/auth';

const Logout = () => {

	const navigate = useNavigate();

	useEffect(() => {
		const logout = async () => {
			try {
				await signOut();
				navigate('/')
			} catch (error) {
				console.error('Error signing out: ', error);
			}
		}
		logout()
	}, [navigate])

	return (
		<></>
	)
}

export default Logout;