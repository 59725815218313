import React from "react";

import { Row, Col } from 'react-bootstrap';

/**
 * Two column layout for used for displaying content
 * @param {<></>} left The contents of the left column
 * @param {<></>} right The contents of the right column
 * @param {Bool} alignItemsCenter Should the contents of the columns be aligned center (defaults false)
 */
const TwoColumnContentLayout = ({ left, right, alignItemsCenter = false, wide = 'right' }) => {
	return (
		<>
			<Row className={"gx-4 gy-0" + (alignItemsCenter ? "align-items-center" : "")}>
				<Col className=""
						xs={{ span: 12, order: 1 }}
						sm={{ span: 12, order: 1 }}
						md={{ span: 6, order: 1 }}
						lg={{ span: wide === 'right' ? 4 : 8, order: 1 }}
						xl={{ span: wide === 'right' ? 4 : 8, order: 1 }}
						xxl={{ span: wide === 'right' ? 4 : 8, order: 1 }}>
					{left}
				</Col>
				<Col className=""
						xs={{ span: 12, order: 2 }}
						sm={{ span: 12, order: 2 }}
						md={{ span: 6, order: 2 }}
						lg={{ span: wide === 'right' ? 8 : 4, order: 2 }}
						xl={{ span: wide === 'right' ? 8 : 4, order: 2 }}
						xxl={{ span: wide === 'right' ? 8 : 4, order: 2 }}>
					{right}
				</Col>
			</Row>
		</>
	)
}

export default TwoColumnContentLayout;