import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../pages/Main";

import 'react-multi-carousel/lib/styles.css';
import { X } from "react-bootstrap-icons";

import { motion, AnimatePresence } from "framer-motion"
import { Nav, Button } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import ReactPlayer from "react-player";

import Home1 from '../../assets/content/gifs/Savings2.mp4'
import Home2 from '../../assets/content/gifs/AllCash2.mp4'
import Home3 from '../../assets/content/gifs/Forecasts2.mp4'
import Home4 from '../../assets/content/gifs/BankAccount1.mp4'

import Pension1 from '../../assets/content/gifs/PensionHome.mp4'
import Pension2 from '../../assets/content/gifs/Retirementplan1.mp4'
import Pension3 from '../../assets/content/gifs/AddPension.mp4'

import Insights1 from '../../assets/content/gifs/SpendingOverUnder.mp4'
import Insights2 from '../../assets/content/gifs/SpendingShopSt.mp4'
import Insights3 from '../../assets/content/gifs/SpendingCat1.mp4'

import Bank1 from '../../assets/content/gifs/AllCash2.mp4'
import Bank2 from '../../assets/content/gifs/BankAccount1.mp4'

import ShopSt1 from '../../assets/content/gifs/Retailers.mp4'
import ShopSt2 from '../../assets/content/gifs/ShopStTrack.mp4'

import Property1 from '../../assets/content/gifs/Property.mp4'

import Mortgage1 from '../../assets/content/gifs/Mortgage.mp4'

const pageTitles = [
	{
		page: 'home',
		text: 'Save & Improve your Financial Future'
	},{
		page: 'pension',
		text: 'Forecast Your Retirement'
	},{
		page: 'insights',
		text: 'Spending Insights'
	},{
		page: 'bank',
		text: 'Add your accounts'
	},{
		page: 'shopst',
		text: 'Save with ShopSt.'
	},{
		page: 'property',
		text: 'Property Valuation'
	},{
		page: 'mortgage',
		text: 'Track your Mortgage'
	}
]

const allHelpItems = [
	{
		page: 'home',
		video: Home1,
		title: 'See which retailors you can save at'
	}, {
		page: 'home',
		video: Home2,
		title: 'View all your accounts in one place'
	}, {
		page: 'home',
		video: Home3,
		title: 'Forecasts to retirement & beyond'
	}, {
		page: 'home',
		video: Home4,
		title: 'All major banks supported'
	}, {
		page: 'pension',
		video: Pension1,
		title: 'View your pension forecasts'
	}, {
		page: 'pension',
		video: Pension2,
		title: 'Add a state pension & adjust your retirement plan'
	}, {
		page: 'pension',
		video: Pension3,
		title: 'Add your pension provider or add a manual pension'
	}, {
		page: 'insights',
		video: Insights1,
		title: 'See how much you are saving or overspending'
	}, {
		page: 'insights',
		video: Insights2,
		title: 'Identify shops that you can save at'
	}, {
		page: 'insights',
		video: Insights3,
		title: 'View your spending categories'
	}, {
		page: 'bank',
		video: Bank1,
		title: 'View all your accounts in one place'
	}, {
		page: 'bank',
		video: Bank2,
		title: 'All major banks supported'
	}, {
		page: 'shopst',
		video: ShopSt1,
		title: 'Save at 100s of retailers'
	}, {
		page: 'shopst',
		video: ShopSt2,
		title: 'Track your retail savings'
	}, {
		page: 'property',
		video: Property1,
		title: 'See your current estimated property value'
	}, {
		page: 'mortgage',
		video: Mortgage1,
		title: 'Track & Forecast your Mortgage'
	}
]


function HelpPopover({ pageName, showHelp, setShowHelp }){

	const userContext = useContext(UserContext)

	const [ isPlaying, setIsPlaying ] = useState(true)
	const [ selectedItem, setSelectedItem ] = useState(undefined)
	const [ currentRandomItems, setCurrentRandomItems ] = useState([])
	const [ userHasInteracted, setUserHasInteracted ] = useState(false)

	const getPageTitle = () => {
		return (
			<h4 className="text-center p-2">{pageTitles.filter(i => i.page === pageName)[0].text}</h4>
		)
	}

	const getPageContents = () => {
		let content = null
		if (pageName === 'home'){
			if (userContext.accounts.length === 0) {
				content = allHelpItems.filter(i => i.page === 'home')
			} else {
				let randomList = currentRandomItems
				if (currentRandomItems.length === 0){
					randomList = shuffleArray(allHelpItems.filter(i => i.page !== 'home'))
					setCurrentRandomItems(randomList)
				}
				content = randomList.slice(0,4)
			}
		} else {
			content = allHelpItems.filter(i => i.page === pageName)
		}
		if (selectedItem === undefined){
			setSelectedItem(content[0])
		}
		content.forEach((c,i) => {
			if (i + 1 === content.length){
				c.next = content[0]
			} else {
				c.next = content[i+1]
			}
		})
		return content
	}

	useEffect(() => {
		// if (pageName === 'home') {
		// 	setSelectedItem(Home1)
		// }
		// if (pageName === 'pension') {
		// 	setSelectedItem(Pension1)
		// }
		// if (pageName === 'insights') {
		// 	setSelectedItem(Insights1)
		// }
	}, [])

	const handleCloseHelp = () => {
		setUserHasInteracted(false)
		setSelectedItem(undefined)
		setShowHelp(false)
		if (pageName === 'home'){
			window.localStorage.setItem('hasClosedHelp', JSON.stringify(true))
		}
	}

	const shuffleArray = (array) => {
		for (let i = array.length - 1; i > 0; i--) {
			let j = Math.floor(Math.random() * (i + 1));
			let temp = array[i];
			array[i] = array[j];
			array[j] = temp;
		}
		return array
	}

	return (<div style={{position: "relative"}}>
		<div className="helpContainer" >
			<AnimatePresence>
				{/* x, y, width, height, top, left, right and bottom */}
				{showHelp && (<>
					<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.5 }}>
						<div className="helpBackDrop" >
							<motion.div
									initial={{ scaleX: 0, scaleY: 0, x: "80px", y: "-200px" }}
									animate={{ scaleX: 1, scaleY: 1, x: "0px", y: "0px" }}
									exit={{ scaleX: 0, scaleY: 0, x: "80px", y: "-200px" }}
									transition={{ duration: 0.5 }}
									style={{ pointerEvents: "all", transformOrigin: "top right"}}>

								<div className="soft-corners shadow p-3 helpPosition" >
									<div style={{textAlign: "right", color: "#666666", height: "0"}}>
										<X onClick={() => handleCloseHelp()} className="fs-1 interaction hasHoverEffect_large" />
									</div>

									{getPageTitle()}

									<div>
										<div className="videoHeight" style={{position: "relative", width: "100%", overflow: "hidden" }}>
											<AnimatePresence>
												{selectedItem != null && <motion.div
														initial={{ opacity: 0, x: "50%" }}
														animate={{ opacity: 1, x: "0%" }}
														exit={{ opacity: 0, x: "-50%" }}
														transition={{ duration: 0.5 }}>
													<ReactPlayer
															className="react-player"
															url={selectedItem.video}
															width="100%"
															height="100%"
															controls={false}
															playing={isPlaying}
															onEnded={() => {setSelectedItem(null); setTimeout(() => setSelectedItem(selectedItem.next), 1000)}}
															loop={userHasInteracted}
															playsinline={true}
															muted={true} />
												</motion.div>}
											</AnimatePresence>
										</div>
									</div>

									{<div className="d-flex mt-1 mb-3 justify-content-center" style={{ flexWrap: "wrap" }}>
										{getPageContents().map((e,i) => {return i < 4 && <motion.div
													key={e.video}
													transition={{ duration: 0.5 }}
													whileTap={{ scale: 0.95 }}
													style={{
														color: '#3377FF',
														zIndex: "999",
														border: e.title === selectedItem?.title ? "2px solid #DEDEDE" : "2px solid transparent",
														backgroundColor: e.title === selectedItem?.title ? "white" : "transparent",
														fontWeight: e.title === selectedItem?.title ? "600" : "400",
														width: "44%"
													}}
													className="m-2 p-2 soft-corners text-center hoverInteract"
													onClick={() => {setSelectedItem(e); setUserHasInteracted(true)}}>
												{e.title}
											</motion.div>
										})}
									</div>}

									<div className="text-center">
									<LinkContainer to="/add-asset" className="nav-contents">
										<Nav.Link>
											<Button>Add Account</Button>
										</Nav.Link>
									</LinkContainer>
									</div>

								</div>
							</motion.div>
						</div>
					</motion.div>
				</>)}
			</AnimatePresence>
		</div>
	</div>)
}

export default HelpPopover;