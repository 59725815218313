import React from "react";
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import currencyFormatter from "../../utils/CurrencyFormatter";
import dateFormatter from "../../utils/DateFormatter";

import styles from '../../scss/exports.scss';

const NetMovementChart = ({ data, displayConfig }) => {

	if (!displayConfig) displayConfig = {};
	if (!displayConfig.bars) displayConfig.bars = [];

	const DrawDottedBorder = () => {
		return (props) => {
			const { value, x, y, width, height} = props
			const rate = value[0] === value[1] ? 0 : (height / (value[1] - value[0]))
			const v1Rate = value[1] * rate
			const newY = y + v1Rate
			return (
				<g>
					<rect
						x={x}
						y={value[1] > 0 ? y : newY}
						width={width}
						height={value[1] > 0 ? v1Rate : -v1Rate}
						stroke={styles['positive-0']}
						strokeDasharray={[3,3]}
						fill='#00000000'
						/>
				</g>
			)
		};
	}

	return (
		<>
			<ResponsiveContainer className="border soft-corners" width="100%" height="100%">
				<ComposedChart
						width={500}
						height={300}
						data={data}
						margin={{
							top: 0,
							right: 5,
							left: 0,
							bottom: 0,
						}}>
					<CartesianGrid strokeDasharray="1 0" vertical={false} stroke="#E5E9F2" />
					<XAxis
							dataKey="name"
							tickFormatter={value => dateFormatter(value)}
							tickLine={false}
							axisLine={false}
							className="text-muted fs-6" />
					<YAxis
							dx={5}
							padding={{ top: 25, bottom: 25 }}
							tickFormatter={value => currencyFormatter(value * 100)}
							tickLine={false}
							axisLine={false}
							className="text-muted fs-6" />
					{displayConfig.renderTooltip && <Tooltip cursor={false} content={<displayConfig.renderTooltip displayConfig={displayConfig} />} />}
					{displayConfig.renderLegend && <Legend content={<displayConfig.renderLegend displayConfig={displayConfig} />} />}
					{displayConfig.bars.map((b,i) => {
						return (
							<Bar
									key={b.dataKey + i}
									dataKey={b.dataKey}
									shape={b.shapeDraw === 'drawDotted' ? DrawDottedBorder() : null}
									fill={b.fill}
									stackId={b.stackId}
									isAnimationActive={false}
									radius={[4,4,0,0]} >
								<LabelList
										style={{fontWeight: "600"}}
										dataKey={b.dataKey}
										position={b.labelPosition}
										formatter={b.labelFormatter}
										fill={b.textConfigFill}>
								</LabelList>
							</Bar>
						)
					})}
				</ComposedChart>
			</ResponsiveContainer>
		</>
	)
}

export default NetMovementChart;