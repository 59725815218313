export const forecastMortgage = async ( moneyHubAssetTypes, user, accountState, setLoadingStage ) => {
	let accounts = [...accountState.state]
	try{
		const mortgageTypes = moneyHubAssetTypes['mortgage']

		const today = new Date().toISOString().split('T')[0]
		let startDate = user.dateAged18
		accounts.filter(a => mortgageTypes.includes(a.type)).forEach(a => {
			a.doForecast = true
			try{
				a.details.startDate = addMonths(new Date(a.details.endDate), -1 * a.details.term)
				if (a.details.startDate < new Date(startDate)){
					startDate = a.details.startDate
				}
				a.details.startDate = a.details.startDate.toISOString().split('T')[0]
			} catch (e) {
				a.doForecast = false
			}
		})
		const endDate = user.dateAged90

		accounts.filter(a => mortgageTypes.includes(a.type) && a.doForecast).forEach(a => {
			let currentValue = null
			let currentPayment = 0
			let currentRate = 1
			let currentDate = new Date(startDate)
			let valueDataGraph = []
			let keyDates = []
			while (currentDate <= endDate){
				const shortDate = currentDate.toISOString().split('T')[0]
				let currentBalances = a.balances.filter(b => b.date === shortDate).sort((a,b) => a.date < b.date ? 1 : -1)
				if (currentBalances.length > 0) {
					currentValue = currentBalances[0].amount.value
					keyDates.push(currentDate)
				} else if (currentValue != null) {
					currentValue = (currentValue * (Math.pow(currentRate, 1/364))) + (currentPayment / 364)
				}
				if (shortDate === a.details.startDate){
					currentPayment = a.details.monthlyRepayment * 12
					currentRate = 1 + (a.details.APR / 100)
				}
				if (a.details.fixedDate !== "" && shortDate === a.details.fixedDate){
					currentRate = 1.075
					const pmt = (ir, np, pv, fv, type) => {
						/*
						* ir   - interest rate per month
						* np   - number of periods (months)
						* pv   - present value
						* fv   - future value
						* type - when the payments are due:
						*        0: end of the period, e.g. end of month (default)
						*        1: beginning of period
						*/
						var pmt, pvif;
						fv || (fv = 0);
						type || (type = 0);
						if (ir === 0) return -(pv + fv)/np;
						pvif = Math.pow(1 + ir, np);
						pmt = - ir * (pv * pvif + fv) / (pvif - 1);
						if (type === 1) pmt /= (1 + ir);
						return pmt;
					}
					const diffDays = (d1, d2) => {
						var nDays
						var tv1 = d1.valueOf()
						var tv2 = d2.valueOf()
						nDays = (tv2 - tv1) / 1000 / 86400
						nDays = Math.round(nDays - 0.5)
						return nDays
					}
					currentPayment = pmt(
							(currentRate - 1) / 364,
							diffDays(currentDate, new Date(a.details.endDate)),
							currentValue, 0, 0) * 364
				}
				if (shortDate === a.details.endDate){
					currentPayment = 0
					currentRate = 1
					currentValue = 0
				}
				if (shortDate === today) {
					a.currentValue = -1 * currentValue
					keyDates.push(currentDate)
				}
				if (keyDates.includes(currentDate) || (currentDate.getDate() === 1)){
					valueDataGraph.push({
						name: Number(currentDate),
						value: currentValue < 0 ? currentValue : null,
						shortName: currentDate.toISOString().split('T')[0]
					})
				}
				currentDate = addDays(currentDate, 1)
			}
			a.valueForecast = valueDataGraph
			const generateRemainingTerm = (d) => {
				let todayYear = new Date().getFullYear()
				let todayMonth = new Date().getMonth()
				let targetYear = d.getFullYear()
				let targetMonth = d.getMonth()
				let years = targetYear - todayYear
				let months = targetMonth - todayMonth
				if (months < 0){
					years --;
					months += 12
				}
				return (years > 0 && (years + ' year' + (years > 1 ? 's ' : ' '))) + months + ' month' + (months > 1 ? 's ' : ' ')
			}
			a.remainingTerm = generateRemainingTerm(new Date(a.details.endDate))
		})
		setLoadingStage(oldLoadingStage => ({...oldLoadingStage, forecastMortgage: true}))
	} catch(e) { console.error(e) }

	accountState.setState(accounts)
}

const addDays = (date, days) => {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}

const addMonths = (date, months) => {
	var d = date.getDate();
	date.setMonth(date.getMonth() + +months);
	if (date.getDate() !== d) {
	  date.setDate(0);
	}
	return date;
}