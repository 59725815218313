import React, { useState } from "react";
import { Form, Row, Col } from 'react-bootstrap';

const months = [
	{
		name: 'Jan',
		label: 'January',
		index: 0
	},{
		name: 'Feb',
		label: 'February',
		index: 1
	},{
		name: 'Mar',
		label: 'March',
		index: 2
	},{
		name: 'Apr',
		label: 'April',
		index: 3
	},{
		name: 'May',
		label: 'May',
		index: 4
	},{
		name: 'Jun',
		label: 'June',
		index: 5
	},{
		name: 'Jul',
		label: 'July',
		index: 6
	},{
		name: 'Aug',
		label: 'August',
		index: 7
	},{
		name: 'Sep',
		label: 'September',
		index: 8
	},{
		name: 'Oct',
		label: 'October',
		index: 9
	},{
		name: 'Nov',
		label: 'November',
		index: 10
	},{
		name: 'Dec',
		label: 'December',
		index: 11
	}
]

const MonthYearPicker = ({ month = undefined, year = undefined, required = false, readOnly = false, allowPast = false, allowFuture = false, onChange = (v) => {console.log('Enable a onChange handler: ', v)} }) => {

	const [ selectedMonth, setSelectedMonth ] = useState(month)
	const [ selectedYear, setSelectedYear ] = useState(year)
	const [ valid, setValid ] = useState(false)

	const getYearOptions = () => {
		let yearOptions = []
		let today = new Date()
		let currentYear = today.getFullYear()
		let ascendingYear = true

		if (allowPast && !allowFuture){
			ascendingYear = false
			for (let i = currentYear - 100; i <= currentYear; i ++){
				yearOptions.push(i)
			}
		} else if (!allowPast && allowFuture){
			for (let i = currentYear + 100; i >= currentYear; i --){
				yearOptions.push(i)
			}
		} else {
			for (let i = currentYear - 100; i <= currentYear + 100; i++){
				yearOptions.push(i)
			}
		}

		yearOptions.sort((a,b) => (a < b ? -1 : 1) * (ascendingYear ? 1 : -1))

		let fullYearOptions = []
		yearOptions.forEach(o => {
			fullYearOptions.push({
				name: o,
				label: o
			})
		})
		return fullYearOptions
	}

	const handleChange = (e) => {
		let shortDate = null
		if (e.target.id === 'monthSelect'){
			setSelectedMonth(e.target.value)
			shortDate = buildShortDate(selectedYear, e.target.value)
		} else if (e.target.id === 'yearSelect'){
			setSelectedYear(e.target.value)
			shortDate = buildShortDate(e.target.value, selectedMonth)
		}

		if (new Date(shortDate) !== 'Invalid Date'){
			setValid(true)
			onChange(shortDate)
		}
	}

	const buildShortDate = (year, month) => {
		return String(year).padStart(4, '0') + '-' + String(Number(month)+1).padStart(2, '0') + '-01'
	}

	const getClassList = () => {
		return 'form-control' + (readOnly ? ' bg-interaction' : ' bg-white')
	}

	return (
		<>
			<Form.Select
					className={getClassList()}
					aria-label="Month Select"
					id="monthSelect"
					onChange={handleChange}
					defaultValue={month}
					disabled={readOnly}
					required={required}>
				<option value="">Month</option>
				{months.map(m => (
					<option value={m.index}>{m.label}</option>
				))}
			</Form.Select>
			<Form.Select
					className={getClassList()}
					aria-label="Year Select"
					id="yearSelect"
					onChange={handleChange}
					defaultValue={year}
					disabled={readOnly}
					required={required}>
				<option value="">Year</option>
				{getYearOptions().map(o => (
					<option value={o.name}>{o.label}</option>
				))}
			</Form.Select>
		</>
	)

}

export default MonthYearPicker;