import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../pages/Main";

import { updateMhAccount, deleteMhAccount } from '../service/DataService';
import { getSessionTokenAsync } from '../utils/GetSessionTokenAsync';
import { generateAuthorizationUrlReconsent } from "../service/DataService";
import { House } from "react-bootstrap-icons";

import QRCode from "react-qr-code";

import { Row, Col, Form, Button, InputGroup, ButtonGroup, ToggleButton, Image, Modal, Spinner, Accordion } from 'react-bootstrap';
import currencyFormatter from "../utils/CurrencyFormatter";
import MonthYearPicker from "./MonthYearPicker";

const ConnectionDetails = ({ type, allowedConnections = [], autoLaunched = false }) => {

	const userContext = useContext(UserContext)
	const navigate = useNavigate()

	const [ authUrl, setAuthUrl ] = useState('')
	const [ showQRModal, setShowQRModal ] = useState(false)
	const [ authorizationMessage, setAuthorizationMessage ] = useState('')

	const [ isSavingBlock, setIsSavingBlock ] = useState(false)

	const typeOptions = {
		'cash:current': ['cash:current', 'savings'],
		'savings': ['cash:current', 'savings']
	}
	const typeNames = {
		'cash:current': 'Current Account',
		'savings': 'Savings Account',
		'card': 'Credit Card',
		'pension': 'Pension',
		'properties:residential': 'Property',
		'mortgage:repayment': 'Mortgage',
		'mortgage:interestOnly': 'Mortgage',
	}
	const pageNames = {
		'cash:current': '/bank-account',
		'savings': '/bank-account',
		'card': '/bank-account',
		'pension': '/pension',
		'properties:residential': '/property',
		'mortgage:repayment': '/mortgage',
		'mortgage:interestOnly': '/mortgage',
	}

	let saveCount = 0
	const saveConnection = async (data, total, allComplete) => {
		const currentSession = await getSessionTokenAsync()
		if (
				data.accountName != null ||
				data.providerName != null ||
				data.newBalanceDate != null ||
				data.newBalanceValue != null ||
				data.postcode != null ||
				data.monthlyRepayment != null ||
				data.APR != null ||
				data.term != null ||
				data.endDate != null ||
				data.interestType != null ||
				data.fixedDate != null){
			if (data.accountName == null){
				data.accountName = userContext.accounts.filter(a => a.id === data.id)[0].accountName
			}
			if (data.providerName == null){
				data.providerName = userContext.accounts.filter(a => a.id === data.id)[0].providerName
				if (data.providerName == null) data.providerName = 'personal'
			}
		}
		if (data.type == null){
			data.type = userContext.accounts.filter(a => a.id === data.id)[0].type
		}
		if (data.postcode != null){
			if (data.details == null) data.details = {}
			data.details.postcode = data.postcode
		}
		if (data.monthlyRepayment != null){
			if (data.details == null) data.details = {}
			data.details.monthlyRepayment = data.monthlyRepayment * 100
		}
		if (data.APR != null){
			if (data.details == null) data.details = {}
			data.details.APR = Number(data.APR)
		}
		if (data.term != null){
			if (data.details == null) data.details = {}
			data.details.term = data.term
		}
		if (data.endDate != null){
			if (data.details == null) data.details = {}
			data.details.endDate = data.endDate
		}
		if (data.interestType != null){
			if (data.details == null) data.details = {}
			data.details.interestType = data.interestType
		}
		if (data.fixedDate != null){
			if (data.details == null) data.details = {}
			data.details.fixedDate = data.fixedDate
		}
		await updateMhAccount(currentSession, userContext.user.moneyhubUser, data, () => {
				saveCount ++;
				if (saveCount === total) allComplete()
			})
	}

	const handleSaveComplete = (values) => {
		let accountTypes = []
		let accounts = [...userContext.accounts]
		accounts.forEach(a => {
			Object.keys(values).forEach(key => {
				if (a.id === key){
					a.hasManualInput = true
					accountTypes.push(a.type)
					Object.keys(values[key]).forEach(property => {
						if (property === 'type'){
							a.type = values[key].type
						}
						if (property === 'accountName'){
							a.accountName = values[key].accountName
						}
						if (property === 'providerName'){
							a.providerName = values[key].providerName
						}
						if (property === 'newBalanceDate'){
							a.balances.push({
								date: values[key].newBalanceDate,
								amount: {
									value: values[key].newBalanceValue * 100
								}
							})
							a.balances.sort((a,b) => (a.date < b.date ? 1 : -1))
							a.balance = a.balances[0]
						}
						if (property === 'postcode'){
							a.details.postcode = values[key].postcode
						}
						if (property === 'monthlyRepayment'){
							a.details.monthlyRepayment = values[key].monthlyRepayment * 100
						}
						if (property === 'APR'){
							a.details.APR = values[key].APR
						}
						if (property === 'term'){
							a.details.term = values[key].term
						}
						if (property === 'endDate'){
							a.details.endDate = values[key].endDate
						}
						if (property === 'interestType'){
							a.details.interestType = values[key].interestType
						}
						if (property === 'fixedDate'){
							a.details.fixedDate = values[key].fixedDate
						}
					})
				}
			})
		})
		userContext.setAccounts(accounts)
		userContext.setUpdateStatic(true)
		userContext.setDoForecasts({ state: userContext.accounts, setState: userContext.setAccounts })
	}

	const generateUrlReconsent = async (required, connectionId) => {
		setAuthUrl("")
		setShowQRModal(true)
		setAuthorizationMessage(required ? "" : "Reauthorisation is not required at this time, but you may do so to extend the consent period.")
		const currentSession = await getSessionTokenAsync()
		await generateAuthorizationUrlReconsent(
			currentSession,
			userContext.user.moneyhubUser,
			connectionId,
			(response) => {
				setAuthUrl(response.data.authorizationUrl)
			},
			(error) => {
				console.error(error)
			}
		)
	}

	// const deleteConnection = async (connectionId) => {
	// 	const currentSession = await getSessionTokenAsync()
	// 	await deleteMhAccount(
	// 		currentSession,
	// 		userContext.user.moneyhubUser,
	// 		connectionId,
	// 		() => {}
	// 	)
	// }

	const Connection = ({ connection, type, connectionKey }) => {
		const [ newValues, setNewValues ] = useState({})
		const [ hasNewValues, setHasNewValues ] = useState(false)
		const [ isSaving, setIsSaving ] = useState(false)
		const [ oldValue, setOldValue ] = useState({})

		useEffect(() => {
			let data = {}
			connection.recordList.forEach(r => {
				data[r.id] = {id: r.id}
			})
			setNewValues(data)
			setOldValue(data)
		}, [connection.recordList])

		useEffect(() => {
			setHasNewValues(oldValue !== newValues)
		}, [newValues, oldValue])

		const discardChanges = () => {
			let data = {}
			connection.recordList.forEach(r => {
				data[r.id] = {id: r.id}
			})
			setNewValues(data)
			setOldValue(data)
			setValidated(false)
		}

		const saveComplete = () => {
			setTimeout(() => {
				setValidated(false)
				setIsSaving(false)
				handleSaveComplete(newValues)
				discardChanges()
				userContext.setLoadingState({...userContext.loadingState, savedBackToAws: false})
			}, 1000)
		}

		const handleSubmit = async () => {
			setValidated(true)
			const form = document.getElementById('details' + connectionKey)
			if (form.checkValidity() === true) {
				setIsSaving(true)
				saveCount = 0
				for await (const key of Object.keys(newValues)) {
					let accountData = {
						...newValues[key],
						id: key
					}
					await saveConnection(accountData, Object.keys(newValues).length, saveComplete)
					if (userContext.showAccountEditor) {
						navigate(pageNames[type])

						userContext.setShowAccountEditor(false)
					}
				}
			}
		}

		const [validated, setValidated] = useState(false);

		const showConnection = () => {
			return !autoLaunched || !connection.recordList.every(a => a.balances.length > 1)
		}

		return (<>
			{(true || showConnection()) && <div className="border mb-4 p-3 bg-interaction soft-corners">
				{!isSaving && <Form noValidate validated={validated} id={"details" + connectionKey}>
					{!connection.isManual && <Form.Group
							as={Row}
							className="mb-3 align-items-center"
							controlId="connectionDetails">
						<Col xs={7}>
							<Form.Label>
								{connection.connection.type != 'zoopla' && <h4>
									<Image src={connection.connectionDetails.iconUrl} height="30px" /> {connection.connectionDetails.friendlyName}
								</h4>}
								{connection.connection.type == 'zoopla' && <h4>
									<House style={{height: "30px"}} /> Property
								</h4>}
							</Form.Label>
						</Col>
						<Col xs={5} className="d-flex justify-content-end text-end">
							{(new Date(connection.connection.expiresAt) < new Date()) && <>
								{/* EXPIRED */}
								<Button variant="danger" className="" onClick={() => generateUrlReconsent(true, connection.connection.id)}>Authorisation Required</Button>
							</>}
							{(new Date(connection.connection.expiresAt) > new Date()) && <>
								{/* STILL VALID */}
								<Button variant="success" className="" onClick={() => generateUrlReconsent(false, connection.connection.id)}>Connection OK</Button>
							</>}
						</Col>
					</Form.Group>}

					{connection.recordList.map((a,i) => (<div key={a.id}>
						{(type.includes('pension')) && <PensionDetails account={a} isManual={connection.isManual} newPensionValues={newValues} setNewPensionValues={setNewValues} />}
						{(type.includes('cash:current')) && <CashAccountDetails account={a} isManual={connection.isManual} newCashAccountValues={newValues} setNewCashAccountValues={setNewValues} />}
						{(type.includes('savings')) && <CashAccountDetails account={a} isManual={connection.isManual} newCashAccountValues={newValues} setNewCashAccountValues={setNewValues} />}
						{(type.includes('card')) && <CardDetails account={a} isManual={connection.isManual} newCardValues={newValues} setNewCardValues={setNewValues} />}
						{(type.includes('properties:residential')) && <PropertyDetails account={a} isManual={connection.isManual} newPropertyValues={newValues} setNewPropertyValues={setNewValues} />}
						{(type.includes('mortgage:repayment')) && <MortgageDetails account={a} isManual={connection.isManual} newMortgageValues={newValues} setNewMortgageValues={setNewValues} />}
						{i < (connection.recordList.length - 1) && <hr />}
					</div>))}

					{(hasNewValues || !connection.recordList[0].hasManualInput) && <Row>
						<Col className="text-center">
							{!autoLaunched && <Button style={{marginRight: "0.5rem"}} variant="secondary" onClick={discardChanges}>
								Discard
							</Button>}
							{connection.recordList[0].hasManualInput && <>
								<Button style={{marginLeft: "0.5rem"}} variant="primary" disabled={isSaving || userContext.user.isDemo} onClick={!isSaving ? handleSubmit : null}>
									{isSaving ? 'Saving...' : (userContext.user.isDemo ? 'Save (Locked on demo)' : 'Save')}
								</Button>
							</>}
							{!connection.recordList[0].hasManualInput && <>
								<Button style={{marginLeft: "0.5rem"}} variant="primary" disabled={isSaving || userContext.user.isDemo} onClick={!isSaving ? handleSubmit : null}>
									{isSaving ? 'Confirming...' : (userContext.user.isDemo ? 'Save (Locked on demo)' : 'Confirm Details')}
								</Button>
							</>}
						</Col>
					</Row>}
				</Form>}
				{isSaving && <div className="text-center position-relative">
					<div className="mt-2">
						<Spinner className="center" animation="border" role="status">
							<span className="visually-hidden">Saving..</span>
						</Spinner>
					</div>
					<div className="mt-3">
						Saving..
					</div>
				</div>}
			</div>}
		</>)
	}

	const CardDetails = ({ account, isManual, newCardValues, setNewCardValues }) => {

		const handleOnChange = (e) => {
			let {name, value} = e.target
			setNewCardValues(oldCardValues => (
				{
					...oldCardValues,
					[account.id]: {
						...oldCardValues[account.id],
						[name]: value
					}
				}
			))
		}

		const getDisplay = (variable) => {
			const newRecord = newCardValues[account.id]
			if (newRecord != null){
				let newValue = newCardValues[account.id][variable]
				if (newValue != null) return newValue
			}
			return account[variable]
		}

		return (<>
			<Form.Group
					as={Row}
					className="mb-3 align-items-center"
					controlId="accountName">
				<Col sm={4}>
					<Form.Label>
						{typeNames[account.type]} Name
					</Form.Label>
				</Col>
				<Col sm={8}>
					<Form.Control
							className="bg-white"
							placeholder=""
							name="accountName"
							onChange={handleOnChange}
							value={getDisplay('accountName')} />
							{/* Change the Conditions here */}
				</Col>
			</Form.Group>
			<Form.Group
					as={Row}
					className=""
					controlId="accountBalances">
				<Col sm={4}>
					<Form.Label>
						{typeNames[account.type]} Balances
					</Form.Label>
				</Col>
				<Col sm={8}>
					<div>
						{account.balances.sort((a,b) => a.date < b.date ? 1 : -1).map((b,i) => {
							if (i < 1){
								return <Row key={"balance_" + b.date + b.amount.value + i} className="mb-3">
									<InputGroup as={Col}>
										<InputGroup.Text>£</InputGroup.Text>
										<Form.Control
												readOnly
												aria-label="Amount (to the nearest pound)"
												value={currencyFormatter(-1 * b.amount.value, 2, true)}
												name={"balances_value_" + i}
												className="text-end" />
										<Form.Control
												readOnly
												type="date"
												value={b.date}
												name={"balances_date_" + i}
												className="" />
									</InputGroup>
								</Row>
							} else { return null}
						})}
					</div>
				</Col>
			</Form.Group>
		</>)
	}

	const CashAccountDetails = ({ account, isManual, newCashAccountValues, setNewCashAccountValues }) => {

		const handleOnChange = (e) => {
			let {name, value} = e.target
			setNewCashAccountValues(oldCashAccountValues => (
				{
					...oldCashAccountValues,
					[account.id]: {
						...oldCashAccountValues[account.id],
						[name]: value
					}
				}
			))
		}

		const getDisplay = (variable) => {
			const newRecord = newCashAccountValues[account.id]
			if (newRecord != null){
				let newValue = newCashAccountValues[account.id][variable]
				if (newValue != null) return newValue
			}
			return account[variable]
		}

		return (<>
			<Form.Group
					as={Row}
					className="mb-3 align-items-center"
					controlId="accountName">
				<Col sm={4}>
					<Form.Label>
						{typeNames[account.type]} Name
					</Form.Label>
				</Col>
				<Col sm={8}>
					<Form.Control
							className="bg-white"
							placeholder=""
							name="accountName"
							onChange={handleOnChange}
							value={getDisplay('accountName')} />
				</Col>
			</Form.Group>
			<Form.Group
					as={Row}
					className="mb-3 align-items-center"
					controlId="type">
				<Col sm={4}>
					<Form.Label>
						Account Type
					</Form.Label>
				</Col>
				<Col sm={8}>
					<Form.Select
							className="bg-white"
							value={getDisplay('type')}
							name="type"
							onChange={handleOnChange}>
						{typeOptions[account.type].map((t,i) => (
							<option key={"optionType" + i} value={t}>{typeNames[t]}</option>
						))}
					</Form.Select>
				</Col>
			</Form.Group>
			<Form.Group
					as={Row}
					className=""
					controlId="accountBalances">
				<Col sm={4}>
					<Form.Label>
						{typeNames[account.type]} Balances
					</Form.Label>
				</Col>
				<Col sm={8}>
					<div>
						{account.balances?.sort((a,b) => a.date < b.date ? 1 : -1).map((b,i) => {
							if (i < 1){
								return <Row key={"balance_" + b.date + b.amount.value + i} className="mb-3">
									<InputGroup as={Col}>
										<InputGroup.Text>£</InputGroup.Text>
										<Form.Control
												readOnly
												aria-label="Amount (to the nearest pound)"
												value={currencyFormatter(b.amount.value, 2, true)}
												name={"balances_value_" + i}
												className="text-end" />
										<Form.Control
												readOnly
												type="date"
												value={b.date}
												name={"balances_date_" + i}
												className="" />
									</InputGroup>
								</Row>
							} else { return null}
						})}
					</div>
				</Col>
			</Form.Group>
		</>)
	}

	const PensionDetails = ({ account, isManual, newPensionValues, setNewPensionValues }) => {

		const [ showNewBalance, setShowNewBalance ] = useState(false)

		const handleOnChange = (e) => {
			let {name, value} = e.target
			setNewPensionValues(oldPensionValues => (
				{
					...oldPensionValues,
					[account.id]: {
						...oldPensionValues[account.id],
						[name]: value
					}
				}
			))
		}

		const getDisplay = (variable) => {
			const newRecord = newPensionValues[account.id]
			if (newRecord != null){
				let newValue = newPensionValues[account.id][variable]
				if (newValue != null) return newValue
			}
			return account[variable]
		}

		return (<>
			<Form.Group
					as={Row}
					className="mb-3 align-items-center"
					controlId="accountName">
				<Col sm={4}>
					<Form.Label>
						{typeNames[account.type]} Name
					</Form.Label>
				</Col>
				<Col sm={8}>
					<Form.Control
							className="bg-white"
							placeholder=""
							name="accountName"
							onChange={handleOnChange}
							value={getDisplay('accountName')}
							required />
				</Col>
			</Form.Group>
			<Form.Group
					as={Row}
					className="mb-3 align-items-center"
					controlId="providerName">
				<Col sm={4}>
					<Form.Label>
						Provider Name
					</Form.Label>
				</Col>
				<Col sm={8}>
					<Form.Control
							className="bg-white"
							placeholder=""
							name="providerName"
							onChange={handleOnChange}
							value={getDisplay('providerName')}
							required />
				</Col>
			</Form.Group>
			<Form.Group
					as={Row}
					className=""
					controlId="accountBalances">
				<Col sm={4}>
					<Form.Label>
						{typeNames[account.type]} Balances
					</Form.Label>
				</Col>
				<Col sm={8}>
					<div>
						{isManual && !showNewBalance && <Row>
							<Button onClick={() => setShowNewBalance(true)} className="text-center" variant="link">Add a new balance</Button>
						</Row>}
						{isManual && showNewBalance && <Row className="mb-3">
							<InputGroup as={Col}>
								<InputGroup.Text>£</InputGroup.Text>
								<Form.Control
										aria-label="Amount (to the nearest pound)"
										value={newPensionValues[account.id]?.newBalanceValue}
										name={"newBalanceValue"}
										onChange={handleOnChange}
										className="bg-white text-end"
										required />
								<InputGroup.Text>.00</InputGroup.Text>
								<Form.Control
										type="date"
										value={newPensionValues[account.id]?.newBalanceDate}
										name={"newBalanceDate"}
										onChange={handleOnChange}
										className="bg-white"
										required
										max={new Date().toISOString().split('T')[0]} />
							</InputGroup>
						</Row>}
						{account.balances?.sort((a,b) => a.date < b.date ? 1 : -1).map((b,i) => (
							<Row key={"balance_" + b.date + b.amount.value + i} className="mb-3">
								<InputGroup as={Col}>
									<InputGroup.Text>£</InputGroup.Text>
									<Form.Control
											readOnly
											aria-label="Amount (to the nearest pound)"
											value={(b.amount.value / 100)}
											name={"balances_value_" + i}
											className="text-end" />
									<InputGroup.Text>.00</InputGroup.Text>
									<Form.Control
											readOnly
											type="date"
											value={b.date}
											name={"balances_date_" + i}
											className="" />
								</InputGroup>
							</Row>
						))}
					</div>
				</Col>
			</Form.Group>
		</>)
	}

	const PropertyDetails = ({ account, isManual, newPropertyValues, setNewPropertyValues }) => {

		const [ showNewBalance, setShowNewBalance ] = useState(autoLaunched && account.balances.length <= 1)

		const handleOnChange = (e) => {
			let {name, value} = e.target
			setNewPropertyValues(oldPropertyValues => (
				{
					...oldPropertyValues,
					[account.id]: {
						...oldPropertyValues[account.id],
						[name]: value
					}
				}
			))
		}

		const handleNewBalanceDate = (e) => {
			setNewPropertyValues(oldPropertyValues => (
				{
					...oldPropertyValues,
					[account.id]: {
						...oldPropertyValues[account.id],
						newBalanceDate: e
					}
				}
			))
		}

		const getDisplay = (variable) => {
			const newRecord = newPropertyValues[account.id]
			if (newRecord != null){
				let newValue = newPropertyValues[account.id][variable]
				if (newValue != null) return newValue
			}
			if (variable === 'postcode'){
				return account.details[variable] ? account.details[variable] : 0
			}
			return account[variable]
		}

		return (<>
			<Form.Group
					as={Row}
					className="mb-3 align-items-center"
					controlId="accountName">
				<Col sm={4}>
					<Form.Label>
						Name
					</Form.Label>
				</Col>
				<Col sm={8}>
					<Form.Control
							className="bg-white"
							placeholder=""
							name="accountName"
							onChange={handleOnChange}
							value={getDisplay('accountName')} />
				</Col>
			</Form.Group>
			<Form.Group
					as={Row}
					className="mb-3 align-items-center"
					controlId="postcode">
				<Col sm={4}>
					<Form.Label>
						Post Code
					</Form.Label>
				</Col>
				<Col sm={8}>
					<Form.Control
							className={!autoLaunched ? "bg-white" : ""}
							placeholder=""
							name="postcode"
							onChange={handleOnChange}
							value={getDisplay('postcode')}
							readOnly={autoLaunched} />
				</Col>
			</Form.Group>
			{!autoLaunched && <Form.Group
					as={Row}
					className=""
					controlId="accountBalances">
				<Col sm={4}>
					<Form.Label>
						{/* account.balances.length <= 1 */}
						Values
					</Form.Label>
				</Col>
				<Col sm={8}>
					<div>
						{!autoLaunched && !showNewBalance && <Row>
							<Button onClick={() => setShowNewBalance(true)} className="text-center" variant="link">Add a new balance</Button>
						</Row>}
						{showNewBalance && <Row className="mb-3">
							<InputGroup as={Col} hasValidation>
								<InputGroup.Text>£</InputGroup.Text>
								<Form.Control
										aria-label="Amount (to the nearest pound)"
										value={newPropertyValues[account.id]?.newBalanceValue}
										name={"newBalanceValue"}
										onChange={handleOnChange}
										className="bg-white text-end"
										required />
								<InputGroup.Text>.00</InputGroup.Text>
								<MonthYearPicker allowPast required onChange={handleNewBalanceDate} />
								{/* <Form.Control
										type="date"
										value={newPropertyValues[account.id]?.newBalanceDate}
										name={"newBalanceDate"}
										onChange={handleOnChange}
										className="bg-white" /> */}
							</InputGroup>
						</Row>}
						{account.balances.sort((a,b) => a.date < b.date ? 1 : -1).map((b,i) => (
							<Row key={"balance_" + b.date + b.amount.value + i} className="mb-3">
								<InputGroup as={Col}>
									<InputGroup.Text>£</InputGroup.Text>
									<Form.Control
											readOnly
											aria-label="Amount (to the nearest pound)"
											value={(b.amount.value / 100)}
											name={"balances_value_" + i}
											className="text-end" />
									<InputGroup.Text>.00</InputGroup.Text>

									<MonthYearPicker
											readOnly
											month={new Date(b.date).getMonth()}
											year={new Date(b.date).getFullYear()} />
									{/* <Form.Control
											readOnly
											type="date"
											value={b.date}
											name={"balances_date_" + i}
											className="" /> */}
								</InputGroup>
							</Row>
						))}
					</div>
				</Col>
			</Form.Group>}
			{autoLaunched && <Form.Group as={Row} className="" controlId="currentBalance">
				{/* <Col sm={4}>
					<Form.Label>
						Current Value
					</Form.Label>
				</Col>
				<Col sm={8}>
					<Row key={"balance_latest"} className="mb-3">
						<InputGroup as={Col}>
							<InputGroup.Text>£</InputGroup.Text>
							<Form.Control
									readOnly
									aria-label="Amount (to the nearest pound)"
									value={(account.balance.amount.value / 100)}
									name={"balances_value_latest"}
									className="text-end" />
							<InputGroup.Text>.00</InputGroup.Text>
							<Form.Control
									readOnly
									type="date"
									value={account.balance.date}
									name={"balances_date_latest"}
									className="" />
						</InputGroup>
					</Row>
				</Col> */}
				<Col sm={4}>
					<Form.Label>
						Purchase Price & Date
					</Form.Label>
				</Col>
				<Col sm={8}>
					<Row key={"balance_purchase"} className="mb-3">
					<InputGroup as={Col}>
							<InputGroup.Text>£</InputGroup.Text>
							<Form.Control
									aria-label="Amount (to the nearest pound)"
									value={newPropertyValues[account.id]?.newBalanceValue}
									name={"newBalanceValue"}
									onChange={handleOnChange}
									className="bg-white text-end"
									required />
							<InputGroup.Text>.00</InputGroup.Text>
							<MonthYearPicker allowPast required onChange={handleNewBalanceDate} />
							{/* <Form.Control
									type="date"
									value={newPropertyValues[account.id]?.newBalanceDate}
									name={"newBalanceDate"}
									onChange={handleOnChange}
									className="bg-white"
									required /> */}
						</InputGroup>
					</Row>
				</Col>
			</Form.Group>}
		</>)
	}

	const MortgageDetails = ({ account, isManual, newMortgageValues, setNewMortgageValues }) => {

		const [ showNewBalance, setShowNewBalance ] = useState(false)

		const handleOnChange = (e) => {
			let {name, value} = e.target
			if (name === 'interestType_v' || name === 'interestType_f'){
				value = (name === 'interestType_v') ? 'variable' : 'fixed'
				name = 'interestType'
			}
			if (name === 'term'){
				setNewMortgageValues(oldMortgageValues => (
					{
						...oldMortgageValues,
						[account.id]: {
							...oldMortgageValues[account.id],
							[name]: value * 12,
							'endDate': new Date(addMonths(new Date(), value * 12)).toISOString().split('T')[0]
						}
					}
				))
			} else if (name === 'value' || name === 'monthlyRepayment'){
				setNewMortgageValues(oldMortgageValues => (
					{
						...oldMortgageValues,
						[account.id]: {
							...oldMortgageValues[account.id],
							[name]: Math.abs(value)
						}
					}
				))
			} else {
				setNewMortgageValues(oldMortgageValues => (
					{
						...oldMortgageValues,
						[account.id]: {
							...oldMortgageValues[account.id],
							[name]: value
						}
					}
				))
			}
		}
		const addMonths = (date, months) => {
			var d = date.getDate();
			date.setMonth(date.getMonth() + +months);
			if (date.getDate() !== d) {
			  date.setDate(0);
			}
			return date;
		}

		const getDisplay = (variable) => {
			const newRecord = newMortgageValues[account.id]
			if (newRecord != null){
				let newValue = newMortgageValues[account.id][variable]
				if (newValue != null) return newValue
			}
			if (variable === 'monthlyRepayment'){
				return account.details[variable] && account.details[variable] / 100
			}
			if (variable === 'term'){
				return account.details[variable] ? account.details[variable] : 0
			}
			if (variable === 'APR'){
				return account.details[variable] ? account.details[variable] : 0
			}
			return account[variable]
		}

		return (<>
			<Form.Group
					as={Row}
					className="mb-3 align-items-center"
					controlId="accountName">
				<Col sm={4}>
					<Form.Label>
						{typeNames[account.type]} Name
					</Form.Label>
				</Col>
				<Col sm={8}>
					<Form.Control
							className="bg-white"
							placeholder=""
							name="accountName"
							onChange={handleOnChange}
							value={getDisplay('accountName')} />
				</Col>
			</Form.Group>
			<Form.Group
					as={Row}
					className="mb-3 align-items-center"
					controlId="providerName">
				<Col sm={4}>
					<Form.Label>
						Provider Name
					</Form.Label>
				</Col>
				<Col sm={8}>
					<Form.Control
							className="bg-white"
							placeholder=""
							name="providerName"
							onChange={handleOnChange}
							value={getDisplay('providerName')} />
				</Col>
			</Form.Group>
			<Form.Group
					as={Row}
					className=""
					controlId="accountBalances">
				<Col sm={4}>
					<Form.Label>
						{typeNames[account.type]} Balances
					</Form.Label>
				</Col>
				<Col sm={8}>
					<div>
						{isManual && !showNewBalance && <Row>
							<Button onClick={() => setShowNewBalance(true)} className="text-center" variant="link">Add a new balance</Button>
						</Row>}
						{isManual && showNewBalance && <Row className="mb-3">
							<InputGroup as={Col}>
								<InputGroup.Text>£</InputGroup.Text>
								<Form.Control
										aria-label="Amount (to the nearest pound)"
										value={newMortgageValues[account.id]?.newBalanceValue}
										name={"newBalanceValue"}
										onChange={handleOnChange}
										className="bg-white text-end" />
								<InputGroup.Text>.00</InputGroup.Text>
								<Form.Control
										type="date"
										value={newMortgageValues[account.id]?.newBalanceDate}
										name={"newBalanceDate"}
										onChange={handleOnChange}
										className="bg-white" />
							</InputGroup>
						</Row>}
						{account.balances?.sort((a,b) => a.date < b.date ? 1 : -1).map((b,i) => {
							return <div key={"balance_" + b.date + b.amount.value + i}>{b.amount.value !== 0 && <Row className="mb-3">
								<InputGroup as={Col}>
									<InputGroup.Text>£</InputGroup.Text>
									<Form.Control
											readOnly
											aria-label="Amount (to the nearest pound)"
											value={Math.floor(-1 * (b.amount.value / 100))}
											name={"balances_value_" + i}
											className="text-end" />
									<InputGroup.Text>.00</InputGroup.Text>
									<Form.Control
											readOnly
											type="date"
											value={b.date}
											name={"balances_date_" + i}
											className="" />
								</InputGroup>
							</Row>}</div>
						})}
					</div>
				</Col>
			</Form.Group>
			<Accordion  defaultActiveKey={!account.hasManualInput ? "0" : "1"} className="mb-3">
				<Accordion.Item eventKey="0">
					<Accordion.Header>More Details</Accordion.Header>
					<Accordion.Body>
						<Row>
							<Form.Group as={Col} className="mb-3" controlId="mortgagePayment" sm={6}>
								<Form.Label>
									Current Monthly Payment
								</Form.Label>
								<InputGroup >
									<InputGroup.Text>£</InputGroup.Text>
									<Form.Control
											required aria-label="Amount (to the nearest pound)"
											name="monthlyRepayment"
											onChange={handleOnChange}
											value={getDisplay('monthlyRepayment')}
											pattern='^\d*$'
											className="bg-white" />
									<InputGroup.Text>.00</InputGroup.Text>
								</InputGroup>
							</Form.Group>
							<Form.Group as={Col} className="mb-3" controlId="mortgageAPR" sm={6}>
								<Form.Label>
									Current APR
								</Form.Label>
								<InputGroup>
									<Form.Control
											name="APR"
											onChange={handleOnChange}
											value={getDisplay('APR')}
											pattern='^\d*\.?\d*$'
											className="bg-white"/>
									<InputGroup.Text>%</InputGroup.Text>
								</InputGroup>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} className="mb-3" controlId="mortgageTerm" sm={6}>
								<Form.Label>
									Remaining Term (years from today)
								</Form.Label>
								<Form.Control
										placeholder="Mortgage Term"
										name="term"
										onChange={handleOnChange}
										value={getDisplay('term')/12}
										pattern='^\d*$'
										className="bg-white" />
							</Form.Group>
							{/* <Form.Group as={Col} className="mb-3" controlId="mortgageEndDate" sm={6}>
								<Form.Label>
									End Date
								</Form.Label>
								<Form.Control
										type="date"
										name="endDate"
										onChange={handleOnChange}
										value={newMortgageValues[account.id]?.endDate || account.details.endDate}
										className="bg-white" />
							</Form.Group> */}
						</Row>
						<Form.Group as={Row} className="mb-3">
							<Form.Label>
								Interest Type
							</Form.Label>
							<ButtonGroup>
								<ToggleButton
										id={"mortgageInterestType:fixed:" + account.id}
										type="radio"
										variant="outline-primary"
										name="interestType_f"
										checked={(getDisplay('interestType')) === 'fixed'}
										onChange={handleOnChange} >
									Fixed / Tracker
								</ToggleButton>
								<ToggleButton
										id={"mortgageInterestType:variable:" + account.id}
										type="radio"
										variant="outline-primary"
										name="interestType_v"
										checked={(getDisplay('interestType')) === 'variable' || getDisplay('interestType') == null}
										onChange={handleOnChange} >
									Variable
								</ToggleButton>
							</ButtonGroup>
						</Form.Group>
						{(getDisplay('interestType') === 'fixed' && getDisplay('interestType') != null) && <>
							<Form.Group required as={Col} className="mb-3" controlId="mortgageFixedEndDate" sm={6}>
								<Form.Label>
									When does the Fixed/Tracker period end?
								</Form.Label>
								<Form.Control
										type="date"
										name="fixedDate"
										onChange={handleOnChange}
										value={getDisplay('fixedDate')}
										className="bg-white" />
							</Form.Group>
						</>}
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</>)
	}

	let connections = []
	const filteredAccounts = userContext.accounts.filter(a => type.includes(a.type))
	/*
	{
		isManual: T/F
		recordList: []
		connection: {}
	}
	*/
	userContext.userConnections.forEach(uc => {
		if (allowedConnections.length === 0 || allowedConnections.includes(uc.id)){
			connections.push({
				isManual: false,
				recordList: [],
				connection: uc,
				connectionDetails: null
			})
		}
	})
	filteredAccounts.forEach(a => {
		let found = false
		connections.forEach(c => {
			if (c.connection?.accountIds.includes(a.id) && (allowedConnections.length === 0 || allowedConnections.includes(a.id))){
				c.recordList.push(a)
				c.connectionDetails = a.connection
				found = true
			}
		})
		if (!found && allowedConnections.length === 0){
			connections.push({
				isManual: true,
				recordList: [a],
				connection: null,
				connectionDetails: a.connection
			})
		}
	})
	connections = connections.filter(c => c.recordList.length > 0)

	const handleModalClose = () => {
		setShowQRModal(false)
	}

	return (<div className="">
		{userContext.loadingState.loadComplete && connections.map((c,i) => (
			<div key={"connection" + i}>
				<Connection connectionKey={i} connection={c} type={c.recordList[0].type} />
			</div>
		))}
		{!userContext.loadingState.loadComplete && <>
			<div className="text-center position-relative">
				<div className="mt-2">
					<Spinner className="center" animation="border" role="status">
						<span className="visually-hidden">Loading..</span>
					</Spinner>
				</div>
				<div className="mt-3">
					Fetching Account Details..
				</div>
			</div>
		</>}
		<Modal show={showQRModal} centered>
			{!userContext.user.isDemo && authUrl && <>
				<Modal.Header closeButton onHide={handleModalClose}>
					<Modal.Title>Connect</Modal.Title>
				</Modal.Header>
			</>}
			<Modal.Body className="text-center">
				{userContext.user.isDemo && <div>
					Updating accounts is unavailable on a demo account
				</div>}
				{!userContext.user.isDemo && authUrl && <>
					<div>
						{authorizationMessage}
					</div>
					<div>
						To reauthorize your account, scan this QR code
					</div>
					<div>
						<QRCode value={authUrl} />
					</div>
					<div>
						{// eslint-disable-next-line react/jsx-no-target-blank
						<a href={authUrl} target="_blank" rel="opener">Or click here to continue in your browser</a>}
					</div>
				</>}
				{!userContext.user.isDemo && !authUrl && <>
					<div className="text-center position-relative">
						<div className="mt-2">
							<Spinner className="center" animation="border" role="status">
								<span className="visually-hidden">Generating Link..</span>
							</Spinner>
						</div>
						<div className="mt-3">
							Generating Link..
						</div>
					</div>
				</>}
			</Modal.Body>
			{!userContext.user.isDemo && authUrl && <>
				<Modal.Footer>
					<Button onClick={handleModalClose}>Finished</Button>
				</Modal.Footer>
			</>}
			{userContext.user.isDemo && <>
				<Modal.Footer>
					<Button onClick={handleModalClose}>Close</Button>
				</Modal.Footer>
			</>}
		</Modal>
	</div>)
}

export default ConnectionDetails;