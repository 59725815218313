const axios = require('axios');

const getBalance = async function(token, moneyhubId, accountId, successCallback){
	const requestConfig = {
		headers: {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'x-api-key': process.env.REACT_APP_AWS_API_KEY,
			'Authorization': token,
		}
	}
	const requestBody = {
		userId: moneyhubId,
		accountId: accountId
	}
	await axios.post(process.env.REACT_APP_AWS_DATA_ENDPOINT + '/mh/balance', requestBody, requestConfig).then(response => {
		successCallback(response.data.balances.data)
		return;
	}).catch(error => {
		console.error('Error (FE/s/d/getBalance): ', error, accountId);
		return;
	})
}

export default getBalance;