import React, {useEffect, useRef, useState} from "react";

import PublicLayout from "../../layouts/PublicLayout";

import { exchangeCodeForToken } from "../../service/DataService";

import { Spinner, Button } from 'react-bootstrap';

const MoneyHubHandler = () => {

	const [ isWorking, setIsWorking ] = useState(true)
	const [ errorMessage, setErrorMessage ] = useState('')

	const runCodeExchange = useRef(false);
	useEffect(() => {
		if (runCodeExchange.current) return;
		const getUrlParams = (search) =>{
			let hashes = search.slice(search.indexOf('?') + 1).split('&')
			return hashes.reduce((params, hash) => {
				let [key, val] = hash.split('=')
				return Object.assign(params, {[key]: decodeURIComponent(val)})
			}, {})
		}
		const finalizeExchange = async (code, id_token, state) => {
			try {
				await exchangeCodeForToken(
					code,
					id_token,
					state,
					() => {
						setIsWorking(false);
					},
					(e) => {
						console.error(e)
						setIsWorking(false);
					}
				)
			} catch (error) {
				console.error(error);
			}
		}
		const params = getUrlParams(window.location.hash)
		const mhCode = params['#code']
		const mhIdToken = params['id_token']
		const mhState = params['state']
		if (mhCode && mhIdToken && mhState) {
			finalizeExchange(mhCode, mhIdToken, mhState)
			runCodeExchange.current = true
		}
		const error = params['#error']
		const errorDescription = params['error_description']
		if (error) {
			console.error(error, errorDescription);
			setErrorMessage('An error has occurred: ' + error + ': ' + errorDescription + '. Please try again later.')
			setIsWorking(false)
		} else {
			setErrorMessage('')
			setIsWorking(false)
		}
	}, [])

	const handleCompleteClick = function(){
		window.close();
	}

	return (
		<PublicLayout>
			{isWorking && <div className="text-center position-relative">
				<div className="mt-2">
					<Spinner className="center" animation="border" role="status">
						<span className="visually-hidden">Finalising Connection</span>
					</Spinner>
				</div>
				<div className="mt-3">
					Finalising Connection..
				</div>
			</div>}
			{!isWorking && <div className="text-center position-relative">
				<div className="mt-3">
					{errorMessage === '' &&
						<Button variant="primary" onClick={handleCompleteClick}>Connection Complete</Button>
					}
					{errorMessage !== '' &&<>
						<p>{errorMessage}</p>
						<Button variant="primary" onClick={handleCompleteClick}>Close</Button>
					</>}
				</div>
			</div>}
		</PublicLayout>
	)
}

export default MoneyHubHandler;