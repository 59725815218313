const housePriceIndex = {
	1980: { base: 12.71 },
	1981: { base: 2.53 },
	1982: { base: 5.88 },
	1983: { base: 11.91 },
	1984: { base: 9.6 },
	1985: { base: 9.49 },
	1986: { base: 14.67 },
	1987: { base: 19.47 },
	1988: { base: 32.87 },
	1989: { base: 7.37 },
	1990: { base: -2.74 },
	1991: { base: -0.8 },
	1992: { base: -7.35 },
	1993: { base: 1.53 },
	1994: { base: 3.51 },
	1995: { base: -0.29 },
	1996: { base: 7.39 },
	1997: { base: 7.88 },
	1998: { base: 12.17 },
	1999: { base: 13.85 },
	2000: { base: 13.48 },
	2001: { base: 4.63 },
	2002: { base: 28.36 },
	2003: { base: 8.29 },
	2004: { base: 10.7 },
	2005: { base: 6.29 },
	2006: { base: 10.37 },
	2007: { base: 7 },
	2008: { base: -14.93 },
	2009: { base: 4.43 },
	2010: { base: 0.37 },
	2011: { base: -0.98 },
	2012: { base: 1.07 },
	2013: { base: 5.41 },
	2014: { base: 7.7 },
	2015: { base: 6.91 },
	2016: { base: 5.16 },
	2017: { base: 4.56 },
	2018: { base: 1.95 },
	2019: { base: 0.9 },
	2020: { base: 6.99 },
	2021: { base: 8.12 },
	2022: { base: 7.9 },
	2023: { base: -1.6 },
	2024: { low: -3, base: -1.5, high: 2.2 },
	2025: { low: 1, base: 2.3, high: 3.5 },
	2026: { low: 3, base: 3.75, high: 4.5 },
	2027: { low: 3.5, base: 4.5, high: 5 },
	2028: { low: 2, base: 3, high: 4.5 },
	2029: { low: 1.68, base: 2.24, high: 2.8 },
	2030: { low: 1.68, base: 2.24, high: 2.8 },
	2031: { low: 1.51, base: 2.01, high: 2.52 },
	2032: { low: 1.51, base: 2.01, high: 2.52 },
	2033: { low: 1.51, base: 2.01, high: 2.52 },
	2034: { low: 1.51, base: 2.01, high: 2.52 },
	2035: { low: 1.51, base: 2.01, high: 2.52 },
	2036: { low: 1.37, base: 1.83, high: 2.29 },
	2037: { low: 1.37, base: 1.83, high: 2.29 },
	2038: { low: 1.37, base: 1.83, high: 2.29 },
	2039: { low: 1.37, base: 1.83, high: 2.29 },
	2040: { low: 1.37, base: 1.83, high: 2.29 },
	2041: { low: 1.26, base: 1.68, high: 2.1 },
	2042: { low: 1.26, base: 1.68, high: 2.1 },
	2043: { low: 1.26, base: 1.68, high: 2.1 },
	2044: { low: 1.26, base: 1.68, high: 2.1 },
	2045: { low: 1.26, base: 1.68, high: 2.1 },
	2046: { low: 1.16, base: 1.55, high: 1.93 },
	2047: { low: 1.16, base: 1.55, high: 1.93 },
	2048: { low: 1.16, base: 1.55, high: 1.93 },
	2049: { low: 1.16, base: 1.55, high: 1.93 },
	2050: { low: 1.16, base: 1.55, high: 1.93 },
	2051: { low: 1.13, base: 1.5, high: 1.88 },
	2052: { low: 1.13, base: 1.5, high: 1.88 },
	2053: { low: 1.13, base: 1.5, high: 1.88 },
	2054: { low: 1.13, base: 1.5, high: 1.88 },
	2055: { low: 1.13, base: 1.5, high: 1.88 },
	2056: { low: 1.13, base: 1.5, high: 1.88 },
	2057: { low: 1.13, base: 1.5, high: 1.88 },
	2058: { low: 1.13, base: 1.5, high: 1.88 },
	2059: { low: 1.13, base: 1.5, high: 1.88 },
	2060: { low: 1.13, base: 1.5, high: 1.88 },
	2061: { low: 1.13, base: 1.5, high: 1.88 },
	2062: { low: 1.13, base: 1.5, high: 1.88 },
	2063: { low: 1.13, base: 1.5, high: 1.88 },
	2064: { low: 1.13, base: 1.5, high: 1.88 },
	2065: { low: 1.13, base: 1.5, high: 1.88 },
	2066: { low: 1.13, base: 1.5, high: 1.88 },
	2067: { low: 1.13, base: 1.5, high: 1.88 },
	2068: { low: 1.13, base: 1.5, high: 1.88 },
	2069: { low: 1.13, base: 1.5, high: 1.88 },
	2070: { low: 1.13, base: 1.5, high: 1.88 },
	2071: { low: 1.13, base: 1.5, high: 1.88 },
	2072: { low: 1.13, base: 1.5, high: 1.88 },
	2073: { low: 1.13, base: 1.5, high: 1.88 },
	2074: { low: 1.13, base: 1.5, high: 1.88 },
	2075: { low: 1.13, base: 1.5, high: 1.88 },
	2076: { low: 1.13, base: 1.5, high: 1.88 },
	2077: { low: 1.13, base: 1.5, high: 1.88 },
	2078: { low: 1.13, base: 1.5, high: 1.88 },
	2079: { low: 1.13, base: 1.5, high: 1.88 },
	2080: { low: 1.13, base: 1.5, high: 1.88 },
	2081: { low: 1.13, base: 1.5, high: 1.88 },
	2082: { low: 1.13, base: 1.5, high: 1.88 },
	2083: { low: 1.13, base: 1.5, high: 1.88 },
	2084: { low: 1.13, base: 1.5, high: 1.88 },
	2085: { low: 1.13, base: 1.5, high: 1.88 },
	2086: { low: 1.13, base: 1.5, high: 1.88 },
	2087: { low: 1.13, base: 1.5, high: 1.88 },
	2088: { low: 1.13, base: 1.5, high: 1.88 },
	2089: { low: 1.13, base: 1.5, high: 1.88 },
	2090: { low: 1.13, base: 1.5, high: 1.88 },
	2091: { low: 1.13, base: 1.5, high: 1.88 },
	2092: { low: 1.13, base: 1.5, high: 1.88 },
	2093: { low: 1.13, base: 1.5, high: 1.88 },
	2094: { low: 1.13, base: 1.5, high: 1.88 },
	2095: { low: 1.13, base: 1.5, high: 1.88 },
	2096: { low: 1.13, base: 1.5, high: 1.88 },
	2097: { low: 1.13, base: 1.5, high: 1.88 },
	2098: { low: 1.13, base: 1.5, high: 1.88 },
	2099: { low: 1.13, base: 1.5, high: 1.88 }
};

export default housePriceIndex;