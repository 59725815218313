import React, { useState } from "react";

import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { CheckSquare, PlusSquare, DashSquare } from "react-bootstrap-icons";

import currencyFormatter from "../../utils/CurrencyFormatter";
import { SSLogo } from "../components";

import titleCase from '../../utils/TitleCase';

const DisplayIncomeAndExpensesRow = ({categorized, c, cssColor, showSavingColumn = true}) => {

	const formatter = new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'numeric' });
	const formattedDate = (d) => formatter.format(new Date(d));

	const [ showIndividual, setShowIndividual ] = useState(categorized[c].show)
	const toggleShow = () => {
		setShowIndividual(!showIndividual)
	}

	const renderIntraAccountHelp = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			This transaction is between two selected accounts
		</Tooltip>
	)

	return (
		<div key={"row" + categorized[c].data[0].id}>
			{/* Medium and up */}
			{/* <Row className="rowHoverColor d-none d-md-flex" onClick={toggleShow}>
				<Col xs={1} className={"pt-1 hasBorder text-center " + cssColor}>
					{categorized[c].data.every(d => d.isIntraAccount) && <CheckSquare />}
				</Col>
				<Col xs={1} className="pt-1 text-end hasBorder text-muted">
					{categorized[c].data.length > 1 ?
							(<div className="hoverInteract">{showIndividual ?
									<DashSquare /> :
									<PlusSquare />}
							</div>) :
							(<div>{formattedDate(categorized[c].data[0].date)}</div>)
					}
				</Col>
				<Col xs={showSavingColumn ? 6 : 8} className={"pt-1 pb-1 text-muted hasBorder " + (categorized[c].data.length > 1 ? "hoverInteract" : "")}>
					{categorized[c].data.every(d => d.isIntraAccount)
							? <del>{c}</del>
							: categorized[c].data.length > 1 ? <u>{c}</u> : <>{c}</>}
				</Col>
				<Col xs={2} className={"pt-1 text-end hasBorder " + cssColor}>
					{categorized[c].data.every(d => d.isIntraAccount)
							? <del>{currencyFormatter(categorized[c].total, 2, true)}</del>
							: currencyFormatter(categorized[c].total, 2, true)}
				</Col>
				{showSavingColumn && <Col xs={2} className="pt-1 text-center bg-light border-bottom border-light hasBorder text-saving-color pb-2">
					{categorized[c].discountPotential > 0 && (
						<>{currencyFormatter(categorized[c].discountPotential, 2, true)} ({categorized[c].data[0].percentageDiscount}%)</>
					)}
				</Col>}
			</Row> */}
			{/* Hide on medium and up */}
			<Row className={"align-items-center" + (categorized[c].discountPotential > 0 ? " mb-0" : " mb-2")} onClick={toggleShow}>
				<Col md={1} className="d-none d-md-flex pt-1 text-center hasBorder text-muted">
					{categorized[c].data.every(d => d.isIntraAccount) && <OverlayTrigger
									placement="right"
									delay={{ show: 250, hide: 400 }}
									overlay={renderIntraAccountHelp}>
								<CheckSquare />
							</OverlayTrigger>}
				</Col>
				<Col xs={2} md={1} className="text-end hasBorder text-muted">
					{categorized[c].data.length > 1 ?
							(<div className="hoverInteract">{showIndividual ?
									<DashSquare /> :
									<PlusSquare />}
							</div>) :
							(<div>{formattedDate(categorized[c].data[0].date)}</div>)
					}
				</Col>
				<Col xs={7} className={"text-truncate hasBorder text-muted " + (categorized[c].data.length > 1 ? "hoverInteract" : "")}>
					{categorized[c].data.every(d => d.isIntraAccount)
							? <del>{titleCase(c.toLowerCase())}</del>
							: titleCase(c.toLowerCase())}
				</Col>
				<Col xs={3} className={"d-flex justify-content-end text-end hasBorder text-muted " + cssColor}>
					<div>
						{categorized[c].data.every(d => d.isIntraAccount)
								? <del>{currencyFormatter(categorized[c].total, 2, true)}</del>
								: currencyFormatter(categorized[c].total, 2, true)}
					</div>
				</Col>
			</Row>
			{categorized[c].discountPotential > 0 && <Row className="text-saving-color align-items-center">
				<Col xs={9} className="text-end">
					<img style={{maxHeight: "20px", maxWidth: "100%"}} src={categorized[c].transaction.vendorDetails.Logo} alt={categorized[c].transaction.vendorDetails.Name} /> save with <SSLogo size="22" style={{marginTop: "-6px"}} />
				</Col>
				<Col xs={3} className="text-end">
						({currencyFormatter(categorized[c].discountPotential, 2, true)})
						({categorized[c].data[0].percentageDiscount}%)
				</Col>
			</Row>}
			{/* <div className="d-none d-md-block">
				{categorized[c].data.length > 1 && showIndividual &&
					categorized[c].data.map((r,j) => {
						return (
							<Row key={"large" + r.id + j} className="rowHoverColor text-muted">
								<Col xs={1}></Col>
								<Col xs={1} className="pt-1 text-end bg-light hasBorder text-muted">
									{formattedDate(r.date)}
								</Col>
								<Col xs={5} className="pt-1 bg-light hasBorder text-truncate text-muted">
									{r.isIntraAccount
											? <del>{r.shortDescription}</del>
											: r.shortDescription}
								</Col>
								<Col xs={3} className={"pt-1 pb-1 text-end bg-light hasBorder text-muted " + cssColor + "-secondary"}>
									{r.isIntraAccount
											? <del>{currencyFormatter(Math.abs(r.amount.value), 2, true)}</del>
											: currencyFormatter(Math.abs(r.amount.value), 2, true)}
								</Col>
								<Col xs={2} className="text-end bg-light">

								</Col>
							</Row>
						)
					})
				}
			</div> */}
			<div className="mb-2 mt-1">
				{categorized[c].data.length > 1 && showIndividual &&
					categorized[c].data.map((r,j) => {
						return (
							<Row key={"small" + r.id + j} className="align-items-center text-muted">
								<Col xs={2} className="text-end bg-light hasBorder">
									{formattedDate(r.date)}
								</Col>
								<Col xs={6} className="bg-light hasBorder text-truncate">
									{r.isIntraAccount
											? <del>{titleCase(r.shortDescription.toLowerCase())}</del>
											: titleCase(r.shortDescription.toLowerCase())}
								</Col>
								<Col xs={4} className={"text-end bg-light hasBorder " + cssColor + "-secondary"}>
									{r.isIntraAccount
											? <del>{currencyFormatter(Math.abs(r.amount.value), 2, true)}</del>
											: currencyFormatter(Math.abs(r.amount.value), 2, true)}
								</Col>
							</Row>
						)
					})
				}
			</div>
		</div>
	)
}

export default DisplayIncomeAndExpensesRow;