import moneyHubAssetTypes from '../../utils/MoneyHubAssetTypes';

const axios = require('axios');

const getAccounts = async function(token, setAccounts, addTransactionsToAccount, shopStVendors, setLoading, categories, categoryGroups){
	const requestConfig = {
		headers: {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'x-api-key': process.env.REACT_APP_AWS_API_KEY,
			'Authorization': token,
		}
	}
	await axios.get(process.env.REACT_APP_AWS_DATA_ENDPOINT + '/account', requestConfig).then(response => {
		let accounts = [...response.data.accounts]
		let hasPension = false
		accounts.forEach(a => {
			a.SpendingAnalysisSelected = true
			a.BankAccountSelected = a.type === 'cash:current'
			a.PropertySelected = moneyHubAssetTypes['property'].includes(a.type)
			a.PensionSelected = moneyHubAssetTypes['pension'].includes(a.type)
			if (moneyHubAssetTypes['mortgage'].includes(a.type)){
				a.MortgageSelected = true
			}
			if (moneyHubAssetTypes['pension'].includes(a.type)){
				a.type = 'pension'
				hasPension = true
			}
		})
		accounts.forEach(a => {
			a.balances = []
		})
		let scenarioPension = {
			id: 'scenarioPension',
			accountName: 'Scenario Pension',
			type: 'pension-scenario',
			PensionSelected: true,
			balance: {
				amount: {
					value: 0,
					majorUnits: 0,
					minorUnits: 0,
					currency: "GBP"
				},
				date: new Date().toISOString().split('T')[0]
			},
			balances: [
				{
					amount: {
						value: 0,
						majorUnits: 0,
						minorUnits: 0,
						currency: "GBP"
					},
					date: new Date().toISOString().split('T')[0]
				}
			],
			currentValue: {
				low: 0,
				base: 0,
				high: 0
			}
		}
		if (hasPension) accounts.push(scenarioPension)
		setAccounts(addTransactionsToAccount([...response.data.transactions], accounts, shopStVendors, categories))

		response.data.balances.forEach(b => {
			accounts.forEach(a => {
				if (a.id === b.accountId) a.balances.push(b)
			})
		})

		setLoading()
		return
	}).catch(error => {
		console.error('Error (FE/s/d/getAwsAccounts): ', error)
		return
	})
}

export default getAccounts;