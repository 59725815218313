import React, { useState } from 'react';
import { updateUserAttributes, confirmUserAttribute } from 'aws-amplify/auth';

import { Modal, Row, Col, Container, Button, Form, InputGroup } from 'react-bootstrap';
import { CheckCircle, PersonLinesFill, Gear } from "react-bootstrap-icons";

import TermsOfBusiness from '../../assets/content/terms-of-business';
import { InteractionTile } from '../components';
import { getSessionTokenAsync } from '../../utils/GetSessionTokenAsync';

import { setUser as setUserDataService } from '../../service/DataService';

const UserSignUpForm = ({ user, setShowUserSignUpForm, setLoadingState }) => {
	const completeColor = '#229424'
	const activeColor = '#3377FF'
	const incompleteColor = '#9C9DAD'

	const [ currentStep, setCurrentStep ] = useState(0)
	const [ emailVerificationNeeded, setEmailVerificationNeeded ] = useState(false)
	const [ emailUpdateCode, setEmailUpdateCode ] = useState('')

	const [ oldUserEmail ] = useState(user.email)

	const getColor = (step, includeActive = true) => {
		if (currentStep < step) return incompleteColor
		if (currentStep === step) return includeActive ? activeColor : incompleteColor
		if (currentStep > step) return completeColor
	}

	const [ userDetails, setUserDetails ] = useState({
		userFirstName: user.firstName,
		userLastName: user.lastName,
		userEmail: user.email,
		userDateOfBirth: user.dateOfBirth,
		userRetirementAge: 65
	})
	const [ financialDetails, setFinancialDetails ] = useState({
		currentSituation: [],
		interests: [],
		feeling: 50,
		currentSituationMaxChoices: 2,
		interestsMaxChoices: 3
	})

	const updateEmailUpdateCode = (e) => {
		const {value} = e.target
		setEmailUpdateCode(value)
	}

	const handleOnChange = (e) => {
		const {name, value} = e.target;
		setUserDetails({
			...userDetails,
			[name]: value
		})
	}
	const handleFeelingOnChange = (e) => {
		const {value} = e.target
		setFinancialDetails({
			...financialDetails,
			'feeling': value
		})
	}
	const isDataComplete = () => {
		let isComplete = true
		if (currentStep === 1){
			Object.keys(userDetails).forEach(k => {
				isComplete &&= (userDetails[k] !== '')
			})
		} else if (currentStep === 2){
			isComplete &&= financialDetails.currentSituation.length > 0
			isComplete &&= financialDetails.interests.length > 0
		}
		return isComplete
	}

	const getCurrentSituationOptions = () => {
		const options = [
			'Saving for a specific goal',
			'Okay, but want to save more',
			'Living payday to payday',
			'Very comfortable',
			'Overspending',
			'No issues, lots of savings'
		]
		let fullOptions = options.map(o => {
			return {
				name: o,
				label: o,
				isSelected: financialDetails.currentSituation.includes(o)
			}
		})
		return fullOptions
	}

	const handleCurrentSituationClick = (e) => {
		const {name} = e.target
		let currentSituationClone = [...financialDetails.currentSituation]
		if (!currentSituationClone.includes(name)){
			if (currentSituationClone.length < financialDetails.currentSituationMaxChoices){
				currentSituationClone.push(name)
			}
		} else {
			currentSituationClone = currentSituationClone.filter(s => s !== name)
		}
		setFinancialDetails({
			...financialDetails,
			'currentSituation': currentSituationClone
		})
	}

	const getInterestsOptions = () => {
		const options = [
			'Improve credit score',
			'Maximise savings',
			'How do I compare?',
			'Spending trends',
			'Pension forecast',
			'Save more each month'
		]
		let fullOptions = options.map(o => {
			return {
				name: o,
				label: o,
				isSelected: financialDetails.interests.includes(o)
			}
		})
		return fullOptions
	}

	const handleInterestsClick = (e) => {
		const {name} = e.target
		let interestsClone = [...financialDetails.interests]
		if (!interestsClone.includes(name)){
			if (interestsClone.length < financialDetails.interestsMaxChoices){
				interestsClone.push(name)
			}
		} else {
			interestsClone = interestsClone.filter(s => s !== name)
		}
		setFinancialDetails({
			...financialDetails,
			'interests': interestsClone
		})
	}

	const updateUserEmail = async () => {
		try {
			const attributes = await updateUserAttributes({
				userAttributes: {
					email: userDetails.userEmail
				}
			})
			return handleUpdateUserAttributeNextSteps(attributes.email)
		} catch (error){
			console.error(error)
		}
	}

	const handleUpdateUserAttributeNextSteps = (output) => {
		const { nextStep } = output
		switch(nextStep.updateAttributeStep){
			case 'CONFIRM_ATTRIBUTE_WITH_CODE':
				setEmailVerificationNeeded(true)
				return true
			case 'DONE':
			default:
		}
		setEmailVerificationNeeded(false)
		return false
	}

	const [ verificationError, setVerificationError ] = useState('')
	const handleConfirmUserEmail = async () => {
		const userAttributeKey = 'email'
		const confirmationCode = emailUpdateCode
		try{
			const error = await confirmUserAttribute({ userAttributeKey: userAttributeKey, confirmationCode: confirmationCode})
			if (error){
				console.error(error)
			} else {
				saveUserOptions()
			}
		} catch (error){
			console.error(error)
			setVerificationError('Invalid verification code')
		}
	}

	const saveSignUpForm = async () => {
		let verificationNeeded = await updateUserEmail()
		if (verificationNeeded) {
			setCurrentStep(3)
		} else {
			saveUserOptions()
		}
	}

	const saveUserOptions = async () => {
		const currentSession = await getSessionTokenAsync()
		let userInfo = {}

		userInfo.firstName = userDetails.userFirstName
		userInfo.lastName = userDetails.userLastName
		userInfo.email = userDetails.userEmail
		userInfo.dateOfBirth = userDetails.userDateOfBirth

		userInfo.lumpSumPercentage = 25
		userInfo.retirementAge = Number(userDetails.userRetirementAge)

		userInfo.currentSituation = financialDetails.currentSituation
		userInfo.interests = financialDetails.interests
		userInfo.feeling = Number(financialDetails.feeling)

		userInfo.termsAccepted = true

		userInfo.oldEmail = oldUserEmail

		await setUserDataService(currentSession, userInfo)
		setTimeout(() => {
			// TODO: put a spinner on the button whilst waiting
			setLoadingState(oldLoading => ({
				...oldLoading,
				user: false
			}))
			setShowUserSignUpForm(false)
		}, 400)
	}

	return (
		<Modal centered show dialogClassName="modal-60w modal-max-w-700 ">
			{currentStep !== 0 && <Modal.Header>
				<Container>
					<Row className="text-center g-0" style={{height: "0px"}}>
						<Col xs={2}>
						</Col>
						<Col xs={4} className="px-4 pt-3">
							<div style={{backgroundColor: getColor(0, false), height: "2px", width: "100%"}}>
							</div>
						</Col>
						<Col xs={4} className="px-4 pt-3">
						<div style={{backgroundColor: getColor(1, false), height: "2px", width: "100%"}}>
							</div>
						</Col>
						<Col xs={2}>
						</Col>
					</Row>
					<Row className="text-center g-0">
						<Col xs={4}>
							<CheckCircle style={{fontSize: "2rem", fill: getColor(0)}} />
							<div style={{color: getColor(0)}}>
								User Agreement
							</div>
						</Col>
						<Col xs={4}>
							<PersonLinesFill style={{fontSize: "2rem", fill: getColor(1)}} />
							<div style={{color: getColor(1)}}>
								Details
							</div>
						</Col>
						<Col xs={4}>
							<Gear style={{fontSize: "2rem", fill: getColor(2)}} />
							<div style={{color: getColor(2)}}>
								Financial Choices
							</div>
						</Col>
					</Row>
				</Container>
			</Modal.Header>}
			<Modal.Body>
				{currentStep === 0 && <div className="mt-3 mx-3">
					<h3 className="mb-3">User waiver and agreement</h3>
					<p>
						Before proceeding, you must read and accept the following terms of use and user agreement:
					</p>
					<p>PLEASE READ THESE TERMS CAREFULLY.</p>
					<p>BY CLICKING ON THE “ACCEPT” BUTTON YOU AGREE TO THESE TERMS WHICH WILL BIND YOU. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT USE THE PRIFT PLATFORM.</p>
					<div className="border p-2" style={{maxHeight: "400px", overflow: "scroll"}}>
						<TermsOfBusiness />
					</div>
					<Row className="mt-3">
						<Col className="text-end">
							<Button onClick={() => setCurrentStep(currentStep + 1)}>Accept</Button>
						</Col>
					</Row>
				</div>}
				{currentStep === 1 && <div className="mt-3 mx-3">
					<InteractionTile title="Personal Details" body={
						<Form className="m-3">
							<Form.Group
									as={Row}
									className="mb-3"
									controlId="userFirstName">
								<Col sm={4}>
									<Form.Label>
										First Name
									</Form.Label>
								</Col>
								<Col sm={8}>
									<Form.Control
											className="bg-white"
											placeholder=""
											name="userFirstName"
											onChange={handleOnChange}
											defaultValue={userDetails.userFirstName} />
								</Col>
							</Form.Group>
							<Form.Group
									as={Row}
									className="mb-3"
									controlId="userLastName">
								<Col sm={4}>
									<Form.Label>
										Last Name
									</Form.Label>
								</Col>
								<Col sm={8}>
									<Form.Control
											className="bg-white"
											placeholder=""
											name="userLastName"
											onChange={handleOnChange}
											defaultValue={userDetails.userLastName} />
								</Col>
							</Form.Group>
							<Form.Group
									as={Row}
									className="mb-3"
									controlId="userEmail">
								<Col sm={4}>
									<Form.Label>
										Email
									</Form.Label>
								</Col>
								<Col sm={8}>
									<Form.Control
											className="bg-white"
											placeholder=""
											name="userEmail"
											onChange={handleOnChange}
											aria-describedby="emailHelpBlock"
											defaultValue={userDetails.userEmail} />
									<Form.Text id="emailHelpBlock" muted>
										You can use your personal email instead of your work email if you wish. No financial information is shared with your employer.
									</Form.Text>
								</Col>
							</Form.Group>
							<Form.Group
									as={Row}
									className="mb-3"
									controlId="userDateOfBirth">
								<Col sm={4}>
									<Form.Label>
										Date of Birth
									</Form.Label>
								</Col>
								<Col sm={8}>
									<Form.Control
											className="bg-white"
											type="date"
											placeholder=""
											name="userDateOfBirth"
											onChange={handleOnChange}
											defaultValue={userDetails.userDateOfBirth} />
								</Col>
							</Form.Group>
							<Form.Group
									as={Row}
									className="mb-3 align-items-center"
									controlId="userRetirementAge">
								<Col sm={4}>
									<Form.Label className="mt-4">
										Planned Retirement Age
									</Form.Label>
								</Col>
								<Col sm={8}>
									<div className="text-center">{userDetails.userRetirementAge}</div>
									<InputGroup>
										<InputGroup.Text>55</InputGroup.Text>
										<Form.Control
												className="bg-white"
												type="range"
												min="55"
												max="75"
												name="userRetirementAge"
												defaultValue={userDetails.userRetirementAge}
												onChange={handleOnChange} />
										<InputGroup.Text>75</InputGroup.Text>
									</InputGroup>
								</Col>
							</Form.Group>
						</Form>} />

					<Row className="mt-3">
						<Col>
							<Button variant="light" onClick={() => {setCurrentStep(currentStep - 1)}}>Back</Button>
						</Col>
						<Col className="text-end">
							<Button disabled={!isDataComplete()} onClick={() => {setCurrentStep(currentStep + 1)}}>Next</Button>
						</Col>
					</Row>
				</div>}
				{currentStep === 2 && <div className="mt-3 mx-3">
					<p className="text-center mb-3" style={{color: "#6B7B9C"}}><em><strong>
						We do not share any personal financial information with your employer
					</strong></em></p>
					<InteractionTile className="bg-white" title="Financial Choices" body={
						<Form className="mt-1">
							<Form.Group
									as={Row}
									className="mb-3"
									controlId="currentSituation">
								<Col sm={4} className="mt-4">
									<Form.Label>
										Which best describes your current situation?
									</Form.Label>
									<div>
										<Form.Text muted>
											(You can select up to two)
										</Form.Text>
									</div>
								</Col>
								<Col sm={8}>
									<Row className="g-1 mb-1">
										<Col xs={12} className="text-center">
											<Form.Text muted>
												{financialDetails.currentSituation.length} / {financialDetails.currentSituationMaxChoices}
											</Form.Text>
										</Col>
										{getCurrentSituationOptions().map(o => (
											<Col key={o.name} xs={12} sm={6} md={4} className="d-flex align-items-stretch">
												<div className="d-grid gap-2" style={{width: "100%"}}>
													<Button
															className="border"
															variant={o.isSelected ? 'info' : 'light'}
															name={o.name}
															onClick={handleCurrentSituationClick}>
														{o.label}
													</Button>
												</div>
											</Col>
										))}
									</Row>
								</Col>

							</Form.Group>
							<hr />
							<Form.Group
									as={Row}
									className="mb-3"
									controlId="interests">
								<Col sm={4} className="mt-4">
									<Form.Label>
										Which topics are you most interested in?
									</Form.Label>
									<div>
										<Form.Text muted>
											(You can select up to three)
										</Form.Text>
									</div>
								</Col>
								<Col sm={8}>
									<Row className="g-1 mb-1">
										<Col xs={12} className="text-center">
											<Form.Text muted>
												{financialDetails.interests.length} / {financialDetails.interestsMaxChoices}
											</Form.Text>
										</Col>
										{getInterestsOptions().map(o => (
											<Col key={o.name} xs={12} sm={6} md={4} className="d-flex align-items-stretch">
												<div className="d-grid gap-2" style={{width: "100%"}}>
													<Button
															className="border"
															variant={o.isSelected ? 'info' : 'light'}
															name={o.name}
															onClick={handleInterestsClick}>
														{o.label}
													</Button>
												</div>
											</Col>
										))}
									</Row>
								</Col>

							</Form.Group>
							<hr />
							<Form.Group
									as={Row}
									className="mb-3"
									controlId="feeling">
								<Col xs={12}>
									<Form.Label>
										How do you feel about your financial situation?
									</Form.Label>
								</Col>
								<Col xs={12}>
									<Row className="d-sm-none">
										<Col xs={6}>
											Very Stressed
										</Col>
										<Col xs={6} className="text-end">
											Very Happy
										</Col>
									</Row>
									<InputGroup>
										<InputGroup.Text className="d-none d-sm-block">Very Stressed</InputGroup.Text>
										<Form.Control
												className="bg-white"
												type="range"
												min="0"
												max="100"
												name="feeling"
												defaultValue={financialDetails.feeling}
												onChange={handleFeelingOnChange} />
										<InputGroup.Text className="d-none d-sm-block">Very Happy</InputGroup.Text>
									</InputGroup>
								</Col>
							</Form.Group>
						</Form>
						} />

					<Row className="mt-3">
						<Col>
							<Button variant="light" onClick={() => {setCurrentStep(currentStep - 1)}}>Back</Button>
						</Col>
						<Col className="text-end">
							<Button disabled={!isDataComplete()} onClick={() => {saveSignUpForm()}}>Next</Button>
						</Col>
					</Row>
				</div>}
				{currentStep === 3 && emailVerificationNeeded && <div>
					<Form>
						<Form.Group>
							<Form.Label>
								A temporary verification code has been sent to your new email. Please enter the code below to verify your identity.
							</Form.Label>
							<Form.Control
									className="bg-white"
									placeholder=""
									name="emailCodeInput"
									onChange={updateEmailUpdateCode} />
							{verificationError !== '' && <Form.Label className="text-danger">
								{verificationError}
							</Form.Label>}
						</Form.Group>
					</Form>
					<Row>
						<Col className="mt-2 text-center">
							<Button onClick={handleConfirmUserEmail}>Continue to Prift</Button>
						</Col>
					</Row>
				</div>}
			</Modal.Body>
		</Modal>
	)
}

export default UserSignUpForm;