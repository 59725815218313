import React, { useContext, useState } from "react";
import { UserContext } from "../pages/Main";

import { Image, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';

import { Square, CheckSquareFill, SquareFill } from "react-bootstrap-icons";

import { ResponsiveContainer, PieChart, Pie, Cell, Label } from 'recharts';

import { ConnectionDetails, InteractionTile } from './components';

import currencyFormatter from "../utils/CurrencyFormatter";
import fetchByDotOperator from "../utils/FetchByDotOperator";
import moneyHubAssetSvgStyles from "../utils/MoneyHubAssetSvgStyles";

import styles from '../scss/exports.scss';

const AccountSelector = ({ displayTypes, oneTypeOnly = false, selected, title = 'Select Accounts:', valuePath = 'balance.amount.value', showDonut = false, editButtonText, mhTypes = displayTypes }) => {

	const userContext = useContext(UserContext)
	const setAccounts = userContext.setAccounts

	const [ showAccountEditor, setShowAccountEditor ] = useState(false)


	const COLORS = [styles['asset-0'],styles['asset-1'],styles['asset-2'],styles['asset-3']]

	const donutChart = () => {
		let data = []
		displayTypes.forEach((t) => {
			userContext.accounts.filter(a => a.type === t).forEach(a => {
				data.push({
					name: a.accountName,
					value: fetchByDotOperator(a, valuePath)
				})
			})
		})
		let total = 0
		data.forEach(d => total += d.value)

		const DonutHole = ({viewBox}) => {
			const {cx, cy} = viewBox;
			return (<>
				<text
						x={cx}
						y={cy - 5}
						fill="rgba(0, 0, 0, 0.87)"
						className="recharts-text recharts-label"
						textAnchor="middle"
						dominantBaseline="central">
					<tspan alignmentBaseline="middle" fontSize="20px">
						{currencyFormatter(total, 0, true)}
					</tspan>
				</text>
				<text
						x={cx}
						y={cy + 20}
						fill="rgba(0, 0, 0, 0.54)"
						className="recharts-text recharts-label"
						textAnchor="middle"
						dominantBaseline="central">
					<tspan fontSize="14px">
						Total Current Value
					</tspan>
				</text>
			</>)
		  }

		return (
			<ResponsiveContainer width="100%" height={250}>
				<PieChart>
					<Pie
							data={data}
							dataKey="value"
							nameKey="name"
							cx="50%"
							cy="50%"
							outerRadius={90}
							innerRadius={75}
							fill="#8884d8"
							startAngle={450}
							endAngle={90}
							isAnimationActive={false}>
						{data.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
						))}
						<Label content={<DonutHole />} position="center" />
					</Pie>
				</PieChart>
			</ResponsiveContainer>
		)
	}

	const accountList = () => {
		return (<>
			<h5 className="text-center">{title}</h5>
			<div className="text-muted">
				{showDonut && donutChart()}
				{displayTypes.map((t) => {
					return (
						<div key={'row' +  t} className="my-3">
							<h6 className={(t === 'properties:residential' || t === 'mortgage') ? "" : "text-center"} key={'title' + t}>
								{
									t === 'cash:current' ? 'Current Accounts' :
									t === 'savings' ? 'Savings Accounts' :
									t === 'card' ? 'Credit Cards' :
									t === 'properties:residential' ? 'Select:' :
									t === 'pension' ? '' :
									t.split(':')[0] === 'mortgage' ? 'Select:' :
									t}
							</h6>
							{userContext.accounts.filter(a => a.type.split(':')[0] === t.split(':')[0]).length === 0 &&
								<Row className="align-items-center text-center" key={'none' + t}>
									<Col xs={12} className="">-none-</Col>
								</Row>
							}
							{userContext.accounts.filter(a => a.type.split(':')[0] === t.split(':')[0]).map((a,i) => {
								return (
									<Row className={"align-items-center g-0 " + (!showDonut ? "interaction" : "")} key={'row' + a.id} onClick={(e) => {
											if (showDonut) return
											let newAccounts = [...userContext.accounts]
											let clickedType
											let clickedToTrue = false
											newAccounts.forEach(account => {
												if (account.id === a.id){
													account[selected] = !account[selected]
													clickedType = account.type
													clickedToTrue = account[selected]
												}
												if (oneTypeOnly && clickedToTrue){
													newAccounts.forEach(account => {
														if (account.type !== clickedType){
															account[selected] = false
														}
													})
												}
											})
											setAccounts(newAccounts)
											}}>
										{!showDonut && <Col xs={1}>
											{a[selected] && (
												<CheckSquareFill style={{marginTop: "-1px"}} className={moneyHubAssetSvgStyles[t]} />
											)}
											{!a[selected] && (
												<Square style={{marginTop: "-1px"}} className={moneyHubAssetSvgStyles[t]} />
											)}
										</Col>}
										{showDonut && <Col xs={1}>
											<SquareFill style={{marginTop: "-1px", fill: COLORS[i % COLORS.length]}} />
										</Col>}
										<Col xs={11}>
											<div className="d-flex align-items-center" style={{paddingLeft: "0.25rem"}}>
												<div>
													{a.connection && <Image src={a.connection.iconUrl} height="25px" />}
												</div>
												<div className="text-truncate" style={{marginRight: "0.5rem", flexGrow: "1"}}>
													{a.accountName}
												</div>
												<div className="mr-auto" style={{whiteSpace: "nowrap"}}>
													{currencyFormatter(fetchByDotOperator(a, valuePath), 0, true)}
												</div>
											</div>
										</Col>
										{/* <Col xs={8} className="text-truncate">
											{a.connection && <Image src={a.connection.iconUrl} height="25px" />}
											{a.accountName}
										</Col>
										<Col xs={3} className="text-end">
											{currencyFormatter(fetchByDotOperator(a, valuePath))}
										</Col> */}
									</Row>
								)
							})}
						</div>
					)
				})}
				<div className="text-center">
					<Button
							variant="secondary"
							disabled={!userContext.loadingState.loadComplete}
							onClick={()=>setShowAccountEditor(true)}>
						{!userContext.loadingState.loadComplete && <Spinner size="sm" className="mx-1" />}{editButtonText}
					</Button>
				</div>
				<Modal show={showAccountEditor} centered dialogClassName="modal-60w modal-max-w-700">
					<Modal.Header closeButton onHide={()=>setShowAccountEditor(false)}>
						<Modal.Title>{editButtonText}</Modal.Title>
					</Modal.Header>
					<Modal.Body className="modal-max-height">
						<ConnectionDetails type={mhTypes} />
					</Modal.Body>
				</Modal>
			</div>
		</>)
	}
	return (
		<>
			<InteractionTile
					body={accountList()} />
		</>
	)
}

export default AccountSelector;