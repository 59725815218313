import demoData from './demoData';

const getDemoBalances = (account) => {
	return demoData.filter(t => t.accountName === account).map(transaction => {
		return {
			amount: {
				value: Number(transaction.balance)
			},
			date: addDays(new Date(), Number(transaction.relativeDate)).toISOString().split('T')[0],
		}
	})
}

const addDays = (date, days) => {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}

export default getDemoBalances;