const axios = require('axios');

const sendShopStRequestEmail = async function(token, userDetails, successCallback){
	const requestConfig = {
		headers: {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'x-api-key': process.env.REACT_APP_AWS_API_KEY,
			'Authorization': token,
		}
	}
	const requestBody = '"' + userDetails + '"';
	await axios.post(process.env.REACT_APP_AWS_DATA_ENDPOINT + '/ss/request', requestBody, requestConfig).then(response => {
		successCallback()
		return;
	}).catch(error => {
		return;
	})
}

export default sendShopStRequestEmail;