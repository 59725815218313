import React, { createContext } from "react";
import { Route, Routes } from "react-router-dom";

import { Amplify } from 'aws-amplify';
import config from './aws-exports';

import { Authenticator, TextField, useAuthenticator, useTheme, Theme, ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Logo } from './components/components';
import { Row, Col } from 'react-bootstrap';

import { Main } from "./pages/contentPages";

import "./scss/style.scss";

Amplify.configure(config);

export const AuthContext = createContext({
	user: {}
});

function ProtectedApp({ signOut, user }) {

	const { tokens } = useTheme();
	const theme: Theme = {
		name: 'Prift Theme',
		tokens: {
		  components: {
			authenticator: {
			  router: {
				boxShadow: `0 0 16px ${tokens.colors.overlay['10']}`,
				borderWidth: '0',
			  },
			  form: {
				padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
			  },
			},
			button: {
			  primary: {
				backgroundColor: '#0d6efd',
			  },
			  link: {
				color: tokens.colors.purple['80'],
			  },
			},
			fieldcontrol: {
			  _focus: {
				boxShadow: `0 0 0 2px ${tokens.colors.purple['60']}`,
			  },
			},
			tabs: {
			  item: {
				color: tokens.colors.neutral['80'],
				_active: {
				  borderColor: tokens.colors.neutral['100'],
				  color: tokens.colors.purple['100'],
				},
			  },
			},
		  },
		},
	  };

	return (
		<ThemeProvider theme={theme}>
			<Authenticator
					components={{
						Header() {
							return (
								<Row>
									<Col className="text-center">
										<Logo logoStyle='login' />
									</Col>
								</Row>

							);
						},
						SignUp: {
							FormFields() {
								const { validationErrors } = useAuthenticator()

								return (
									<>
										<Authenticator.SignUp.FormFields />

										<TextField
												errorMessage={validationErrors['custom:hubspotkey']}
												hasError={!!validationErrors['custom:hubspotkey']}
												name="custom:hubspotkey"
												label="Your Unique Key"
												placeholder="123456" />
									</>
								)
							}
						}
					}}
					services={{
						async validateCustomSignUp(formData){
							if (!formData['custom:hubspotkey']){
								return {
									'custom:hubspotkey': 'You will have received this in your welcome email'
								}
							}
						}
					}}>
				{({ signOut, user }) => {
					window.localStorage.setItem('hasClosedHelp', JSON.stringify(false))
					return (<div className="App">
						<AuthContext.Provider value={{user}}>
							<Routes>
								<Route path="/*" element={<Main />} />
							</Routes>
						</AuthContext.Provider>
					</div>)
				}}
			</Authenticator>
		</ThemeProvider>
	);
}

export default ProtectedApp;