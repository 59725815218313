/**
 * Forecast Cash Accounts values
 * @param {*} moneyHubAssetTypes The object type map to get specific cash account types from
 * @param {*} user The current user
 * @param {*} accounts The list of accounts for the current user
 * @param {*} setLoadingStage Function to update the loading stage
 */
export const forecastCashAccount = async ( moneyHubAssetTypes, user, accountState, setLoadingStage ) => {
	let accounts = [...accountState.state]
	const cashAccountTypes = moneyHubAssetTypes['cashAccounts']
	const today = new Date().toISOString().split('T')[0]

	// Start at the users aged 18 date, or the earliest account balance
	let startDate = user.dateAged18
	accounts.filter(a => cashAccountTypes.includes(a.type)).forEach(a => {
		if (a.balances.length > 0) {
			if (new Date(a.balances[0].date) < new Date(startDate)){
				startDate = a.balances[0].date
			}
		}
	})
	// End at the users aged 90 date
	const endDate = user.dateAged90

	// For cash account type accounts, create a forecast
	accounts.filter(a => cashAccountTypes.includes(a.type)).forEach(a => {
		let interestTransactions = a.transactions.filter(t => t.longDescription.toLowerCase().includes('interest'))
		let currentValue = { low: null, base: null, high: null }
		let currentDate = new Date(startDate)
		let valueDataGraph = []
		let keyDates = []
		let balancesSinceLastInterestAmount = []
		let mostRecentInterest = null

		let allDates = []
		const earliestData = a.transactionData.earliestDate.split('T')[0]
		for (let d = today; d > earliestData; d = addDays(d, -1).toISOString().split('T')[0]){
			allDates.push(d)
		}
		let datedBalances = []
		let currentBalance = a.balance.amount.value
		let previousDayTransactions = 0
		a.balancesCalculated2 = {}
		allDates.forEach(d => {
			let transactionsForDay = []
			currentBalance -= previousDayTransactions
			const transactions = a.transactions?.filter(t => t.shortDate === d)
			const transactionValue = transactions.reduce((a, t) => a + t.amount.value, 0)
			previousDayTransactions = transactionValue
			transactionsForDay.push(...transactions)
			datedBalances.push({
				name: d,
				balance: currentBalance
			})
			a.balancesCalculated2[d] = currentBalance
		})
		a.balancesCalculated = datedBalances

		// For each day from start to end dates
		while (currentDate <= endDate){
			const shortDate = currentDate.toISOString().split('T')[0]
			let currentBalance = a.balancesCalculated2[shortDate] || null

			if (shortDate <= today) {
				if (currentBalance){
					// A specific balance is available for this day
					currentValue = {
						low: currentBalance,
						base: currentBalance,
						high: currentBalance
					}
					keyDates.push(currentDate)
				}
			} else if (currentValue.low != null && currentValue.base != null && currentValue.high != null){
				// Generate a forecast balance for this day
				let yearRateValue = a.details.APR || (mostRecentInterest * 100) || (a.type === 'savings' ? 2.7 : 0)
				if (a.type === 'card' && currentDate > addDays(new Date(), 0)) yearRateValue = 0
				let yearRate = {low: 0, base: 0, high: 0}
				if (shortDate > today){
					if (yearRateValue ){
						yearRate.low = yearRateValue
						yearRate.base = yearRateValue
						yearRate.high = yearRateValue
					}
				}
				const baseRate = 1 + (yearRate.base/100)
				const lowRate = yearRate.low ? (1 + (yearRate.low/100)) : baseRate
				const highRate = yearRate.high ? (1 + (yearRate.high/100)) : baseRate
				currentValue.low = currentValue.low * (Math.pow(lowRate, 1/364))
				currentValue.base = currentValue.base * (Math.pow(baseRate, 1/364))
				currentValue.high = currentValue.high * (Math.pow(highRate, 1/364))
			}

			let interestTransactionsOnThisDay = interestTransactions.filter(t => t.date.split('T')[0] === shortDate)

			if (interestTransactionsOnThisDay.length > 0) {
				let thisPeriodIsValid = true
				let interestSum = 0
				interestTransactionsOnThisDay.forEach(t => {
					interestSum += t.amount.value
				})
				let balancesSum = 0
				let b1 = balancesSinceLastInterestAmount[0]
				thisPeriodIsValid = balancesSinceLastInterestAmount.every(e => b1 > 0 ? e >= 0 : e <= 0)
				balancesSinceLastInterestAmount.forEach(b => {
					balancesSum += b
				})

				const totalInterest = interestSum
				const countBalances = balancesSinceLastInterestAmount.length
				const averageBalance = balancesSum / countBalances
				const rate = 364 * ((Math.pow((1 + (totalInterest / averageBalance)), (1/countBalances))) - 1)
				if (thisPeriodIsValid) mostRecentInterest = rate
				balancesSinceLastInterestAmount = []
				currentValue.base != null && balancesSinceLastInterestAmount.push(currentValue.base)
			} else {
				currentValue.base != null && balancesSinceLastInterestAmount.push(currentValue.base)
			}

			// Setup the extra information needed from the new forecasts
			if (shortDate === today) {
				a.currentValue = {...currentValue}
				keyDates.push(currentDate)
			}
			if (keyDates.includes(currentDate) || (currentDate.getDate() === 1)){
				valueDataGraph.push({
					name: Number(currentDate),
					low: currentValue.low,
					base: currentValue.base,
					high: currentValue.high,
					highDiff: currentValue.high - currentValue.low
				})
			}
			currentDate = addDays(currentDate, 1)
		}
		a.estimatedInterestRate = mostRecentInterest

		a.valueForecast = valueDataGraph
	})
	accountState.setState(accounts)
}

/**
 * Adds days to a date
 * @param {Date} date
 * @param {Integer} days
 * @returns
 */
const addDays = (date, days) => {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}