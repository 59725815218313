import React, { useState, useContext } from 'react';
import { UserContext } from "../Main";

import { createUser } from '../../service/AdminService';

import { Button, Form, Row, Col, Toast } from 'react-bootstrap';

import Tile from '../../components/tiles/Tile';

const AddUsers = () => {
	const userContext = useContext(UserContext);

	const [ isSavingUser, setIsSavingUser ] = useState(false);
	const [ showToast, setShowToast ] = useState(false);

	const [ userDetails, setUserDetails ] = useState({
		firstName: '',
		lastName: '',
		email: ''
	});
	const handleOnChange = (e) => {
		const {name, value} = e.target;
		setUserDetails({
			...userDetails,
			[name]: value
		})
	}

	const [ toastDetails, setToastDetails ] = useState({
		firstName: '',
		lastName: '',
		email: ''
	});

	const handleSubmit = async () => {
		setIsSavingUser(true);
		await createUser(userContext.token, userDetails);
		setToastDetails(userDetails);
		setShowToast(true);
		setUserDetails({
			firstName: '',
			lastName: '',
			email: ''
		});

		setIsSavingUser(false);
	}
	const body = () => {
		return (
			<Form>
				<Row>
					<Form.Group as={Col} className="mb-3" controlId="1">
						<Form.Label>First Name</Form.Label>
						<Form.Control type="text" name="firstName" value={userDetails.firstName || ''} onChange={handleOnChange} />
					</Form.Group>
					<Form.Group as={Col} className="mb-3" controlId="1">
						<Form.Label>Last Name</Form.Label>
						<Form.Control type="text" name="lastName" value={userDetails.lastName || ''} onChange={handleOnChange} />
					</Form.Group>
				</Row>
				<Row>
					<Form.Group as={Col} className="mb-3" controlId="1">
						<Form.Label>Email</Form.Label>
						<Form.Control type="text" name="email" value={userDetails.email || ''} onChange={handleOnChange} />
					</Form.Group>
				</Row>
				<Row>
					<Col className="text-end">
						<Button className="mb-3" variant="primary" disabled={isSavingUser} onClick={!isSavingUser ? handleSubmit : null}>
							{isSavingUser ? 'Saving...' : 'Add User'}
						</Button>
					</Col>
				</Row>
				<Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
					<Toast.Header>
						<strong className="me-auto">User Created</strong>
						<small>{toastDetails.firstName} {toastDetails.lastName}</small>
					</Toast.Header>
					<Toast.Body>User created successfully {toastDetails.email}</Toast.Body>
				</Toast>
			</Form>
		)
	}

	return (
		<>
			<Tile
					title={<h4 className="">Add User</h4>}
					body={body()} />
		</>
	)
}

export default AddUsers;