import React, { useContext, useEffect } from "react";
import { UserContext } from "../Main";

import { PageTitle, AccountSelector, Tile, InteractionTile, ForecastChart } from '../../components/components';
import TwoColumnContentLayout from '../../layouts/TwoColumnContentLayout';

import { Row, Col, Image } from 'react-bootstrap';

import currencyFormatter from "../../utils/CurrencyFormatter";
import dateFormatter from "../../utils/DateFormatter";
import moneyHubAssetTypes from "../../utils/MoneyHubAssetTypes";

import styles from '../../scss/exports.scss';

const Mortgage = ( ) => {

	const userContext = useContext(UserContext)
	const selected = "MortgageSelected"
	const displayTypes = moneyHubAssetTypes['mortgageOverride']

	const selectedAccounts = userContext.accounts.filter(a => a[selected] && displayTypes.includes(a.type.split(':')[0]))
	useEffect(() => {

	}, [selectedAccounts])

	const RenderTooltip = ({ active, label, payload, displayConfig }) => {
		let mortgageData = { low: 0, base: 0, high: 0 }
		displayConfig.chartNames.forEach((n,i) => {
			let base = payload.filter(p => p.dataKey === n)
			if (base.length > 0) mortgageData.base += base[0].value
		})
		const tooltipContents = () => {
			return (
				<>
					{label && <h6 className="text-start">{dateFormatter(new Date(label).toISOString(), 'yearOnly')}</h6>}
					<div className="fw-bold fs-6 d-flex justify-content-between text-muted">
						Estimated Balance:
					</div>
					<div className="fs-6 d-flex justify-content-between fw-bold text-spending-color">
						<div>
							Mortgage:&nbsp;
						</div>
						<div className="text-end mr-auto">
							{currencyFormatter(Math.max(0, mortgageData.base), 0, true)}
						</div>
					</div>
				</>
			)
		}
		return(
			active &&
					<InteractionTile body={tooltipContents()} padding="p-1" bottomMargin="" />
		)
	}

	const lineChart = () => {
		let chartData = []
		let chartNames = []
		let chartDates = []
		selectedAccounts.forEach(a => {
			chartNames.push(a.accountName)
		})
		selectedAccounts.forEach(a => {
			a.valueForecast?.forEach(f => {
				chartDates.push(f.name)
			})
		})
		chartDates = [...new Set(chartDates)]
		chartDates.sort((a,b) => a > b ? 1 : -1)
		chartDates.forEach(d => {
			let emptyDataPoint = {}
			emptyDataPoint.name = d
			chartNames.forEach(n => {
				emptyDataPoint[n] = null
			})
			emptyDataPoint.preTodayZeros = null
			emptyDataPoint.postTodayZeros = null
			chartData.push(emptyDataPoint)
		})

		selectedAccounts.forEach(a => {
			a.valueForecast?.forEach(f => {
				let data = chartData.filter(cd => cd.name === f.name)[0]
				data[a.accountName] = -1 * Math.floor(f.value)
			})
		})
		let previousData = {}
		let zerosStart = false
		const today = new Date()
		chartData.forEach(cd => {
			chartNames.forEach(n => {
				if (!cd[n] && !zerosStart) cd[n] = previousData[n]
				previousData[n] = cd[n]
				if (cd[n] !== undefined) zerosStart = true
			})
			if (zerosStart && cd.name <= today) cd.preTodayZeros = 0
			if (zerosStart) cd.postTodayZeros = 0
		})

		let dataConfig = {}
		// dataConfig.renderLegend = RenderLegend
		dataConfig.renderTooltip = RenderTooltip
		dataConfig.chartNames = chartNames
		dataConfig.color = styles['liability-0']

		return (<>
			<h5>Your Mortgage Forecast</h5>
			<div className="mx-0" style={{"height":"300px"}}>
				<ForecastChart data1={chartData} displayConfig={dataConfig} />
			</div>
		</>)
	}

	const mortgageDetails = () => {
		let outstandingBalance = 0
		let currentMonthlyPayment = 0
		selectedAccounts.forEach(a => {
			outstandingBalance += a.currentValue
			currentMonthlyPayment += a.details.monthlyRepayment
		})

		return (
			<Tile body={<>
				{(selectedAccounts.length === 1) && <div>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							<h5 className="text-dark mb-0 pb-0">Mortgage Details:</h5>
						</Col>
						<Col xs={6} className="text-dark">
							{selectedAccounts[0].accountName}
						</Col>
					</Row>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							Provider:
						</Col>
						<Col xs={6} className="text-dark">
							<Row className="align-items-center">
									{selectedAccounts[0].connection && <>
										<Col xs={2} sm={1}>
											<Image src={selectedAccounts[0].connection.iconUrl} height="25px" width="25px" />
										</Col>
										<Col xs={10} sm={11} className="px-3">
											{selectedAccounts[0].connection.friendlyName}
										</Col>
									</>}
									{!selectedAccounts[0].connection && <div>
										{selectedAccounts[0].providerName}
									</div>}
							</Row>
						</Col>
					</Row>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							Outstanding Balance:
						</Col>
						<Col xs={6} className="">
							<div className={outstandingBalance >= 0 ? "text-spending-color" : "text-asset-color"}>
								{currencyFormatter(outstandingBalance, 0, true)}
							</div>
						</Col>
					</Row>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							Current Monthly Payment:
						</Col>
						<Col xs={6} className="text-dark">
							{currencyFormatter(currentMonthlyPayment, 0, true)}
						</Col>
					</Row>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							Remaining Mortgage Term:
						</Col>
						<Col xs={6} className="text-dark">
							{selectedAccounts[0].remainingTerm}
						</Col>
					</Row>
				</div>}
				{(selectedAccounts.length > 1) && <div>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							<h5 className="text-dark mb-0 pb-0">Mortgage Details:</h5>
						</Col>
						<Col xs={6} className="">
							Multiple Selected
						</Col>
					</Row>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							Outstanding Balance:
						</Col>
						<Col xs={6} className="">
							<div className={outstandingBalance >= 0 ? "text-spending-color" : "text-asset-color"}>
								{currencyFormatter(outstandingBalance, 0, true)}
							</div>
						</Col>
					</Row>
					<Row className="mb-3 align-items-center">
						<Col xs={6} className="text-end">
							Current Monthly Payment:
						</Col>
						<Col xs={6} className="text-dark">
							{currencyFormatter(currentMonthlyPayment, 0, true)}
						</Col>
					</Row>
				</div>}
				{(selectedAccounts.length === 0) && <div>
					Select an mortgage to see more details
				</div>}
			</>} />
		)
	}

	return (
		<>
			<PageTitle pageName="Mortgages" helpPage="mortgage" >
				<TwoColumnContentLayout
						left={<AccountSelector title="Mortgages" displayTypes={displayTypes} selected={selected} valuePath='currentValue' editButtonText="Edit Mortgages" mhTypes={moneyHubAssetTypes['mortgage']} />}
						right={<span className="text-muted">{mortgageDetails()}</span>} />

				<hr className="mt-0" />

				{lineChart()}
			</PageTitle>
		</>
	)
}

export default Mortgage;