const axios = require('axios');

const patchMhTransactions = async function(token, moneyhubId, transactions, successCallback){
	const requestConfig = {
		headers: {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'x-api-key': process.env.REACT_APP_AWS_API_KEY,
			'Authorization': token,
		}
	}
	const requestBody = {
		userId: moneyhubId,
		transactions: transactions
	}
	await axios.patch(process.env.REACT_APP_AWS_DATA_ENDPOINT + '/mh/transactions', requestBody, requestConfig).then(response => {
		successCallback(response.data.transactions)
		return;
	}).catch(error => {
		console.error('Error (FE/s/d/patchMhTransactions): ', error);
		return;
	})
}

export default patchMhTransactions;