import React, { useEffect, useState, createContext, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";

// Services
import { getUser, getMhConnections, getMhAccounts, getBalance, getMhTransactions, getShopStVendors, getDemoData, getDemoTransactions, getUserConnections, sendErrorReportEmail, getAccounts, setAccount as saveAccounts } from '../service/DataService';

// Forecasts
import { forecastMortgage } from '../utils/forecast/forecastMortgage';
import { forecastPension } from '../utils/forecast/forecastPension';
import { forecastProperty } from '../utils/forecast/forecastProperty';
import { forecastCashAccount } from '../utils/forecast/forecastCashAccount';

// Data Transform
import { addTransactionsToAccount } from '../utils/dataTransform/addTransactionsToAccount';

// Pages
import { Home, MyAccount, AddAsset, SpendingAnalysis, BankAccounts, Property, Pension, Mortgage, ShopStSaving, Help, ManageConnections } from "./contentPages";
import { AddUsers } from "./adminPages";

// Components
import { Modal, Spinner, Button, Accordion, Toast, ToastContainer, Row, Col } from 'react-bootstrap';
import TwoColumnLayout from '../layouts/TwoColumnLayout';
import { NavigationLargeDisplay, NavigationSmallDisplay, UserSignUpForm, ConnectionDetails } from '../components/components';

// Util
import moneyHubAssetTypes from '../utils/MoneyHubAssetTypes';
import moneyHubCategoryGrouping from '../utils/MoneyHubCategoryGrouping';
import { getSessionTokenAsync } from '../utils/GetSessionTokenAsync';
import { getWSService } from '../service/websocket/WebSocketService';

export const UserContext = createContext({
	user: {},
	setUser: () => {},
	token: ''
});

const Main = () => {
	const navigate = useNavigate()

	useEffect(() => {
		getWSService().addMessageListener((e, user) => {
			if (e === 'Pong') return
			let data = JSON.parse(e)
			if (data.message === "Internal server error") return
			reloadAccounts(false)
		})

		// Ping websocket to keep it open, standard close time is 1 minute, so 50 seconds should stay open
		// setTimeout(() => getWSService().sendPing(), 10000)
		setInterval(() => getWSService().sendPing(), 50000)
	}, [])

	const [user, setUser] = useState({})
	const [connections, setConnections] = useState([])
	const [ categories, setCategories ] = useState([])
	const [accounts, setAccounts] = useState([])
	const [userConnections, setUserConnections] = useState([])
	const [shopStVendors, setShopStVendors] = useState([])
	const [loadingMessage, setLoadingMessage] = useState('Loading..')

	const [ loadingBlocker, setLoadingBlocker ] = useState(false)
	const [ showAccountEditor, setShowAccountEditor ] = useState(false)
	const [ editConnection, setEditConnection ] = useState([])
	const [ hasError, setHasError ] = useState(null)

	const [hasFullData, setHasFullData] = useState(false)
	const [pensionSliderValues, setPensionSliderValues] = useState({})
	const [pensionStaticTotals, setPensionStaticTotals] = useState({
		retirementIncome: null,
		lumpSumValue: null,
	})
	const [showUserSignUpForm, setShowUserSignUpForm] = useState(false)
	const [ errorReportLabel, setErrorReportLabel ] = useState('Report Issue')

	const [ showToast, setShowToast ] = useState(null)
	const [ tenAfterLastAdd, setTenAfterLastAdd ] = useState(null)

	const [ currentSession, setCurrentSession ] = useState(null)
	const getCurrentSession = async () => {
		if (currentSession) return currentSession
		const newCurrentSession = await getSessionTokenAsync()
		setCurrentSession(newCurrentSession)
		return newCurrentSession
	}
	const [ loadingState, setLoadingState ] = useState({
			user: false,
			mhConnections: false,
			shopStVendors: false,
					demoAccounts: false,
					demoTransactions: false,
					demoForecasts: false,
			awsAccount: false,
		awsForecasts: false,

			mhUserConnections: false,
			mhAccounts: false,
			mhBalances: false,
			mhTransactions: false,
		mhBufferForecast: false,
		socketUserSet: false,
		mhBufferShown: false,

			savedBackToAws: false,
		shownUserManualUpdates: false,
		loadComplete: false
	})
	//const [ mhAccountBuffer, setMhAccountBuffer ] = useState([])
	let mhAccountBuffer = useRef([])
	useEffect(() => {
		(async () => {
			if (!loadingState.user){
				setLoadingMessage('Loading User..')
				return await getUser(await getCurrentSession(), (user) => {
					setUser(user)
					setLoadingState({...loadingState, user: true})
					console.log(user)
				})
			}
			if (user.termsAccepted == null || !user.termsAccepted || user.dateOfBirth == null || user.retirementAge == null || user.lumpSumPercentage == null){
				setShowUserSignUpForm(true)
				return
			} else {
				setShowUserSignUpForm(false)
			}
			if (!loadingState.mhConnections){
				setLoadingMessage('Loading Providers..')
				return await getMhConnections(await getCurrentSession(), (connections) => {
					setConnections(connections)
					setLoadingState({...loadingState, mhConnections: true})
				})
			}
			if (!loadingState.shopStVendors){
				setLoadingMessage('Loading Potential Saving..')
				return await getShopStVendors(await getCurrentSession(), (ssVendors) => {
					setShopStVendors(ssVendors)
					setLoadingState({...loadingState, shopStVendors: true})
					console.log(ssVendors)
				})
			}
			if (user.isDemo){
				if (!loadingState.demoAccounts){
					setLoadingMessage('Loading Demo Data..')
					return await getDemoData((accounts) => {
						setAccounts(accounts)
						setLoadingState({...loadingState, demoAccounts: true})
					})
				}
				if (!loadingState.demoTransactions){
					const transactions = getDemoTransactions()

					let allCategories = []
					transactions.forEach(t => {
						allCategories.push(t.categoryId)
					})
					allCategories = [...new Set(allCategories)]
					let categories = {}
					allCategories.forEach(c => {
						categories[c] = {
							name: c,
							group: c,
							displayCategory: moneyHubCategoryGrouping[c] || c,
							groupCode: c,
							categoryId: c
						}
					})
					setCategories(categories)

					setAccounts(addTransactionsToAccount(transactions, accounts, shopStVendors, categories))

					setLoadingState({...loadingState, demoTransactions: true})
					return
				}
				if (!loadingState.demoForecasts){
					setUpdateStatic(true)
					setDoForecasts({ state: accounts, setState: setAccounts })
					setLoadingState({...loadingState, demoForecasts: true})
					return
				}
			} else {
				if (!loadingState.awsAccounts){
					mhAccountBuffer.current = []
					setLoadingMessage('Loading Accounts..')
					setTimeout(() => setLoadingMessage('Loading Balances..'), 1500)
					setTimeout(() => setLoadingMessage('Loading Transactions..'), 3000)
					return await getAccounts(await getCurrentSession(), (a) => setAccounts(a), addTransactionsToAccount, shopStVendors, () => setLoadingState({...loadingState, awsAccounts: true}), categories)
				}
				if (!loadingState.awsForecasts){
					setUpdateStatic(true)
					let lastDate = null
					accounts.forEach(a => {
						if (lastDate === null || lastDate < a.dateAdded){
							lastDate = a.dateAdded
						}
					})
					setTenAfterLastAdd(addMinutes(new Date(lastDate), 5))
					setDoForecasts({ state: accounts, setState: setAccounts })
					setLoadingMessage('Forecasting Accounts..')
					setLoadingState({...loadingState, awsForecasts: true})
					return
				}
				if (!loadingState.loadComplete) setHasFullData(true)
				if (!loadingState.mhUserConnections){
					setLoadingMessage('Fetching Connections..')
					return await getUserConnections(await getCurrentSession(), user.moneyhubUser, (response) => {
						setUserConnections(response.connections.data)
						let categoryGroups = {}
						response.categoryGroups.data.forEach(cg => {
							categoryGroups[cg.id] = cg.key
						})

						let categories = {}
						response.categories.data.forEach(c => {
							categories[c.categoryId] = {
								name: c.key,
								group: categoryGroups[c.group],
								displayCategory: moneyHubCategoryGrouping[c.key] || c.key,
								groupCode: c.group,
								categoryId: c.categoryId
							}
						})

						setCategories(categories)
						setLoadingState({...loadingState, mhUserConnections: true})
					})
				}
				if (!loadingState.mhAccounts){
					setLoadingMessage('Fetching Accounts..')
					return await getMhAccounts(await getCurrentSession(), user.moneyhubUser, connections, (accounts) => {
						mhAccountBuffer.current = accounts
						setLoadingState({...loadingState, mhAccounts: true})
					})
				}
				if (mhAccountBuffer.current.length > 0 && !loadingState.mhBalances){
					setLoadingMessage('Fetching Balances..')
					let mhBalancePromises = []
					mhAccountBuffer.current.forEach(a => {
						if (a.type !== 'pension-scenario'){
							mhBalancePromises.push(getBalance(currentSession, user.moneyhubUser, a.id, (balances) => {
									let accounts = [...mhAccountBuffer.current]
									accounts.forEach(newA => {
										if (newA.id === a.id){
											newA.balances = balances
											if (newA.type === 'properties:residential'){
												if (balances.length > 1){
													newA.hasManualInput = true
												}
											}
										}
									})
									mhAccountBuffer.current = accounts
								}
							))
						}
					})
					await Promise.all(mhBalancePromises)
					setLoadingState({...loadingState, mhBalances: true})
					return
				}
				if (mhAccountBuffer.current.length > 0 && !loadingState.mhTransactions){
					setLoadingMessage('Fetching Transactions..')
					return await getMhTransactions(await getCurrentSession(), user.moneyhubUser, (transactions) => {
						mhAccountBuffer.current = addTransactionsToAccount(transactions, mhAccountBuffer.current, shopStVendors, categories)
						setLoadingState({...loadingState, mhTransactions: true})
					})
				}
				if (mhAccountBuffer.current.length > 0 && !loadingState.mhForecasts){
					setLoadingMessage('Forecasting Accounts..')
					let lastDate = null
					accounts.forEach(a => {
						if (lastDate === null || lastDate < a.dateAdded){
							lastDate = a.dateAdded
						}
					})
					setTenAfterLastAdd(addMinutes(new Date(lastDate), 5))
					setUpdateStatic(true)
					setDoForecasts({ state: mhAccountBuffer.current, setState: (a) => mhAccountBuffer.current = a })
					setLoadingState({...loadingState, mhForecasts: true})
					return
				}
				if (!loadingState.socketUserSet) {
					setLoadingMessage('Socket Loading..')
					getWSService().setUser(user)
					setLoadingState({...loadingState, socketUserSet: true})
					return
				}
				// Flip the buffer to display
				if (!loadingState.mhBufferShown) {
					setLoadingMessage('Flipping Buffer..')
					setAccounts([...mhAccountBuffer.current])
					setLoadingState({...loadingState, mhBufferShown: true})
					return
				}
				const needsData = accounts.filter(a => !a.hasManualInput && a.type !== 'pension-scenario' && a.connection != null)
				if (!loadingState.shownUserManualUpdates) {
					let connections = []//...new Set(needsData.map(a => a.connectionId))]
					needsData.forEach(a => {
						if (a.type === "properties:residential"){
							if (a.balances?.length <= 1) {
								connections.push(a.id)
								connections.push(a.connectionId)
							}
						} else {
							connections.push(a.id)
							connections.push(a.connectionId)
						}
					})
					connections = [...new Set(connections)]
					setEditConnection(connections)
					setHasError(null)
					if (connections.length > 0) {
						setShowAccountEditor(true)
						const pageNames = {
							'cash:current': '/bank-account',
							'savings': '/bank-account',
							'card': '/bank-account',
							'pension': '/pension',
							'properties:residential': '/property',
							'mortgage:repayment': '/mortgage',
							'mortgage:interestOnly': '/mortgage',
						}
						navigate(pageNames[connections[0].type])
					}
					setLoadingState({...loadingState, shownUserManualUpdates: true})
					return
				}
			}
			setHasFullData(true)
			if (!loadingState.loadComplete) setLoadingState({...loadingState, loadComplete: true})
			setShowToast(addMinutes(new Date(), 0.1))
			setTimeout(() => {setShowToast(false)}, 10000)
			if (!user.isDemo && !loadingState.savedBackToAws) {
				setLoadingState({...loadingState, savedBackToAws: true})
				await saveUserAccounts()
				return
			}
			console.log(accounts)
		})()
	}, [loadingState])
	const [ doForecasts, setDoForecasts ] = useState(null)
	const [ updateStatic, setUpdateStatic ] = useState(false)
	useEffect(() => {
		(async () => {
			if (doForecasts != null){
				setLoadingMessage('Forecasting Property..')
				await forecastProperty(moneyHubAssetTypes, user, doForecasts, () => {})
				setLoadingMessage('Forecasting Pensions..')
				await forecastPension(moneyHubAssetTypes, user, doForecasts, pensionSliderValues, pensionStaticTotals, setPensionStaticTotals, () => {}, updateStatic)
				setLoadingMessage('Forecasting Mortgages..')
				await forecastMortgage(moneyHubAssetTypes, user, doForecasts, () => {})
				setLoadingMessage('Forecasting Cash Accounts..')
				await forecastCashAccount(moneyHubAssetTypes, user, doForecasts, () => {})
				setUpdateStatic(false)
				setDoForecasts(null)
			}
		})()
	}, [doForecasts])

	useEffect(() => {
		setPensionSliderValues({
			retirementAge: user.retirementAge,
			lumpSumPercentage: user.lumpSumPercentage,
			monthlyPayment: pensionSliderValues.monthlyPayment,
			currentMonthlyPayment: user.currentMonthlyPayment
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(user)])
	useEffect(() => {
		loadingState.user && setDoForecasts({ state: accounts, setState: setAccounts })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pensionSliderValues)])

	const addMinutes = (date, diff) => {
		var initialDate = new Date(date);
		return new Date(initialDate.getTime() + diff*60000);
	}

	const reloadAccounts = (showLoading = true) => {

		setLoadingState(oldLoading => {
			return {
				...oldLoading,
				demoAccounts: false,
				demoTransactions: false,
				demoForecasts: false,
				awsAccount: false,
				awsForecasts: false,
				mhUserConnections: false,
				mhAccounts: false,
				mhBalances: false,
				mhTransactions: false,
				mhBufferForecast: false,
				socketUserSet: false,
				mhBufferShown: false,
				savedBackToAws: false,
				shownUserManualUpdates: false,
				loadComplete: false,
			}
		})
		setHasFullData(!showLoading)
	}

	const saveUserAccounts = async () => {
		let fields = [
			'id',
			'dateAdded',
			'dateModified',
			'accountName',
			'type',
			'accountType',
			'providerName',
			'providerId',
			'providerReference',
			'connectionId',
			'balance',
			'additionalBalances',
			'currency',
			'details',
			'performanceScore',
			'hasManualInput',
			'connection',
			'productName',
			'accountOpeningDate',
			'accountReference',
			'providerAccountName',
			'providerAccountId',
			'transactionData',
			'shopStAverageSaving',
		]

		const currentSession = await getSessionTokenAsync()
		let accountInfo = {}
		const today = new Date()
		const addDays = (date, days) => {
			var result = new Date(date);
			result.setDate(result.getDate() + days);
			return result;
		}
		const last180Days = addDays(today, -180)

		let accountsClone = []
		let transactions = []
		let balances = []
		accounts.forEach(a => {
			let aClone = {}
			fields.forEach(f => {
				aClone[f] = a[f]
			})
			if (!a.type.includes('-')) {
				accountsClone.push(aClone)
				const last180Transactions = a.transactions?.filter(t => new Date(t.date) >= last180Days)
				last180Transactions && transactions.push(...last180Transactions)
				a.balances?.forEach(b => {
					let bClone = {...b}
					bClone.accountId = a.id
					balances.push(bClone)
				})
			}
		})

		accountInfo.accounts = accountsClone
		accountInfo.transactions = transactions
		accountInfo.balances = balances

		// console.log(accountInfo)

		await saveAccounts(currentSession, accountInfo)
	}

	const reloadTimer = useRef(null)
	useEffect(() => {
		if (loadingBlocker){
			if (reloadTimer.current == null){
				reloadTimer.current = setTimeout(() => window.location.reload(false), 10000)
			}
		} else {
			clearTimeout(reloadTimer.current)
		}
		return () => {
			clearTimeout(reloadTimer.current)
		}
	}, [loadingBlocker])

	const getLeftCol = () => {
		return (
			<UserContext.Provider value={{user, setUser}}>
				<NavigationLargeDisplay />
			</UserContext.Provider>
		)
	}

	const getTopRow = () => {
		return (
			<NavigationSmallDisplay />
		)
	}

	const sendErrorEmail = async () => {
		const currentSession = await getSessionTokenAsync()
		await sendErrorReportEmail(currentSession, hasError, async () => {
			setErrorReportLabel('Issue Reported')
		})
	}

	const getRightCol = () => {
		return (
			<>
			<div className="p-0 d-none d-xl-block" style={{position: "relative", pointerEvents: "none"}}>
				<Row className="g-0" style={{position: "absolute", width: "100%", top: "92px", height: "3rem", zIndex: "998"}}>
					{(new Date(showToast) > new Date()) && <Col xs={12} className="text-center fw-bold shadow-sm" style={{color: "white", backgroundColor: "#198754", paddingTop: "0.75rem", paddingBottom: "0.75rem"}}>
						Accounts updated
					</Col>}
					{(new Date(tenAfterLastAdd) > new Date()) && <Col xs={12} className="text-center fw-bold shadow-sm" style={{color: "white", backgroundColor: "#FFF8F2", pointerEvents: "all"}}>
						<Button onClick={reloadAccounts} variant="warning" style={{padding: "2px", margin: "0.5rem"}}>Update Balances & Transactions</Button>
					</Col>}
				</Row>
			</div>
			<div className="p-0 d-xl-none" style={{position: "relative", pointerEvents: "none"}}>
				<Row className="g-0" style={{position: "absolute", width: "100%", height: "3rem", zIndex: "998"}}>
					{(new Date(showToast) > new Date()) && <Col xs={12} className="text-center fw-bold shadow-sm" style={{color: "white", backgroundColor: "#198754", paddingTop: "0.75rem", paddingBottom: "0.75rem"}}>
						Accounts updated
					</Col>}
					{(new Date(tenAfterLastAdd) > new Date()) && <Col xs={12} className="text-center fw-bold shadow-sm" style={{color: "white", backgroundColor: "#FFF8F2", pointerEvents: "all"}}>
						<Button onClick={reloadAccounts} variant="warning" style={{padding: "2px", margin: "0.5rem"}}>Update Balances & Transactions</Button>
					</Col>}
				</Row>
			</div>

			{hasFullData && !loadingBlocker && (
				<UserContext.Provider value={{
						user, setUser,
						setLoadingMessage, setLoadingBlocker,
						showAccountEditor, setShowAccountEditor,
						loadingState, setLoadingState,
						setDoForecasts,
						connections,
						categories,
						accounts, setAccounts,
						shopStVendors,
						reloadAccounts,
						pensionSliderValues, setPensionSliderValues,
						pensionStaticTotals, setPensionStaticTotals,
						userConnections,
						setUpdateStatic }}>

					{/* {loadingMessage} */}
					<Routes>
						<Route exact path="/" element={<Home />} />
						<Route path="/spending-insights" element={<SpendingAnalysis />} />
						<Route path="/shop-st-savings" element={<ShopStSaving />} />
						<Route path="/property" element={<Property />} />
						<Route path="/pension" element={<Pension />} />
						<Route path="/bank-account" element={<BankAccounts />} />
						<Route path="/mortgage" element={<Mortgage />} />
						<Route path="/my-details" element={<MyAccount />} />
						<Route path="/manage-accounts" element={<ManageConnections />} />
						<Route path="/help" element={<Help />} />
						<Route path="/add-asset" element={<AddAsset />} />
						{user.isClientAdmin &&
							<>
								<Route exact path="/dashboard" element="Client HR Dashboard" />
							</>
						}
						{user.isAdmin &&
							<>
								<Route exact path="/admin" element="Admin Page" />
								<Route path="/admin/add-users" element={<AddUsers />} />
								<Route path="/admin/house-price-index" element="HPI Page" />
								<Route path="/admin/pension-returns" element="Pension Returns Page" />
							</>
						}
					</Routes>
					{!user.isDemo && <Modal show={showAccountEditor} centered dialogClassName="modal-60w modal-max-w-700">
						<Modal.Header closeButton onHide={()=>setShowAccountEditor(false)}>
							<Modal.Title>Update Account Info</Modal.Title>
						</Modal.Header>
						<Modal.Body className="modal-max-height">
							<ConnectionDetails type={['cash:current', 'savings', 'card', 'mortgage:repayment', 'mortgage:interestOnly', 'properties:residential', 'pension']} allowedConnections={editConnection} autoLaunched={true} />
						</Modal.Body>
					</Modal>}
				</UserContext.Provider>
			)}
			{!hasFullData && !showUserSignUpForm && (
				<Modal centered show>
					<Modal.Body>
						<div className="text-center position-relative">
							<div className="mt-2">
								<Spinner className="center" animation="border" role="status">
									<span className="visually-hidden">Loading..</span>
								</Spinner>
							</div>
							<div className="mt-3">
								{loadingMessage}
							</div>
						</div>
					</Modal.Body>
				</Modal>
			)}
			{loadingBlocker && (
				<Modal centered show>
					<Modal.Header closeButton onHide={() => window.location.reload(false)}>

					</Modal.Header>
					<Modal.Body>
						<div className="text-center position-relative">
							<div className="mt-2">
								<Spinner className="center" animation="border" role="status">
									<span className="visually-hidden">Loading..</span>
								</Spinner>
							</div>
							<div className="mt-3">
								Loading..
							</div>
						</div>
					</Modal.Body>
				</Modal>
			)}
			{showUserSignUpForm && (
				<UserSignUpForm
						user={user}
						setShowUserSignUpForm={setShowUserSignUpForm}
						setLoadingState={setLoadingState} />
			)}
			{hasError != null && (
				<Modal centered show>
					<Modal.Header>
						Prift Error
					</Modal.Header>
					<Modal.Body>
						<div className="my-2">
							An error has occurred. We are working hard to resolve this as soon as possible. For further information please email:
						</div>
						<div className="my-2 text-center">
							support@prift.co
						</div>
						<Accordion defaultActiveKey={"0"} className="my-2">
							<Accordion.Item eventKey="1">
								<Accordion.Header className="fs-5">More Details</Accordion.Header>
								<Accordion.Body className="fs-6">{hasError}</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</Modal.Body>
					<Modal.Footer>
						{/* TODO: Email the error message to support */}
						<Button disabled={errorReportLabel !== 'Report Issue'} variant="secondary" onClick={sendErrorEmail}>{errorReportLabel}</Button>
						<Button onClick={() => navigate('/logout')}>Logout</Button>
					</Modal.Footer>
				</Modal>
			)}
			{/* {showToast && <ToastContainer position="top-center" style={{ zIndex: 1, marginTop: "50px" }}>
				<Toast onClose={() => setShowToast(false)} show={showToast} delay={4000} autohide style={{ color: 'white', backgroundColor: '#3377FF'}}>
					<Toast.Body>
						Accounts updated
					</Toast.Body>
				</Toast>
			</ToastContainer>} */}
			</>
		)
	}

	return (
		<>
			<TwoColumnLayout top={getTopRow()} left={getLeftCol()} right={getRightCol()} />
		</>
	)
}

export default Main;